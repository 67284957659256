import { Card } from '@amzn/stencil-react-components/card';
import { Checkbox } from '@amzn/stencil-react-components/dist/submodules/form';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { H3, Label, Text } from '@amzn/stencil-react-components/text';
import { CancelTokenSource } from 'axios';
import i18n from 'i18next';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import React, { Component, Dispatch } from 'react';
import { isDesktop } from 'react-device-detect';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import {
    SubmitCovidTestLiteRequest,
    submitCovidTestLiteRequestFrom
} from 'src/apps/vaccination/models/apiRequest/submitCovidTestLiteRequest';
import {
    createSubmitCovidTestLiteAction,
    createGetCardStatusSuccessAction,
    updateCovidTestLiteRecordAction,
} from '../../../actions/vaccinationBonusActions';
import { withClient } from 'src/contexts/clientContext';
import { ILoggerProps, withLogger } from 'src/logger';
import { OmnitureHelper } from 'src/models/omnitureHelper';
import { ApiClientsProps } from 'src/models/ApiClients';
import { CardType } from '../../../models/apiRequest/cardType';
import { InitiateSubmitCardRequest } from '../../../models/apiRequest/initiateSubmitCardRequest';
import { AppState } from 'src/models/appState';
import { CardStatus } from '../../../models/vaccination/getCardsStatusResponse';
import { GetVaccinationCardStatusResponse } from '../../../models/vaccination/getVaccinationCardStatusResponse';
import {
    defaultCovidTestLiteRecord,
    isRecordFilled,
    isRecordFilledWithoutImage,
    CovidTestLiteRecord,
    CovidTestLiteResult,
} from '../../../models/vaccination/covidTestLiteRecord';
import { ROUTES } from 'src/routes';
import { PrimaryButton, TertiaryButton } from '../../__shared__/button/Button';
import { CardUploader } from '../__shared__/CardUploader';
import { ConfirmationModal } from '../__shared__/ConfirmationModal';
import { ErrorStatusIndicator } from '../__shared__/ErrorStatusIndicator';
import { ProcessingSpinner } from '../__shared__/ProcessingSpinner';
import { dateDisplayOptions } from '../VaccinationBonusPage';
import { ErrorBannerWithRejectReasons } from './ErrorBannerWithRejectReasons';
import { PreviewImageSection } from './PreviewImageSection';
import { EmployeeInfo } from 'src/contexts/EmployeeInfoContext';
import { fileTypeFromBlob } from "file-type";
import { sendTelemetryEvent } from 'src/utils/telemetry/TelemetryHelper';

const PAGE_NAME = 'CovidTestLiteReview';
const CARD_STATUS_TIMEOUT_MS = 30000;

interface Props extends RouteComponentProps, ILoggerProps, ApiClientsProps {
    record: CovidTestLiteRecord;
    updateCovidTestLiteRecord: (record: CovidTestLiteRecord) => void;
    submitCovidTestLite: (request: SubmitCovidTestLiteRequest) => void;
    updateCardStatusResponse: (cardType: CardType, response: GetVaccinationCardStatusResponse) => void;
    isSubmittingCovidTestLite: boolean;
    isSubmittingCovidTestLiteSuccess: boolean;
    isSubmittingCovidTestLiteFailure: boolean;
}

interface State {
    isConsent: boolean;
    highlightEmptyFields: boolean;
    isUploadingImage: boolean;
    isUploadingImageSuccess: boolean;
    isUploadingImageFailure: boolean;
    cancelTokenSource: CancelTokenSource | undefined;
    errorBannerMessageId: string | undefined;
    rejectReasons: string[];
    cardStatusResponse: GetVaccinationCardStatusResponse | undefined;
    confirmCancelModalVisible: boolean;
}

const initialState: State = {
    isConsent: false,
    highlightEmptyFields: false,
    isUploadingImage: false,
    isUploadingImageSuccess: false,
    isUploadingImageFailure: false,
    cancelTokenSource: undefined,
    errorBannerMessageId: undefined,
    rejectReasons: [],
    cardStatusResponse: undefined,
    confirmCancelModalVisible: false,
};

export class _CovidTestLiteReview extends Component<Props, State> {
    static contextType = EmployeeInfo;

    state: State = initialState;

    componentDidMount() {
        const fullyFilled = isRecordFilled(this.props.record);
        if (!fullyFilled) {
            this.props.history.push(ROUTES.VACCINATION.COVID_TEST_LITE.ADD_DETAILS.path);
        }
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ) {
        this.handleSuccessSubmit(prevProps);
        this.handleFailedSubmit(prevProps);
    }

    handleConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ isConsent: e?.target?.checked });
    handleEditDetails = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.props.history.push(ROUTES.VACCINATION.COVID_TEST_LITE.ADD_DETAILS.path);
    };
    handleEditImage = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.props.history.push(ROUTES.VACCINATION.COVID_TEST_LITE.ADD_IMAGE.path);
    };

    handleSubmit = async () => {
        if (! this.state.isConsent) {
            this.setState({ highlightEmptyFields: true } );
            return;
        }

        try {
            const cardStatusResponse = await this.submitCardImage();
            if (cardStatusResponse && this.state.isUploadingImageSuccess) {
                this.submitTest(cardStatusResponse.imagePath);
            }
        } catch (err) {
            this.props.logger.error(`${PAGE_NAME}: Couldn't submit CovidTestLite result, err:`, err);
            this.cancelUpload('genericErrorMessage');
            return;
        }
    };

    handleCancelConfirmed = () => {
        this.resetRecord();
        this.props.history.push(ROUTES.VACCINATION.path);
    };

    handleSuccessSubmit = (prevProps: Props) => {
        if (!this.props.isSubmittingCovidTestLiteSuccess || prevProps.isSubmittingCovidTestLiteSuccess) {
            return;
        }

        window.scrollTo(0, 0);

        if (this.state.cardStatusResponse?.status === CardStatus.REJECTED) {
            // stay here with error banner
        } else if (this.props.record.result === CovidTestLiteResult.POSITIVE) {
            this.resetRecord();
            this.props.history.push(ROUTES.VACCINATION.COVID_TEST_LITE.POSITIVE_RESULT.path);
        } else {
            this.resetRecord();
            this.props.history.push(ROUTES.VACCINATION.path);
        }
    };

    handleFailedSubmit = (prevProps: Props) => {
        if (!this.props.isSubmittingCovidTestLiteFailure || prevProps.isSubmittingCovidTestLiteFailure) {
            return;
        }

        this.setErrorMessage('genericErrorMessage');
    };

    render() {
        const isConsentError = this.state.highlightEmptyFields && !this.state.isConsent;

        return (
            <div className="test-attestation-form">
                <View maxWidth="480px" minWidth="320px" margin="0 auto" padding="20px 15px" backgroundColor="neutral0">
                    <ErrorBannerWithRejectReasons
                        messageId={this.state.errorBannerMessageId}
                        rejectReasons={this.state.rejectReasons}
                        onDismissed={this.resetErrorMessage}
                    />

                    <Col>
                        <H3 fontWeight="bold">
                            {i18n.t('covidTestLite.testInfoSummaryForm.title')}
                        </H3>
                        <Spacer height={20}/>
                        <Text>{i18n.t('covidTestLite.testInfoSummaryForm.subTitle')}</Text>
                    </Col>

                    <Spacer height={32}/>

                    <Card>
                        <Col gridGap="S200" width={'100%'}>
                            <Text fontWeight="bold">{i18n.t('covidTestLite.testInfoSummaryForm.details.title')}</Text>
                            <Row gridGap="S100">
                                <Text>{i18n.t('covidTestLite.testInfoSummaryForm.details.result')}</Text>
                                <Text fontWeight="bold">{i18n.t(`covidTestLite.testInfoForm.testRadio.${this.props.record.result}`)}</Text>
                            </Row>
                            <Row gridGap="S100">
                                <Text>{i18n.t('covidTestLite.testInfoSummaryForm.details.collectionDate')}</Text>
                                <Text fontWeight="bold">{this.props.record.collectionDate && this.toLocaleDateString(this.props.record.collectionDate)}</Text>
                            </Row>
                            <TertiaryButton isFullWidth={true} onClick={this.handleEditDetails}>
                                <Text fontSize="T400" fontWeight="regular">{i18n.t('covidTestLite.testInfoSummaryForm.editButton')}</Text>
                            </TertiaryButton>
                        </Col>
                    </Card>

                    <Spacer height={20}/>
                    <Card>
                        <Col gridGap="S200" width="100%">
                            <Text fontWeight="bold">{i18n.t('covidTestLite.testInfoSummaryForm.imageTitle')}</Text>
                            <PreviewImageSection
                                imageSrc={this.props.record.imageSrc}
                                imageAlt={i18n.t('covidTestLite.fileUploadForm.cardPreviewAlt')}
                                enlargeButtonTitle={i18n.t('covidTestLite.fileUploadForm.enlarge')}
                            />
                            <TertiaryButton isFullWidth={true} onClick={this.handleEditImage}>
                                <Text fontSize="T400" fontWeight="regular">{i18n.t('covidTestLite.testInfoSummaryForm.editButton')}</Text>
                            </TertiaryButton>
                        </Col>
                    </Card>

                    <Spacer height={20}/>

                    <Col>
                        <Label>
                            <Row alignItems="top" gridGap="S300">
                                <Checkbox checked={this.state.isConsent} required={true} onChange={this.handleConsentChange}
                                          error={isConsentError} />
                                <Label>{i18n.t('covidTestLite.testInfoSummaryForm.consent')}</Label>
                            </Row>
                        </Label>
                        {isConsentError && <ErrorStatusIndicator message={i18n.t('covidTestLite.errorTooltip.checkConsent')}/>}
                    </Col>

                    <Spacer height={20}/>

                    <Col>
                        <PrimaryButton isFullWidth={true} onClick={this.handleSubmit}>
                            <Text fontSize="T400">{i18n.t('covidTestLite.testInfoSummaryForm.submitButton')}</Text>
                        </PrimaryButton>
                    </Col>

                    {isDesktop && <>
                        <Spacer height={20}/>
                        <Col>
                            <TertiaryButton isFullWidth={true} onClick={() => this.setState({ confirmCancelModalVisible: true })}>
                                <Text>{i18n.t('covidTestLite.testInfoSummaryForm.cancelButton')}</Text>
                            </TertiaryButton>

                            <ConfirmationModal
                                isOpen={this.state.confirmCancelModalVisible}
                                title={i18n.t('covidTestLite.confirmCancelModal.title')}
                                text={i18n.t('covidTestLite.confirmCancelModal.text')}
                                discardText={i18n.t('covidTestLite.confirmCancelModal.goBackButton')}
                                confirmText={i18n.t('covidTestLite.confirmCancelModal.confirmButton')}
                                onConfirmClick={this.handleCancelConfirmed}
                                onClose={() => this.setState({ confirmCancelModalVisible: false })}
                            />
                        </Col>
                    </>}

                    <ProcessingSpinner
                        title={i18n.t('covidTestLite.testInfoSummaryForm.processingTitle')}
                        blurb={i18n.t('covidTestLite.testInfoSummaryForm.processingBlurb')}
                        isProcessing={this.state.isUploadingImage || this.props.isSubmittingCovidTestLite}
                        onHide={() => this.cancelUpload('userCancelled')}
                    />
                </View>
            </div>
        );
    }

    private toLocaleDateString = (dateTime: DateTime): string => {
        return dateTime.toLocaleString(dateDisplayOptions as DateTimeFormatOptions, { locale: this.context.locale });
    };

    private submitTest = (imagePath?: string) => {
        const record: CovidTestLiteRecord = {
            ...this.props.record,
            imagePath: imagePath,
        };
        this.props.submitCovidTestLite(submitCovidTestLiteRequestFrom(record, this.context.employeeId));
    };

    // returns S3 image path or `undefined` in case of error
    private submitCardImage = async (): Promise<GetVaccinationCardStatusResponse | undefined> => {
        const uploader = new CardUploader({
            logger: this.props.logger,
            loggerPrefix: PAGE_NAME,
            client: this.props.clients.campaignsClientSDK,
        });

        this.setState({
            isUploadingImage: true,
            isUploadingImageSuccess: false,
            isUploadingImageFailure: false,
        });

        const saveCardStatusResponse = (response: GetVaccinationCardStatusResponse) => {
            this.setState({
                cardStatusResponse: response,
                isUploadingImageSuccess: true,
            });
            this.props.updateCardStatusResponse(cardType, response);
        };

        const cardType = CardType.CovidTestLite;

        const initiateRequest = new InitiateSubmitCardRequest(this.context.employeeId, 'unknown', this.context.countryCode, cardType);

        const imageFile = this.props.record.imageFile;
        const mimeType = imageFile != undefined ? await fileTypeFromBlob(imageFile) : undefined;
        const mimeTypeString = mimeType?.mime || this.props.record.imageFile?.type || "";

        const imageSrc = this.props.record.imageSrc;

        const preserveImageFile = () => {
            this.props.record.imageFile = imageFile;
            this.props.record.imageSrc = imageSrc;
            this.props.updateCovidTestLiteRecord(this.props.record);
        }

        return await uploader.uploadCard({
            file: imageFile,
            mimeType: mimeTypeString,
            initiateRequest: initiateRequest,
            cardStatusTimeoutMs: CARD_STATUS_TIMEOUT_MS,
            onRememberCancelToken: (token) => {
                this.setState({ cancelTokenSource: token });
            },
            onCancelled: () => {
                this.cancelUpload('userCancelled');
            },
            onError: (exception?: any) => {
                this.props.logger.error(`Couldn't upload image, exception ${exception}`);
                this.setState({ isUploadingImageFailure: true });
                this.cancelUpload('genericErrorMessage');
            },
            onCardStatusTimeout: () => {
                this.setState({ isUploadingImageFailure: true });
                this.props.updateCovidTestLiteRecord(this.props.record);
            },
            onCardHung: (response: GetVaccinationCardStatusResponse) => {
                saveCardStatusResponse(response);
                preserveImageFile();
            },
            onCardRejected: (response: GetVaccinationCardStatusResponse, reasons: string[]) => {
                saveCardStatusResponse(response);
                this.cancelUpload('cardRejected', reasons);
                preserveImageFile();
            },
            onCardApproved: (response: GetVaccinationCardStatusResponse) => {
                OmnitureHelper.setOmniturePageName(`${PAGE_NAME} - Submit Card finished success`);
                sendTelemetryEvent(PAGE_NAME, `${PAGE_NAME} - Submit Card finished success`);
                saveCardStatusResponse(response);
            },
        });
    };

    private cancelUpload = (errorMessageId?: string, rejectionReasons?: string[]) => {
        this.state.cancelTokenSource?.cancel(`${errorMessageId}`);

        this.setState({
            isUploadingImage: false,
            cancelTokenSource: undefined,
        });

        if (errorMessageId) {
            this.setErrorMessage(errorMessageId, rejectionReasons);
        }
    };

    private setErrorMessage = (messageId: string, rejectionReasons?: string[]) => {
        this.setState({
            errorBannerMessageId: messageId,
            rejectReasons: rejectionReasons || this.state.rejectReasons,
        });
        window.scrollTo(0, 0);  // scroll to error message banner
    };

    private resetErrorMessage = () => this.setState({ errorBannerMessageId: undefined, rejectReasons: [] });

    private resetState = () => this.setState(initialState);
    private resetRecord = () => this.props.updateCovidTestLiteRecord(defaultCovidTestLiteRecord);
    private resetAll = () => {
        this.resetState();
        this.resetRecord();
    }
}

const mapStateToProps = ({ vaccination }: AppState) => {
    return {
        record: vaccination.covidTestLiteLatestRecord,
        isSubmittingCovidTestLite: vaccination.isSubmittingCovidTestLite,
        isSubmittingCovidTestLiteSuccess: vaccination.isSubmittingCovidTestLiteSuccess,
        isSubmittingCovidTestLiteFailure: vaccination.isSubmittingCovidTestLiteFailure,
    } as Props
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    updateCovidTestLiteRecord: (record: CovidTestLiteRecord) => dispatch(updateCovidTestLiteRecordAction(record)),
    submitCovidTestLite: (request: SubmitCovidTestLiteRequest) => dispatch(createSubmitCovidTestLiteAction(request)),
    updateCardStatusResponse: (cardType: CardType, response: GetVaccinationCardStatusResponse) =>
        dispatch(createGetCardStatusSuccessAction(cardType, response)),
} as Props);

export const CovidTestLiteReview1 =
    connect(mapStateToProps, mapDispatchToProps)(withClient(withLogger(withRouter(_CovidTestLiteReview))));
