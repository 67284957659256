import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { H3, Text } from '@amzn/stencil-react-components/text';
import { IconInformationFill, IconSize } from '@amzn/stencil-react-components/icons';
import i18n from 'i18next';
import React, { Component, Dispatch } from 'react';
import { isDesktop } from 'react-device-detect';
import { connect } from 'react-redux';
import { BrowserRouterProps, RouteComponentProps, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import {
  CovidTestLiteRecord,
  defaultCovidTestLiteRecord, isRecordFilledWithoutImage
} from 'src/apps/vaccination/models/vaccination/covidTestLiteRecord';
import {
  updateCovidTestLiteRecordAction,
} from '../../../actions/vaccinationBonusActions';
import { withClient } from 'src/contexts/clientContext';
import { ILoggerProps, withLogger } from 'src/logger';
import { AppState } from 'src/models/appState';
import { ROUTES } from 'src/routes';
import { PrimaryButton, TertiaryButton } from '../../__shared__/button/Button';
import { ConfirmationModal } from '../__shared__/ConfirmationModal';
import { ErrorStatusIndicator } from '../__shared__/ErrorStatusIndicator';
import { ErrorBannerWithRejectReasons } from './ErrorBannerWithRejectReasons';
import { FileUploadForm } from './FileUploadForm';
import './styles.scss';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

export interface CovidTestLiteAddImageProps extends RouteComponentProps, ILoggerProps, BrowserRouterProps {
  record: CovidTestLiteRecord;
  updateCovidTestLiteRecord: (record: CovidTestLiteRecord) => void;
}

interface CovidTestLiteAddImageState extends CovidTestLiteRecord {
  highlightEmptyFields: boolean;
  errorBannerMessageId: string | undefined;
  confirmCancelModalVisible: boolean;
}

export class _CovidTestLiteAddImage extends Component<CovidTestLiteAddImageProps, CovidTestLiteAddImageState> {
  state: CovidTestLiteAddImageState = {
    ...this.props.record,

    highlightEmptyFields: false,
    errorBannerMessageId: undefined,
    confirmCancelModalVisible: false,
  };

  componentDidMount(): void {
    if (!isRecordFilledWithoutImage(this.state)) {
      this.props.history.push(ROUTES.VACCINATION.path);
    }
  }

  dismissErrorMessage = () => this.setState({ errorBannerMessageId: undefined });

  setImage = (file: File | undefined, imageSrc: string) => {
    this.setState({
      imageFile: file,
      imageSrc: imageSrc,
      errorBannerMessageId: undefined,
    });
  }

  handleContinue = (event: any) => {
    if (!this.state.imageFile) {
      this.setState({
        highlightEmptyFields: true,
        errorBannerMessageId: 'fieldsAreUnfilled',
      });
      window.scrollTo(0, 0);
      return;
    }

    this.props.updateCovidTestLiteRecord(this.state);
    this.props.history.push(ROUTES.VACCINATION.COVID_TEST_LITE.REVIEW.path);
    window.scrollTo(0, 0);
  };

  handleCancelConfirmed = () => {
    this.props.updateCovidTestLiteRecord(defaultCovidTestLiteRecord);
    this.props.history.push(ROUTES.VACCINATION.path);
  };

  render() {
    const isImageNotTakenError = this.state.highlightEmptyFields && !this.state.imageFile;

    return (
      <div className="test-attestation-form">
        <View maxWidth="480px" minWidth="320px" margin="0 auto" padding="20px 15px" backgroundColor="neutral0">
          <ErrorBannerWithRejectReasons
            messageId={this.state.errorBannerMessageId}
            onDismissed={this.dismissErrorMessage}
          />

          <Col>
            <H3 fontWeight="bold">
              {i18n.t('covidTestLite.addImageForm.title')}
            </H3>
            <Spacer height={10}/>
            <Text>{i18n.t('covidTestLite.addImageForm.subTitle')}</Text>
          </Col>

          <Spacer height={20}/>

          <Col>
            <FileUploadForm
              imageFile={this.state.imageFile}
              imageSrc={this.state.imageSrc}
              setImage={this.setImage}
              setCardError={this.setCardError}
            />
            {isImageNotTakenError && <ErrorStatusIndicator message={i18n.t('covidTestLite.errorTooltip.provideImage')} /> }
          </Col>

          <Spacer height={20}/>

          <Row gridGap="S200" color={'neutral60'}>
            <Col padding={'3px 0 0 0'}>
              <IconInformationFill size={IconSize.ExtraSmall} aria-hidden={true} />
            </Col>
            <Text>
              <b>{i18n.t('covidTestLite.addImageForm.checkReadabilityTitle')}</b>{' '}
              {i18n.t('covidTestLite.addImageForm.checkReadabilitySubtitle')}
            </Text>
          </Row>

          <Spacer height={20}/>

          <Col>
            <PrimaryButton
              isFullWidth={true}
              onClick={this.handleContinue}
              data-omniture-link="Covid Test Lite Reporting - Continue to Review"
              data-pxt-telemetry-events={describeTelemetryClickEvent('Covid Test Lite Reporting - Continue to Review')}
            >
              <Text>{i18n.t('covidTestLite.addImageForm.continueButton')}</Text>
            </PrimaryButton>
          </Col>

          {isDesktop && <>
            <Spacer height={20}/>
            <Col>
              <TertiaryButton isFullWidth={true} onClick={() => this.setState({ confirmCancelModalVisible: true })}>
                <Text>{i18n.t('covidTestLite.addImageForm.cancelButton')}</Text>
              </TertiaryButton>

              <ConfirmationModal
                isOpen={this.state.confirmCancelModalVisible}
                title={i18n.t('covidTestLite.confirmCancelModal.title')}
                text={i18n.t('covidTestLite.confirmCancelModal.text')}
                discardText={i18n.t('covidTestLite.confirmCancelModal.goBackButton')}
                confirmText={i18n.t('covidTestLite.confirmCancelModal.confirmButton')}
                onConfirmClick={this.handleCancelConfirmed}
                onClose={() => this.setState({ confirmCancelModalVisible: false })}
              />
            </Col>
          </>}
        </View>
      </div>
    );
  }

  private setCardError = (messageId: string, rejectionReasons?: string[]) =>
    this.setState({
      imageFile: undefined,
      imageSrc: '',
      errorBannerMessageId: messageId,
    });

}

export const mapStateToProps = (state: AppState) => ({
  record: state.vaccination.covidTestLiteLatestRecord,
} as CovidTestLiteAddImageProps);

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateCovidTestLiteRecord: (record: CovidTestLiteRecord) => dispatch(updateCovidTestLiteRecordAction(record)),
} as CovidTestLiteAddImageProps);

export const CovidTestLiteAddImage =
  connect(mapStateToProps, mapDispatchToProps)(withClient(withLogger(withRouter(_CovidTestLiteAddImage))));
