import * as React from 'react';
import i18n from 'i18next';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { get as lodashGet } from 'lodash';
import { CovidTestLiteAddImage } from 'src/apps/vaccination/components/vaccination/CovidTestLite/CovidTestLiteAddImage';
import { CovidTestLiteInfoForm } from 'src/apps/vaccination/components/vaccination/CovidTestLite/CovidTestLiteInfoForm';
import {
  CovidTestLitePositiveResult
} from 'src/apps/vaccination/components/vaccination/CovidTestLite/CovidTestLitePositiveResult';
import { CovidTestLiteReview1 } from 'src/apps/vaccination/components/vaccination/CovidTestLite/CovidTestLiteReview';
import { ROUTES } from '../../../../routes';
import { NoMatch } from '../../../../components/NoMatch';
import { VaccinationBonusLanding } from './VaccinationBonusLanding';
import { VaccinationBonusRequest } from './VaccinationBonusRequest';
import { VaccinationBonusConfirm } from './VaccinationBonusConfirm';
import { VaccinationBonusFAQ } from './VaccinationBonusFAQ';
import { VaccinationProofPage } from './VaccinationProofPage';
import { VaccinationScanCardPage } from './VaccinationScanCard/VaccinationScanCard';
import { GetVaccinationCardStatusResponse } from '../../models/vaccination/getVaccinationCardStatusResponse';
import { VaccinationSubmissions } from './VaccinationSubmissions';
import { TestAttestationInfoForm } from './TestAttestation/TestAttestationInfoForm';
import { TestAttestationAddImage } from './TestAttestation/TestAttestationAddImage';
import { TestAttestationPositiveResult } from './TestAttestation/TestAttestationPositiveResult';
import { TestAttestationReview } from './TestAttestation/TestAttestationReview';
import { CardType } from '../../models/apiRequest/cardType';
import { ReturnToWork } from './TestAttestation/ReturnToWork';
import { EmployeeInfo } from '../../../../contexts/EmployeeInfoContext';
import { useEffect } from 'react';
import { METRIC_NAME, metricsPublisher } from 'src/metrics';
import { isPopstarFeatureEnabled } from '../../../../models/EmployeeInfoRecord';
import { VaccinationFeatures } from '../../constant/vaccinationFeatures';

export interface VaccinationBonusPageProps extends RouteComponentProps {
  isInitiatingCardUpload: boolean;
  isInitiatingCardUploadFailed: boolean;

  isLoadingFromBackend: boolean;

  getVaccinationBonus: () => void;
  getCovidTestLiteRecords: () => void;
  getTestAttestations: () => void;
  getVaccinationUpdates: () => void;
  getVaccinationCountryConfig: () => void;
  getVaccinationCardStatus: () => void;
  setVaccinationCardStatus: (status: GetVaccinationCardStatusResponse) => void;
  vaccinationCardUpdatedAt: number;
  isInitialLoad: boolean;

  anyBoosterSubmitted: boolean;
  boosterCardUpdatedAt: number;
  getBoosterCardStatus: () => void;
  setBoosterCardStatus: (status: GetVaccinationCardStatusResponse) => void;
  getFirstDoseCardStatus: () => void;
  setFirstDoseCardStatus: (status: GetVaccinationCardStatusResponse) => void;
  getTestAttestationCardStatus: () => void;
  firstDoseCardUpdatedAt: number;
  getSurveyStatus: () => void;
  getVaccinationCompliance: () => void;
}

const LatencyMetric = () => {
  useEffect(() => {
    metricsPublisher.emitLatencyMetric(METRIC_NAME.APP_LATENCY_CF, window?.performance?.now());
  }, []);

  return (null);
}

export const dateDisplayOptions = { year: 'numeric', month: 'short', day: 'numeric' };
export class VaccinationBonusPage extends React.Component<VaccinationBonusPageProps, {}> {
  static contextType = EmployeeInfo;
  constructor(props: VaccinationBonusPageProps) {
    super(props);
    this.renderVaccinationBonusLanding = this.renderVaccinationBonusLanding.bind(this);
    this.renderVaccinationProof = this.renderVaccinationProof.bind(this);
    this.renderFAQ = this.renderFAQ.bind(this);
    this.renderScanCard = this.renderScanCard.bind(this);
    this.renderUploadBoosterCard = this.renderUploadBoosterCard.bind(this);
    this.renderUploadFirstDoseCard = this.renderUploadFirstDoseCard.bind(this);
  }

  componentDidMount() {
    this.props.getVaccinationCountryConfig();
    this.props.getVaccinationBonus();
    this.props.getCovidTestLiteRecords();
    this.props.getTestAttestations();
    this.props.getVaccinationUpdates();
    this.props.getVaccinationCompliance();
    if (isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)) {
      this.props.getVaccinationCardStatus();
      if (isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_BOOSTERS)
        && this.props.anyBoosterSubmitted) {
        this.props.getBoosterCardStatus();
      }
      this.props.getFirstDoseCardStatus()
    }
    if (isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_TEST_ATTESTATION)) {
      this.props.getTestAttestationCardStatus();
    }
    this.props.getSurveyStatus();
  }

  componentDidUpdate(prevProps: VaccinationBonusPageProps) {
    if (isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_BOOSTERS) && prevProps.anyBoosterSubmitted !== this.props.anyBoosterSubmitted && this.props.anyBoosterSubmitted) {
      this.props.getBoosterCardStatus();
    }
  }

  render() {
    return (
      <>
        <Switch>
          <Route path={ROUTES.VACCINATION.path} exact={true} render={this.renderVaccinationBonusLanding} />
          <Route path={ROUTES.VACCINATION.REQUEST.path} exact={true} component={VaccinationBonusRequest} />
          {isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_VACCINE_BADGE) && (
            <Route path={ROUTES.VACCINATION.PROOF.path} exact={true} render={this.renderVaccinationProof} />
          )}
          <Route path={ROUTES.VACCINATION.CONFIRM.path} exact={true} component={VaccinationBonusConfirm} />
          {this.hasFAQcontent() && !isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_NO_BENEFITS) && (
            <Route path={ROUTES.VACCINATION.FAQ.path} exact={true} render={this.renderFAQ} />
          )}
          {isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD) && (
            <Route path={ROUTES.VACCINATION.SUBMIT_CARD.path} exact={true} render={this.renderScanCard} />
          )}
          {isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
            && isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_BOOSTERS)
            && this.props.anyBoosterSubmitted && (
              <Route path={ROUTES.VACCINATION.BOOSTER_CARD.path} exact={true} render={this.renderUploadBoosterCard} />
            )}
          {isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
            && isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_FIRSTDOSE_CARD_UPLOAD) && (
              <Route path={ROUTES.VACCINATION.FIRSTDOSE_CARD.path} exact={true} render={this.renderUploadFirstDoseCard} />
            )}
          <Route path={ROUTES.VACCINATION.SUBMISSIONS.path} component={VaccinationSubmissions} />
          {isPopstarFeatureEnabled(this.context, VaccinationFeatures.COVID_TEST_LITE) && (<>
            <Route exact={true} path={ROUTES.VACCINATION.COVID_TEST_LITE.ADD_DETAILS.path} component={CovidTestLiteInfoForm} />
            <Route exact={true} path={ROUTES.VACCINATION.COVID_TEST_LITE.ADD_IMAGE.path} component={CovidTestLiteAddImage} />
            <Route exact={true} path={ROUTES.VACCINATION.COVID_TEST_LITE.REVIEW.path} component={CovidTestLiteReview1} />
            <Route exact={true} path={ROUTES.VACCINATION.COVID_TEST_LITE.POSITIVE_RESULT.path} component={CovidTestLitePositiveResult} />
          </>)}
          {isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_TEST_ATTESTATION) && (
            <>
              <Route exact={true} path={ROUTES.VACCINATION.TEST_ATTESTATION.path} component={TestAttestationInfoForm} />
              <Route exact={true} path={ROUTES.VACCINATION.TEST_ATTESTATION.ADD_IMAGE.path} component={TestAttestationAddImage} />
              <Route exact={true} path={ROUTES.VACCINATION.TEST_ATTESTATION.REVIEW.path} component={TestAttestationReview} />
              <Route exact={true} path={ROUTES.VACCINATION.TEST_ATTESTATION.POSITIVE_RESULT.path} component={TestAttestationPositiveResult} />
              <Route exact={true} path={ROUTES.VACCINATION.TEST_ATTESTATION.RETURN_TO_WORK.path} component={ReturnToWork} />
            </>
          )}
          <Route component={NoMatch} />
        </Switch>
        <LatencyMetric />
      </>
    );
  }

  private renderVaccinationBonusLanding(): JSX.Element {
    return (
      <VaccinationBonusLanding
        hasFAQcontent={this.hasFAQcontent()}
        submitSurveyResponse={lodashGet(this.props.location, 'state.survey', false)}
      />
    );
  }

  private renderFAQ(): JSX.Element {
    return <VaccinationBonusFAQ countryCode={this.context.countryCode} />;
  }

  private renderScanCard(): JSX.Element {
    return (
      <VaccinationScanCardPage
        employeeId={this.context.employeeId}
        countryCode={this.context.countryCode}
        isInitiatingCardUpload={this.props.isInitiatingCardUpload}
        isInitiatingCardUploadFailed={this.props.isInitiatingCardUploadFailed}
        isInitialLoad={this.props.isInitialLoad}
        getVaccinationCardStatus={this.props.getVaccinationCardStatus}
        setVaccinationCardStatus={this.props.setVaccinationCardStatus}
        vaccinationCardUpdatedAt={this.props.vaccinationCardUpdatedAt}
        cardType={CardType.Vaccine}
        getComplianceStatus={this.props.getVaccinationCompliance}
      />
    );
  }

  private renderUploadBoosterCard(): JSX.Element {
    return (
      <VaccinationScanCardPage
        employeeId={this.context.employeeId}
        countryCode={this.context.countryCode}
        isInitiatingCardUpload={this.props.isInitiatingCardUpload}
        isInitiatingCardUploadFailed={this.props.isInitiatingCardUploadFailed}
        isInitialLoad={this.props.isInitialLoad}
        getVaccinationCardStatus={this.props.getBoosterCardStatus}
        setVaccinationCardStatus={this.props.setBoosterCardStatus}
        vaccinationCardUpdatedAt={this.props.boosterCardUpdatedAt}
        cardType={CardType.Booster}
        getComplianceStatus={this.props.getVaccinationCompliance}
      />
    );
  }

  private renderUploadFirstDoseCard(): JSX.Element {
    return (
      <VaccinationScanCardPage
        employeeId={this.context.employeeId}
        countryCode={this.context.countryCode}
        isInitiatingCardUpload={this.props.isInitiatingCardUpload}
        isInitiatingCardUploadFailed={this.props.isInitiatingCardUploadFailed}
        isInitialLoad={this.props.isInitialLoad}
        getVaccinationCardStatus={this.props.getFirstDoseCardStatus}
        setVaccinationCardStatus={this.props.setFirstDoseCardStatus}
        vaccinationCardUpdatedAt={this.props.firstDoseCardUpdatedAt}
        cardType={CardType.FirstDose}
        getComplianceStatus={this.props.getVaccinationCompliance}
      />
    );
  }

  private renderVaccinationProof = () => {
    return <VaccinationProofPage isLoadingFromBackend={this.props.isLoadingFromBackend} />;
  };

  private hasFAQcontent = (): boolean => {
    return !!this.context.countryCode && i18n.exists(`vaccinationBonus_faq_${this.context.countryCode}_0_question`);
  };
}
