import { combineReducers } from 'redux';
import { AppState } from '../../models/appState';
import { routerReducer } from 'react-router-redux';
import { vaccination } from '../../apps/vaccination/reducers/vaccinationReducer';
import { uiFeatures } from './uiFeaturesReducer';
import { feature } from './featureReducer';
import { timezone } from './timezoneReducer';

export const rootReducer = combineReducers<AppState>({
    routerReducer,
    vaccination,
    uiFeatures,
    feature,
    timezone
});
