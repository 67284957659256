import { Col, Hr, Spacer, View } from '@amzn/stencil-react-components/layout';
import { H3, H4, Text } from '@amzn/stencil-react-components/text';
import i18n from 'i18next';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import React, { Component, Dispatch } from 'react';
import { HelpBlock } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { CovidTestLiteRecord, CovidTestLiteResult } from 'src/apps/vaccination/models/vaccination/covidTestLiteRecord';
import { withClient } from 'src/contexts/clientContext';
import { ILoggerProps, withLogger } from 'src/logger';
import { ApiClientsProps } from 'src/models/ApiClients';
import { AppState } from 'src/models/appState';
import { CountryConfig } from '../../../models/vaccination/countryConfig';
import { ROUTES } from 'src/routes';
import { PrimaryButton } from '../../__shared__/button/Button';
import { dateDisplayOptions } from '../VaccinationBonusPage';
import { EmployeeInfo } from 'src/contexts/EmployeeInfoContext';

export interface CovidTestLitePositiveResultProps extends RouteComponentProps, ILoggerProps, ApiClientsProps {
    countryConfig: CountryConfig;
    latestRecord: CovidTestLiteRecord;
}

export interface CovidTestLitePositiveResultState {
}

const initialState: CovidTestLitePositiveResultState = {
};

export class _CovidTestLitePositiveResult extends Component<CovidTestLitePositiveResultProps, CovidTestLitePositiveResultState> {
    static contextType = EmployeeInfo;

    state: CovidTestLitePositiveResultState = initialState;

    handleGoBackMainPage = () => {
        this.props.history.push(ROUTES.VACCINATION.path);
    };

    render() {
        return (
            <div className="test-attestation-form">
                <View maxWidth="480px" minWidth="320px" height="100%" margin="0 auto" padding="20px 15px" backgroundColor="neutral0">
                    <Col gridGap="S200">
                        <H3 fontWeight="bold">
                            {i18n.t('covidTestLite.positiveResultForm.title')}
                        </H3>

                        <Col gridGap="S200">
                            <Text dangerouslySetInnerHTML={{ __html: i18n.t('covidTestLite.positiveResultForm.followUpGuidance.title') }} />
                            <ul>
                                <li><Text dangerouslySetInnerHTML={{ __html: i18n.t('covidTestLite.positiveResultForm.followUpGuidance.bullet1') }} /></li>
                                <li><Text dangerouslySetInnerHTML={{ __html: i18n.t('covidTestLite.positiveResultForm.followUpGuidance.bullet2') }} /></li>
                            </ul>
                            <Text dangerouslySetInnerHTML={{ __html: i18n.t('covidTestLite.positiveResultForm.followUpGuidance.text1') }} />
                            <Text dangerouslySetInnerHTML={{ __html: i18n.t('covidTestLite.positiveResultForm.followUpGuidance.text2') }} />
                            <Text dangerouslySetInnerHTML={{ __html: i18n.t('covidTestLite.positiveResultForm.followUpGuidance.text3') }} />
                        </Col>

                        <Hr/>
                        <Spacer height={10}/>

                        <Col gridGap="S300">
                            <Text>{i18n.t('covidTestLite.positiveResultForm.backText')}</Text>
                            <PrimaryButton onClick={this.handleGoBackMainPage}>
                                {i18n.t('covidTestLite.positiveResultForm.backButton')}
                            </PrimaryButton>
                        </Col>

                    </Col>
                </View>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    countryConfig: state.vaccination.countryConfig,
    latestRecord: state.vaccination.covidTestLiteLatestRecord,
} as CovidTestLitePositiveResultProps);

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
} as CovidTestLitePositiveResultProps);

export const CovidTestLitePositiveResult =
    connect(mapStateToProps, mapDispatchToProps)(withClient(withLogger(withRouter(_CovidTestLitePositiveResult))));
