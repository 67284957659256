import { Record } from 'immutable';

const uiFeaturesStateRecordDefault = {
    hideHeaders: false,
};

type UIFeaturesStateRecordType = {
    hideHeaders: boolean;
};

export class UIFeaturesStateRecord extends Record<UIFeaturesStateRecordType>(uiFeaturesStateRecordDefault) { }
