import defaultTheme from '@amzn/stencil-react-theme-default';
import { StencilTheme } from '@amzn/stencil-react-theme-default';

const fontSizes = {
  T800: {
    xl: { fontSize: '14px', lineHeight: 1.25 },
    l: { fontSize: '14px', lineHeight: 1.25 },
    m: { fontSize: '14px', lineHeight: 1.25 },
    s: { fontSize: '14px', lineHeight: 1.3 }
  },
  T700: {
    xl: { fontSize: '18px', lineHeight: 1.25 },
    l: { fontSize: '18px', lineHeight: 1.31818 },
    m: { fontSize: '18px', lineHeight: 1.18182 },
    s: { fontSize: '18px', lineHeight: 1.29412 }
  },
  T600: {
    xl: { fontSize: '24px', lineHeight: 1.3 },
    l: { fontSize: '24px', lineHeight: 1.3333 },
    m: { fontSize: '24px', lineHeight: 1.375 },
    s: { fontSize: '24px', lineHeight: 1.35714 }
  },
  T500: {
    xl: { fontSize: '20px', lineHeight: 1.375 },
    l: { fontSize: '20px', lineHeight: 1.42857 },
    m: { fontSize: '20px', lineHeight: 1.46154 },
    s: { fontSize: '20px', lineHeight: 1.45455 }
  },
  T400: {
    xl: { fontSize: '16px', lineHeight: 1.5 },
    l: { fontSize: '16px', lineHeight: 1.54545 },
    m: { fontSize: '16px', lineHeight: 1.4 },
    s: { fontSize: '16px', lineHeight: 1.55556 }
  },
  T300: {
    xl: { fontSize: '14px', lineHeight: 1.55556 },
    l: { fontSize: '14px', lineHeight: 1.55556 },
    m: { fontSize: '14px', lineHeight: 1.5 },
    s: { fontSize: '14px', lineHeight: 1.5 }
  },
  T200: {
    xl: { fontSize: '14px', lineHeight: 1.5 },
    l: { fontSize: '14px', lineHeight: 1.5 },
    m: { fontSize: '14px', lineHeight: 1.57143 },
    s: { fontSize: '14px', lineHeight: 1.57143 }
  },
  T100: {
    xl: { fontSize: '14px', lineHeight: 1.57143 },
    l: { fontSize: '14px', lineHeight: 1.57143 },
    m: { fontSize: '14px', lineHeight: 1.66667 },
    s: { fontSize: '14px', lineHeight: 1.66667 }
  }
};

export const theme: StencilTheme = {
  ...defaultTheme,
  font: {
    ...defaultTheme.font,
    primary: {
      ...defaultTheme.font.primary,
      size: fontSizes
    },
    monospace: {
      ...defaultTheme.font.monospace,
      size: fontSizes
    }
  }
};
