import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import './index.scss';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { addLoggerContext, getLogger, LoggerProvider } from 'src/logger';
import KatalLogger from '@amzn/katal-logger';
import { theme } from './theme';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { buildStore } from 'src/redux/store';
import { EmployeeInfoRecord } from 'src/models/EmployeeInfoRecord';
import { EmployeeInfoContextProvider } from 'src/contexts/EmployeeInfoContext';
import { getCampaignsClient } from 'src/apps/vaccination/clients/campaignsClientSDK';
import { getTimeoffClient } from './clients/timeoffClientSDK';
import AppInitWrapper from './components/AppInitWrapper';
import { UIFeaturesStateRecord } from './models/uiFeatures/UIFeaturesStateRecord';
import { createSetUIFeaturesAction } from './actions/uiFeaturesActions';
import { FeatureClientSDK } from './clients/featureClientSDK';
import { createGetTimeZoneRequestAction } from './actions/timezoneActions';
import { createGetFeaturesRequestAction } from './actions/featureActions';
import App from './components/App';
import { ClientContextProvider } from './contexts/clientContext';
import reportWebVitals from './utils/webVitals';
import {PROXY_MIGRATION_VACCINATION_BONUS_API_FEATURE_GATE} from "src/consts";

const getTraceId = (): string | null => {
  // Trace Context - https://www.w3.org/TR/trace-context/
  const traceparent = (document.head.querySelector('meta[name="traceparent"]') as HTMLMetaElement)?.content;
  if (traceparent) {
    const parts = traceparent.split('-');
    if (parts.length >= 2) {
      return parts[1];
    }
  }
  return null;
}

const generateSpanId = (): string | null => {
  if (crypto && crypto.getRandomValues) {
    const bytes = new Uint32Array(2);
    crypto.getRandomValues(bytes);
    return bytes.reduce((hex, bytes) => hex + bytes.toString(16).padStart(8, '0'), '');
  } else {
    return null;
  }
}

export const renderApp = async (containerId: string) => {

  const appRoot = document.getElementById(containerId);
  const appData = appRoot.dataset;

  const logger: KatalLogger = getLogger();
  addLoggerContext({
    employeeId: appData.employeeId,
    countryCode: appData.countryCode,
    locale: i18n.language,
    traceId: getTraceId(),
    spanId: generateSpanId()
  });

  const employeeInfo = new EmployeeInfoRecord({
    employeeId: appData.employeeId,
    countryCode: appData.countryCode,
    locale: i18n.language,
    hireDate: appData.hireDate,
    firstName: appData.employeeFirstName,
    lastName: appData.employeeLastName,
    popstarFeatures: JSON.parse(appData.popstarFeatures as string),
  });

  // Feature client SDK cant use handleErrorStatusCodes
  // Because the unauthorized page will call get_features
  // So when that call returns unauthorized, then infinite loop begins
  const featureClientSDK = new FeatureClientSDK(axios.get);

  const isReverseProxyEnabled = employeeInfo.popstarFeatures.includes(PROXY_MIGRATION_VACCINATION_BONUS_API_FEATURE_GATE);

  const campaignsClientSDK = getCampaignsClient(employeeInfo.employeeId, logger, isReverseProxyEnabled);
  const timeoffClientSDK = getTimeoffClient(employeeInfo.employeeId, logger);

  const store = buildStore({ config: { employeeInfo, campaignsClientSDK, featureClientSDK, timeoffClientSDK } });
  store.dispatch(createGetFeaturesRequestAction());
  store.dispatch(createGetTimeZoneRequestAction());

  const uiFeatures = new UIFeaturesStateRecord({
    hideHeaders: appData.hideHeaders === 'true'
  });
  store.dispatch(createSetUIFeaturesAction(uiFeatures));
  
  reportWebVitals();

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <LoggerProvider logger={logger}>
          <EmployeeInfoContextProvider value={employeeInfo}>
            <StencilProvider theme={theme}>
              <AppInitWrapper>
                <React.Suspense fallback={<Spinner size={SpinnerSize.Large} />}>
                  <I18nextProvider i18n={i18n}>
                    <ClientContextProvider clients={{ campaignsClientSDK, featureClientSDK }}>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </ClientContextProvider>
                  </I18nextProvider>
                </React.Suspense>
              </AppInitWrapper>
            </StencilProvider>
          </EmployeeInfoContextProvider>
        </LoggerProvider>
      </Provider>
    </React.StrictMode>,
    appRoot
  );
}