import { CardType } from './cardType';

export class InitiateSubmitCardRequest {
    constructor(
        readonly employeeId: string,
        readonly siteId: string,
        readonly countryCode: string,
        readonly cardType?: CardType,   // if omitted it's treated by backend as `CardType.Vaccine`
    ) {}
}
