import React, { FC } from 'react';
import { ButtonProps as StencilButtonProps } from '@amzn/stencil-react-components/dist/submodules/button/interfaces';
import { styledWithTheme } from '@amzn/stencil-react-components/context';
import { Button as StencilButton } from '@amzn/stencil-react-components/dist/submodules/button';
import { ButtonVariant } from '@amzn/stencil-react-components/button';

export interface ButtonProps extends StencilButtonProps {
  isFullWidth?: boolean;
  disabled?: boolean;
}

export const Button = styledWithTheme(StencilButton as FC<ButtonProps>)(({ isFullWidth, disabled }) => ({
  width: isFullWidth ? '100%' : undefined,
  disabled: disabled,
  '&&': {
    outline: 'none !important'
  }
}));

export const PrimaryButton: FC<ButtonProps> = props => <Button variant={ButtonVariant.Primary} {...props} />;

export const SecondaryButton: FC<ButtonProps> = props => <Button variant={ButtonVariant.Secondary} {...props} />;

export const TertiaryButton: FC<ButtonProps> = props => <Button variant={ButtonVariant.Tertiary} {...props} />;
