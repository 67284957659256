// tslint:disable align
import React, { FC, useEffect, useState } from 'react';
import { LoadingIndicator, LoadingIndicatorTheme } from '@atoz/atoz-common-ui-components';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../routes';
import { useCurrentEffect } from '../../../../utils/useCurrentEffect';
import './vaccination-proof-page.css';
import { connect } from 'react-redux';
import { AppState } from 'src/models/appState';
import { LoggerProps, withLogger } from '../../../../logger';
import { GreenBadgeScreen } from './GreenBadgeScreen';
import { submitOmnitureEvent } from '../../../../utils/adobeAnalytics';
import { BlueBadgeScreen } from './BlueBadgeScreen';
import { useEmployeeContext } from '../../../../contexts/EmployeeInfoContext';
import { isPopstarFeatureEnabled } from '../../../../models/EmployeeInfoRecord';
import { VaccinationFeatures } from '../../constant/vaccinationFeatures';
import { sendTelemetryEvent } from 'src/utils/telemetry/TelemetryHelper';
import { VACCINATION_BONUS_TELEMETRY_SCOPE } from 'src/consts';

const ONE_SECOND = 1000;

type Props = {
  isVaccineCompliant: boolean;
  isLoadingFromBackend: boolean;
  isInitialLoad: boolean;
  isTestCompliant: boolean;
} & LoggerProps;

export const _VaccinationProofPage: FC<Props> = ({ isVaccineCompliant, isLoadingFromBackend, isInitialLoad, isTestCompliant, logger }) => {
  const history = useHistory();
  const context = useEmployeeContext();
  const [isOddTime, setIsOddTime] = useState(false);

  useEffect(() => {
    logger.info(`isVaccineCompliant: ${isVaccineCompliant} isLoadingFromBackend ${isLoadingFromBackend} `);
    logger.info(`isInitialLoad: ${isInitialLoad}`);
  }, []);

  useCurrentEffect(
    isCurrent => {
      const interval = setInterval(() => isCurrent() && setIsOddTime(!isOddTime), ONE_SECOND);
      return () => clearInterval(interval);
    },
    [isOddTime]
  );

  useEffect(() => {
    if (!isVaccineCompliant && !isTestCompliant) {
      logger.info('VaccinationProof:reroute');
      submitOmnitureEvent('VaccinationBonus - Reroute From GreenBadge');
      sendTelemetryEvent(VACCINATION_BONUS_TELEMETRY_SCOPE, `${VACCINATION_BONUS_TELEMETRY_SCOPE} - Reroute From GreenBadge`);
      history.replace(ROUTES.VACCINATION.path);
      return;
    }
  }, [isVaccineCompliant]);

  return (
    <div className="proof-of-vaccination-page-wrapper">
      {isInitialLoad || isLoadingFromBackend ? (
        <LoadingIndicator theme={LoadingIndicatorTheme.LIGHT} />
      ) : (
          isVaccineCompliant && <GreenBadgeScreen /> ||
          isPopstarFeatureEnabled(context, VaccinationFeatures.ATOZ_TEST_ATTESTATION) && <BlueBadgeScreen />
      )}
    </div>
  );
};

const mapState = (state: AppState) => ({
  isVaccineCompliant: state.vaccination.isVaccineCompliant(),
  isInitialLoad: state.vaccination.isInitialLoad,
  isTestCompliant: state.vaccination.isTestCompliant()
});

export const VaccinationProofPage = connect(mapState)(withLogger(_VaccinationProofPage));
