import { Action } from 'redux';

type TimezoneErrorPayload = any;
type TimezonePayload = string;

export type TimezoneActions = GetTimeZoneRequestAction
    | GetTimeZoneSuccessAction
    | GetTimeZoneFailureAction;

export enum TimezoneActionTypes {
    GET_TIMEZONE_REQUEST = 'GET_TIMEZONE_REQUEST',
    GET_TIMEZONE_SUCCESS = 'GET_TIMEZONE_SUCCESS',
    GET_TIMEZONE_FAILURE = 'GET_TIMEZONE_FAILURE'
}

export interface TimezoneAction extends Action {
    type: TimezoneActionTypes;
    payload?: TimezonePayload | TimezoneErrorPayload;
    error?: boolean;
}

export interface GetTimeZoneRequestAction extends TimezoneAction {
    type: TimezoneActionTypes.GET_TIMEZONE_REQUEST;
}

export interface GetTimeZoneSuccessAction extends TimezoneAction {
    type: TimezoneActionTypes.GET_TIMEZONE_SUCCESS;
}

export interface GetTimeZoneFailureAction extends TimezoneAction {
    type: TimezoneActionTypes.GET_TIMEZONE_FAILURE;
}

export const createGetTimeZoneRequestAction = (): GetTimeZoneRequestAction => ({
    type: TimezoneActionTypes.GET_TIMEZONE_REQUEST
});

export const createGetTimeZoneSuccessAction = (timeZone: TimezonePayload): GetTimeZoneSuccessAction => ({
    type: TimezoneActionTypes.GET_TIMEZONE_SUCCESS,
    payload: timeZone
});

export const createGetTimeZoneFailureAction = (error: TimezoneErrorPayload): GetTimeZoneFailureAction => ({
    type: TimezoneActionTypes.GET_TIMEZONE_FAILURE,
    payload: error,
    error: true
});
