import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCampaignsClient } from '../clients/campaignsClientSDK';
import { VaccinationSurveyResponse } from '../models/vaccination/vaccinationSurveyResponse';

export const getVaccinationSurveyAnswersV2Op = createAsyncThunk<VaccinationSurveyResponse, string>(
    'vaccinationBonus/getVaccinationSurveyAnswersV2Op',
    async (employeeId: string) => {
      const campaignsClientSDK = getCampaignsClient(employeeId);
        return await campaignsClientSDK
            .getVaccinationSurveyAnswersV2(employeeId, 'FULL_VACCINATION_SURVEY').toPromise();
    }
);
