import { get as lodashGet } from 'lodash';
import { UtilsContext } from '../../../../models/UtilsContext';

export const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

export const isFileTypeAllowed = (file: any, meta: any, context: UtilsContext = { logger: console }) => {
  const unknown = 'unknown';
  let mimeType = lodashGet(meta, 'mime', unknown);
  const isAllowed = ALLOWED_FILE_TYPES.includes(mimeType);
  if (!isAllowed) {
    if (mimeType === unknown) {
      mimeType = lodashGet(file, 'type', unknown);
    }
    const fileName = lodashGet(file, 'name', unknown);
    const extension = lodashGet(meta, 'ext', fileName.split('.').pop());
    context.logger.warn('User attempted to upload disallowed file type [' + mimeType + '] with extension [' + extension + ']');
  }
  return isAllowed;
};
