import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { appEpic } from 'src/epics/appEpic';
import { rootReducer } from 'src/redux/reducers/root';

export const buildStore = ({config}) => {
  const epicMiddleware = createEpicMiddleware(appEpic, {
    dependencies: config
  });

  return configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      [
        epicMiddleware,
        ...getDefaultMiddleware({
          thunk: {
            extraArgument: config,
          },
          serializableCheck: false,
          immutableCheck: false,
        })
      ]
  });
}
