import i18n from 'i18next';
import React, { FC } from 'react';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

type Props = {
  skip: () => void;
};

const _Footer: FC<Props> = ({ skip }) => {
  return (
    <>
      <button
        className="btn btn-secondary col-xs-12"
        onClick={skip}
        data-omniture-link="Vaccination Bonus - Skip Card Upload click"
        data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Skip Card Upload click')}
      >
        {i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.skipForNow')}
      </button>
      <h2 className="section-title">{i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.missingTitle')}</h2>
      <p className="content">{i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.missingText')}</p>
      <a href="http://covid19.aboutamazon.com/">{i18n.t('vaccinationBonus.workWithoutMask.banner.learnMoreLink')}</a>
    </>
  );
};

export const Footer = _Footer;
