import React, { FC, useState } from 'react';
import { StencilIcon, StencilIconColor, StencilIconType } from '@atoz/atoz-common-ui-components';
import { IconSize } from '@amzn/stencil-react-components/icons';
import { Collapse } from 'react-bootstrap';
import i18n from 'i18next';

type Props = {};

const _CardUploadGuidance: FC<Props> = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const setExpandSectionBackEnter = (e: React.KeyboardEvent) => {
    if (e) {
      if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
        // 'Spacebar' is used by older IE/FF versions
        e.preventDefault();
        setIsExpanded(!isExpanded);
      }
    }
  };
  const setExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className="card-upload-guidance-section">
      <h2 className="card-upload-guidance-title" onClick={setExpand}>
        Guidance{' '}
        <span
          className="atoz-punch-chevron"
          tabIndex={0}
          onKeyDown={setExpandSectionBackEnter}
          aria-label={i18n.t('vaccinationBonus.cardUpload.guidance.label')}
          alt-text={i18n.t('vaccinationBonus.cardUpload.guidance.label')}
          role="button"
          aria-expanded={isExpanded}
        >
          <StencilIcon
            color={StencilIconColor.Primary}
            icon={isExpanded ? StencilIconType.ChevronUp : StencilIconType.ChevronDown}
            size={IconSize.Small}
          />
        </span>
      </h2>
      <Collapse in={isExpanded}>
        <div>
          <ul>
            <li>{i18n.t('vaccinationBonus.cardUpload.guidance.listItem1')}</li>
            <li>{i18n.t('vaccinationBonus.cardUpload.guidance.listItem2')}</li>
            <li>{i18n.t('vaccinationBonus.cardUpload.guidance.listItem3')}</li>
            <li>{i18n.t('vaccinationBonus.cardUpload.guidance.listItem1')}</li>
          </ul>
          <p>{i18n.t('vaccinationBonus.cardUpload.guidance.helpText')}</p>
        </div>
      </Collapse>
    </div>
  );
};

export const CardUploadGuidance = _CardUploadGuidance;
