import { VaccinationBonusRecord } from '../vaccination/vaccinationBonusRecord';

// tslint:disable variable-name
const DEFAULT_ONSITE_VALUE = true;

export class SubmitVaccinationBonusRequest {
  public static defaultOnsiteValue = () => { return DEFAULT_ONSITE_VALUE; }

  public static fromVaccinationBonusRequest(request: VaccinationBonusRecord, employeeId: string): SubmitVaccinationBonusRequest {
    const vaccinationDate: string = request.vaccinationDate.isValid ? request.vaccinationDate.toISODate() : 'ERROR';
    return new SubmitVaccinationBonusRequest(
      employeeId,
      vaccinationDate,
      request.vaccineType.enumValue,
      request.onsite != null ? request.onsite : DEFAULT_ONSITE_VALUE,
      request.duringShift,
      request.noBenefits
    );
  }

  constructor(
    private readonly employeeId: string,
    private readonly vaccinationDate: string,
    private readonly vaccineType: string,
    private readonly onsite: boolean,
    private readonly duringShift: boolean | null,
    private readonly noBenefits: boolean
  ) { }
}
