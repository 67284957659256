import React, { Component } from 'react';
import i18n from 'i18next';
import { ILoggerProps, withLogger } from '../../../../../logger';
import { connect } from 'react-redux';
import { AppState } from '../../../../../models/appState';
import { Anchor } from '@atoz/atoz-common-ui-components';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../../routes';
import { EmployeeInfo } from 'src/contexts/EmployeeInfoContext';
import { isPopstarFeatureEnabled } from '../../../../../models/EmployeeInfoRecord';
import { VaccinationFeatures } from '../../../constant/vaccinationFeatures';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

interface Props extends ILoggerProps {
  hasFAQcontent: boolean;
  resourcesLink?: string;
}

class _HelpfulLinks extends Component<Props> {
  static contextType = EmployeeInfo;
  constructor(props: Props) {
    super(props);
  }

  render() {
    const renderFaqLink = !isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_NO_BENEFITS) && this.props.hasFAQcontent;
    const renderResourceLink = !!this.props.resourcesLink;
    if (
      !renderFaqLink &&
      !renderResourceLink &&
      !isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
    ) {
      // nothing to render
      return null;
    }
    return (
      <div className="vaccination-paper">
        <h2 className="sub-title">{i18n.t('vaccinationBonus.section.links')}</h2>
        <p className="description">{i18n.t('vaccinationBonus.blurb.resources')}</p>
        <ul className="helpful-links-list">
          {renderFaqLink &&
            this.renderLink(
              ROUTES.VACCINATION.FAQ.path,
              Link,
              i18n.t('vaccinationBonus.link.faq'),
              'VaccinationBonus - FAQ click'
            )}
          {renderResourceLink &&
            this.renderLink(
              this.props.resourcesLink!,
              'a',
              i18n.t('vaccinationBonus.link.resources'),
              'Vaccination Bonus - Resources click'
            )}
          {isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD) && this.context.countryCode === 'USA' &&
            this.renderLink(
              'https://www.cdc.gov/vaccines/programs/iis/contacts-locate-records.html#state',
              'a',
              i18n.t('vaccinationBonus.link.getCard'),
              'Vaccination Bonus - Get CDC card link click'
            )}
          {this.context.countryCode === 'USA' && this.renderLink(
            'https://covid19.aboutamazon.com/us/en/home/vaccine.html',
            'a',
            i18n.t('vaccinationBonus.link.findVaccine'),
            'Vaccination Bonus - Find vaccine link click'
          )}
        </ul>
      </div>
    );
  }

  private renderLink = (target: string, component: 'a' | typeof Link, text: string, eventTag: string) => (
    <li className="helpful-links-list-item">
      <Anchor
        component={component}
        href={target}
        to={target}
        data-omniture-link={eventTag}
        data-pxt-telemetry-events={describeTelemetryClickEvent(eventTag)}
      >
        {text}
      </Anchor>
    </li>
  );
}

export const mapState = ({ vaccination }: AppState) =>
  ({
    resourcesLink: vaccination.countryConfig?.resourcesLink,
  } as Props);

export const HelpfulLinks = connect(mapState)(withLogger(_HelpfulLinks));
