import React, { Dispatch, FC, useEffect, useState } from 'react';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { DateTimeFormatOptions, LocaleOptions } from 'luxon';
import { CovidTestLiteRecord } from 'src/apps/vaccination/models/vaccination/covidTestLiteRecord';
import { dateDisplayOptions } from './VaccinationBonusPage';
import { Feedback } from '@atoz/atoz-common-ui-components';
import { createInternalVaccinationBonusRequestUpdateAction, VaccinationBonusActions } from '../../actions/vaccinationBonusActions';
import { AppState } from '../../../../models/appState';
import { CardStatus } from '../..//models/vaccination/getVaccinationCardStatusResponse';
import { VaccinationBonusRecord } from '../../models/vaccination/vaccinationBonusRecord';
import { ROUTES } from '../../../../routes';
import { CardType } from '../../models/apiRequest/cardType';
import { ImagePreview } from './__shared__/ImagePreview';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { ILoggerProps, withLogger } from '../../../../logger';
import axios from 'axios';
import { getPdfImage } from '../../utils/vaccination/getPdfImage';
import { useEmployeeContext } from '../../../../contexts/EmployeeInfoContext';
import { EmployeeInfoRecord, isPopstarFeatureEnabled } from '../../../../models/EmployeeInfoRecord';
import { isUploadAllowed, VaccinationFeatures } from '../../constant/vaccinationFeatures';
import { getCampaignsClient } from '../../clients/campaignsClientSDK';
import {getTranslatedVaccineType} from "../../utils/vaccination/getTranslatedVaccineType";
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

// text that is expected to be delivered instead of URL when the image has been removed in the backend
const IMAGE_REMOVED = 'REMOVED';

interface Props extends ILoggerProps {
  previousRecords: List<VaccinationBonusRecord>;
  covidTestLiteRecords: List<CovidTestLiteRecord>;
  cardStatus: Record<CardType, CardStatus>;
  rejectionReasons: Record<CardType, List<string>>;
  canReuploadCard: Record<CardType, boolean>;
  updateVaccinationBonusRequest: (request: VaccinationBonusRecord) => void;
};

type PerImageProps = {
  pictureData: string|undefined;
  cardLoaded: boolean;
  isPreviewVisible: boolean;
};

const DefaultImageProps: PerImageProps = {
  pictureData: undefined,
  cardLoaded: false,
  isPreviewVisible: false,
};

const getImage = async (path: string) => await axios.get(path, { responseType: 'arraybuffer' })
  .then((response) => {
    const image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return {
      imageBase64: `data:${response.headers['content-type'].toLowerCase()};base64,${image}`,
      type: response.headers['content-type'].toLowerCase()
    };
  });

const _VaccinationSubmissionsPage: FC<Props> = (props: Props) => {
  const history = useHistory();
  const employeeInfo = useEmployeeContext();
  const [imageProps, setImageProps] = useState<Record<CardType, PerImageProps>>({
    Vaccine: DefaultImageProps,
    Booster: DefaultImageProps,
    FirstDose: DefaultImageProps,
  } as Record<CardType, PerImageProps>);
  const [cardIsLoading, setCardIsLoading] = useState<Record<CardType, boolean>>({
    Vaccine: false,
    Booster: false,
    FirstDose: false,
  } as Record<CardType, boolean>);
  const [needToScroll, setNeedToScroll] = useState<boolean>(true);

  const toggleCardPreview = (cardType: CardType) => () => {
    // only one card can open preview at a time, make sure all others are closed
    const newValue = !imageProps[cardType].isPreviewVisible;
    Object.keys(CardType).filter(item => isNaN(Number(item)))
      .filter(item => imageProps[item] !== undefined)
      .forEach(item => imageProps[item].isPreviewVisible = false);
    imageProps[cardType].isPreviewVisible = newValue;
    setImageProps({...imageProps});
  }

  const getPictureData = async (cardType: CardType) => {
    const newImageProps = { ...imageProps[cardType] };

    // get predefined URL
    const campaignsClientSDK = getCampaignsClient(employeeInfo.employeeId);
    const response = await campaignsClientSDK.getVaccinationCardURL(employeeInfo.employeeId, cardType).toPromise();
    props.logger.info('Got cardUrl response ' + JSON.stringify(response));
    if (response.url && response.url !== IMAGE_REMOVED) {
      // download image data
      try {
        const image = await getImage(response.url);
        if (image.type.includes('pdf')) {
          const pdfContent = await getPdfImage(image.imageBase64);
          newImageProps.pictureData = pdfContent;
        } else {
          newImageProps.pictureData = image.imageBase64;
        }
      } catch (err) {
        props.logger.error('Exception downloading image: ' + err);
      }
    } else {
      newImageProps.pictureData = IMAGE_REMOVED;
    }

    newImageProps.cardLoaded = true;
    setImageProps(oldImageProps => {
      oldImageProps[cardType] = newImageProps;
      return {...oldImageProps};
    });
  };

  const loadCard = (cardType: CardType) => {
    if (shouldShowCardSection(props.cardStatus[cardType], employeeInfo) && !imageProps[cardType].pictureData && !cardIsLoading[cardType]) {
      setCardIsLoading(prevState => { // prevent future calling of getPictureData for same card type
        prevState[cardType] = true;
        return {...prevState};
      });
      getPictureData(cardType);
    }
  };

  useEffect(() => {
    if (needToScroll) {
      // only scroll on first mount
      setNeedToScroll(false);
      window.scrollTo(0, 0);
    }

    if (!shouldShowCardSection(props.cardStatus[CardType.Vaccine], employeeInfo)) {
      // only load card if there is no vaccine card to be displayed
      loadCard(CardType.FirstDose);
    }
    loadCard(CardType.Vaccine);
    loadCard(CardType.Booster);
  });

  const handleDoseEdit = (record: VaccinationBonusRecord) => async (event: any) => {
    event.preventDefault();
    props.updateVaccinationBonusRequest(record.set('isEdit', true).set('disclaimerConfirmed', false));
    history.push(ROUTES.VACCINATION.REQUEST.path);
  };

  const handleCardReplace = (cardType: CardType) => {
    let path: string;
    switch (cardType) {
      case CardType.Vaccine:
        path = ROUTES.VACCINATION.SUBMIT_CARD.path;
        break;
      case CardType.Booster:
        path = ROUTES.VACCINATION.BOOSTER_CARD.path;
        break;
      case CardType.FirstDose:
        path = ROUTES.VACCINATION.FIRSTDOSE_CARD.path;
        break;
      default:
        return (event: any) => {
          event.preventDefault();
          props.logger.error(`No replace option for card type ${cardType}`)
        };
    }
    return async (event: any) => {
      event.preventDefault();
      history.push(path);
    }
  }

  const renderRecord = (record: VaccinationBonusRecord) => (
    <div className="vaccination-paper report-vaccine-status" key={record.vaccinationDose}>
      <h3 className="sub-title">{doseTitle(record.vaccinationDose, record.isBooster)}</h3>

      {isPopstarFeatureEnabled(employeeInfo, VaccinationFeatures.VACCINATION_BONUS_ALLOW_ON_OFF_SITE) &&
        renderReport(
          i18n.t('vaccinationBonus.confirm.onOffSiteLabel'),
          i18n.t(`vaccinationBonus.onOffSite.${record.onsite ? 'onsite' : 'offsite'}`)
      )}
      {isPopstarFeatureEnabled(employeeInfo, VaccinationFeatures.VACCINATION_BONUS_ALLOW_SHIFT_SELECTION)
        && record.duringShift != null &&
        renderReport(
          i18n.t('vaccinationBonus.confirm.duringShiftLabel'),
          i18n.t(`vaccinationBonus.duringShift.${record.duringShift ? 'duringShift' : 'outsideShift'}`)
      )}
      {renderReport(
        i18n.t('vaccinationBonus.confirm.vaccineTypeLabel'),
        getTranslatedVaccineType(record.vaccineType.name)
      )}
      {renderReport(
        i18n.t('vaccinationBonus.confirm.vaccineReceivedLabel'),
        record.vaccinationDate.toLocaleString(dateDisplayOptions as DateTimeFormatOptions, { locale: employeeInfo.locale })
      )}
      {renderReport(
        i18n.t('vaccinationBonus.confirm.vaccineSubmittedLabel'),
        record.createdAt.toLocaleString(dateDisplayOptions as DateTimeFormatOptions, { locale: employeeInfo.locale })
      )}
      {record.updatedAt.isValid && renderReport(
        i18n.t('vaccinationBonus.confirm.vaccineUpdatedLabel'),
        record.updatedAt.toLocaleString(dateDisplayOptions as DateTimeFormatOptions, { locale: employeeInfo.locale })
      )}

      {isUploadAllowed(employeeInfo) &&
        <div className="report-button-section">
          <button
            className="btn btn-primary col-xs-12"
            onClick={handleDoseEdit(record)}
            data-omniture-link="Vaccination Bonus - Edit dose click"
            data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Edit dose click')}
          >
            {i18n.t('vaccinationBonus.submissions.dose.edit')}
          </button>
        </div>
      } 
    </div>
  );

  const renderCovidTestLiteRecord = (record: CovidTestLiteRecord) => (
    <div className="vaccination-paper report-vaccine-status" key={record.createAt}>
      <h3 className="sub-title">{i18n.t('vaccinationBonus.submissions.covidTestLiteRecords.testDetails')}</h3>

      {renderReport(
          i18n.t('vaccinationBonus.submissions.covidTestLiteRecords.testResult'),
          i18n.t(`vaccinationBonus.submissions.covidTestLiteRecords.testResult.${record.result}`),
      )}
      {renderReport(
        i18n.t('vaccinationBonus.submissions.covidTestLiteRecords.dateOfTest'),
        record.collectionDate.toLocaleString(dateDisplayOptions as DateTimeFormatOptions, { locale: employeeInfo.locale }),
      )}
    </div>
  )

  const cardStatusSection = (cardType: CardType) => {
    return shouldShowCardSection(props.cardStatus[cardType], employeeInfo) && (
      <div className="vaccination-card-preview vaccination-paper report-vaccine-status">
        <h3 className="sub-title">{i18n.t(`vaccinationBonus.submissions.card.${cardType}`)}</h3>
        <p>{
          i18n.t(`vaccinationBonus.reportHistory.messages.cardStatus.${props.cardStatus[cardType]}`,
            { cardType: i18n.t(`vaccinationBonus.submissions.card.${cardType}`)})
        }</p>
        {(props.cardStatus[cardType] === CardStatus.REJECTED) && (!props.rejectionReasons[cardType].isEmpty()) && (
          <ul>
            {props.rejectionReasons[cardType].map((value, key) =>
              <li key={key}>{i18n.t(`vaccinationBonus.workWithoutMask.VaccineCard.rejectionReasons.${value.toUpperCase()}`)}</li>
            )}
          </ul>
        )}
        {!imageProps[cardType].cardLoaded && <Spinner size={SpinnerSize.Medium} />}
        {imageProps[cardType].cardLoaded && imageProps[cardType].pictureData && imageProps[cardType].pictureData !== IMAGE_REMOVED && (
          <>
            <div className="card-image-box" onClick={toggleCardPreview(cardType)}>
              <img alt={i18n.t('vaccinationBonus.preview.alt')} src={imageProps[cardType].pictureData} />
            </div>
            <ImagePreview
              imageSrc={imageProps[cardType].pictureData}
              show={imageProps[cardType].isPreviewVisible}
              onHide={toggleCardPreview(cardType)}
            />
            <Feedback show={true} warning={true}>
              {i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.enlarge')}
            </Feedback>
          </>
        )}
        {imageProps[cardType].cardLoaded && imageProps[cardType].pictureData && imageProps[cardType].pictureData === IMAGE_REMOVED && (
          <p>{i18n.t('vaccinationBonus.submissions.imageRemoved')}</p>
        )}
        {imageProps[cardType].cardLoaded && !imageProps[cardType].pictureData && (
          <p className="error">{i18n.t('vaccinationBonus.submissions.imageError')}</p>
        )}
        {props.canReuploadCard[cardType] && isUploadAllowed(employeeInfo) && (
          <div className="report-button-section">
            <button
              className="btn btn-primary col-xs-12"
              onClick={handleCardReplace(cardType)}
              data-omniture-link="Vaccination Bonus - Replace card click"
              data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Replace card click')}
            >
              {i18n.t('vaccinationBonus.submissions.replace')}
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="vaccination-bonus-page">
      <div className="background">
        <div className="title">{i18n.t('vaccinationBonus.title.previousSubmissions')}</div>
        <p className="description">{i18n.t('vaccinationBonus.blurb.previousSubmissions')}</p>

        <h2 className="sub-title">{i18n.t('vaccinationBonus.submissions.records')}</h2>
        {!shouldShowCardSection(props.cardStatus[CardType.Vaccine], employeeInfo) && cardStatusSection(CardType.FirstDose)}
        {cardStatusSection(CardType.Vaccine)}
        {cardStatusSection(CardType.Booster)}
        
        {!props.previousRecords.isEmpty() && (
          <>
            <h2 className="sub-title">{i18n.t('vaccinationBonus.submissions.doses')}</h2>
            {props.previousRecords.map(renderRecord)}
          </>
        )}

        {!props.covidTestLiteRecords.isEmpty() && (<>
          <h2 className="sub-title">{i18n.t('vaccinationBonus.submissions.covidTestLiteRecords')}</h2>
          {props.covidTestLiteRecords.map(renderCovidTestLiteRecord)}
        </>)}
      </div>
    </div>
  );
};

const doseTitle = (vaccinationDose: number, isBooster: boolean|undefined) => {
  if (isBooster) return i18n.t('vaccinationBonus.submissions.dose.other');

  switch (vaccinationDose) {
    case 1:
      return i18n.t('vaccinationBonus.submissions.dose.first');
    case 2:
      return i18n.t('vaccinationBonus.submissions.dose.second');
    default:
      return i18n.t('vaccinationBonus.submissions.dose.other');
  }
}

const shouldShowCardSection = (cardStatus: CardStatus, employeeInfo: EmployeeInfoRecord) => {
  if (!isPopstarFeatureEnabled(employeeInfo, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)) return false;
  switch (cardStatus) {
    case CardStatus.UPLOADED:
    case CardStatus.APPROVED:
    case CardStatus.REJECTED:
      return true;
    default:
      return false;
  }
}

const renderReport = (label: string, content: string) => (
  <div className="confirm-section">
    <div className="confirm-label">{label}</div>
    <div className="confirm-content">{content}</div>
  </div>
);

const mapState = ({ vaccination }: AppState) => {
  const sortedCovidTestLiteRecords = vaccination.covidTestLiteRecords
      .sort((left, right) => right.collectionDate.diff(left.collectionDate).milliseconds);

  return {
    previousRecords: vaccination.previousVaccinationRecords,
    covidTestLiteRecords: sortedCovidTestLiteRecords,
    cardStatus: {
      Vaccine: vaccination.vaccinationCardStatus,
      Booster: vaccination.boosterCardStatus,
      FirstDose: vaccination.firstDoseCardStatus,
    },
    rejectionReasons: {
      Vaccine: vaccination.cardRejectionReasons,
      Booster: vaccination.boosterCardRejectionReasons,
      FirstDose: vaccination.firstDoseRejectionReasons
    },
    canReuploadCard: {
      Vaccine: vaccination.vaccinationCardReupload,
      Booster: vaccination.boosterCardReupload,
      FirstDose: vaccination.firstDoseCardReupload,
    },
  }
}

const mapDispatch = (dispatch: Dispatch<VaccinationBonusActions>) => ({
  updateVaccinationBonusRequest: (request: VaccinationBonusRecord) =>
    dispatch(createInternalVaccinationBonusRequestUpdateAction(request)),
});

export const VaccinationSubmissions = connect(mapState, mapDispatch)(withLogger(_VaccinationSubmissionsPage));
