import React, { Dispatch, FC, useState } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UploadIcon from '../upload.svg';
import NoteBookIcon from '../notebook.svg';
import { ReloadButtonSection } from './ReloadButtonSection';
import { AppState } from '../../../../../models/appState';
import { CardStatus } from '../../../models/vaccination/getVaccinationCardStatusResponse';
import { ROUTES } from '../../../../../routes';
import { createSubmitVaccinationSurveyAction, VaccinationBonusActions } from '../../../actions/vaccinationBonusActions';
import { SubmitVaccinationSurveyRequest } from '../../../models/apiRequest/submitVaccinationSurveyRequest';
import { SurveyModal } from './SurveyModal';
import { isPopstarFeatureEnabled } from '../../../../../models/EmployeeInfoRecord';
import { useEmployeeContext } from '../../../../../contexts/EmployeeInfoContext';
import { isUploadAllowed, VaccinationFeatures } from '../../../constant/vaccinationFeatures';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

type Props = {
  canSubmitRequest: boolean;
  isLoadingFromBackendFailed: boolean;
  isFinalDoseReported: boolean;
  isVaccineCompliant: boolean;
  cardNotUploaded: boolean;
  vaccinationCardStatus: CardStatus;
  boosterCardStatus: CardStatus;
  isInoculationPeriodPassed: boolean;
  isFirstDoseReported: boolean;
  canViewSurvey: boolean;
  inoculationPassedDate: string;
  isGrandFathered: boolean;
  inoculationPeriod: number;
  canSubmitBooster: boolean;
  canSubmitFirstDose: boolean;
  boosterNeedsPhoto: boolean;
  submitVaccinationRequest: (request: SubmitVaccinationSurveyRequest) => {};
  isSurveyRequired: boolean;
  surveyResponseSubmittedSuccess: boolean;
  surveyResponseSubmittedFailure: boolean;
  submittedVaccinationsCount: number;
};

const _ReportVaccineStatusSection: FC<Props> = (props: Props) => {
  const [isVaccinationReportModalOpened, setIsVaccinationReportModalOpened] = useState<boolean>(false);

  return (
    <div className="vaccination-paper report-vaccine-status">
      <h2 className="sub-title">{i18n.t('vaccinationBonus.reportStatus.title')}</h2>
      {props.isLoadingFromBackendFailed && <ReloadButtonSection/>}
      {getText(props)}
      <div className="report-button-section">{getButtons(props, () => {
        setIsVaccinationReportModalOpened(true);
      })}</div>

      <SurveyModal
          close={() => setIsVaccinationReportModalOpened(false)}
          isOpen={isVaccinationReportModalOpened}
          onCloseButtonClick={() => {
            setIsVaccinationReportModalOpened(false);
          }} onClick={() => alert('Boop!')}
      />
    </div>
  );
};

const getText = (props: Props) => {
  /* Case 1: failure to load from backend */
  if (props.isLoadingFromBackendFailed) {
    console.log('Text Case 1: failure to load from backend');
    return null;
  }
  const employee = useEmployeeContext();

  /* Case 2: can submit dose */
  if (props.canSubmitRequest && isUploadAllowed(employee)) {
    console.log('Text Case 2: can submit dose');
    return <p className="description">{i18n.t('vaccinationBonus.blurb.reportStatus')}</p>;
  }

  if (props.isFinalDoseReported && isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_BADGE)) {
    /* Case 3: Pending card submission */
    if (isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD) && props.cardNotUploaded) {
      console.log('Text Case 3: Pending card submission');
      return (
        <>
          <p className="description">{i18n.t('vaccinationBonus.vaccinationStatus.afterFinalDose.cardUploadUxAction')}</p>
          <p className="description">{i18n.t('vaccinationBonus.vaccinationStatus.afterFinalDose.description')}</p>
        </>
      );
    }

    /* Case 4: Image under review */
    if (isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
      && [CardStatus.REJECTED, CardStatus.UPLOADED].includes(props.vaccinationCardStatus)) {
      console.log('Text Case 4: Image under review');
      return (
        <>
          <p className="description">
            {i18n.t('vaccinationBonus.vaccinationStatus.afterFinalDose.afterCardUploadUxAction', {
              days: props.inoculationPeriod
            })}
          </p>
          <p className="description">{i18n.t('vaccinationBonus.vaccinationStatus.afterFinalDose.description')}</p>
        </>
      );
    }

    /* Case 5: Waiting for eligibility (inoculation) */
    if (!props.isInoculationPeriodPassed) {
      console.log('Text Case 5: Waiting for eligibility (inoculation)');
      return (
        <>
          <p className="description">
            {i18n.t('vaccinationBonus.vaccinationStatus.afterFinalDose.afterCardUploadUxAction', {
              days: props.inoculationPeriod
            })}
          </p>
          <p className="description">{i18n.t('vaccinationBonus.vaccinationStatus.afterFinalDose.description')}</p>
        </>
      );
    } else {
      /* Case 6: Green screen eligible / card verified / grandfathered */
      console.log('Text Case 6: Green screen eligible / card verified / grandfathered');
      return (isPopstarFeatureEnabled(employee, VaccinationFeatures.VACCINATION_BONUS_BOOSTERS) && props.canSubmitBooster) ? <p className="description">{i18n.t('vaccinationBonus.blurb.canSubmitBooster')}</p>
                                    : <p className="description">{i18n.t('vaccinationBonus.vaccinationStatus.afterFinalDose.description')}</p>;
    }
  }

  console.log('Text Case 7: Nothing more to do');
  /* Case 7: Nothing more to do */
  return (isPopstarFeatureEnabled(employee, VaccinationFeatures.VACCINATION_BONUS_BOOSTERS) && props.canSubmitBooster && isUploadAllowed(employee)) ? <p className="description">{i18n.t('vaccinationBonus.blurb.canSubmitBooster')}</p>
                                : <p className="description">{i18n.t('vaccinationBonus.blurb.thankYou')}</p>;
};

const getButtons = (props: Props, openVaccinationModal: () => void) => {
  const [isInfoboxVisible, setInfoboxVisible] = useState<boolean>(false);
  const toggleInfoboxVisibility = () => setInfoboxVisible(!isInfoboxVisible);

  /* Case 1: failure to load from backend */
  if (props.isLoadingFromBackendFailed) {
    console.log('Buttons Case 1: failure to load from backend');
    return null;
  }
  const notVaccinatedButtonVisible = props.isSurveyRequired && !(props.surveyResponseSubmittedSuccess || props.surveyResponseSubmittedFailure);
  
  const employee = useEmployeeContext();
  /* Case 2: can submit dose */
  if (props.canSubmitRequest && isUploadAllowed(employee)) {
    console.log('Buttons Case 2: can submit dose survey state ', props.isSurveyRequired);
    console.log('Buttons Case 2: can submit firstDose',
      isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_FIRSTDOSE_CARD_UPLOAD) && props.canSubmitFirstDose);

    return (
      <>
        {(isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_FIRSTDOSE_CARD_UPLOAD) && props.canSubmitFirstDose) && (addSubmitFirstDoseButton(props))}
        {!(isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_FIRSTDOSE_CARD_UPLOAD) && props.canSubmitFirstDose) && (
          <Link
            className="btn btn-primary col-xs-12"
            to={ROUTES.VACCINATION.REQUEST.path}
            data-omniture-link="Vaccination Bonus - Report click"
            data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Report click')}
          >
            {!props.isFirstDoseReported
              ? i18n.t('vaccinationBonus.button.reportFirstVaccination')
              : ((props.submittedVaccinationsCount < 2)
                ? i18n.t('vaccinationBonus.button.reportSecondVaccination')
                : i18n.t('vaccinationBonus.button.reportVaccinationNumber', { number: (props.submittedVaccinationsCount + 1) }))
            }
          </Link>
        )}
        {(notVaccinatedButtonVisible) && (
            <button
              className="btn btn-secondary"
              data-omniture-link="Vaccination Bonus - Not Vaccinated SurveyV2"
              data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Not Vaccinated SurveyV2')}
              onClick={() => {
                openVaccinationModal()
              }}
            >
            {i18n.t('vaccinationBonus.button.notFullyVaccinated')}
          </button>
        )}
      </>
    );
  }

  if (props.isFinalDoseReported && isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_BADGE)) {
    /* Case 3: Pending card submission */
    if (isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD) && props.cardNotUploaded) {
      console.log('Buttons Case 3: Pending card submission');
      return (
        <>
          <Link
            className="btn btn-primary"
            to={ROUTES.VACCINATION.SUBMIT_CARD.path}
            data-omniture-link="Vaccination Bonus - Submit Card"
            data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Submit Card')}
          >
            <img src={UploadIcon} className="button-icon" alt="icon" />{' '}
            {i18n.t('vaccinationBonus.workWithoutMask.banner.submitProofPhotoBtn')}
          </Link>
          {addGrandfatheredUx(props)}
          {addBoosterButtons(props, true)}
        </>
      );
    }

    /* Case 4: Image under review */
    if ((isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
      && [CardStatus.REJECTED, CardStatus.UPLOADED].includes(props.vaccinationCardStatus))) {
      console.log('Buttons Case 4: Image under review');
      return (
        <>
          <p className="not-eligible">{i18n.t('vaccinationBonus.vaccinationStatus.afterFinalDose.cardOnReview')}</p>
          {addGrandfatheredUx(props)}
          {addBoosterButtons(props)}
        </>
      );
    }
    /* Case 5: Waiting for eligibility (inoculation) */
    if (!props.isInoculationPeriodPassed) {
      console.log('Buttons Case 5: Waiting for eligibility (inoculation)');
      return (
        <p className="not-eligible">
          {i18n.t('vaccinationBonus.workWithoutMask.banner.eligibleOnDateBtn', { date: props.inoculationPassedDate })}
        </p>
      );
    } else if (props.isVaccineCompliant && isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_BADGE)) {
      /* Case 6: Green screen eligible / card verified / grandfathered */
      console.log('Buttons Case 6: Green screen eligible / card verified / grandfathered');
      return (
        <>
          <Link
            className="btn btn-primary"
            to={ROUTES.VACCINATION.PROOF.path}
            data-omniture-link="Vaccination Bonus - Proof Link"
            data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Proof Link')}
          >
            <img src={NoteBookIcon} className="button-icon" alt="icon" />{' '}
            {i18n.t('vaccinationBonus.workWithoutMask.banner.vaccinationConfirmationBtn')}
          </Link>
          {addBoosterButtons(props)}
        </>
      );
    }
  }

  console.log('Buttons Nothing to do');
  return addBoosterButtons(props);
};

const addGrandfatheredUx = (props: Props) => {
  const employee = useEmployeeContext();

  if (isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
    && props.isGrandFathered && props.isInoculationPeriodPassed)
    return (
      <Link
        className="btn btn-secondary"
        to={ROUTES.VACCINATION.PROOF.path}
        data-omniture-link="Vaccination Bonus - Proof Link"
        data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Proof Link')}
      >
        <img src={NoteBookIcon} className="button-icon" alt="icon" />{' '}
        {i18n.t('vaccinationBonus.workWithoutMask.banner.vaccinationConfirmationBtn')}
      </Link>
    );
  return null;
};

const addBoosterButtons = (props: Props, hidePhotoBtn = false) => {
  const employee = useEmployeeContext();
  return (
    <>
      {isUploadAllowed(employee) && <>
        {isPopstarFeatureEnabled(employee, VaccinationFeatures.VACCINATION_BONUS_BOOSTERS) && props.canSubmitBooster && (
        <Link
          className="btn btn-secondary"
          to={ROUTES.VACCINATION.REQUEST.path}
          data-omniture-link="Vaccination Bonus - Submit Booster Link"
          data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Submit Booster Link')}
        >
          {i18n.t('vaccinationBonus.button.reportAdditionalDose')}
        </Link>
        )}
        {!hidePhotoBtn && props.boosterCardStatus === CardStatus.UPLOADED &&
            <p className="not-eligible">{i18n.t('vaccinationBonus.vaccinationStatus.afterFinalDose.cardOnReview')}</p>
        }
        {!hidePhotoBtn && isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
          && props.boosterNeedsPhoto && (
          <Link
            className="btn btn-secondary"
            to={ROUTES.VACCINATION.BOOSTER_CARD.path}
            data-omniture-link="Vaccination Bonus - Submit Booster Cards"
            data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Submit Booster Cards')}
          >
            <img src={UploadIcon} className="button-icon" alt="icon" />{' '}
            {i18n.t('vaccinationBonus.workWithoutMask.banner.submitProofPhotoBtn')}
          </Link>
        )}
      </>}
    </>
  );
};

const addSubmitFirstDoseButton = (props: Props) => {
  return (
      <>
        <Link
            className="btn btn-primary"
            to={ROUTES.VACCINATION.FIRSTDOSE_CARD.path}
            data-omniture-link="Vaccination Bonus - Submit FirstDose Card"
            data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Submit FirstDose Card')}
        >
          <img src={UploadIcon} className="button-icon" alt="icon" />{' '}
          {i18n.t('vaccinationBonus.workWithoutMask.banner.submitProofPhotoBtn')}
        </Link>
      </>
  );
};

const mapState = ({ vaccination }: AppState) => ({
  isFinalDoseReported: vaccination.isFinalDoseReported(),
  isVaccineCompliant: vaccination.isVaccineCompliant(),
  canSubmitRequest: vaccination.canSubmitRequest(),
  isLoadingFromBackendFailed: vaccination.isLoadingFromBackendFailed(),
  cardNotUploaded: !vaccination.isCardUploaded(),
  vaccinationCardStatus: vaccination.vaccinationCardStatus,
  isInoculationPeriodPassed: vaccination.isInoculationPeriodPassed(),
  isFirstDoseReported: vaccination.isFirstDoseReported(),
  canViewSurvey: vaccination.canViewSurvey(),
  inoculationPassedDate: vaccination.getInoculationPassedDate(),
  isGrandFathered: !vaccination.isCardUploadMandatory() && !vaccination.isCardUploaded(),
  inoculationPeriod: vaccination.getInoculationPeriod(),
  canSubmitBooster: vaccination.canSubmitBooster(),
  canSubmitFirstDose: vaccination.canSubmitFirstDose(),
  boosterNeedsPhoto: vaccination.mustUploadBoosterCard(),
  isSurveyRequired: vaccination.isSurveyRequired,
  surveyResponseSubmittedSuccess: vaccination.surveyResponseSubmittedSuccess,
  surveyResponseSubmittedFailure: vaccination.surveyResponseSubmittedFailure,
  submittedVaccinationsCount: vaccination.reportedVaccinationsCount(),
  boosterCardStatus: vaccination.boosterCardStatus,
});

export const mapDispatch = (dispatch: Dispatch<VaccinationBonusActions>) => ({
  submitVaccinationRequest: (request: SubmitVaccinationSurveyRequest) => dispatch(createSubmitVaccinationSurveyAction(request))
} as Props);

export const ReportVaccineStatusSection = connect(mapState, mapDispatch)(_ReportVaccineStatusSection);
