import { Settings } from 'luxon';
import { useSelector } from 'react-redux';
import { TimezoneAction, TimezoneActionTypes } from 'src/actions/timezoneActions';
import { getLogger } from 'src/logger';
import { AppState } from 'src/models/appState';
import { TimezoneStateRecord } from 'src/models/timeoff/timezoneStateRecord';

export const timezone = (
    state: TimezoneStateRecord = new TimezoneStateRecord(),
    action: TimezoneAction
): TimezoneStateRecord => {
    switch (action.type) {
        case (TimezoneActionTypes.GET_TIMEZONE_REQUEST):
            return state.set('isLoadingTimeZone', true);
        case (TimezoneActionTypes.GET_TIMEZONE_SUCCESS):
            Settings.defaultZone = action.payload;
            return state.set('isLoadingTimeZone', false)
                .set('timeZone', action.payload);
        case (TimezoneActionTypes.GET_TIMEZONE_FAILURE):
            return state.set('isLoadingTimeZone', false)
                .set('loadTimeZoneFailed', true);
        default: return state;
    }
};

export function useTimezoneState() {
    return useSelector((state: AppState) => state.timezone);
}
