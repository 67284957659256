import { Telemetry } from './Telemetry';

export function sendTelemetryEvent(
    scope: string,
    message: string,
) {
    Telemetry.client.sendImperativeEvents({
        event: {
            // TODO: Remove after having our own namespace in PxtTelemetrySchemaRegistry
            resourceName: 'arn:pxt:telemetry:event:core:unstructuredlog:1.0.0',
            payload: {
                message: message,
            },
        },
        metaPayload: {
            scopePath: [scope],
        },
    });
}

export function describeTelemetryClickEvent(
    message: string,
) {
    return Telemetry.client.describeEvents({
        triggerResourceNames: ["arn:pxt:telemetry:trigger:core:click:*"], // This tells PXT Telemetry you want to decorate clicks
        events: [
            {
                // TODO: Remove after having our own namespace in PxtTelemetrySchemaRegistry
                resourceName: "arn:pxt:telemetry:event:core:unstructuredlog:1.0.0",
                payload: { message: message },
            },
        ],
    }).eventAttributeValue;
}
