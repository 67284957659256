// tslint:disable: no-any

export class OmnitureHelper {
    /**
     * Call Omniture to update the metrics for a given page title
     * @param title - The page title with which to call omniture
     */
    static setOmniturePageName(title: string) {
        if (OmnitureHelper.hasOmniture(window)) {
            ((window as any).s as { pageName: string }).pageName = title;
            ((window as any).s as any).t();
        }
    }

    /**
     * Determine if the current window has omniture js available
     */
    static hasOmniture(window: any): window is { s: {} } {
        return window.s != null;
    }
}