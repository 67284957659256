import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../../routes';
import './skip-confirmation.scss';
import i18n from 'i18next';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

type Props = {
  show: boolean;
  onHide: () => void;
};

export const SkipConfirmationBox: FC<Props> = ({ show, onHide }) => (
  <Modal show={show} onHide={onHide} className="skip-confirmation-dialog">
    <Modal.Header closeButton={true}>
      <Modal.Title className="title">{i18n.t('vaccinationBonus.workWithoutMask.skipConfirmationBox.title')}</Modal.Title>
      <Modal.Body>
        <p>{i18n.t('vaccinationBonus.workWithoutMask.skipConfirmationBox.paragraph_1')}</p>
        <p>{i18n.t('vaccinationBonus.workWithoutMask.skipConfirmationBox.paragraph_2')}</p>
      </Modal.Body>
      <Modal.Footer className="skip-confirmation-footer">
        <button
          className="btn btn-secondary cancel-btn"
          onClick={onHide}
          data-omniture-link="Vaccination Bonus - Skip Card Upload cancel"
          data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Skip Card Upload cancel')}  
        >
          {i18n.t('vaccinationBonus.button.cancel')}
        </button>
        <Link
          className="btn btn-primary proceed-btn"
          to={ROUTES.VACCINATION.path}
          data-omniture-link="Vaccination Bonus - Skip Card Upload confirm"
          data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Skip Card Upload confirm')}  
        >
          {i18n.t('vaccinationBonus.workWithoutMask.skipConfirmationBox.confirmBtn')}
        </Link>
      </Modal.Footer>
    </Modal.Header>
  </Modal>
);
