import { UIFeaturesAction, UIFeaturesActionTypes } from '../../actions/uiFeaturesActions';
import { UIFeaturesStateRecord } from '../../models/uiFeatures/UIFeaturesStateRecord';

export const uiFeatures = (
    state: UIFeaturesStateRecord = new UIFeaturesStateRecord(),
    action: UIFeaturesAction
): UIFeaturesStateRecord => {
    switch (action.type) {
        case (UIFeaturesActionTypes.SET_UI_FEATURES):
            return state.set('hideHeaders', action.payload.hideHeaders);
        default:
            return state;
    }
};