import React, { FC } from 'react';
import { Col } from '@amzn/stencil-react-components/layout';
import { StatusIndicator, Status } from '@amzn/stencil-react-components/status-indicator';

export const ErrorStatusIndicator: FC<{message: string}> = ({message}) => {
  return (
    <Col backgroundColor="red10" padding="10px 15px 10px 15px">
      <StatusIndicator messageText={message} status={Status.Negative} />
    </Col>
  );
};
