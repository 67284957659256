
import { getKatalValue } from './utils/katal';

// auto-filled by `KatalManifestWebpackLoader` package
// https://katal.corp.amazon.com/#/developer/manifest/templating
export const STAGE_NAME = getKatalValue('{{Stage}}', 'Stage', 'Beta');

// you can find this URL in AWS console under Lambda Applications
// https://us-west-2.console.aws.amazon.com/lambda/home?region=us-west-2#/applications/AtoZCampaignApp-Beta
// when CDK stack is recreated this needs to be updated
export const FALLBACK_LOGGER_URL = "https://cm3cv8hw1k.execute-api.us-west-2.amazonaws.com/prod/v1/log";

export enum ErrorTypes {
    CAUGHT_ERROR = 'Caught error',
    DEPENDENCY_LOAD_FAILED = 'Unable to load dependency bundle',
    UNCAUGHT_ERROR = 'Uncaught error',
    UNCAUGHT_RENDERING_ERROR = 'Uncaught rendering error',
    BAD_DATA = 'Bad data'
}

const isProd = () => STAGE_NAME.toLowerCase() === 'prod';

/**
 * The namespace acts as public api key for PXT Telemetry. It will be used to grant access
 * to the telemetry data. (See https://console.harmony.a2z.com/pxt-telemetry-docs/producers/guides/onboard)
 */
export const NAMESPACE = isProd() ? 'AtoZ_Campaign_App_Prod' : 'AtoZ_Campaign_App_Beta';

export const VACCINATION_BONUS_TELEMETRY_SCOPE = 'VaccinationBonus';
export const PROXY_MIGRATION_VACCINATION_BONUS_API_FEATURE_GATE = 'ProxyMigration:AtoZVaccinationBonusApiPrimaryFeatureGate';