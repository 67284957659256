import * as pdf from 'pdfjs-dist';

export const getPdfImage = async (blob: string): Promise<string> => {
  const doc = await pdf.getDocument(blob).promise;
  const page = await doc.getPage(1);

  const viewport = page.getViewport({ scale: 1 });
  const canvas = document.createElement('canvas');
  canvas.width = viewport.width;
  canvas.height = viewport.height;
  const context = canvas.getContext('2d');
  if (!context) {
    return '';
  }

  await page.render({
    canvasContext: context,
    viewport: viewport
  }).promise;

  return canvas.toDataURL();
};
