import { Record, Map } from 'immutable';
import { DateTime } from 'luxon';

const defaultVaccinationBonusUpdate = {
  createdAt: DateTime.invalid('default'),
  source: '',
  payload: Map<string, string>(),
};

type VaccinationBonusUpdateType = {
  createdAt: DateTime;
  source: string;
  payload: Map<string, string>;
};

export class VaccinationBonusUpdate extends Record<VaccinationBonusUpdateType>(defaultVaccinationBonusUpdate) {
}
