import { Modal } from 'react-bootstrap';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import React, { FC } from 'react';
import i18n from 'i18next';

type Props = {
  title: string;
  blurb: string;
  isProcessing: boolean;
  onHide: () => void;
};

const _ProcessingSpinner: FC<Props> = ({ title, blurb, isProcessing, onHide }) => {
  const handleCancel = (e: any) => {
    e.preventDefault();
    onHide();
  };

  return (
    <Modal show={isProcessing} onHide={onHide} id="wait-for-upload">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Spinner size={SpinnerSize.Medium} />
        <p className="processing-blurb">{blurb}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="cancel-processing">
          <a onClick={handleCancel}>{i18n.t('actions.cancel')}</a>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const ProcessingSpinner = _ProcessingSpinner;
