import { Metric, ReportHandler, getFID, getCLS, getFCP, getLCP, getTTFB } from 'web-vitals';
import { metricsPublisher } from '../metrics';

/**
 * Reports individual web-vitals
 */
const reportWebVital: ReportHandler = ({ name, value }: Metric) => {
  // Submit web vitals through Katal Metrics
  if (name === 'CLS') {
    metricsPublisher.emitCountMetric(name, value);
  } else {
    metricsPublisher.emitLatencyMetric(name, value);
  }
};

/**
 * Reports all the web vitals
 * https://github.com/GoogleChrome/web-vitals
 */
const reportWebVitals = async () => {
  getFID(reportWebVital);
  getCLS(reportWebVital);
  getFCP(reportWebVital);
  getLCP(reportWebVital);
  getTTFB(reportWebVital);
};

export default reportWebVitals;
