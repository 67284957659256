import React, { FC, useState } from 'react';
import { PreviewStatus } from '../__shared__/PreviewStatus';
import { ImagePreview } from '../__shared__/ImagePreview';
import i18n from 'i18next';
import { IconInformationFill } from '@amzn/stencil-react-components/icons';
import { IconSize } from '@amzn/stencil-react-components/dist/submodules/icons/interfaces';
import { isIOS } from 'react-device-detect';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

type Props = {
  previewStatus: PreviewStatus;
  selectFile: (event: any) => void;
  submitCard: (event: React.FormEvent<HTMLButtonElement>) => void;
  skipCardUpload: () => void;
  imageSrc: any;
  isLoading: boolean;
  requestPermissions: (event: any) => void;
  loadFile: (event: any) => void;
};

const _PreviewSection: FC<Props> = ({
  previewStatus,
  selectFile,
  submitCard: submitCard,
  skipCardUpload,
  imageSrc,
  isLoading,
  requestPermissions,
  loadFile
}) => {
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
  const toggleCardPreview = () => setIsPreviewVisible(!isPreviewVisible);
  return (
    <>
      <div className="vaccination-card-preview">
        {previewStatus === PreviewStatus.Ready && (
          <div className="vaccination-paper report-vaccine-status">
            <h2 className="card-upload-image-view-title">{i18n.t('vaccinationBonus.submissions.card.default')}</h2>
            <div className="card-image-box" onClick={toggleCardPreview}>
              <img alt={i18n.t('vaccinationBonus.preview.alt')} src={imageSrc} className="vaccination-card-preview" />
            </div>
            <p className="card-image-box-tooltip">{i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.enlarge')}</p>
            {isIOS ? (
              <label className="card-upload-replace-btn btn-link reset-link-attr">
                {i18n.t('vaccinationBonus.submissions.replace')}
                <input
                  type="file"
                  accept="image/*,application/pdf"
                  style={{ display: 'none' }}
                  onClick={requestPermissions}
                  onChange={loadFile}
                />
              </label>
            ) : (
              <a className="card-upload-replace-btn" role="button" onClick={selectFile}>
                {i18n.t('vaccinationBonus.submissions.replace')}
              </a>
            )}
            <ImagePreview imageSrc={imageSrc} show={isPreviewVisible} onHide={toggleCardPreview} />
          </div>
        )}
        {previewStatus === PreviewStatus.Creating && <span>{i18n.t('vaccinationBonus.preview.loading')}</span>}
        {previewStatus === PreviewStatus.Error && <span>{i18n.t('vaccinationBonus.preview.error')}</span>}
      </div>
      {/*<h2 className="section-title">{i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.checkReadabilityTitle')}</h2>*/}
      <p className="card-upload-info content">
        <IconInformationFill display="inline" size={IconSize.ExtraSmall} color={'#5C7274'} />{' '}
        <span className="bold">{i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.checkReadabilityTitle')}</span>
        {i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.previewBlurb')}
      </p>
      <div className="report-button-section">
        <button
          className="btn btn-primary col-xs-12"
          onClick={submitCard}
          disabled={isLoading}
          data-omniture-link="Vaccination Bonus - Submit Card"
          data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Submit Card')}
        >
          {i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.submitCard')}
        </button>
        <button
          className="btn btn-secondary col-xs-12"
          onClick={skipCardUpload}
          data-omniture-link="Vaccination Bonus - Skip Card Upload click"
          data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Skip Card Upload click')}
        >
          {i18n.t('actions.cancel')}
        </button>
      </div>
    </>
  );
};

export const PreviewSection = _PreviewSection;
