import { logger } from './logger';

export enum METRIC_NAME {
    APP_MOUNT = 'app.react.mount',
    APP_LATENCY_CF = 'app.latency.cf',
    APP_ERROR_UNKNOWN = 'app.error.unknown',
    APP_ERROR_INITIALIZE = 'app.error.initialization',
}

export enum METRIC_UNIT {
    COUNT = 'Count',
    MILLISECONDS = 'Milliseconds',
    NONE = 'None',
}

export class Metrics {
    /**
     * Helper method to log count metric (default value 1)
     */
    public emitCountMetric = (metricName: string, metricValue: number = 1, options = {}): void => {
        const emfLog = this.createEmfLog(metricName, metricValue, METRIC_UNIT.COUNT, options);
        logger.info(metricName, {
            emfLog: emfLog,
        });
    }

    /**
     * Helper method to log latency metric (in milliseconds)
     */
    public emitLatencyMetric = (metricName: string, metricValue, options = {}): void => {
        const emfLog = this.createEmfLog(metricName, metricValue, METRIC_UNIT.MILLISECONDS, options);
        logger.info(metricName, {
            emfLog: emfLog,
        });
    }

    /**
     * Given metric/dimension values, creates an EMF payload in the expected
     * AWS syntax.
     *
     * More Info:
     * https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch_Embedded_Metric_Format_Specification.html
     */
    private createEmfLog = (metricName, metricValue, metricUnit, options) => {
        // Add the page name dimensions to all incoming dimensions
        const allDimensions = {
            PageName: 'AtoZCampaignPage'
        };
        return {
            _aws: {
                Timestamp: Date.now(),
                CloudWatchMetrics: [
                    {
                        Namespace: 'CampaignApp',
                        Dimensions: Object.keys(allDimensions).map((d) => [d]),
                        Metrics: [
                            {
                                Name: metricName,
                                Unit: metricUnit,
                            },
                        ],
                    },
                ],
            },
            ...allDimensions,
            [metricName]: metricValue,
        };
    }
}

let metricPublisher: Metrics = null;
const getMetricsPublisher = () => {
    if (!metricPublisher) {
        metricPublisher = new Metrics();
    }
    return metricPublisher;
};

export const metricsPublisher = getMetricsPublisher();
