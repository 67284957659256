import i18n from 'i18next';

const buildTranslationKey = (...parts): string => {
    return parts.filter(Boolean).join('_');
}

export interface TranslationInfo {
    id: string;
    translation: string;
    key: string;
    index: number;
  }

export const getTranslationInfos = (base, id = '', postfix = ''): TranslationInfo[] => {
    if (!id) {
        id = base;
        base = '';
    }
    let index = 0;
    let list = [];
    let key = buildTranslationKey(base, id, index.toString(), postfix);
    if (i18n.exists("not exsisting key always exists in tests")) {
        // in tests default i18n.exists mock implementation always returns true...
        // we don't want to get stuck in forever loop
        // and it seems too much work to always remember to mock that with a better one
        // so we just add this workaround
        return list;
    }
    while (i18n.exists(key)) {
        list.push({
            id: id,
            translation: i18n.t(key),
            key: postfix ? buildTranslationKey(base, id, index.toString()) : key,
            index: index
        });
        key = buildTranslationKey(base, id, ++index, postfix);
    }
    return list;
}

export const getExistingTranslations = (id, names = []): Map<string, string> => {
    let translations = new Map();
    for (const name of names) {
        let key = buildTranslationKey(id, name);
        if (i18n.exists(key)) {
            translations.set(name, i18n.t(key));
        }
    }
    return translations;
}
