// tslint:disable align
import i18n from 'i18next';
import React, { Component } from 'react';
import '../vaccination-proof-page.css';
import { CancelTokenSource } from 'axios';
import { CardType } from '../../../models/apiRequest/cardType';
import { ROUTES } from '../../../../../routes';
import { BrowserRouterProps, RouteComponentProps, withRouter } from 'react-router-dom';
import { ILoggerProps, withLogger } from '../../../../../logger';
import { InitiateSubmitCardRequest } from '../../../models/apiRequest/initiateSubmitCardRequest';
import { fileTypeFromBlob } from 'file-type';
import { CardStatus, GetVaccinationCardStatusResponse } from '../../../models/vaccination/getVaccinationCardStatusResponse';
import * as pdf from 'pdfjs-dist';
import { CardUploader } from '../__shared__/CardUploader';
import { PreviewStatus } from '../__shared__/PreviewStatus';
import { Header } from './Header';
import { Footer } from './Footer';
import { LoadingIndicator, LoadingIndicatorTheme } from '@atoz/atoz-common-ui-components';
import { PreviewSection } from './PreviewSection';
import { OmnitureHelper } from '../../../../../models/omnitureHelper';
import { ProcessingSpinner } from '../__shared__/ProcessingSpinner';
import { withClient } from '../../../../../contexts/clientContext';
import { ApiClientsProps } from '../../../../../models/ApiClients';
import { getPdfImage } from '../../../utils/vaccination/getPdfImage';
import { isFileTypeAllowed } from '../../../utils/vaccination/isFileTypeAllowed';
import { isPopstarFeatureEnabled } from '../../../../../models/EmployeeInfoRecord';
import { EmployeeInfo } from '../../../../../contexts/EmployeeInfoContext';
import { VaccinationFeatures } from '../../../constant/vaccinationFeatures';
import { sendTelemetryEvent } from 'src/utils/telemetry/TelemetryHelper';
import { VACCINATION_BONUS_TELEMETRY_SCOPE } from 'src/consts';

const PAGE_NAME = 'VaccinationScanCard';
const CARD_STATUS_TIMEOUT_MS = 30 * 1000;
const ONE_HUNDRED_MB = 100 * 1000 * 1024;

declare global {
  interface Window {
    atozmobile?: {
      camera: {
        getStatus: () => void;
        requestPermissions: (onSuccess: (result: any) => void, onError: (err: Error) => void) => void;
      };
    };
  }
}

(() => {
  // we need to set path to worker file
  // it will be located in same place as main script
  let workerPath = 'pdf.worker.js';
  if (document.currentScript) {
    workerPath = new URL(workerPath, (document.currentScript as HTMLScriptElement).src).toString();
    const preloadLink = document.createElement('link');
    preloadLink.href = workerPath;
    preloadLink.rel = 'prefetch';
    preloadLink.as = 'script';
    document.head.appendChild(preloadLink);
  }
  pdf.GlobalWorkerOptions.workerSrc = workerPath;
})();

export interface VaccinationScanCardPageProps extends RouteComponentProps, ILoggerProps, BrowserRouterProps, ApiClientsProps {
  employeeId: string;
  countryCode: string;
  isInitiatingCardUpload: boolean;
  isInitiatingCardUploadFailed: boolean;
  isInitialLoad: boolean;
  getVaccinationCardStatus: () => void;
  setVaccinationCardStatus: (status: GetVaccinationCardStatusResponse) => void;
  getComplianceStatus: () => void;
  vaccinationCardUpdatedAt: number;
  cardType: CardType;
}

enum TakePhotoState {
  Initial = 'Initial',
  ImageTaken = 'ImageTaken',
  Error = 'Error'
}

type VaccinationScanCardPageState = {
  src: string;
  previewStatus: PreviewStatus;
  file: File | undefined;
  isLoading: boolean;
  employeeId: string;
  status: TakePhotoState;
  errorMessage: null | string;
  rejectReasons: string[];
  isSkipBoxVisible: boolean;
  mimeType: any;
  isUploading: boolean;
  cancelTokenSource: CancelTokenSource | undefined;
};

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

class _VaccinationScanCardPage extends Component<VaccinationScanCardPageProps, VaccinationScanCardPageState> {
  state: VaccinationScanCardPageState = {
    src: '',
    previewStatus: PreviewStatus.No,
    file: undefined,
    isLoading: false,
    employeeId: '',
    status: TakePhotoState.Initial,
    errorMessage: null,
    rejectReasons: [],
    isSkipBoxVisible: false,
    mimeType: null,
    isUploading: false,
    cancelTokenSource: undefined,
  };

  static contextType = EmployeeInfo;

  loadFile = async (event: any) => {
    try {
      this.props.logger.info(`VaccinationScanCard:load:initiated`);
      // this.resetError();
      // event.persist();
      const file = event.target.files[0];
      const mimeType = await fileTypeFromBlob(file);
      this.props.logger.info(`retrieved: ${file.name}`);
      let src = '';
      if (mimeType?.mime === 'application/pdf') {
        this.setState(() => ({
          previewStatus: PreviewStatus.Creating
        }));
        try {
          src = await getPdfImage(URL.createObjectURL(file));
        } catch (e) {
          this.props.logger.error('VaccinationScanCard:getPDFImage error', e);
        }
      } else if ([file.type.substr(0, 6), mimeType?.mime.substr(0, 6)].includes('image/')) {
        src = URL.createObjectURL(file);
      }

      this.setState(() => ({
        src: src,
        previewStatus: !!src ? PreviewStatus.Ready : PreviewStatus.Error,
        file,
        mimeType,
        status: TakePhotoState.ImageTaken
      }));

      if (file.size > ONE_HUNDRED_MB) {
        this.submitCardError('rejectionReasons.IMAGE_TOO_LARGE');
      }
    } catch (err) {
      this.props.logger.error(`VaccinationScanCard:load:${err.message}`, err, {});
    }
  };

  submitCard = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!this.isSelectedImageFileTypeAllowed()) {
      this.resetImage();
      return this.submitCardError('invalidTypeErrorMessage');
    }

    const {
      logger,
      employeeId,
      countryCode,
      history,
      cardType,
      clients: { campaignsClientSDK }
    } = this.props;

    logger.info('VaccinationScanCard:submitCard');

    const uploader = new CardUploader({
      logger: logger,
      loggerPrefix: PAGE_NAME,
      client: campaignsClientSDK,
    });

    this.setState({ isUploading: true });

    const initiateRequest = new InitiateSubmitCardRequest(employeeId, 'unknown', countryCode, cardType);

    const finalise = (response: GetVaccinationCardStatusResponse) => {
      this.props.setVaccinationCardStatus(response);
    };

    const finaliseWithReroute = (response: GetVaccinationCardStatusResponse) => {
      this.props.setVaccinationCardStatus(response);
      history.push(ROUTES.VACCINATION.path, { cardUploaded: true });
    };

    const mimeType = this.state.mimeType?.mime || this.state.file?.type || "";
    await uploader.uploadCard({
      file: this.state.file!,
      mimeType: mimeType,
      initiateRequest: initiateRequest,
      isCardStatusReady: (response: GetVaccinationCardStatusResponse) => {
          const cardStatus = CardStatus[response.status.toUpperCase()] || CardStatus.INITIAL;
        if (isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)) {
            if (response.mlFinished) return true;
            return ![CardStatus.NOT_UPLOADED, CardStatus.INITIAL, CardStatus.UPLOADED].includes(cardStatus);
          }
          return ![CardStatus.NOT_UPLOADED, CardStatus.INITIAL].includes(cardStatus);
      },
      onRememberCancelToken: (token) => {
        this.setState({ cancelTokenSource: token });
      },
      onCancelled: () => {
        this.cancelUpload('userCancelled');
      },
      onError: (exception?: any) => {
        const payload: GetVaccinationCardStatusResponse = {
          status: CardStatus.REJECTED.toString(),
          createdAt: this.props.vaccinationCardUpdatedAt,
          updatedAt: this.props.vaccinationCardUpdatedAt + 1,
          imagePath: '',
          canReupload: true,
          rejectionReason: null,
          mlFinished: false,
          complianceDetails: null,
        };
        if (exception) {
          logger.error('VaccinationScanCard:handleSuccessInitiateSubmit error', exception);
        }; // else error has already been logged
        logger.info('update status to uploaded payload', payload);
        this.props.setVaccinationCardStatus(payload);
        this.submitCardError('genericErrorMessage');
      },
      cardStatusTimeoutMs: CARD_STATUS_TIMEOUT_MS,
      onCardStatusTimeout: () => {
        this.cancelUpload('genericErrorMessage');
      },
      onCardHung: (response: GetVaccinationCardStatusResponse) => {
        finaliseWithReroute(response)
      },
      onCardRejected: (response: GetVaccinationCardStatusResponse, reasons: string[]) => {
        this.submitCardError('cardRejected', reasons);
        this.props.getComplianceStatus();
        finalise(response);
      },
      onCardApproved: (response: GetVaccinationCardStatusResponse) => {
        OmnitureHelper.setOmniturePageName('Vaccination Bonus - Submit Card finished success');
        sendTelemetryEvent(VACCINATION_BONUS_TELEMETRY_SCOPE, `${VACCINATION_BONUS_TELEMETRY_SCOPE} - Submit Card finished success`);
        this.props.getComplianceStatus();
        finaliseWithReroute(response);
      },
    });
  };

  submitCardError = (message: string, rejectionReasons?: string[]) =>
    this.setState(() => ({
      isLoading: false,
      isUploading: false,
      status: TakePhotoState.Error,
      errorMessage: message,
      rejectReasons: rejectionReasons || this.state.rejectReasons
    }));

  resetError = () =>
    this.setState(() => ({
      errorMessage: null
    }));

  skip = () => {
    this.props.logger.info('VaccinationScanCard:skip');
    this.setState({ isSkipBoxVisible: true });
  };

  componentDidMount(): void {
    /* 
      Force user to go back to home page when they click on back button
      otherwise they go back to submission confirmation page and have to submit again 
      which result in a 400 error because they can't submit twice the same vaccination 
    */
    this.props.history.listen((loc, action) => {
      if (action === 'POP') {
        this.props.history.push(ROUTES.VACCINATION.path);
      }
    })
  }

  render() {
    if (this.state.isLoading)
      return (
        <div className="vaccination-bonus-page">
          <div className="background">
            <LoadingIndicator theme={LoadingIndicatorTheme.LIGHT} />
          </div>
        </div>
      );
    return (
      <div className="vaccination-bonus-page">
        <div className="background">
          <Header
            dismissErrorBox={this.resetError}
            errorMessage={this.state.errorMessage}
            rejectReasons={this.state.rejectReasons}
            isSkipBoxVisible={this.state.isSkipBoxVisible}
            loadFile={this.loadFile}
            onSkipConfirmationBoxHide={() => this.setState({ isSkipBoxVisible: false })}
            previewStatus={this.state.previewStatus}
            requestPermissions={this.requestPermissions}
            selectFile={this.selectFile}
          />
          {[TakePhotoState.Initial].includes(this.state.status) && <Footer skip={this.skip} />}
          {[TakePhotoState.ImageTaken, TakePhotoState.Error].includes(this.state.status) && (
            <PreviewSection
              imageSrc={this.state.src}
              isLoading={this.state.isLoading}
              previewStatus={this.state.previewStatus}
              selectFile={this.selectFile}
              skipCardUpload={this.skip}
              submitCard={this.submitCard}
              loadFile={this.loadFile}
              requestPermissions={this.requestPermissions}
            />
          )}
          <ProcessingSpinner
            title={i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.processingTitle')}
            blurb={i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.processingBlurb')}
            isProcessing={this.state.isUploading}
            onHide={this.cancelUpload}
          />
        </div>
      </div>
    );
  }

  private selectFile = async (event: any) => {
    await this.requestPermissions(event);

    // create input element and trigger
    const elem = document.createElement('input');
    // https://stackoverflow.com/questions/3691061/uiimagepickercontroller-exception-source-type-must-be-uiimagepickercontroller/48466875#48466875
    elem.accept = ALLOWED_FILE_TYPES.join() + ',capture=camera';
    elem.style.display = 'none';
    elem.type = 'file';
    elem.onchange = (a: any) => {
      this.props.logger.info('VaccinationScan:input onChange', a);
      this.loadFile(a);
    };
    elem.click();
  };

  private requestPermissions = async (event: any) => {
    if (window.atozmobile && window.atozmobile.camera && typeof window.atozmobile.camera.getStatus === 'function') {
      this.props.logger.info('VaccinationScan:requestPermissions have getStatus function');
    } else {
      this.props.logger.info(`VaccinationScan:requestPermissions don't have integration`);
    }
    await this.wrapRequestPermissions();
    this.props.logger.info('VaccinationScan:requestPermissions continue');
  };

  private wrapRequestPermissions = async () => {
    if (window.atozmobile && window.atozmobile.camera && typeof window.atozmobile.camera.requestPermissions === 'function') {
      return new Promise((resolve, reject) => {
        window?.atozmobile?.camera.requestPermissions(
          (a: any) => {
            this.props.logger.info('VaccinationScan:requestPermissions success');
            resolve(a);
          },
          (err: any) => {
            this.props.logger.info('VaccinationScan:requestPermissions failed');
            reject(err);
          }
        );
      });
    }
    return Promise.resolve();
  };

  private isSelectedImageFileTypeAllowed = () => {
    const { file, mimeType } = this.state;
    const { logger } = this.props;
    return isFileTypeAllowed(file, mimeType, { logger });
  };

  private resetImage = () =>
    this.setState({
      src: '',
      file: undefined
    });

  private cancelUpload = (errorMessageId?: string) => {
    this.state.cancelTokenSource?.cancel();
    this.setState({ isUploading: false });
    if (errorMessageId) {
      this.submitCardError(errorMessageId);
    }
  };
}

export const VaccinationScanCardPage = withClient(withRouter(withLogger(_VaccinationScanCardPage)));
