import React, { FC } from 'react';
import { Alert } from 'react-bootstrap';
import RedIconExcl from './icon_error.svg';
import './vaccination-alert.scss';
import i18n from 'i18next';

export const VaccinationAlert: FC<{ onClose: () => void }> = ({ children, onClose }) => (
  <Alert bsStyle="info" className="vaccination-alert">
    <img src={RedIconExcl} className="vaccination-alert-icon" alt="exclamation mark"/>
    <p className="vaccination-alert-message">{children}</p>
    <button onClick={onClose} className="close-icon btn-link pull-right" aria-label={i18n.t('actions_close')} />
  </Alert>
);
