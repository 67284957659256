import TakePhotoIcon from './take-photo.svg';
import i18n from 'i18next';
import React, { CSSProperties, FC, useEffect } from 'react';
import { LoggerProps, withLogger } from '../../../../../logger';

export type Props = {
  title: string;
  onClick: (event: any) => void;
  onChange: (event: any) => void;
  style?: CSSProperties;
  withoutIcon?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
} & LoggerProps;

export const _IosSubmitButton: FC<Props> = ({ title,
                                              onClick, onChange, style, logger, withoutIcon,
                                              onMouseEnter, onMouseLeave }) => {
  useEffect(() => {
    logger.info(`render IOS input`);
  }, []);

  return (
    <label className={'btn btn-primary full-width-btn'} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {! withoutIcon && <img src={TakePhotoIcon} className="take-photo-icon" />}
      {title}
      <input type="file" accept="image/*,application/pdf" style={{ display: 'none' }} onClick={onClick} onChange={onChange} />
    </label>
  );
};

export const IosSubmitButton = withLogger(_IosSubmitButton);
