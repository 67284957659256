import { Record } from 'immutable';

export const employeeInfoRecordDefault = {
    employeeId: '',
    countryCode: '',
    locale: 'en',
    hireDate: '',
    firstName: '',
    lastName: '',
    popstarFeatures: [],
};

type EmployeeInfoRecordType = {
    employeeId: string;
    countryCode: string;
    locale: string;
    hireDate: string;
    firstName: string;
    lastName: string;
    popstarFeatures: string[];
};

export class EmployeeInfoRecord extends Record<EmployeeInfoRecordType>(employeeInfoRecordDefault) {
    constructor(employeeInfo: EmployeeInfoRecordType) {
        super(employeeInfo);
    }
}

export function isPopstarFeatureEnabled(context: EmployeeInfoRecord, feature: string): boolean {
    return context.popstarFeatures && context.popstarFeatures.includes(feature);
}
