import { Record, List } from 'immutable';
import { DateTime } from 'luxon';

const UNKNOWN_VACCINE_TYPE = 'UNKNOWN';
const DEFAULT_START_DATE = DateTime.fromISO('2021-01-01');
const UNSPECIFIED_DAYS_BETWEEN = 0;
const DEFAULT_BOOSTER_PERIOD = 1;
const DEFAULT_INOCULATION_PERIOD = 14;
const DEFAULT_MAX_BOOSTERS = 0;
const DEFAULT_VACCINATION_EXPIRATION = 0;
const DEFAULT_REQUIRED_BOOSTERS = 0;

const vaccineTypeRecordDefault: VaccineTypeRecordType = {
    name: 'Unknown',
    doses: 2,
    enumValue: UNKNOWN_VACCINE_TYPE,
    hidden: true,
    daysBetween: UNSPECIFIED_DAYS_BETWEEN,
    startDate: DEFAULT_START_DATE,
    inoculationPeriod: DEFAULT_INOCULATION_PERIOD,
    boosterPeriod: DEFAULT_BOOSTER_PERIOD,
    maxBoosters: DEFAULT_MAX_BOOSTERS,
    vaccinationExpiration: DEFAULT_VACCINATION_EXPIRATION,
    requiredBoosters: DEFAULT_REQUIRED_BOOSTERS,
};

interface VaccineTypeRecordType {
    name: string;
    doses: number;
    enumValue: string;
    hidden: boolean;
    daysBetween: number;
    startDate: DateTime;
    inoculationPeriod: number;
    boosterPeriod: number;
    maxBoosters: number;
    vaccinationExpiration: number;
    requiredBoosters: number;
}

export class VaccineTypeRecord extends Record<VaccineTypeRecordType>(vaccineTypeRecordDefault) {
    static UNKNOWN = UNKNOWN_VACCINE_TYPE;
    static DEFAULT_START_DATE = DEFAULT_START_DATE;
    static UNSPECIFIED_DAYS_BETWEEN = UNSPECIFIED_DAYS_BETWEEN;
    static DEFAULT_BOOSTER_PERIOD = DEFAULT_BOOSTER_PERIOD;
    static DEFAULT_INOCULATION_PERIOD = DEFAULT_INOCULATION_PERIOD;
    static DEFAULT_MAX_BOOSTERS = DEFAULT_MAX_BOOSTERS;
    static DEFAULT_VACCINATION_EXPIRATION = DEFAULT_VACCINATION_EXPIRATION;
    static DEFAULT_REQUIRED_BOOSTERS = DEFAULT_REQUIRED_BOOSTERS;

    public isValid() {
        return this.enumValue !== VaccineTypeRecord.UNKNOWN;
    }
}

export function getVaccineType(vaccineType: string, vaccineTypeRecords: List<VaccineTypeRecord>): VaccineTypeRecord {
    return vaccineType && vaccineTypeRecords.find((vaccineProvider) => vaccineProvider.enumValue.toUpperCase() === vaccineType.toUpperCase())
            // using the 'UNKNOWN' vaccine type from loaded types first, as there could be country-specific restrictions for 
            // unknown vaccine types e.g. different number of doses
           || vaccineTypeRecords.find((vaccineProvider) => vaccineProvider.enumValue === VaccineTypeRecord.UNKNOWN)
           // if an 'UNKNOWN' type is not defined in the loaded types, use the default
           || new VaccineTypeRecord();
}