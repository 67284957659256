import * as React from 'react';
import { ApiClients } from '../models/ApiClients';

export const ClientContext = React.createContext({} as ApiClients);

export const ClientContextProvider: React.FC<{
  children: React.ReactNode;
  clients: ApiClients;
}> = ({ children, clients }) => <ClientContext.Provider value={clients}>{children}</ClientContext.Provider>;

export function withClient(WrappedComponent: React.JSXElementConstructor<any>) {
  return function WrapperComponent(props: any) {
    return <ClientContext.Consumer>{clients => <WrappedComponent {...props} clients={clients} />}</ClientContext.Consumer>;
  };
}

export const useClientContext = () => React.useContext(ClientContext);
