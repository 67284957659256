import React, { Component } from 'react';
import i18n from 'i18next';
import { CovidTestLiteRecord } from 'src/apps/vaccination/models/vaccination/covidTestLiteRecord';
import { ILoggerProps, withLogger } from '../../../../../logger';
import { Table } from 'react-bootstrap';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { VaccinationBonusRecord } from '../../../models/vaccination/vaccinationBonusRecord';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { AppState } from '../../../../../models/appState';
import { dateDisplayOptions } from '../VaccinationBonusPage';
import { VaccinationBonusUpdate } from '../../../models/vaccination/vaccinationBonusUpdate';
import { CardStatus } from '../../../models/vaccination/getVaccinationCardStatusResponse';
import { Action, Dispatch } from 'redux';
import { getVaccinationSurveyAnswersV2Op } from '../../../actions/vaccinationBonusOperations';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { parseVaccinationUpdate } from '../../../models/__shared__/vaccinationUpdateUtils';
import { VaccinationSurveyRecord } from '../../../models/vaccination/vaccinationSurveyRecord';
import { ROUTES } from '../../../../../routes';
import { Link } from 'react-router-dom';
import { EmployeeInfo } from 'src/contexts/EmployeeInfoContext';
import { CardType } from '../../../models/apiRequest/cardType';
import { TestAttestationRecord } from '../../../models/vaccination/testAttestationRecord';
import {getTranslatedVaccineType} from "../../../utils/vaccination/getTranslatedVaccineType";
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

interface Props extends ILoggerProps {
  previousRecords: List<VaccinationBonusRecord>;
  covidTestLiteRecords: List<CovidTestLiteRecord>;
  testAttestationRecords: List<TestAttestationRecord>;
  surveyAnswers: List<VaccinationSurveyRecord>;
  updates: List<VaccinationBonusUpdate>;
  cardStatus: Record<CardType, CardStatus>;
  cardUpdatedAt: Record<CardType, number>;
  employeeId: string;

  fetchSurveyAnswersV2: (employeeId: string) => void;
}

interface HistoryRow {
  date: DateTime;
  text: string;
  hideDate?: boolean;
  strikethrough?: boolean;
}

class _ReportingHistory extends Component<Props> {
  static contextType = EmployeeInfo;
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchSurveyAnswersV2(this.context.employeeId);
  }

  render() {
    let entries = List<HistoryRow | undefined>();
    entries = entries.concat(this.props.previousRecords.flatMap(this.previousRecordToHistoryRows));
    entries = entries.concat(this.props.covidTestLiteRecords.flatMap(this.covidTestLiteHistoryRows));
    entries = entries.concat(this.props.testAttestationRecords.flatMap(this.testAttestationHistoryRows));
    entries = entries.concat(this.props.updates.map(this.updateToHistoryRow).filter(row => !!row));
    entries = entries.push(this.surveyAnswerToHistoryRow());
    entries = entries.concat(this.cardStatusToHistoryRow());

    const sortedEntries = entries.filter(obj => !!obj)
      .map(obj => obj as HistoryRow)
      .sort((entryA, entryB) => entryB.date.diff(entryA.date).milliseconds);

    return (
      <div className="reporting-history">
        <h2 className="sub-title">{i18n.t('vaccinationBonus.section.reportingHistory')}</h2>
        <p className="description">{i18n.t('vaccinationBonus.reportHistory.blurb')}</p>

        <div className="report-button-section">
          <Link
            className="btn btn-primary"
            to={ROUTES.VACCINATION.SUBMISSIONS.path}
            data-omniture-link="Vaccination Bonus - View submissions"
            data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - View submissions')}
          >
            {i18n.t('vaccinationBonus.reportHistory.viewSubmissions')}
          </Link>
        </div>

        {!sortedEntries.isEmpty() && (
          <>
            <Table className="history-table">
              <thead className="history-table-header">
                <tr>
                  <th>{i18n.t('vaccinationBonus.reportHistory.columnHeaders.date')}</th>
                  <th>{i18n.t('vaccinationBonus.reportHistory.columnHeaders.update')}</th>
                </tr>
              </thead>
              <tbody>
                {sortedEntries.map(this.renderHistoryRow)}
              </tbody>
            </Table>
          </>
        )}
      </div>
    );
  }

  private previousRecordToHistoryRows = (record: VaccinationBonusRecord): HistoryRow[] => {
    const vaccineNameString = getTranslatedVaccineType(record.vaccineType.name);
    const vaccineDisplayString = i18n.t('vaccinationBonus.reportHistory.messages.vaccineReceived', {
      vaccineType: vaccineNameString,
      interpolation: { escapeValue: false }
    });

    let updatedRow = null;
    if (record.updatedAt > record.createdAt) {
      updatedRow = {
        date: record.updatedAt,
        text: i18n.t('vaccinationBonus.reportHistory.messages.vaccineUpdated'),
      } as HistoryRow;
    }

    const submittedRow: HistoryRow = {
      date: record.createdAt,
      text: i18n.t('vaccinationBonus.reportHistory.messages.vaccineSubmitted'),
      strikethrough: updatedRow !== null,
    };

    const receivedRow: HistoryRow = {
      date: record.vaccinationDate,
      text: record.vaccineType.isValid()
        ? vaccineDisplayString
        : `${vaccineDisplayString} ${i18n.t('vaccinationBonus.reportHistory.messages.unknownDisclaimer')}`,
      hideDate:
        !record.vaccineType.isValid() || !record.vaccinationDate.isValid || record.vaccinationDate < record.vaccineType.startDate
    };

    if (updatedRow !== null) {
      return [submittedRow, receivedRow, updatedRow]
    }
    return [submittedRow, receivedRow];
  };

  private covidTestLiteHistoryRows = (record: CovidTestLiteRecord): HistoryRow[] => {
    const row: HistoryRow = {
      date: record.createdAtDate ?? DateTime.invalid('UNKNOWN!'),
      text: i18n.t('covidTestLite.reportHistory.messages.covidTestLiteResultReported')
    };
    return [row];
  }

  private testAttestationHistoryRows = (record: TestAttestationRecord): HistoryRow[] => {
    const row: HistoryRow = {
      date: record.createdAtDate ?? DateTime.invalid('UNKNOWN!'),
      text: i18n.t('vaccinationBonus.reportHistory.messages.testResultReported')
    };
    return [row];
  };

  private updateToHistoryRow = (update: VaccinationBonusUpdate): HistoryRow|undefined => {
    const result = parseVaccinationUpdate(update, this.props.logger);
    if (result === undefined) {
      return undefined;
    }

    return {
      date: result.date,
      text: result.text
    }
  };

  private surveyAnswerToHistoryRow = (): HistoryRow | undefined => {
    if (this.props.surveyAnswers.isEmpty()) {
      return undefined;
    }

    const mostRecentAnswer = this.props.surveyAnswers.max((answerA, answerB) => answerA.date.diff(answerB.date).milliseconds)!;

    return {
      date: mostRecentAnswer.date,
      text: i18n.t(`vaccinationBonus.reportHistory.messages.unvaccinated.${mostRecentAnswer.answer}`)
    };
  };

  private cardStatusToHistoryRow = (): HistoryRow[] => {
    const result: HistoryRow[] = [];

    [CardType.FirstDose, CardType.Vaccine, CardType.Booster, CardType.PCRTest].forEach((cardType) => {
      switch (this.props.cardStatus[cardType]) {
        case CardStatus.UPLOADED:
        case CardStatus.APPROVED:
        case CardStatus.REJECTED:
          result.push({
            date: DateTime.fromMillis(this.props.cardUpdatedAt[cardType]),
            text: i18n.t(`vaccinationBonus.reportHistory.messages.cardStatus.${this.props.cardStatus[cardType]}`,
              { cardType: i18n.t(`vaccinationBonus.submissions.card.${cardType}`)})
          } as HistoryRow);
          break;
        default:
          // INITIAL, NOT_UPLOADED etc. - do nothing
      }
    });

    return result;
  };

  private renderHistoryRow = (row: HistoryRow, key: number) => (
    <tr key={key} className={row.strikethrough ? 'strikethrough' : ''}>
      <td className="date-cell">
        {!row.hideDate &&
          row.date.toLocaleString(dateDisplayOptions as DateTimeFormatOptions, { locale: this.context.locale })}
      </td>
      <td>{row.text}</td>
    </tr>
  );
}

const mapState = ({ vaccination }: AppState): Props => ({
  previousRecords: vaccination.previousVaccinationRecords,
  covidTestLiteRecords: vaccination.covidTestLiteRecords,
  testAttestationRecords: vaccination.testAttestations,
  surveyAnswers: vaccination.surveyAnswers,
  updates: vaccination.vaccinationUpdates,
  cardStatus: {
    [CardType.FirstDose]: vaccination.firstDoseCardStatus,
    [CardType.Vaccine]: vaccination.vaccinationCardStatus,
    [CardType.Booster]: vaccination.boosterCardStatus,
    [CardType.PCRTest]: vaccination.cardStatusResponse.get(CardType.PCRTest)?.status,
  },
  cardUpdatedAt: {
    [CardType.FirstDose]: vaccination.firstDoseCardUpdatedAt,
    [CardType.Vaccine]: vaccination.vaccinationCardUpdatedAt,
    [CardType.Booster]: vaccination.boosterCardUpdatedAt,
    [CardType.PCRTest]: vaccination.cardStatusResponse.get(CardType.PCRTest)?.updatedAt,
  },
} as Props);

const mapDispatch = (dispatch: ThunkDispatch<{}, {}, never> & Dispatch<Action>) => ({
  fetchSurveyAnswersV2: (employeeId: string) => dispatch(getVaccinationSurveyAnswersV2Op(employeeId))
});

export const ReportingHistory = connect(mapState, mapDispatch)(withLogger(_ReportingHistory));
