import { Record } from 'immutable';

const featureRecordDefault = {
    name: '',
    active: false
};

type FeatureRecordType = {
    name: string;
    active: boolean;
};

export class FeatureRecord extends Record<FeatureRecordType>(featureRecordDefault) { }