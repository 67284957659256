import KatalLogger from '@amzn/katal-logger';
import i18n from 'i18next';
import { DateTime } from 'luxon';
import { VaccinationBonusUpdate } from '../vaccination/vaccinationBonusUpdate';

interface UpdateResult {
  date: DateTime;
  text: string;
}

export function parseVaccinationUpdate(update: VaccinationBonusUpdate, logger: KatalLogger): UpdateResult | undefined {
  switch (update.source) {
    case 'TAA':
    case 'DML_TIME':
      return parseTimeUpdate(update, logger);
    case 'TAA_ETS':
      return parseEtsUpdate(update, logger);
    case 'DML_PAY':
      return parseDmlPayUpdate(update, logger);
    case 'DML_SALARIED':
      return parseDmlSalariedUpdate(update, logger);
    case 'PAYROLL':
      return parsePayrollUpdate(update, logger);
    default:
      logger.warn('Unknown update source: "' + update.source + '"');
      return undefined;
  }
}

const parseTimeUpdate = (update: VaccinationBonusUpdate, logger: KatalLogger) => {
  switch (update.payload.get('STATUS')) {
    case 'ACCEPTED':
      const awardAmount = update.payload.get('TIME_AWARD_APPLIED');
      const awardType = update.payload.get('TIME_AWARD_TYPE');
      if (awardAmount === undefined || awardType === undefined) {
        logger.warn('Incomplete update content: awardAmount="' + awardAmount + '", awardType="' + awardType + '"');
        return undefined;
      }
      switch (awardType.toLowerCase()) {
        case 'upt':
          return {
            date: update.createdAt,
            text: i18n.t('vaccinationBonus.reportHistory.messages.uptGranted', { amount: awardAmount }),
          };
        case 'attendance':
        case 'attendance_points':
          return {
            date: update.createdAt,
            text: i18n.t('vaccinationBonus.reportHistory.messages.attendanceGranted', { amount: awardAmount }),
          };
        case 'infractions':
          return {
            date: update.createdAt,
            text: i18n.t('vaccinationBonus.reportHistory.messages.infractionExcused', { amount: awardAmount }),
          };
        case 'normal_discipline':
          return {
            date: update.createdAt,
            text: i18n.t('vaccinationBonus.reportHistory.messages.disciplineExcused', { amount: awardAmount }),
          };
        default:
          logger.warn('Unknown award type: "' + awardType + '"');
          return undefined;
      }
    case 'NOT_APPLICABLE':
      return {
        date: update.createdAt,
        text: i18n.t('vaccinationBonus.reportHistory.messages.timeRejected', { reason: 'Not applicable' }),
      };
    case 'REJECTED':
      const reason = update.payload.get('STATUS_REASON', 'N/A');
      return {
        date: update.createdAt,
        text: i18n.t('vaccinationBonus.reportHistory.messages.timeRejected', { reason: reason }),
      };
    default:
      logger.warn('Unknown time status: "' + update.payload.get('STATUS') + '"');
      return undefined;
  }
};

const parseEtsUpdate = (update: VaccinationBonusUpdate, logger: KatalLogger) => {
  switch (update.payload.get('STATUS')) {
    case 'ACCEPTED':
      const awardRefunded = update.payload.get('TIME_AWARD_REFUNDED');
      const awardType = update.payload.get('TIME_AWARD_TYPE');
      if (awardRefunded === undefined || awardType === undefined) {
        logger.warn('Incomplete update content: awardRefunded="' + awardRefunded + '", awardType="' + awardType + '"');
        return undefined;
      }
      switch (awardType.toLowerCase()) {
        case 'upt':
          return {
            date: update.createdAt,
            text: i18n.t('vaccinationBonus.reportHistory.messages.uptRefunded', { amount: awardRefunded }),
          };
        case 'points':
          return {
            date: update.createdAt,
            text: i18n.t('vaccinationBonus.reportHistory.messages.attendanceGranted', { amount: awardRefunded }),
          };
        case 'asi':
          return {
            date: update.createdAt,
            text: i18n.t('vaccinationBonus.reportHistory.messages.infractionExcused', { amount: awardRefunded }),
          };
        default:
          logger.warn('Unknown award type: "' + awardType + '"');
          return undefined;
      }
    case 'REJECTED':
      const reason = update.payload.get('STATUS_REASON', 'N/A');
      if (reason.toLowerCase() === 'not eligible') {
        return {
          date: update.createdAt,
          text: i18n.t('vaccinationBonus.reportHistory.messages.timeRejected', { reason: reason }),
        };
      }
      logger.info(`Silently ignoring status '${update.payload.get('STATUS')}' and reason '${reason}'`)
      return undefined;
    default:
      logger.warn(`Unknown ets time status: "${update.payload.get('STATUS')}'`);
      return undefined;
  }
};

const parseDmlPayUpdate = (update: VaccinationBonusUpdate, logger: KatalLogger) => {
  switch (update.payload.get('STATUS')) {
    case 'ACCEPTED':
      return {
        date: update.createdAt,
        text: i18n.t('vaccinationBonus.reportHistory.messages.payout'),
      };
    case 'REJECTED':
      const reason = update.payload.get('STATUS_REASON', 'N/A');
      return {
        date: update.createdAt,
        text: i18n.t('vaccinationBonus.reportHistory.messages.payRejected', { reason: reason }),
      };
    default:
      logger.warn('Unknown DML_PAY status: "' + update.payload.get('STATUS') + '"');
      return undefined;
  }
};

const parseDmlSalariedUpdate = (update: VaccinationBonusUpdate, logger: KatalLogger) => {
  switch (update.payload.get('STATUS')) {
    case 'ACCEPTED':
      const awardAmount = update.payload.get('TIME_AWARD_APPLIED');
      return {
        date: update.createdAt,
        text: i18n.t('vaccinationBonus.reportHistory.messages.ptoAwarded', { amount: awardAmount }),
      };
    case 'REJECTED':
      const reason = update.payload.get('STATUS_REASON', 'N/A');
      return {
        date: update.createdAt,
        text: i18n.t('vaccinationBonus.reportHistory.messages.timeRejected', { reason: reason }),
      };
    default:
      logger.warn('Unknown DML_SALARIED status: "' + update.payload.get('STATUS') + '"');
      return undefined;
  }
};

const parsePayrollUpdate = (update: VaccinationBonusUpdate, logger: KatalLogger) => {
  switch (update.payload.get('STATUS')) {
    case 'ACCEPTED':
      return {
        date: update.createdAt,
        text: i18n.t('vaccinationBonus.reportHistory.messages.paycheck'),
      };
    case 'REJECTED':
      const reason = update.payload.get('STATUS_REASON', 'N/A');
      return {
        date: update.createdAt,
        text: i18n.t('vaccinationBonus.reportHistory.messages.rejected', { reason: reason }),
      };
    default:
      logger.warn('Unknown PAYROLL status: "' + update.payload.get('STATUS') + '"');
      return undefined;
  }
};
