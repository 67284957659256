import React, { FC, ReactElement } from 'react';
import { Watermark } from '../../models/vaccination/watermark';
import { useEmployeeContext } from 'src/contexts/EmployeeInfoContext';
import { Col } from '@amzn/stencil-react-components/layout';

type Props = {
  topSection: ReactElement;
  bottomSection: ReactElement;
  watermark: Watermark;
};

const _BadgeScreen: FC<Props> = ({ topSection, bottomSection, watermark }) => {
  const employeeId = useEmployeeContext().employeeId;
  return (
    <div className={watermark}>
      <div className="proof-of-vaccination-page">
        {topSection}
        <div className="employee-photo-wrapper">
          <img className="employee-photo" src={`/badge_photo/${employeeId}`} alt="badge photo" width="240" />
        </div>
        <Col maxWidth="480px" minWidth="320px">
          {bottomSection}
        </Col>
      </div>
    </div>
  );
};

export const BadgeScreen = _BadgeScreen;
