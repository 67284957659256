import React, { CSSProperties, FC, useEffect } from 'react';
import TakePhotoIcon from './take-photo.svg';
import { LoggerProps, withLogger } from 'src/logger';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

type Props = {
  title: string;
  onClick: (event: any) => void;
  style?: CSSProperties;
  omnitureLink?: string;
  withoutIcon?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
} & LoggerProps;

export const _StandardSubmitButton: FC<Props> =
  ({ title,
     onClick, style, omnitureLink, logger, withoutIcon,
     onMouseEnter, onMouseLeave}) => {

  useEffect(() => {
    logger.info(`render standard input`);
  }, []);

  const omnLink = omnitureLink ?? 'Vaccination Bonus - Select Card';

  return (
    <button
      className={'btn btn-primary full-width-btn'}
      style={style}
      onClick={onClick}
      data-omniture-link={omnLink}
      data-pxt-telemetry-events={describeTelemetryClickEvent(omnLink)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {! withoutIcon && <img src={TakePhotoIcon} className="take-photo-icon" />}
      {title}
    </button>
  );
};

export const StandardSubmitButton = withLogger(_StandardSubmitButton);
