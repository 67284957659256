import 'rxjs/add/operator/mergeMap';
import { Observable } from 'rxjs/Observable';
import { ActionsObservable } from 'redux-observable';
import { Store } from 'redux';
import { AppAction } from '../actions/appAction';
import { AppState } from '../models/appState';
import { EpicDIConfig } from './config';
import { map, catchError } from 'rxjs/operators';
import {
    TimezoneActionTypes,
    TimezoneAction,
    createGetTimeZoneSuccessAction,
    createGetTimeZoneFailureAction,
    GetTimeZoneSuccessAction,
    GetTimeZoneRequestAction
} from '../actions/timezoneActions';

export function getTimeZoneEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { employeeInfo, timeoffClientSDK }: EpicDIConfig) {
    return action$.ofType(TimezoneActionTypes.GET_TIMEZONE_REQUEST)
        .mergeMap((action: GetTimeZoneRequestAction) => timeoffClientSDK
            .getTimeZone(employeeInfo.employeeId)
            .pipe(
                map((response: string): GetTimeZoneSuccessAction => createGetTimeZoneSuccessAction(response)),
                catchError(error => Observable.of<TimezoneAction>(createGetTimeZoneFailureAction(error)))
            ));
}