

// value - Katal placeholder, it should be replaced on deploy
// but in some cases, eg. local development build it won't be replaced
// so here we use fallbackValue instead for those cases
export const getKatalValue = (value: string, placeholderName: string, fallbackValue: string) => {
    const placeholder = '{{' + placeholderName + '}}';
    if (!value || value === placeholder) {
        // if placeholder wasn't replaced lets use fallbackValue
        value = fallbackValue;
    };
    return value;
}
