import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
  createGetBoosterCardStatusAction,
  createGetBoosterCardStatusSuccessAction,
  createGetCardStatusAction, createGetCovidTestLiteAction,
  createGetFirstDoseCardStatusAction,
  createGetFirstDoseCardStatusSuccessAction,
  createGetSurveyStatusAction,
  createGetTestAttestationsAction,
  createGetVaccinationBonusAction,
  createGetVaccinationCardStatusAction,
  createGetVaccinationCardStatusSuccessAction,
  createGetVaccinationComplianceAction,
  createGetVaccinationCountryConfigAction,
  createGetVaccinationUpdatesAction,
  VaccinationBonusActions,
} from '../../actions/vaccinationBonusActions';
import { VaccinationBonusPage, VaccinationBonusPageProps } from '../../components/vaccination/VaccinationBonusPage';
import { CardType } from '../../models/apiRequest/cardType';
import { AppState } from 'src/models/appState';
import { GetVaccinationCardStatusResponse } from '../../models/vaccination/getVaccinationCardStatusResponse';

export const mapStateToProps = ({ vaccination }: AppState) => {
  return {
    isInitiatingCardUpload: vaccination.isInitiatingCardUpload,
    isInitiatingCardUploadFailed: vaccination.isInitiatingCardUploadFailed,

    isLoadingFromBackend: vaccination.isLoadingFromBackend(),

    vaccinationCardUpdatedAt: vaccination.vaccinationCardUpdatedAt,
    isInitialLoad: vaccination.isInitialLoad,
    anyBoosterSubmitted: vaccination.previousVaccinationRecords.some(record => record.isBooster),
    boosterCardUpdatedAt: vaccination.boosterCardUpdatedAt,
    firstDoseCardUpdatedAt: vaccination.firstDoseCardUpdatedAt
  } as unknown as VaccinationBonusPageProps
};

export const mapDispatchToProps = (dispatch: Dispatch<VaccinationBonusActions>) => ({
  getVaccinationBonus: () => dispatch(createGetVaccinationBonusAction()),
  getCovidTestLiteRecords: () => dispatch(createGetCovidTestLiteAction()),
  getTestAttestations: () => dispatch(createGetTestAttestationsAction()),
  getVaccinationUpdates: () => dispatch(createGetVaccinationUpdatesAction()),
  getVaccinationCountryConfig: () => dispatch(createGetVaccinationCountryConfigAction()),
  getVaccinationCardStatus: () => dispatch(createGetVaccinationCardStatusAction()),
  setVaccinationCardStatus: (request: GetVaccinationCardStatusResponse) =>
    dispatch(createGetVaccinationCardStatusSuccessAction(request)),
  getBoosterCardStatus: () => dispatch(createGetBoosterCardStatusAction()),
  setBoosterCardStatus: (request: GetVaccinationCardStatusResponse) =>
    dispatch(createGetBoosterCardStatusSuccessAction(request)),
  getFirstDoseCardStatus: () => dispatch(createGetFirstDoseCardStatusAction()),
  setFirstDoseCardStatus: (request: GetVaccinationCardStatusResponse) =>
      dispatch(createGetFirstDoseCardStatusSuccessAction(request)),
  getTestAttestationCardStatus: () => dispatch(createGetCardStatusAction(CardType.PCRTest)),
  getSurveyStatus: () => dispatch(createGetSurveyStatusAction()),
  getVaccinationCompliance: () => dispatch(createGetVaccinationComplianceAction()),
  });

export const RequestVaccinationBonusContainerWithRouter = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VaccinationBonusPage));
