import { Modal } from 'react-bootstrap';
import React, { FC } from 'react';
import i18n from 'i18next';

type Props = {
  show: boolean;
  onHide: () => void;
  imageSrc: any;
};

const _ImagePreview: FC<Props> = ({ imageSrc, onHide, show }) => {
  return (
    <Modal show={show} onHide={onHide} id="card-preview">
      <Modal.Header closeButton={true} />
      <Modal.Body>
        <img src={imageSrc} className="card-preview" alt={i18n.t('vaccinationBonus.submissions.card.default')} />
      </Modal.Body>
    </Modal>
  );
};

export const ImagePreview = _ImagePreview;
