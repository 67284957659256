import axios, { CancelTokenSource } from 'axios';
import KatalLogger from '@amzn/katal-logger';
import { CampaignsClientSDK } from '../../../clients/campaignsClientSDK';
import { safeCardType } from '../../../models/apiRequest/cardType';
import { InitiateSubmitCardRequest } from '../../../models/apiRequest/initiateSubmitCardRequest';
import {
  CardStatus,
  GetVaccinationCardStatusResponse
} from '../../../models/vaccination/getVaccinationCardStatusResponse';
import { InitiateSubmitCardResponse } from '../../../models/vaccination/initiateSubmitCardResponse';

export const STATUS_UPDATE_PERIOD_MS = 3000;

enum UploadStatus {
  UPLOADED = 'UPLOADED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED'
}

interface Props {
  client: CampaignsClientSDK;
  logger: KatalLogger;
  loggerPrefix: string;
}

export interface UploadProps {
  file: File;
  mimeType: string;
  initiateRequest: InitiateSubmitCardRequest;

  // alternative is-card-status-ready logic
  isCardStatusReady?: (response: GetVaccinationCardStatusResponse) => boolean;

  onRememberCancelToken: (token: CancelTokenSource) => void;
  onCancelled: () => void;

  onError: (exception?: any) => void;

  cardStatusTimeoutMs: number;
  onCardStatusTimeout: () => void;
  onCardHung: (response: GetVaccinationCardStatusResponse) => void;
  onCardRejected: (response: GetVaccinationCardStatusResponse, reasons: string[]) => void;
  onCardApproved: (response: GetVaccinationCardStatusResponse) => void;
}

export class CardUploader {
  constructor(readonly props: Props) {}

  /**
   * Uploads the image file for a Card into S3 bucket securely
   * @returns undefined in case of error OR s3 image path
   */
  uploadCard = async (props: UploadProps): Promise<GetVaccinationCardStatusResponse | undefined> => {
    const {
      client,
      logger,
      loggerPrefix,
    } = this.props;

    logger.info(`${loggerPrefix}:uploadCard started`);

    let signedUrlResponse: InitiateSubmitCardResponse;
    try {
      signedUrlResponse = await client.initiateSubmitVaccinationCard(props.initiateRequest).toPromise();
    } catch (e) {
      logger.error(`${loggerPrefix}:uploadCard initiateSubmitVaccinationCard error: ${e}`);
      props.onError(e);
      return;
    }

    logger.info(`${loggerPrefix}:uploadCard Uploading to '${signedUrlResponse.url}'`);

    const uploadStatus = await this.putFile(props.file, props.mimeType, signedUrlResponse.url, props.onRememberCancelToken);

    if (uploadStatus === UploadStatus.CANCELED) {
      props.onCancelled();
      return;
    } else if (uploadStatus === UploadStatus.FAILED) {
      props.onError();
      return;
    }

    logger.info(`${loggerPrefix}:uploadCard Upload completed`);

    const employeeId = props.initiateRequest.employeeId;
    const cardType = safeCardType(props.initiateRequest.cardType);

    let cardStatusResponse: GetVaccinationCardStatusResponse = undefined as any;

    const isCardStatusReady = props.isCardStatusReady ?? this.isCardStatusReady;

    const isTimedOut = await this.repeatUntilOrTimeout(STATUS_UPDATE_PERIOD_MS, props.cardStatusTimeoutMs, async () => {
      try {
        cardStatusResponse = await client.getVaccinationCardStatus(employeeId, cardType).toPromise();
        logger.info(`${loggerPrefix}:uploadCard:getVaccinationCardStatus:response=${JSON.stringify(cardStatusResponse)}`);
        return this.isStatusRelatedToThisCard(signedUrlResponse, cardStatusResponse)
          && isCardStatusReady(cardStatusResponse);
      } catch (e) {
        props.onError(e);
        return true;
      }
    });

    if (isTimedOut) {
      if (this.isStatusRelatedToThisCard(signedUrlResponse, cardStatusResponse)) {
        props.onCardHung(cardStatusResponse);
        return cardStatusResponse;
      } else {
        props.onCardStatusTimeout();
        return undefined;
      }
    }

    switch (cardStatusResponse?.status) {
      case CardStatus.APPROVED:
        props.onCardApproved(cardStatusResponse);
        break;

      case CardStatus.REJECTED:
        const rejectionReasons = cardStatusResponse.rejectionReason ? cardStatusResponse.rejectionReason.split(',') : [];
        props.onCardRejected(cardStatusResponse, rejectionReasons);
        break;

      case CardStatus.UPLOADED:
        props.onCardHung(cardStatusResponse);
        break;

      default:
        break;  // onError() case handled above
    }

    return cardStatusResponse;
  };

  private repeatUntilOrTimeout = async (repeatIntervalMs: number, timeoutMs: number, fn: () => Promise<boolean>) => {
    const startTimeMs = performance.now();

    return new Promise((resolve) => {
      const interval = setInterval(async () => {
        const isTimedOut: boolean = performance.now() - startTimeMs > timeoutMs;
        const stopRepeating = isTimedOut || (await fn());

        if (stopRepeating) {
          clearInterval(interval);
          resolve(isTimedOut);
          return;
        }
      }, repeatIntervalMs);
    });
  };

  private putFile = async (file: File, mimeType: string, uploadUrl: string, onRememberCancelToken: (token: CancelTokenSource) => void) => {
    const {
      logger,
      loggerPrefix,
    } = this.props;

    const start = performance.now();

    const cancelTokenSource = axios.CancelToken.source();
    onRememberCancelToken(cancelTokenSource);

    try {
      const response = await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': mimeType,
          cancelToken: cancelTokenSource.token
        }
      });

      logger.info(`${loggerPrefix}:uploadFile uploading time: ` +
        `${Math.round(performance.now() - start)}ms with size: ${file.size} result: `,
        response
      );

      return UploadStatus.UPLOADED;

    } catch (err) {
      if (axios.isCancel(err)) {
        logger.info(`${loggerPrefix}:uploadFile: canceled`);
        return UploadStatus.CANCELED;
      } else {
        let msg = `${loggerPrefix}:uploadFile: failed`;
        // log network error only as warning since it's expected
        // that people can have poor connection and lose it mid-upload
        if (err.message === 'Network Error') {
          logger.warn(msg, err, {});
        } else {
          logger.error(msg, err, {});
        }
        return UploadStatus.FAILED;
      }
    }
  };

  private isCardStatusReady = (response: GetVaccinationCardStatusResponse) => {
    const cardStatus = CardStatus[response.status.toUpperCase()] || CardStatus.INITIAL;
    return [CardStatus.APPROVED, CardStatus.REJECTED].includes(cardStatus);
  };

  private isStatusRelatedToThisCard = (signedUrlResp: InitiateSubmitCardResponse, statusResp: GetVaccinationCardStatusResponse) => {
    // while Card in INITIAL status, GetVaccinationCardStatus endpoint will return status for the previous Card,
    // so need to verify the image path first
    return signedUrlResp.url.includes(statusResp?.imagePath);
  };

}
