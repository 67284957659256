import React, { Dispatch, FC } from 'react';
import { Col, View } from '@amzn/stencil-react-components/layout';
import { H1, Text } from '@amzn/stencil-react-components/text';
import i18n from 'i18next';
import { ReloadButtonSection } from 'src/apps/vaccination/components/vaccination/LandingComponents/ReloadButtonSection';
import { SurveyModal } from 'src/apps/vaccination/components/vaccination/LandingComponents/SurveyModal';
import { PrimaryButton } from '../../__shared__/button/Button';
import { ROUTES } from 'src/routes';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from 'src/models/appState';
import { Action } from 'redux';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

type Props = {
}

export const _TestReportingSection: FC<Props> = () => {
    return (
        <div className="vaccination-paper report-vaccine-status">
            <h2 className="sub-title">{i18n.t('covidTestLite.reportingSection.title')}</h2>
            <p className="description">{i18n.t('covidTestLite.reportingSection.description')}</p>
            <div className="report-button-section">
                <Link
                    className="btn btn-primary col-xs-12"
                    to={ROUTES.VACCINATION.COVID_TEST_LITE.ADD_DETAILS.path}
                    data-omniture-link="Vaccination Bonus - Report CovidTestLite click"
                    data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Report CovidTestLite click')}
                >
                    {i18n.t('covidTestLite.reportingSection.actionButtons.submitMyResults')}
                </Link>
            </div>
        </div>
    );
};

const mapState = (state: AppState) => ({
});

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
});

export const CovidTestLiteReportingSection = connect(mapState, mapDispatchToProps)(_TestReportingSection);
