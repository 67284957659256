import React, { FC } from 'react';
import { SkipConfirmationBox } from './SkipConfirmationBox';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import i18n from 'i18next';
import { CardUploadGuidance } from './CardUploadGuidance';
import { HelpBlock } from 'react-bootstrap';
import { isIOS } from 'react-device-detect';
import { IosSubmitButton } from '../__shared__/IosSubmitButton';
import { StandardSubmitButton } from '../__shared__/StandardSubmitButton';
import { PreviewStatus } from '../__shared__/PreviewStatus';
import { List } from 'immutable';

type Props = {
  errorMessage: string | null;
  rejectReasons: string[];
  previewStatus: PreviewStatus;
  isSkipBoxVisible: boolean;
  onSkipConfirmationBoxHide: () => void;
  dismissErrorBox: () => void;
  requestPermissions: (event: any) => void;
  loadFile: (event: any) => void;
  selectFile: (event: any) => void;
};

const _Header: FC<Props> = ({
  errorMessage,
  rejectReasons,
  isSkipBoxVisible,
  onSkipConfirmationBoxHide,
  dismissErrorBox,
  previewStatus,
  loadFile,
  selectFile,
  requestPermissions
}) => {
  return (
    <div className="select-photo-btn">
      <SkipConfirmationBox show={isSkipBoxVisible} onHide={onSkipConfirmationBoxHide} />
      {errorMessage && (
        <div className="toast-section">
          <MessageBanner onDismissed={dismissErrorBox} isDismissible={true} type={MessageBannerType.Error}>
            {i18n.t(`vaccinationBonus.workWithoutMask.VaccineCard.${errorMessage}`)}
            {!!rejectReasons && (
              <ul>
                {rejectReasons.map((value, key) => (
                  <li key={key}>
                    {i18n.t(`vaccinationBonus.workWithoutMask.VaccineCard.rejectionReasons.${value.toUpperCase()}`)}
                  </li>
                ))}
              </ul>
            )}
          </MessageBanner>
        </div>
      )}
      <h1 className="badge-screen-title">{i18n.t('vaccinationBonus.workWithoutMask.greenBadge.title')}</h1>
      <p className="content">{i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.blurb')}</p>
      <CardUploadGuidance />
      {previewStatus !== PreviewStatus.Ready &&
        (isIOS ? (
          <IosSubmitButton title={i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.photo')}
                           onClick={requestPermissions} onChange={loadFile} />
        ) : (
          <StandardSubmitButton title={i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.photo')}
                                onClick={selectFile} />
        ))}
      {errorMessage === 'invalidTypeErrorMessage' && (
        <HelpBlock>
          <div className="help-block-icon st-sprite st-alert-icon-2 pull-left" />
          <span className="help-block-text">{i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.helpBlockText')}</span>
        </HelpBlock>
      )}
    </div>
  );
};

export const Header = _Header;
