import { TestAttestationRecord } from '../vaccination/testAttestationRecord';
import { TestAttestationResponseRecord } from './getTestAttestationResponse';

export interface SubmitTestAttestationRequest extends TestAttestationResponseRecord {
  employeeId: string;
  imagePath?: string,
}

export function submitTestAttestationRequestFrom(attestation: TestAttestationRecord, employeeId: string): SubmitTestAttestationRequest {
  return {
    createAt: attestation.createAt,
    createdAtDate: attestation.createdAtDate?.toISODate(),
    result: attestation.result,
    collectionDate: attestation.collectionDate ? attestation.collectionDate.toISODate() : '',
    resultDate: attestation.resultDate ? attestation.resultDate.toISODate() : '',
    provider: attestation.provider,
    testType: attestation.testType,
    returnToWorkDate: attestation.returnToWorkDate ? attestation.returnToWorkDate.toISODate() : '',
    employeeId: employeeId,
    imagePath: attestation.imagePath,
  } as SubmitTestAttestationRequest
} 
