import { combineEpics } from 'redux-observable';
import { getFeatureEpic } from './featureEpic';
import { getTimeZoneEpic } from './timezoneEpic';
import {
    submitVaccinationBonusEpic,
    getVaccinationBonusEpic,
    getVaccinationUpdatesEpic,
    getVaccinationCardStatusEpic,
    getVaccinationCountryConfigEpic,
    getBoosterCardStatusEpic,
    submitVaccinationSurveyEpic,
    getVaccinationSurveyStatusEpic,
    getFirstDoseCardStatusEpic,
    getVaccinationComplianceEpic,
    getTestAttestationsEpic,
    submitTestAttestationEpic,
    updateTestAttestationEpic,
    getCardStatusEpic,
    refreshComplianceEpic,
    getCovidTestLiteEpic, submitCovidTestLiteEpic, updateCovidTestLiteEpic,
} from '../apps/vaccination/epics/vaccinationBonusEpic';

export const appEpic = combineEpics(
    getFeatureEpic,
    getTimeZoneEpic,
    submitVaccinationBonusEpic,
    getVaccinationBonusEpic,
    getVaccinationUpdatesEpic,
    getVaccinationCountryConfigEpic,
    getVaccinationCardStatusEpic,
    getCardStatusEpic,
    getBoosterCardStatusEpic,
    submitVaccinationSurveyEpic,
    getVaccinationSurveyStatusEpic,
    getFirstDoseCardStatusEpic,
    getVaccinationComplianceEpic,
    getTestAttestationsEpic,
    submitTestAttestationEpic,
    updateTestAttestationEpic,
    refreshComplianceEpic,
    getCovidTestLiteEpic, submitCovidTestLiteEpic, updateCovidTestLiteEpic,
);
