/* istanbul ignore file */
import React, { Component } from 'react';
import KatalLogger, { Level } from "@amzn/katal-logger";
import { STAGE_NAME, FALLBACK_LOGGER_URL } from './consts';
import { getKatalValue } from './utils/katal';
import { METRIC_NAME, metricsPublisher } from 'src/metrics';

let logger_: KatalLogger = null;

export const initializeLogger = (context?: {}) => {
  let loggerURL = getKatalValue('{{KatalLoggerUrl}}', 'KatalLoggerUrl', FALLBACK_LOGGER_URL);
  logger_ = new KatalLogger({
    url: loggerURL,
    logThreshold: Level.INFO,
    maxLogLineSize: 10000,
    logToConsole: STAGE_NAME !== 'prod',
    context
  });

  logger_.addErrorListener(() => {
    // emit the error count metric
    metricsPublisher.emitCountMetric(METRIC_NAME.APP_ERROR_UNKNOWN);
    return true;
  });

  return logger_;
};

export const getLogger = () => {
  if (!logger_) {
    initializeLogger();
  }
  return logger_;
};

export const addLoggerContext = (extraContext: {}) => {
  if (!logger_) {
    throw new Error("Logger must be initialized!");
  }
  logger_.context = Object.assign(logger.context || {}, extraContext);
};

export const LoggerContext = React.createContext({logger: getLogger()});

export const useLoggerContext = () => React.useContext(LoggerContext);

// tslint:disable-next-line:interface-name
export interface ILoggerProps {
  logger: KatalLogger;
}

export type LoggerProps = {
  logger: KatalLogger;
};

export class LoggerProvider extends Component<LoggerProps, Readonly<{}>> {
  logger: KatalLogger = this.props.logger;

  render() {
    return (
      <LoggerContext.Provider
        value={{
          logger: this.logger,
        }}
      >
        {this.props.children}
      </LoggerContext.Provider>
    );
  }
}

export function withLogger(WrappedComponent: React.JSXElementConstructor<any>) {
  return function ComponentWrapper(props: any) {
    return (
      <LoggerContext.Consumer>
        {(state) =>
          <WrappedComponent
            {...props}
            logger={state.logger}
          />
        }
      </LoggerContext.Consumer>
    );
  };
}

export const logger = getLogger();