import React, { FC, useState } from 'react';
import { Col } from '@amzn/stencil-react-components/layout';
import { TertiaryButton } from '../../__shared__/button/Button';
import { ImagePreview } from '../__shared__/ImagePreview';
import { ConfirmationModal } from '../__shared__/ConfirmationModal';
import i18n from 'i18next';
import './styles.scss';

export type Props = {
  imageSrc: string;
  imageAlt: string;
  enlargeButtonTitle: string;
  removeButtonTitle?: string;
  onRemoveImage?: () => void;
}

const _PreviewImageSection: FC<Props> = ({ imageSrc, imageAlt, enlargeButtonTitle,
                                           removeButtonTitle, onRemoveImage }) => {

  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const toggleCardPreview = () => setPreviewVisible(!isPreviewVisible);

  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);

  return (
    <div className="card-preview-image-section">
      <div className="card-image-box" onClick={toggleCardPreview}>
        <img className="card-preview" src={imageSrc} alt={imageAlt} />
      </div>
      <p className="card-image-box-tooltip">{enlargeButtonTitle}</p>

      {removeButtonTitle && <Col justifyContent="center">
        <TertiaryButton isFullWidth={false} onClick={() => setConfirmRemoveVisible(true)}>
          {removeButtonTitle}
        </TertiaryButton>

        <ConfirmationModal
          isOpen={isConfirmRemoveVisible}
          title={i18n.t('testAttestation.fileUploadForm.confirmRemoveModal.title')}
          text={i18n.t('testAttestation.fileUploadForm.confirmRemoveModal.text')}
          discardText={i18n.t('testAttestation.fileUploadForm.confirmRemoveModal.goBackButton')}
          confirmText={i18n.t('testAttestation.fileUploadForm.confirmRemoveModal.confirmButton')}
          onConfirmClick={onRemoveImage}
          onClose={() => setConfirmRemoveVisible(false)}
        />
      </Col>}

      <ImagePreview imageSrc={imageSrc} show={isPreviewVisible} onHide={toggleCardPreview} />
    </div>
  );
};

export const PreviewImageSection = _PreviewImageSection;
