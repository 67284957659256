import { fileTypeFromBlob } from 'file-type';
import { FileTypeResult } from 'file-type/core';
import i18n from 'i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouterProps, RouteComponentProps } from 'react-router-dom';
import { ILoggerProps, withLogger } from '../../../../../logger';
import { AppState } from '../../../../../models/appState';
import { getPdfImage } from '../../../utils/vaccination/getPdfImage';
import { isFileTypeAllowed } from '../../../utils/vaccination/isFileTypeAllowed';
import { ErrorStatusIndicator } from '../__shared__/ErrorStatusIndicator';
import { SelectImageButton } from '../__shared__/SelectImageButton';
import { SecondaryStencilButtonStyle } from '../__shared__/StencilButtonStyles';
import { PreviewStatus } from '../__shared__/PreviewStatus';
import { CardUploadGuidance } from '../__shared__/CardUploadGuidance';
import { Col, Spacer } from '@amzn/stencil-react-components/layout';
import { Card } from '@amzn/stencil-react-components/card';
import { Text } from '@amzn/stencil-react-components/text';
import { PreviewImageSection } from './PreviewImageSection';

const ONE_HUNDRED_MB = 100 * 1024 * 1024;
const LOG_PREFIX = 'PcrTestCard';

enum TakePhotoState {
  Initial = 'Initial',
  ImageTaken = 'ImageTaken',
  Error = 'Error'
}

export interface FileUploadFormProps extends RouteComponentProps, ILoggerProps, BrowserRouterProps {
  imageFile: File | undefined;
  imageSrc: string;
  setImage: (file: File | undefined, imageSrc: string) => void;
  setCardError: (messageId: string, rejectionReasons?: string[]) => void;
}

type FileUploadFormState = {
  status: TakePhotoState;
  previewStatus: PreviewStatus;
  src: string;
  file: File | undefined;
  mimeType: FileTypeResult | undefined;
  isInvalidImageType: boolean;
}

export class _FileUploadForm extends Component<FileUploadFormProps, FileUploadFormState> {
  state: FileUploadFormState = {
    status: this.props.imageSrc && this.props.imageFile ? TakePhotoState.ImageTaken : TakePhotoState.Initial,
    previewStatus: PreviewStatus.No,
    src: this.props.imageSrc,
    file: this.props.imageFile,
    mimeType: undefined,
    isInvalidImageType: false,
  };

  render() {
    return (
      <Col>
        <CardUploadGuidance title={i18n.t('testAttestation.addImageForm.imageGuidance.title')}
                            label={i18n.t('testAttestation.addImageForm.imageGuidance.label')}>
          <div>
            <ul>
              <li>{i18n.t('testAttestation.addImageForm.imageGuidance.listItem1')}</li>
              <li>{i18n.t('testAttestation.addImageForm.imageGuidance.listItem2')}</li>
              <li>{i18n.t('testAttestation.addImageForm.imageGuidance.listItem3')}</li>
              <li>{i18n.t('testAttestation.addImageForm.imageGuidance.listItem4')}</li>
            </ul>
            <p>{i18n.t('testAttestation.addImageForm.imageGuidance.helpText')}</p>
          </div>
        </CardUploadGuidance>

        <Card display="block" justifyContent="center" >

          {this.state.status !== TakePhotoState.ImageTaken && <Col>
            <Text fontWeight="bold">{i18n.t('testAttestation.fileUploadForm.imageWrapper')}</Text>
            <Spacer height={5} />
            <Card justifyContent="center" backgroundColor="neutral10">
              <Col justifyContent="center">
                {this.state.isInvalidImageType && (
                  <ErrorStatusIndicator message={i18n.t('testAttestation.fileUploadForm.error.invalidImageType')} />
                )}
                <Spacer height={24} />
                <SelectImageButton
                  title={i18n.t('testAttestation.fileUploadForm.selectImageButton')}
                  onFileSelected={this.setImageFile}
                  logPrefix={LOG_PREFIX}
                  style={SecondaryStencilButtonStyle}
                  withoutIcon={true}
                />
                <Spacer height={24} />
              </Col>
            </Card>
          </Col>}

          {this.state.status === TakePhotoState.ImageTaken &&
            <PreviewImageSection
              imageSrc={this.state.src}
              imageAlt={i18n.t('testAttestation.fileUploadForm.cardPreviewAlt')}
              enlargeButtonTitle={i18n.t('testAttestation.fileUploadForm.enlarge')}
              removeButtonTitle={i18n.t('testAttestation.fileUploadForm.removeImageButton')}
              onRemoveImage={() => this.resetImageFile()}
            />}

        </Card>
      </Col>
    );
  }

  private setImageFile = async (file: File) => {
    try {
      this.props.logger.info(`${LOG_PREFIX}:load:initiated`);
      const mimeType = await fileTypeFromBlob(file);
      this.props.logger.info(`${LOG_PREFIX}:retrieved: ${file.name}, mime: ${mimeType?.mime}`);

      if (! this.isSelectedImageFileTypeAllowed(file, mimeType)) {
        this.resetImageFile({ isInvalidImageType: true });
        this.props.setCardError('invalidTypeErrorMessage');
        return;
      } else if (file.size > ONE_HUNDRED_MB) {
        this.resetImageFile();
        this.props.setCardError('genericErrorMessage', ['IMAGE_TOO_LARGE']);
        return;
      }

      let src = '';
      if (mimeType?.mime === 'application/pdf') {
        this.setState({ previewStatus: PreviewStatus.Creating });
        try {
          src = await getPdfImage(URL.createObjectURL(file));
        } catch (e) {
          this.props.logger.error(`${LOG_PREFIX}:getPDFImage error`, e);
          this.props.setCardError('invalidPdfFile');
          return;
        }
      } else if ([file.type.substr(0, 6), mimeType?.mime.substr(0, 6)].includes('image/')) {
        src = URL.createObjectURL(file);
      }

      this.props.setImage(file, src);
      this.setState({
        status: TakePhotoState.ImageTaken,
        src: src,
        file: file,
        mimeType: mimeType,
        previewStatus: !!src ? PreviewStatus.Ready : PreviewStatus.Error,
        isInvalidImageType: false,
      });
    } catch (err) {
      this.props.logger.error(`${LOG_PREFIX}:load:${err.message}`, err, {});
    }
  };

  private resetImageFile = (props?: {isInvalidImageType: boolean}) => {
    this.props.setImage(undefined, '');
    this.setState({
      src: '',
      file: undefined,
      mimeType: undefined,
      previewStatus: PreviewStatus.No,
      status: TakePhotoState.Initial,
      isInvalidImageType: props?.isInvalidImageType ?? false,
    });
  };

  private isSelectedImageFileTypeAllowed = (file: File, mimeType?: FileTypeResult) => {
    const { logger } = this.props;
    return isFileTypeAllowed(file, mimeType, { logger });
  };

}

const mapState = (state: AppState, ownProps: Partial<FileUploadFormProps>) => ({
  imageFile: ownProps.imageFile,
  imageSrc: ownProps.imageSrc,
  setImage: ownProps.setImage,
  setCardError: ownProps.setCardError,
} as FileUploadFormProps);

export const FileUploadForm = connect(mapState)(withLogger(_FileUploadForm));
