/* tslint:disable:max-line-length */
import * as React from 'react';
import { default as i18n } from 'i18next';
import { get as lodashGet } from 'lodash';
import {
    CovidTestLiteReportingSection
} from 'src/apps/vaccination/components/vaccination/CovidTestLite/CovidTestLiteReportingSection';
import { CardType } from '../../models/apiRequest/cardType';
import { LoadingIndicator, LoadingIndicatorTheme } from '@atoz/atoz-common-ui-components';
import { ReportVaccineStatusSection } from './LandingComponents/ReportVaccineStatusSection';
import { ReportingHistory } from './LandingComponents/ReportingHistory';
import { HelpfulLinks } from './LandingComponents/HelpfulLinks';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { BrowserRouterProps, RouteComponentProps, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../../routes';
import { CardStatus, GetVaccinationCardStatusResponse } from '../../models/vaccination/getVaccinationCardStatusResponse';
import { VaccinationBonusRecord } from '../../models/vaccination/vaccinationBonusRecord';
import { AppState } from '../../../../models/appState';
import { Dispatch } from 'redux';
import {
  createInternalVaccinationBonusRequestUpdateAction,
  resetVaccinationResponse,
  VaccinationBonusActions
} from '../../actions/vaccinationBonusActions';
import { connect } from 'react-redux';
import { EmployeeInfo } from '../../../../contexts/EmployeeInfoContext';
import { TestReportingSection } from './TestAttestation/TestReportingSection';
import { isPopstarFeatureEnabled } from '../../../../models/EmployeeInfoRecord';
import { VaccinationFeatures } from '../../constant/vaccinationFeatures';
import { GetVaccinationBonusResponse } from '../../models/vaccination/getVaccinationBonusResponse';

interface VaccinationBonusLandingProps extends RouteComponentProps, BrowserRouterProps {
  hasFAQcontent: boolean;
  isLoadingFromBackend: boolean;
  isLoadingFromBackendFailed: boolean;
  submitVaccinationBonusResponse: GetVaccinationBonusResponse;
  submitSurveyResponse: boolean;
  surveyResponseSubmittedSuccess: boolean;
  surveyResponseSubmittedFailure: boolean;
  resetResponse: () => void;
  showHeaders: boolean;
  editDoseSuccessful: boolean;
  inoculationPeriodPassed: boolean;
  updateVaccinationBonusRequest: (request: VaccinationBonusRecord) => void;
  cardStatusResponse: Map<CardType, GetVaccinationCardStatusResponse>;
  isSubmittingCovidTestLiteSuccess: boolean;
}

class _VaccinationBonusLanding extends React.Component<VaccinationBonusLandingProps> {
  static contextType = EmployeeInfo;

  componentDidMount() {
    this.props.updateVaccinationBonusRequest(new VaccinationBonusRecord());
  }

  render() {
    return <>{this.props.isLoadingFromBackend ? <LoadingIndicator theme={LoadingIndicatorTheme.LIGHT} /> : this.renderPage()}</>;
  }

  private resetSurveyResponse = () => {
    this.props.history.push(ROUTES.VACCINATION.path);
  };

  private renderSuccessToast = () => {
    const vaccinationCardStatusResponse = this.props.cardStatusResponse.get(CardType.Vaccine);
    const vaccinationRejectionReasons = vaccinationCardStatusResponse?.rejectionReason?.split(',')
      .map(reason => i18n.t(`vaccinationBonus.workWithoutMask.VaccineCard.rejectionReasons.${reason}`));

    const testAttestationCardStatusResponse = this.props.cardStatusResponse.get(CardType.PCRTest);
    const testAttestationRejectionReasons = testAttestationCardStatusResponse?.rejectionReason?.split(',')
      .map(reason => i18n.t(`testAttestation.rejectionReasons.${reason}`));

    return (
      <div className="toast-section">
        {lodashGet(this.props.submitVaccinationBonusResponse, 'duplicate', false) && (
          <MessageBanner onDismissed={this.props.resetResponse} isDismissible={true} type={MessageBannerType.Success}>
            {i18n.t('vaccinationBonus.message.duplicateReport')}
          </MessageBanner>
        )}
        {this.props.submitSurveyResponse && (
          <MessageBanner onDismissed={this.resetSurveyResponse} isDismissible={true} type={MessageBannerType.Success}>
            {i18n.t('vaccinationBonus.message.successfulSurvey')}
          </MessageBanner>
        )}
        {this.props.surveyResponseSubmittedSuccess && (
            <MessageBanner onDismissed={this.resetSurveyResponse} isDismissible={true} type={MessageBannerType.Success}>
                {i18n.t('vaccinationBonus.message.successSurveySubmission')}
            </MessageBanner>
        )}
        {this.props.surveyResponseSubmittedFailure && (
          <MessageBanner onDismissed={this.resetSurveyResponse} isDismissible={true} type={MessageBannerType.Error}>
              {i18n.t('vaccinationBonus.message.failSurveySubmission')}
          </MessageBanner>
        )}
        {(this.props.submitVaccinationBonusResponse || this.props.editDoseSuccessful) && (
          <MessageBanner onDismissed={this.props.resetResponse} isDismissible={true} type={MessageBannerType.Success}>
            {i18n.t('vaccinationBonus.message.successfulReport')}
          </MessageBanner>
        )}
        {(this.props.isSubmittingCovidTestLiteSuccess) && (
          <MessageBanner onDismissed={this.props.resetResponse} isDismissible={true} type={MessageBannerType.Success}>
            {i18n.t('covidTestLite.message.successfulReport')}
          </MessageBanner>
        )}
        {vaccinationCardStatusResponse && (
          <>
            {isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
              && vaccinationCardStatusResponse.mlFinished ? (
              <>
                {vaccinationCardStatusResponse.status === CardStatus.REJECTED && (
                  <MessageBanner isDismissible={true} type={MessageBannerType.Error}>
                    {i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.cardRejected')}
                    {vaccinationRejectionReasons && <ul>
                      {vaccinationRejectionReasons.map((reason, key) => <li key={key}>{reason}</li>)}
                    </ul>}
                  </MessageBanner>
                )}
                {vaccinationCardStatusResponse.status === CardStatus.UPLOADED && (
                  <MessageBanner isDismissible={true} type={MessageBannerType.Warning}>
                    {i18n.t('vaccinationBonus.workWithoutMask.VaccineCard.cardPendingManualReview')}
                  </MessageBanner>
                )}
              </>
            ) : (
              vaccinationCardStatusResponse.status === CardStatus.UPLOADED &&
              this.props.inoculationPeriodPassed && (
                <MessageBanner onDismissed={this.props.resetResponse} isDismissible={true} type={MessageBannerType.Success}>
                  {i18n.t('vaccinationBonus.message.certificateAvailable')}
                </MessageBanner>
              )
            )}
          </>
        )}
        {isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_TEST_ATTESTATION) && testAttestationCardStatusResponse?.mlFinished && <>
          {testAttestationCardStatusResponse?.status === CardStatus.REJECTED && (
            <MessageBanner isDismissible={true} type={MessageBannerType.Error}>
              {i18n.t('testAttestation.errorMessage.cardRejected')}
              {testAttestationRejectionReasons &&
                <ul>
                  {testAttestationRejectionReasons.map((reason, key) => <li key={key}>{reason}</li>)}
                </ul>}
            </MessageBanner>
          )}
          {testAttestationCardStatusResponse.status === CardStatus.UPLOADED && (
            <MessageBanner isDismissible={true} type={MessageBannerType.Warning}>
              {i18n.t('testAttestation.cardPendingManualReview')}
            </MessageBanner>
          )}
        </>}
      </div>
    );
  };

  private renderPage = () => {
      const testAttestationEnabled = isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_TEST_ATTESTATION);
      const covidTestLiteEnabled = isPopstarFeatureEnabled(this.context, VaccinationFeatures.COVID_TEST_LITE);
      return (
          <div className="vaccination-bonus-page">
              <div className="background">
                  {this.props.showHeaders && <h1 className="title">{i18n.t('vaccinationBonus.title.landing')}</h1>}
                  {this.renderSuccessToast()}
                  {covidTestLiteEnabled && <CovidTestLiteReportingSection/>}
                  {(testAttestationEnabled && !this.props.inoculationPeriodPassed) && <TestReportingSection/>}
                  <ReportVaccineStatusSection/>
                  <HelpfulLinks hasFAQcontent={this.props.hasFAQcontent}/>
                  <ReportingHistory/>
              </div>
          </div>
      );
  }
}

export const mapStateToProps = ({ vaccination, uiFeatures }: AppState, ownProps: Partial<VaccinationBonusLandingProps>) =>
  ({
    hasFAQcontent: ownProps.hasFAQcontent || false,
    isLoadingFromBackend: vaccination.isLoadingFromBackend(),
    isLoadingFromBackendFailed: vaccination.isLoadingFromBackendFailed(),
    submitVaccinationBonusResponse: vaccination.submitVaccinationBonusResponse,
    submitSurveyResponse: ownProps.submitSurveyResponse || false,
    showHeaders: !uiFeatures.hideHeaders,
    editDoseSuccessful: vaccination.editDoseSuccessful,
    inoculationPeriodPassed: vaccination.isInoculationPeriodPassed(),
    surveyResponseSubmittedSuccess: vaccination.surveyResponseSubmittedSuccess,
    surveyResponseSubmittedFailure: vaccination.surveyResponseSubmittedFailure,
    cardStatusResponse: vaccination.cardStatusResponse,
    isSubmittingCovidTestLiteSuccess: vaccination.isSubmittingCovidTestLiteSuccess,
  } as VaccinationBonusLandingProps);

export const mapDispatch = (dispatch: Dispatch<VaccinationBonusActions>) => ({
  resetResponse: () => dispatch(resetVaccinationResponse()),
  updateVaccinationBonusRequest: (request: VaccinationBonusRecord) =>
    dispatch(createInternalVaccinationBonusRequestUpdateAction(request))
});

export const VaccinationBonusLanding = connect(mapStateToProps, mapDispatch)(withRouter(_VaccinationBonusLanding));
