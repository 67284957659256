import { Card } from '@amzn/stencil-react-components/card';
import { Checkbox } from '@amzn/stencil-react-components/dist/submodules/form';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { H3, Label, Text } from '@amzn/stencil-react-components/text';
import { CancelTokenSource } from 'axios';
import i18n from 'i18next';
import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';
import React, { Component, Dispatch } from 'react';
import { isDesktop } from 'react-device-detect';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import {
  createSubmitTestAttestationAction,
  createGetCardStatusSuccessAction,
  updateTestAttestationRecordAction,
  createGetVaccinationComplianceAction,
} from '../../../actions/vaccinationBonusActions';
import { withClient } from 'src/contexts/clientContext';
import { ILoggerProps, withLogger } from 'src/logger';
import { OmnitureHelper } from 'src/models/omnitureHelper';
import { ApiClientsProps } from 'src/models/ApiClients';
import { CardType } from '../../../models/apiRequest/cardType';
import { InitiateSubmitCardRequest } from '../../../models/apiRequest/initiateSubmitCardRequest';
import {
  SubmitTestAttestationRequest,
  submitTestAttestationRequestFrom
} from '../../../models/apiRequest/submitTestAttestationRequest';
import { AppState } from 'src/models/appState';
import { CardStatus } from '../../../models/vaccination/getCardsStatusResponse';
import { GetVaccinationCardStatusResponse } from '../../../models/vaccination/getVaccinationCardStatusResponse';
import {
  defaultTestAttestationRecord,
  isRecordFilled,
  isRecordFilledWithoutImage,
  PCRTestProvider,
  PCRTestResult,
  TestAttestationRecord
} from '../../../models/vaccination/testAttestationRecord';
import { ROUTES } from 'src/routes';
import { PrimaryButton, TertiaryButton } from '../../__shared__/button/Button';
import { CardUploader } from '../__shared__/CardUploader';
import { ConfirmationModal } from '../__shared__/ConfirmationModal';
import { ErrorStatusIndicator } from '../__shared__/ErrorStatusIndicator';
import { ProcessingSpinner } from '../__shared__/ProcessingSpinner';
import { dateDisplayOptions } from '../VaccinationBonusPage';
import { ErrorBannerWithRejectReasons } from './ErrorBannerWithRejectReasons';
import { PreviewImageSection } from './PreviewImageSection';
import { EmployeeInfo } from 'src/contexts/EmployeeInfoContext';
import { fileTypeFromBlob } from "file-type";
import { sendTelemetryEvent } from 'src/utils/telemetry/TelemetryHelper';

const PAGE_NAME = 'TestAttestationReview';
const CARD_STATUS_TIMEOUT_MS = 30000;

interface Props extends RouteComponentProps, ILoggerProps, ApiClientsProps {
  record: TestAttestationRecord;
  updateTestAttestationRecord: (record: TestAttestationRecord) => void;
  submitTestAttestation: (request: SubmitTestAttestationRequest) => void;
  updateCardStatusResponse: (cardType: CardType, response: GetVaccinationCardStatusResponse) => void;
  getVaccinationCompliance: () => void;
  isSubmittingTestAttestation: boolean;
  isSubmittingTestAttestationSuccess: boolean;
  isSubmittingTestAttestationFailure: boolean;
}

interface State {
  isConsent: boolean;
  highlightEmptyFields: boolean;
  isUploadingImage: boolean;
  isUploadingImageSuccess: boolean;
  isUploadingImageFailure: boolean;
  cancelTokenSource: CancelTokenSource | undefined;
  errorBannerMessageId: string | undefined;
  rejectReasons: string[];
  cardStatusResponse: GetVaccinationCardStatusResponse | undefined;
  confirmCancelModalVisible: boolean;
}

const initialState: State = {
  isConsent: false,
  highlightEmptyFields: false,
  isUploadingImage: false,
  isUploadingImageSuccess: false,
  isUploadingImageFailure: false,
  cancelTokenSource: undefined,
  errorBannerMessageId: undefined,
  rejectReasons: [],
  cardStatusResponse: undefined,
  confirmCancelModalVisible: false,
};

export class _TestAttestationReview extends Component<Props, State> {
  static contextType = EmployeeInfo;

  state: State = initialState;

  componentDidMount() {
    const fullyFilled =
      isRecordFilled(this.props.record) ||
      isRecordFilledWithoutImage(this.props.record) && this.props.record.provider === PCRTestProvider.AMAZON_DX;
    if (!fullyFilled) {
      this.props.history.push(ROUTES.VACCINATION.TEST_ATTESTATION.path);
    }
  }

  componentDidUpdate(
      prevProps: Readonly<Props>,
      prevState: Readonly<State>,
      snapshot?: any
  ) {
    this.handleSuccessSubmit(prevProps);
    this.handleFailedSubmit(prevProps);
  }

  handleConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ isConsent: e?.target?.checked });
  handleEditDetails = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.props.history.push(ROUTES.VACCINATION.TEST_ATTESTATION.path);
  };
  handleEditImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.props.history.push(ROUTES.VACCINATION.TEST_ATTESTATION.ADD_IMAGE.path);
  };

  handleSubmit = async () => {
    if (! this.state.isConsent) {
      this.setState({ highlightEmptyFields: true } );
      return;
    }

    try {
      if (this.props.record.provider === PCRTestProvider.AMAZON_DX) {
        this.submitTest(undefined);
      } else {
        const cardStatusResponse = await this.submitCardImage();
        if (cardStatusResponse && this.state.isUploadingImageSuccess) {
          this.submitTest(cardStatusResponse.imagePath);
        }
      }
    } catch (err) {
      this.props.logger.error(`${PAGE_NAME}: Could't submit test result, err:`, err);
      this.cancelUpload('genericErrorMessage');
      return;
    }
  };

  handleCancelConfirmed = () => {
    this.resetRecord();
    this.props.history.push(ROUTES.VACCINATION.path);
  };

  handleSuccessSubmit = (prevProps: Props) => {
    if (!this.props.isSubmittingTestAttestationSuccess || prevProps.isSubmittingTestAttestationSuccess) {
      return;
    }

    if (this.state.cardStatusResponse?.status === CardStatus.REJECTED) {
      // stay here with error banner
    } else if (this.props.record.result === PCRTestResult.POSITIVE &&
        (this.props.record.provider === PCRTestProvider.AMAZON_DX || this.state.cardStatusResponse?.status === CardStatus.APPROVED)) {
      this.resetRecord();
      this.props.history.push(ROUTES.VACCINATION.TEST_ATTESTATION.POSITIVE_RESULT.path);
    } else {
      this.resetRecord();
      this.props.history.push(ROUTES.VACCINATION.path);
    }
  };

  handleFailedSubmit = (prevProps: Props) => {
    if (!this.props.isSubmittingTestAttestationFailure || prevProps.isSubmittingTestAttestationFailure) {
      return;
    }

    this.setErrorMessage('genericErrorMessage');
  };

  render() {
    const isConsentError = this.state.highlightEmptyFields && !this.state.isConsent;

    return (
      <div className="test-attestation-form">
        <View maxWidth="480px" minWidth="320px" margin="0 auto" padding="20px 15px" backgroundColor="neutral0">
          <ErrorBannerWithRejectReasons
            messageId={this.state.errorBannerMessageId}
            rejectReasons={this.state.rejectReasons}
            onDismissed={this.resetErrorMessage}
          />

          <Col>
            <H3 fontWeight="bold">
              {i18n.t('testAttestation.testInfoSummaryForm.title')}
            </H3>
            <Spacer height={20}/>
            <Text>{i18n.t('testAttestation.testInfoSummaryForm.subTitle')}</Text>
          </Col>

          <Spacer height={32}/>

          <Card>
            <Col gridGap="S200" width={'100%'}>
              <Text fontWeight="bold">{i18n.t('testAttestation.testInfoSummaryForm.details.title')}</Text>
              <Row gridGap="S100">
                <Text>{i18n.t('testAttestation.testInfoSummaryForm.details.result')}</Text>
                <Text fontWeight="bold">{i18n.t(`testAttestation.testInfoForm.testRadio.${this.props.record.result}`)}</Text>
              </Row>
              <Row gridGap="S100">
                <Text>{i18n.t('testAttestation.testInfoSummaryForm.details.collectionDate')}</Text>
                <Text fontWeight="bold">{this.props.record.collectionDate && this.toLocaleDateString(this.props.record.collectionDate)}</Text>
              </Row>
              <Row gridGap="S100">
                <Text>{i18n.t('testAttestation.testInfoSummaryForm.details.resultDate')}</Text>
                <Text fontWeight="bold">{this.props.record.resultDate && this.toLocaleDateString(this.props.record.resultDate)}</Text>
              </Row>
              <Row gridGap="S100">
                <Text>{i18n.t('testAttestation.testInfoSummaryForm.details.provider')}</Text>
                <Text fontWeight="bold">{i18n.t(`testAttestation.testInfoForm.testProvider.${this.props.record.provider}`)}</Text>
              </Row>
              {this.props.record.provider !== PCRTestProvider.AMAZON_DX &&
                <Row gridGap="S100">
                    <Text>{i18n.t('testAttestation.testInfoSummaryForm.details.testType')}</Text>
                    <Text fontWeight="bold">{i18n.t(`testAttestation.testInfoForm.typeOfTest.${this.props.record.testType}`)}</Text>
                </Row>
              }
              <TertiaryButton isFullWidth={true} onClick={this.handleEditDetails}>
                <Text fontSize="T400" fontWeight="regular">{i18n.t('testAttestation.testInfoSummaryForm.editButton')}</Text>
              </TertiaryButton>
            </Col>
          </Card>

          {this.props.record.provider !== PCRTestProvider.AMAZON_DX && <>
            <Spacer height={20}/>
            <Card>
              <Col gridGap="S200" width="100%">
                <Text fontWeight="bold">{i18n.t('testAttestation.testInfoSummaryForm.imageTitle')}</Text>
                <PreviewImageSection
                  imageSrc={this.props.record.imageSrc}
                  imageAlt={i18n.t('testAttestation.fileUploadForm.cardPreviewAlt')}
                  enlargeButtonTitle={i18n.t('testAttestation.fileUploadForm.enlarge')}
                />
                <TertiaryButton isFullWidth={true} onClick={this.handleEditImage}>
                  <Text fontSize="T400" fontWeight="regular">{i18n.t('testAttestation.testInfoSummaryForm.editButton')}</Text>
                </TertiaryButton>
              </Col>
            </Card>
          </>}

          <Spacer height={20}/>

          <Col>
            <Label>
              <Row alignItems="top" gridGap="S300">
                <Checkbox checked={this.state.isConsent} required={true} onChange={this.handleConsentChange}
                          error={isConsentError} />
                <Label>{i18n.t('testAttestation.testInfoSummaryForm.consent')}</Label>
              </Row>
            </Label>
            {isConsentError && <ErrorStatusIndicator message={i18n.t('testAttestation.errorTooltip.checkConsent')}/>}
          </Col>

          <Spacer height={20}/>

          <Col>
            <PrimaryButton isFullWidth={true} onClick={this.handleSubmit}>
              <Text fontSize="T400">{i18n.t('testAttestation.testInfoSummaryForm.submitButton')}</Text>
            </PrimaryButton>
          </Col>

          {isDesktop && <>
            <Spacer height={20}/>
            <Col>
              <TertiaryButton isFullWidth={true} onClick={() => this.setState({ confirmCancelModalVisible: true })}>
                <Text>{i18n.t('testAttestation.testInfoSummaryForm.cancelButton')}</Text>
              </TertiaryButton>

              <ConfirmationModal
                isOpen={this.state.confirmCancelModalVisible}
                title={i18n.t('testAttestation.confirmCancelModal.title')}
                text={i18n.t('testAttestation.confirmCancelModal.text')}
                discardText={i18n.t('testAttestation.confirmCancelModal.goBackButton')}
                confirmText={i18n.t('testAttestation.confirmCancelModal.confirmButton')}
                onConfirmClick={this.handleCancelConfirmed}
                onClose={() => this.setState({ confirmCancelModalVisible: false })}
              />
            </Col>
          </>}

          <ProcessingSpinner
            title={i18n.t('testAttestation.testInfoSummaryForm.processingTitle')}
            blurb={i18n.t('testAttestation.testInfoSummaryForm.processingBlurb')}
            isProcessing={this.state.isUploadingImage || this.props.isSubmittingTestAttestation}
            onHide={() => this.cancelUpload('userCancelled')}
          />
        </View>
      </div>
    );
  }

  private toLocaleDateString = (dateTime: DateTime): string => {
    return dateTime.toLocaleString(dateDisplayOptions as DateTimeFormatOptions, { locale: this.context.locale });
  };

  private submitTest = (imagePath?: string) => {
    const record: TestAttestationRecord = {
      ...this.props.record,
      imagePath: imagePath,
    };
    this.props.submitTestAttestation(submitTestAttestationRequestFrom(record, this.context.employeeId));
  };

  // returns S3 image path or `undefined` in case of error
  private submitCardImage = async (): Promise<GetVaccinationCardStatusResponse | undefined> => {
    const uploader = new CardUploader({
      logger: this.props.logger,
      loggerPrefix: PAGE_NAME,
      client: this.props.clients.campaignsClientSDK,
    });

    this.setState({
      isUploadingImage: true,
      isUploadingImageSuccess: false,
      isUploadingImageFailure: false,
    });

    const saveCardStatusResponse = (response: GetVaccinationCardStatusResponse) => {
      this.setState({
        cardStatusResponse: response,
        isUploadingImageSuccess: true,
      });
      this.props.updateCardStatusResponse(cardType, response);
    };

    const cardType = CardType.PCRTest;

    const initiateRequest = new InitiateSubmitCardRequest(this.context.employeeId, 'unknown', this.context.countryCode, cardType);

    const imageFile = this.props.record.imageFile;
    const mimeType = imageFile != undefined ? await fileTypeFromBlob(imageFile) : undefined;
    const mimeTypeString = mimeType?.mime || this.props.record.imageFile?.type || "";

    return await uploader.uploadCard({
      file: this.props.record.imageFile!,
      mimeType: mimeTypeString,
      initiateRequest: initiateRequest,
      onRememberCancelToken: (token) => {
        this.setState({ cancelTokenSource: token });
      },
      onCancelled: () => {
        this.cancelUpload('userCancelled');
      },
      onError: (exception?: any) => {
        this.props.logger.error(`Couldn't upload image, exception ${exception}`);
        this.setState({ isUploadingImageFailure: true });
        this.cancelUpload('genericErrorMessage');
      },
      cardStatusTimeoutMs: CARD_STATUS_TIMEOUT_MS,
      onCardStatusTimeout: () => {
        this.setState({ isUploadingImageFailure: true });
        this.cancelUpload('genericErrorMessage');
      },
      onCardHung: (response: GetVaccinationCardStatusResponse) => {
        saveCardStatusResponse(response);
      },
      onCardRejected: (response: GetVaccinationCardStatusResponse, reasons: string[]) => {
        saveCardStatusResponse(response);
        this.props.getVaccinationCompliance();
        this.cancelUpload('cardRejected', reasons);
      },
      onCardApproved: (response: GetVaccinationCardStatusResponse) => {
        OmnitureHelper.setOmniturePageName(`${PAGE_NAME} - Submit Card finished success`);
        sendTelemetryEvent(PAGE_NAME, `${PAGE_NAME} - Submit Card finished success`);
        this.props.getVaccinationCompliance();
        saveCardStatusResponse(response);
      },
    });
  };

  private cancelUpload = (errorMessageId?: string, rejectionReasons?: string[]) => {
    this.state.cancelTokenSource?.cancel(`${errorMessageId}`);

    this.setState({
      isUploadingImage: false,
      cancelTokenSource: undefined,
    });

    if (errorMessageId) {
      this.setErrorMessage(errorMessageId, rejectionReasons);
    }
  };

  private setErrorMessage = (messageId: string, rejectionReasons?: string[]) => {
    this.setState({
      errorBannerMessageId: messageId,
      rejectReasons: rejectionReasons || this.state.rejectReasons,
    });
    window.scrollTo(0, 0);  // scroll to error message banner
  };

  private resetErrorMessage = () => this.setState({ errorBannerMessageId: undefined, rejectReasons: [] });

  private resetState = () => this.setState(initialState);
  private resetRecord = () => this.props.updateTestAttestationRecord(defaultTestAttestationRecord);
  private resetAll = () => {
    this.resetState();
    this.resetRecord();
  }
}

const mapStateToProps = ({ vaccination }: AppState) => ({
  record: vaccination.testAttestationRecord,
  isSubmittingTestAttestation: vaccination.isSubmittingTestAttestation,
  isSubmittingTestAttestationSuccess: vaccination.isSubmittingTestAttestationSuccess,
  isSubmittingTestAttestationFailure: vaccination.isSubmittingTestAttestationFailure,
} as Props);

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateTestAttestationRecord: (record: TestAttestationRecord) => dispatch(updateTestAttestationRecordAction(record)),
  submitTestAttestation: (request: SubmitTestAttestationRequest) => dispatch(createSubmitTestAttestationAction(request)),
  updateCardStatusResponse: (cardType: CardType, response: GetVaccinationCardStatusResponse) =>
      dispatch(createGetCardStatusSuccessAction(cardType, response)),
  getVaccinationCompliance: () => dispatch(createGetVaccinationComplianceAction())
} as Props);

export const TestAttestationReview =
  connect(mapStateToProps, mapDispatchToProps)(withClient(withLogger(withRouter(_TestAttestationReview))));
