import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import {AtozGatewayClient, Logger, STAGES} from 'atoz-api-gateway-client';
import {SubmitCovidTestLiteRequest} from 'src/apps/vaccination/models/apiRequest/submitCovidTestLiteRequest';
import {CardType} from '../models/apiRequest/cardType';
import {SubmitTestAttestationRequest} from '../models/apiRequest/submitTestAttestationRequest';
import * as API from '../paths/api/campaignsApiGateway'
import {SubmitVaccinationBonusRequest} from '../models/apiRequest/submitVaccinationBonusRequest';
import {InitiateSubmitCardRequest} from '../models/apiRequest/initiateSubmitCardRequest';
import {SubmitVaccinationSurveyRequest} from '../models/apiRequest/submitVaccinationSurveyRequest';
import {STAGE_NAME} from 'src/consts';
import {getLogger} from 'src/logger';

export const ATOZ_REVERSE_PROXY_VACCINATION_BONUS_API_SUFFIX = '/apis/AtoZCampaignsService'

const baseUrlMap = new Map<STAGES, string>([
    [STAGES.beta, 'https://atoz.integ.amazon.com'],
    [STAGES.gamma, 'https://atoz.integ.amazon.com'],
    [STAGES.prod, 'https://atoz.amazon.work'],
])

const getHosts = (stage: STAGES, isReverseProxyEnabled?: boolean): Map<STAGES, string> | undefined => {
    if (!isReverseProxyEnabled) {
        return undefined;
    }
    if (stage === STAGES.development) {
        return new Map<STAGES, string>([
            [STAGES.development, 'http://localhost:8080/gateway'],
        ])
    }
    const baseUrl = window.location.origin || baseUrlMap.get(stage);
    return new Map<STAGES, string>([
        [stage, `${baseUrl}${ATOZ_REVERSE_PROXY_VACCINATION_BONUS_API_SUFFIX}`],
    ]);
}

export const getCampaignsService = (employeeId: string, stage: STAGES, logger?: Logger, isReverseProxyEnabled?: boolean) => {
    return new AtozGatewayClient({
        clientId: 'ATOZ_CAMPAIGNS_SERVICE',
        hosts: getHosts(stage, isReverseProxyEnabled),
        stage,
        employeeId,
        logger,
    });
}

export class CampaignsClientSDK {
    private client: AtozGatewayClient;

    constructor(campaignsService: AtozGatewayClient) {
        this.client = campaignsService;
    }

    submitVaccinationBonus(request: SubmitVaccinationBonusRequest) {
        const requestPath: string = API.SUBMIT_VACCINATION_BONUS();
        return this.client.post(requestPath, JSON.stringify(request)).pipe();
    }

    getVaccinationBonus(employeeId: string) {
        const requestPath: string = API.GET_VACCINATION_BONUS(employeeId);
        return this.client.get(requestPath).pipe();
    }

    getVaccinationUpdates(employeeId: string) {
        const requestPath: string = API.GET_VACCINATION_UPDATES(employeeId);
        return this.client.get(requestPath).pipe();
    }

    getVaccinationCountryConfig(country: string) {
        const requestPath: string = API.GET_VACCINATION_COUNTRY_CONFIG(country);
        return this.client.get(requestPath).pipe();
    }

    initiateSubmitVaccinationCard(payload: InitiateSubmitCardRequest) {
        const requestPath: string = API.INITIATE_SUBMIT_VACCINATION_CARD();
        return this.client.post(requestPath, JSON.stringify(payload)).pipe();
    }

    getVaccinationCardStatus(employeeId: string, cardType: CardType) {
        const requestPath: string = API.GET_VACCINATION_CARD_STATUS(employeeId, cardType ?? CardType.Vaccine);
        return this.client.get(requestPath).pipe();
    }

    getVaccinationSurveyAnswersV2(employeeId: string, surveyType: string) {
        const requestPath: string = API.GET_VACCINATION_RESPONSE_SURVEY(employeeId, surveyType);
        return this.client.get(requestPath).pipe();
    }

    submitVaccinationSurveyAnswerV2(payload: SubmitVaccinationSurveyRequest) {
        const requestPath: string = API.VACCINATION_RESPONSE_SURVEY();
        return this.client.post(requestPath, JSON.stringify(payload)).pipe();
    }

    getVaccinationSurveyStatusV2(employeeId: string, country: string) {
        const requestPath = API.GET_VACCINATION_SURVEY_STATUS(employeeId, country);
        return this.client.get(requestPath).pipe()
    }

    getVaccinationCardURL(employeeId: string, cardType: CardType) {
        const requestPath: string = API.GET_VACCINATION_CARD_S3_URL(employeeId, cardType);
        return this.client.get(requestPath).pipe();
    }

    getVaccinationCompliance(employeeId: string, country: string) {
        const requestPath: string = API.GET_VACCINATION_COMPLIANCE(employeeId, country);
        return this.client.get(requestPath).pipe();
    }

    getTestAttestations(employeeId: string) {
        const requestPath: string = API.GET_TEST_ATTESTATIONS(employeeId);
        return this.client.get(requestPath).pipe();
    }

    submitTestAttestation(request: SubmitTestAttestationRequest) {
        const requestPath: string = API.SUBMIT_TEST_ATTESTATION();
        return this.client.post(requestPath, JSON.stringify(request)).pipe();
    }

    updateTestAttestation(request: SubmitTestAttestationRequest) {
        const requestPath: string = API.UPDATE_TEST_ATTESTATION();
        return this.client.put(requestPath, JSON.stringify(request)).pipe();
    }

    getCovidTestLite(employeeId: string) {
        const requestPath: string = API.GET_COVID_TEST_LITE(employeeId);
        return this.client.get(requestPath).pipe();
    }

    submitCovidTestLite(request: SubmitCovidTestLiteRequest) {
        const requestPath: string = API.SUBMIT_COVID_TEST_LITE();
        return this.client.post(requestPath, JSON.stringify(request)).pipe();
    }

    updateCovidTestLite(request: SubmitCovidTestLiteRequest) {
        const requestPath: string = API.UPDATE_COVID_TEST_LITE();
        return this.client.put(requestPath, JSON.stringify(request)).pipe();
    }

    updateVaccinationBonus(data: any) {
        const path: string = API.UPDATE_VACCINATION_BONUS();
        return this.client.put(path, data).pipe();
    }
}

let campaignsClient: CampaignsClientSDK = null;
export const getCampaignsClient = (employeeId?: string, logger?: Logger, isReverseProxyEnabled?: boolean) => {
    if (!campaignsClient) {
        if (!employeeId) {
            throw "employeeId is required for first use!";
        }
        if (!logger) {
            logger = getLogger();
        }
        const stageName = STAGES[STAGE_NAME.toLowerCase()] || STAGES.development;
        const campaignsService = getCampaignsService(employeeId, stageName, logger, isReverseProxyEnabled);
        campaignsClient = new CampaignsClientSDK(campaignsService);
    }

    return campaignsClient;
}
