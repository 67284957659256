import { Action } from 'redux';
import { UIFeaturesStateRecord } from '../models/uiFeatures/UIFeaturesStateRecord';

type UIFeaturesPayloads = UIFeaturesStateRecord;

export enum UIFeaturesActionTypes {
    SET_UI_FEATURES= 'SET_UI_FEATURES'
}

export interface UIFeaturesAction extends Action {
    type: UIFeaturesActionTypes;
    payload: UIFeaturesPayloads;
}

export interface SetUIFeaturesAction extends UIFeaturesAction {
    type: UIFeaturesActionTypes.SET_UI_FEATURES;
    payload: UIFeaturesStateRecord;
}

export const createSetUIFeaturesAction =
    (uiFeaturesRecord: UIFeaturesStateRecord): SetUIFeaturesAction => ({
        type: UIFeaturesActionTypes.SET_UI_FEATURES,
        payload: uiFeaturesRecord
    });