import React, { Component, Dispatch } from 'react';
import i18n from 'i18next';
import { Form, FormGroup, HelpBlock } from 'react-bootstrap';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { ROUTES } from '../../../../routes';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { VaccinationBonusRecord } from '../../models/vaccination/vaccinationBonusRecord';
import { LoadingIndicator, LoadingIndicatorTheme } from '@atoz/atoz-common-ui-components';
import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';
import { Feedback } from '@atoz/atoz-common-ui-components';
import { SubmitVaccinationBonusRequest } from '../../models/apiRequest/submitVaccinationBonusRequest';
import { VaccinationAlert } from '../__shared__/vaccinationAlert/VaccinationAlert';
import { VaccinationFormWarning } from '../../models/vaccinationFormWarning/VaccinationFormWarning';
import { dateDisplayOptions } from './VaccinationBonusPage';
import { VaccineTypeRecord } from '../../models/vaccination/vaccineTypeRecord';
import {
  createGetVaccinationBonusSuccessAction,
  createGetVaccinationComplianceAction,
  createInternalVaccinationBonusRequestUpdateAction,
  createSubmitVaccinationBonusRequestAction,
  setEditDoseSuccessful,
  VaccinationBonusActions
} from '../../actions/vaccinationBonusActions';
import { AppState } from '../../../../models/appState';
import { GetVaccinationBonusResponse } from '../../models/vaccination/getVaccinationBonusResponse';
import { EmployeeInfo } from '../../../../contexts/EmployeeInfoContext';
import { isPopstarFeatureEnabled } from '../../../../models/EmployeeInfoRecord';
import { VaccinationFeatures } from '../../constant/vaccinationFeatures';
import { getLogger } from 'src/logger';
import { getCampaignsClient } from '../../clients/campaignsClientSDK';
import {getTranslatedVaccineType} from "../../utils/vaccination/getTranslatedVaccineType";
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

interface VaccinationBonusConfirmProps extends RouteComponentProps {
  request: VaccinationBonusRecord;

  vaccineTypes: List<VaccineTypeRecord>;
  previousRecords: List<VaccinationBonusRecord>;

  updateVaccinationBonusRequest: (request: VaccinationBonusRecord) => void;
  submitVaccinationBonusRequest: (request: SubmitVaccinationBonusRequest) => void;
  isSubmittingVaccinationReport: boolean;
  isSubmittingVaccinationReportFailed: boolean;
  isLoadingFromBackend: boolean;
  isLoadingFromBackendFailed: boolean;
  submitVaccinationBonusResponse: GetVaccinationBonusResponse;

  isFinalDoseReported: boolean;
  mustSubmitCard: boolean;
  updateRecords: (response: GetVaccinationBonusResponse) => void;
  setEditDoseSuccessful: () => void;
  getCompliance: () => void;
}

interface VaccinationBonusConfirmState {
  validationAlerts: VaccinationFormWarning[];
  isBooster: boolean;
  isEdit: boolean;
  request: VaccinationBonusRecord;
}

class _VaccinationBonusConfirm extends Component<VaccinationBonusConfirmProps, VaccinationBonusConfirmState> {
  state = {
    validationAlerts: [] as VaccinationFormWarning[],
    isBooster: this.props.request.isBooster,
    isEdit: this.props.request.isEdit,
    request: this.props.request
  };

  static contextType = EmployeeInfo;

  constructor(props: VaccinationBonusConfirmProps) {
    super(props);
  }

  componentDidMount() {
    if (this.shouldNotRequest()) {
      this.props.history.push(ROUTES.VACCINATION.path);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<VaccinationBonusConfirmProps>,
    prevState: Readonly<VaccinationBonusConfirmState>,
    snapshot?: any
  ) {
    this.handleFailedReportSubmit(prevProps);
    this.handleSuccessReportSubmit(prevProps);
  }

  render() {
    return (
      <div className="vaccination-bonus-page vaccination-request">
        {(this.props.isSubmittingVaccinationReport || this.props.isLoadingFromBackend)
          ? <LoadingIndicator theme={LoadingIndicatorTheme.LIGHT} />
          : this.renderConfirm()}
      </div>
    );
  }

  private renderConfirm = () => {
    const { onsite, duringShift, vaccineType, vaccinationDate } = this.props.request;
    return (
      <>
        {this.state.validationAlerts.map(alert => (
          <VaccinationAlert key={alert.id} onClose={this.closeAlert(alert.id)}>
            {alert.message}
          </VaccinationAlert>
        ))}
        <div className="background styled-form">
          <Form>
            <div className="title">{this.state.isEdit ? i18n.t('vaccinationBonus.title.confirmEdit') : i18n.t('vaccinationBonus.title.confirm')}</div>
            <p className="vaccination-help-block-text">{i18n.t('vaccinationBonus.blurb.helpBlock')}</p>
            {i18n.t('vaccinationBonus.blurb.submissionSummary')}
            <hr />
            {isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_ON_OFF_SITE) &&
              this.renderReport(
                i18n.t('vaccinationBonus.confirm.onOffSiteLabel'),
                i18n.t(`vaccinationBonus.onOffSite.${onsite ? 'onsite' : 'offsite'}`)
            )}
            {isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_SHIFT_SELECTION) &&
              this.renderReport(
                i18n.t('vaccinationBonus.confirm.duringShiftLabel'),
                i18n.t(`vaccinationBonus.duringShift.${duringShift ? 'duringShift' : 'outsideShift'}`)
            )}
            {this.renderReport(
              i18n.t('vaccinationBonus.confirm.vaccineTypeLabel'),
              getTranslatedVaccineType(vaccineType.name)
            )}
            {this.renderReport(
              i18n.t('vaccinationBonus.confirm.vaccineReceivedLabel'),
              vaccinationDate.toLocaleString(dateDisplayOptions as DateTimeFormatOptions, { locale: this.context.locale })
            )}
            {this.renderReport(
              i18n.t('vaccinationBonus.confirm.vaccineSubmittedLabel'),
              DateTime.local().toLocaleString(dateDisplayOptions as DateTimeFormatOptions, { locale: this.context.locale })
            )}
            <hr />
            {this.renderLegalAgreement()}
            {this.renderValidation()}
          </Form>
        </div>
      </>
    );
  };

  private renderReport = (label: string, content: string) => (
    <div className="confirm-section">
      <div className="confirm-label">{label}</div>
      <div className="confirm-content">{content}</div>
    </div>
  );

  private closeAlert = (id: string) => () =>
    this.setState(({ validationAlerts }) => ({ validationAlerts: validationAlerts.filter(alert => alert.id !== id) }));

  private legalAgreementChange = () => {
    const nextValue = !this.state.request.disclaimerConfirmed;
    const nextRequest = this.state.request
      .set('disclaimerConfirmed', !this.state.request.disclaimerConfirmed)
      .set('disclaimerConfirmedWarning', !nextValue);
    this.setState({ request: nextRequest });
  };

  private readonly renderLegalAgreement = () => {
    const { disclaimerConfirmed: checked, disclaimerConfirmedWarning } = this.state.request;
    return (
      <FormGroup controlId="legalAgreement">
        <div className="legal-agreement-wrapper">
          <input
            type="checkbox"
            onChange={this.legalAgreementChange}
            checked={checked}
            aria-checked={checked}
            id="legal-agreement-checkbox"
          />
          <label htmlFor="legal-agreement-checkbox" id="legal-agreement-label" />
          <HelpBlock
            dangerouslySetInnerHTML={{ __html: this.getLegalAgreementText() }}
          />
        </div>
        <Feedback show={disclaimerConfirmedWarning}>{i18n.t('vaccinationBonus.errors.legalAgreement')}</Feedback>
      </FormGroup>
    );
  };

  private readonly getLegalAgreementText = () => {
    if (i18n.exists(`vaccinationBonus.blurb.reportDisclaimerConfirmation.${this.context.countryCode}`)) {
      return i18n.t(`vaccinationBonus.blurb.reportDisclaimerConfirmation.${this.context.countryCode}`);
    }
    return i18n.t('vaccinationBonus.blurb.reportDisclaimerConfirmation.default');
  };

  private readonly renderValidation = () => (
    <div className="report-button-section">
      <button
        onClick={this.handleConfirm}
        className="btn btn-primary col-xs-12"
        data-omniture-link={'Vaccination Bonus - Submit report'}
        data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Submit report')}
      >
        {i18n.t('vaccinationBonus.button.submit')}
      </button>
      <Link
        to={ROUTES.VACCINATION.REQUEST.path}
        className="btn btn-secondary col-xs-12"
        data-omniture-link={'Vaccination Bonus - Try again'}
        data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Try again')}
      >
        {i18n.t('vaccinationBonus.button.modify')}
      </Link>
    </div>
  );

  private handleConfirm = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const validationResult = this.state.request.isDisclaimerConfirmed();
    if (!validationResult) {
      this.setState({
        request: this.state.request.set('disclaimerConfirmedWarning', true),
        validationAlerts: [new VaccinationFormWarning({ message: i18n.t('vaccinationBonus.message.errorLegalAgreement') })]
      });

      window.scrollTo(0, 0);
      return;
    }
    const requestToSubmit = this.state.request.set('noBenefits', isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_NO_BENEFITS));
    this.props.updateVaccinationBonusRequest(requestToSubmit);
    if (this.state.isEdit) {
      this.handleEditRequest(this.state.request);
    } else {
      this.props.submitVaccinationBonusRequest(SubmitVaccinationBonusRequest.fromVaccinationBonusRequest(requestToSubmit, this.context.employeeId));
    }
  };

  private handleEditRequest = async (request: VaccinationBonusRecord) => {
    try {
      const campaignsClientSDK = getCampaignsClient(this.context.employeeId);
      // TODO: refactor to use actions (need to chain action)
      const response = await campaignsClientSDK.updateVaccinationBonus({
        employeeId: this.context.employeeId,
        vaccinationDose: request.vaccinationDose,
        vaccinationDate: request.vaccinationDate.toISODate(),
        vaccineType: request.vaccineType.enumValue,
        onsite: request.onsite,
        duringShift: request.duringShift,
        syncUpdate: !isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_DIFFERENT_TYPE),
      }).toPromise();
      // response contains list of updated records
      this.props.updateRecords(response);
      this.props.setEditDoseSuccessful();
      this.props.getCompliance();
      this.props.history.push(ROUTES.VACCINATION.path);
    } catch (err) {
      getLogger().error(err);
      // update failed
      this.setState(({ validationAlerts }) => ({
        validationAlerts: validationAlerts.concat(
          new VaccinationFormWarning({ message: i18n.t('vaccinationBonus.message.failedReport') })
        )
      }));
    }
  }

  private handleFailedReportSubmit = (prevProps: Readonly<VaccinationBonusConfirmProps>) => {
    if (
      this.props.isSubmittingVaccinationReportFailed &&
      this.props.isSubmittingVaccinationReportFailed !== prevProps.isSubmittingVaccinationReportFailed
    ) {
      // submission failed
      this.setState(({ validationAlerts }) => ({
        validationAlerts: validationAlerts.concat(
          new VaccinationFormWarning({ message: i18n.t('vaccinationBonus.message.failedReport') })
        )
      }));
    }
  };

  private handleSuccessReportSubmit = (prevProps: Readonly<VaccinationBonusConfirmProps>) => {
    if (
      !this.props.isLoadingFromBackend && this.props.isLoadingFromBackend !== prevProps.isLoadingFromBackend
    ) {
      // finished refreshing vaccination bonus
      if (this.props.isLoadingFromBackendFailed) {
        // something went wrong, eject to landing
        this.props.history.push(ROUTES.VACCINATION.path);
      } else {
        if (this.props.isFinalDoseReported
          && (isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
            && this.props.mustSubmitCard)) {
          if (this.state.isBooster) {
            // user uploaded a booster, send them to upload booster card
            this.props.history.push(ROUTES.VACCINATION.BOOSTER_CARD.path);
          } else {
            // user uploaded final dose, send them to upload vaccination card
            this.props.history.push(ROUTES.VACCINATION.SUBMIT_CARD.path);
          }
        } else if (!this.props.isFinalDoseReported
          && isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
          && isPopstarFeatureEnabled(this.context, VaccinationFeatures.ATOZ_FIRSTDOSE_CARD_UPLOAD)) {
          this.props.history.push(ROUTES.VACCINATION.FIRSTDOSE_CARD.path);
        } else {
          this.props.history.push(ROUTES.VACCINATION.path);
        }
      }
    }
  };

  private shouldNotRequest = () => {
    if (!this.state.request) return true;
    return !this.state.request.isComplete();
  };
}

export const mapStateToProps = ({ vaccination }: AppState) => ({
  request: vaccination.vaccinationBonusRequest,

  vaccineTypes: vaccination.vaccineTypeRecords,
  previousRecords: vaccination.previousVaccinationRecords,

  isSubmittingVaccinationReport: vaccination.isSubmittingVaccinationReport,
  isSubmittingVaccinationReportFailed: vaccination.isSubmittingVaccinationReportFailed,
  isLoadingFromBackend: vaccination.isLoadingFromBackend(),
  isLoadingFromBackendFailed: vaccination.isLoadingFromBackendFailed(),
  submitVaccinationBonusResponse: vaccination.submitVaccinationBonusResponse,

  isFinalDoseReported: vaccination.isFinalDoseReported(),
  mustSubmitCard: ((vaccination.isCardUploadMandatory() && !vaccination.isCardUploaded()) ||
    (vaccination.mustUploadBoosterCard() && vaccination.vaccinationBonusRequest.isBooster)),
} as VaccinationBonusConfirmProps);

export const mapDispatch = (dispatch: Dispatch<VaccinationBonusActions>) => ({
  updateVaccinationBonusRequest: (request: VaccinationBonusRecord) => dispatch(createInternalVaccinationBonusRequestUpdateAction(request)),
  submitVaccinationBonusRequest: (request: SubmitVaccinationBonusRequest) => dispatch(createSubmitVaccinationBonusRequestAction(request)),
  updateRecords: (response: GetVaccinationBonusResponse) => dispatch(createGetVaccinationBonusSuccessAction(response)),
  setEditDoseSuccessful: () => dispatch(setEditDoseSuccessful()),
  getCompliance: () => dispatch(createGetVaccinationComplianceAction())
} as VaccinationBonusConfirmProps);

// @ts-ignore
export const VaccinationBonusConfirm = connect(mapStateToProps, mapDispatch)(withRouter(_VaccinationBonusConfirm));
