import React from 'react';
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { H4, Text } from '@amzn/stencil-react-components/text';
import { Modal } from '@amzn/stencil-react-components/modal';
import { FC } from 'react';
import { PrimaryButton, SecondaryButton } from '../../__shared__/button/Button';

interface Props {
  title: string;
  text: string | React.ReactNode;

  isOpen: boolean;
  onClose: () => void;

  confirmText: string;
  onConfirmClick?: () => void;

  discardText: string;
  onDiscardClick?: () => void;
}

export const ConfirmationModal: FC<Props> = ({ title, text,
                                        isOpen, onClose,
                                        confirmText,  onConfirmClick,
                                        discardText, onDiscardClick }) => {
  const onInternalDiscardClick = () => {
    if (onDiscardClick) {
      onDiscardClick();
    }
    onClose();
  };

  const onInternalConfirmClick = () => {
    if (onConfirmClick) {
      onConfirmClick();
    }
    onClose();
  };

  return (
    <Modal aria-labelledby={title} isOpen={isOpen} close={onClose}>
      <Col backgroundColor="neutral0" padding="S300" width={'340px'}>
        <Col justifyContent="left">
          <H4 fontWeight={'bold'}>{title}</H4>
          <Spacer height={10} />
          <Text>{text}</Text>
        </Col>
        <Spacer height={20} />
        <Row justifyContent="center" gridGap="S300">
          <SecondaryButton onClick={onInternalDiscardClick}>{discardText}</SecondaryButton>
          <PrimaryButton onClick={onInternalConfirmClick}>{confirmText}</PrimaryButton>
        </Row>
      </Col>
    </Modal>
  );
};
