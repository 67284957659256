import { Record } from 'immutable';
import { DateTime } from 'luxon';
import { TestAttestationResponseRecord } from '../apiRequest/getTestAttestationResponse';

export enum PCRTestResult {
  UNKNOWN = '',
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
}

export enum PCRTestProvider {
  UNKNWON = '',
  AMAZON_DX = 'AMAZON_DX',
  HEALTHCARE_PROVIDER = 'HEALTHCARE_PROVIDER',
  PHARMACY = 'PHARMACY',
}

export enum PCRTestType {
  UNKNOWN = '',
  PCR = 'PCR',
  RAPID_OR_ANTIGEN = 'RAPID_OR_ANTIGEN',
}

export interface TestAttestationRecord {
  createAt: number | undefined;
  createdAtDate: DateTime | undefined;

  result: PCRTestResult;
  collectionDate: DateTime | undefined;
  resultDate: DateTime | undefined;
  provider: PCRTestProvider;
  testType: PCRTestType;

  imageFile: File | undefined;
  imageSrc: string;

  imagePath?: string;   // backend image path
  returnToWorkDate: DateTime | undefined;
}

export const defaultTestAttestationRecord: TestAttestationRecord = {
  createAt: undefined,
  createdAtDate: undefined,
  result: PCRTestResult.UNKNOWN,
  collectionDate: undefined,
  resultDate: undefined,
  provider: PCRTestProvider.UNKNWON,
  testType: PCRTestType.UNKNOWN,

  imageFile: undefined,
  imageSrc: '',

  imagePath: undefined,
  returnToWorkDate: undefined
};

export function isTestTypeRequired(provider: PCRTestProvider): boolean {
  return [PCRTestProvider.HEALTHCARE_PROVIDER, PCRTestProvider.PHARMACY].includes(provider);
}

export function isRecordFilledWithoutImage(record: TestAttestationRecord): boolean {
  return !!record.result
    && !!record.collectionDate
    && !!record.resultDate
    && (!isTestTypeRequired(record.provider) || !!record.testType);
}

export function isRecordFilled(record: TestAttestationRecord): boolean {
  return isRecordFilledWithoutImage(record) && !!record.imageFile;
}

export function testAtestationRecordFrom(params: TestAttestationResponseRecord): TestAttestationRecord {
  if (params) {
    return {
      createAt: params.createAt,
      createdAtDate: DateTime.fromISO(params.createdAtDate),
      result: params.result,
      collectionDate: DateTime.fromISO(params.collectionDate),
      resultDate: DateTime.fromISO(params.resultDate),
      provider: params.provider,
      testType: params.testType,
      returnToWorkDate: params.returnToWorkDate ? DateTime.fromISO(params.returnToWorkDate) : undefined
    } as TestAttestationRecord;
  }
  return defaultTestAttestationRecord;

}