import React, { FC, useState } from 'react';
import { StencilIcon, StencilIconColor, StencilIconType } from '@atoz/atoz-common-ui-components';
import { IconSize } from '@amzn/stencil-react-components/icons';
import { Collapse } from 'react-bootstrap';
import './styles.scss';

type Props = {
  title: string;
  label: string;
  children: JSX.Element;
};

const _CardUploadGuidance: FC<Props> = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const setExpandSectionBackEnter = (e: React.KeyboardEvent) => {
    if (e) {
      if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
        // 'Spacebar' is used by older IE/FF versions
        e.preventDefault();
        setIsExpanded(!isExpanded);
      }
    }
  };
  const setExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className="card-upload-guidance-section">
      <h4 className="card-upload-guidance-title" onClick={setExpand}>
        {props.title}{' '}
        <span
          tabIndex={0}
          onKeyDown={setExpandSectionBackEnter}
          aria-label={props.label}
          alt-text={props.label}
          role="button"
          aria-expanded={isExpanded}
        >
          <StencilIcon
            title={props.label}
            color={StencilIconColor.Primary}
            icon={isExpanded ? StencilIconType.ChevronUp : StencilIconType.ChevronDown}
            size={IconSize.Small}
          />
        </span>
      </h4>
      <Collapse in={isExpanded}>
        {props.children}
      </Collapse>
    </div>
  );
};

export const CardUploadGuidance = _CardUploadGuidance;
