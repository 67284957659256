declare global {
  interface Window {
    s?: {
      pageName: string;
      tl: () => void;
    };
  }
}

export function submitOmnitureEvent(info: string) {
  if (!window.s) return;
  window.s.pageName = info;
  window.s.tl();
}
