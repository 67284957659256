import { List, Record } from 'immutable';
import { DateTime } from 'luxon';
import { SubmitVaccinationSurveyResponses } from './submitVaccinationSurveyResponses';

const defaultRecord = {
    answer: '',
    date: DateTime.invalid('default')
};

type Props = {
    answer: string;
    date: DateTime;
}

export class VaccinationSurveyRecord extends Record<Props>(defaultRecord) {
    static fromRaw({answer, createdAt}: { answer: string; createdAt: number }) {
        return new VaccinationSurveyRecord({answer, date: DateTime.fromMillis(createdAt)});
    }
    static fromRawSurveyV2(response: SubmitVaccinationSurveyResponses): List<VaccinationSurveyRecord> {
        const results: VaccinationSurveyRecord[] = [];
        if (response.responses) {
            for (const t of response.responses) {
                if (!t.response) {
                    continue;
                }
                const responseObject = JSON.parse(t.response) as {data: string};
                if (responseObject.data === 'No') {
                    results.push(VaccinationSurveyRecord.fromRaw(
                        {
                            answer: 'not_fully_vaccinated',
                            createdAt: t.createdAt
                        }));
                }
            }
        }
        return List(results);
    }
}
