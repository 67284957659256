import { RawComplianceDetails } from './ComplianceDetails';

export enum CardStatus {
  NOT_UPLOADED = 'NOT_UPLOADED',
  INITIAL = 'INITIAL',
  UPLOADED = 'UPLOADED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export interface GetVaccinationCardStatusResponse {
  status: string;
  createdAt: number;
  updatedAt: number;
  imagePath: string;
  canReupload?: boolean;
  rejectionReason: null | string;
  mlFinished: boolean;
  complianceDetails: RawComplianceDetails;
}
