import React from 'react';

import { METRIC_NAME, metricsPublisher } from '../metrics';
import { i18nWhenReady } from '../i18n';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';

enum InitializationStatus {
    Failure = 0,
    Success = 1,
}

interface PropsType {
    children: JSX.Element,
}

/**
  * Simple class to manage the application initialization metric.
  *
  * Note this is meant as a demonstration of how to use the initialization metric, and
  * is almost certainly inadequate for a more complex application.
  *
  * It waits for itself to be mounted (if it is the outermost component that means all of
  * its children are rendered and mounted by React, though are not necessarily fully rendered
  * in the browser), then waits for the translations to be ready.
  *
  * In your actual application, you are likely not fully initialized until you have completed
  * your initial REST calls.  You will need to either integrate that logic here, or else move
  * the initialization metric elsewhere.
  *
  * For applications using a state manager (e.g. Redux) it may make more sense to determine if
  * your application is initialized from that layer instead.
  */
export class AppInitWrapper extends React.Component<PropsType, { isInitialized: boolean }> {
    constructor(props) {
        super(props);
        this.state = { isInitialized: false };
    }

    componentDidMount() {
        // Our application is initialized, with the possible exception of i18n
        // Use this callback to log our final initialization time and status
        i18nWhenReady((err) => {
            this.logInitializationMetric(err ? InitializationStatus.Failure : InitializationStatus.Success);
        });
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.logInitializationMetric(InitializationStatus.Failure);
        // In your application you may want to update the UI; here we will re-throw and fail the whole app, after logging
        throw error;
    }

    render() {
        if (this.state.isInitialized) {
            return this.props.children;
        } else {
            return <Spinner size={SpinnerSize.Large} />;
        }
    }

    // Helper method to publish the initialization metric
    private logInitializationMetric(status: InitializationStatus) {
        if (!this.state.isInitialized) {
            this.setState({ isInitialized: true });
            if (status !== InitializationStatus.Success)
                metricsPublisher.emitCountMetric(METRIC_NAME.APP_ERROR_INITIALIZE);
        }
    }
}

export default AppInitWrapper;
