/* istanbul ignore file */
import * as React from 'react';
import { EmployeeInfoRecord, employeeInfoRecordDefault } from '../models/EmployeeInfoRecord';

export const EmployeeInfo = React.createContext<EmployeeInfoRecord>(
    new EmployeeInfoRecord(employeeInfoRecordDefault)
);

export const EmployeeInfoContextProvider: React.FC<{
    children: React.ReactNode;
    value?: EmployeeInfoRecord;
}> = ({ value, children }) => {
    return (
        <EmployeeInfo.Provider value={value || new EmployeeInfoRecord(employeeInfoRecordDefault)}>
            {children}
        </EmployeeInfo.Provider>
    );
};

export const useEmployeeContext = () => React.useContext(EmployeeInfo);
