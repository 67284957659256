import { List } from "immutable";
import { FeatureRecord } from "./feature/featureRecord";

const featureMap = new Map<string, boolean>();

export function setFeatureEnabled(name: string, active: boolean) {
  featureMap.set(name, active);
}

export function isEnabled(name: string, features: List<FeatureRecord>): boolean {
  const foundFeature: FeatureRecord | undefined = features.find(feature => feature.name === name);
  return foundFeature !== undefined && foundFeature.active;
}

export function isFeatureEnabled(key: string) {
  const result = featureMap.get(key);
  return result === undefined ? false : result;
}

// Variable initialized in ESSP package that contains all feature gates
// https://code.amazon.com/packages/EmployeeSelfServicePortal/blobs/cbe61b2bb8b84673d787c7fbd66cac98512c7262/--/rails-root/app/views/layouts/application.html.haml#L106
declare var FEATURES: Object;

// Mocked values of FEATURES are in index.html
export function isEsspFeatureEnabled(name: string): boolean {
  return typeof FEATURES !== 'undefined' && FEATURES.hasOwnProperty(name) && FEATURES[name];
}
