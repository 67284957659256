import { theme } from '../../../../../theme';

const baseStencilButtonStyle = {
  fontFamily: theme.font.primary.family,
  fontSize: theme.font.primary.size.T400.xl.fontSize,
  fontWeight: theme.font.primary.fontWeight.medium,
  lineHeight: theme.font.primary.size.T400.xl.lineHeight,

  alignItems: 'center',
  borderColor: 'transparent',
  borderRadius: '1000px',
  borderStyle: 'solid',
  borderWidth: '2px',
  boxShadow: 'none',
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  hyphens: 'none',
  justifyContent: 'center',
  margin: 0,
  minHeight: '36px',
  minWidth: '128px',
  outline: 'none !important',
  overflow: 'visible',
  padding: '24px 21px',
  position: 'relative',
  textAlign: 'center',
  textTransform: 'none',
  transition: 'background-color .2s ease 0s,border-color .2s ease 0s',
  userSelect: 'none',
  whiteSpace: 'nowrap',
  WebkitAppearance: 'button',
  WebkitBoxAlign: 'center',
  WebkitBoxPack: 'center',
  WebkitFontSmoothing: 'antialiased',
  WebkitTapHighlightColor: 'transparent',
  WebkitTextSizeAdjust: '100%',
};

export const PrimaryStencilButtonStyle = {
  ...baseStencilButtonStyle,
  color: theme.color.neutral0,
  backgroundColor: theme.color.primary70,
};

export const SecondaryStencilButtonStyle = {
  ...baseStencilButtonStyle,
  color: theme.color.primary70,
  borderColor: theme.color.primary70,
  backgroundColor: theme.color.neutral0,
  ':hover': {
    backgroundColor: theme.color.neutral10,
  },
};

export const TertiaryStencilButtonStyle = {
  ...baseStencilButtonStyle,
  color: theme.color.primary70,
  backgroundColor: 'transparent',
  ':hover': {
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: theme.color.primary70,
  },
};
