import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Observable } from 'rxjs/Observable';
import * as API from '../apps/vaccination/paths/api/feature';
import { FeatureRecord } from '../models/feature/featureRecord';
import { RawFeatureResponse } from '../models/feature/rawFeatureResponse';
import { List, Map, fromJS } from 'immutable';
import { setFeatureEnabled } from '../models/clientFeatures';

export class FeatureClientSDK {

    // tslint:disable-next-line:no-any
    private _get: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<any>>;

    /* tslint:disable */
    constructor(get: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<any>>) {
        this._get = get;
    }
    /* tslint:enable */

    getFeatures() {
        const path: string = API.FEATURE_API;
        const self = this;
        return this._get(path,
            {
                timeout: 3000,
                transitional: {
                    silentJSONParsing: true,
                    forcedJSONParsing: true,
                    clarifyTimeoutError: true // will distinguish between ETIMEDOUT and ECONNABORTED
                }
            }).then(function (response) {
            return self.fromRawFeatureResponse(response.data);
        })
    }

    private fromRawFeatureResponse(raw: RawFeatureResponse): List<FeatureRecord> {
        const features: Map<string, boolean> = Map(fromJS(raw.features));
        let result: List<FeatureRecord> = List<FeatureRecord>();

        features.map((entry: boolean, key: string) => {
            const feature: FeatureRecord = new FeatureRecord({
                name: key,
                active: entry
            });

            // This is a proposed, simple solution to circumvent having to push properties for features through
            // multi-layered dependencies of Records, which can not easily take advantage of ReactJS's context
            // A Map should provide better performance than List for resolving entries by key (name)
            setFeatureEnabled(feature.name, feature.active);
            result = result.push(feature);
        });
        return result;
    }
}