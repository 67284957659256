import { DetailedRadio, InputFooter } from '@amzn/stencil-react-components/dist/submodules/form';
import { Col, Spacer } from '@amzn/stencil-react-components/layout';
import { Label, Text } from '@amzn/stencil-react-components/text';
import React, { FC, useState } from 'react';

interface Option {
  title: string;
  details: string | React.ReactNode;
  value: string;
}

interface Props {
  groupName: string;
  title: string;
  value?: string;
  options: Option[];
  onChange: (value: string) => void;
  error?: () => boolean;
  errorText?: string;
}

export const DetailedRadioGroup: FC<Props> = ({ groupName, title, value, options,
                                                onChange,
                                                error, errorText }) => {
  const [selectedOption, setSelectedOption] = useState(value);

  return (
    <Col>
      <Col>
        <Label>{title}</Label>
      </Col>
      <Spacer height={10}/>
      <Col gridGap="S200">
        {options.map((option, i) =>
          <DetailedRadio
            name={groupName}
            key={`${groupName}-${i}`}
            id={`${groupName}-${i}`}
            titleText={option.title}
            details={typeof option.details === 'string'
              ? <Text fontWeight="regular">{option.details}</Text>
              : option.details
            }
            value={option.value}
            checked={selectedOption === option.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const val = e?.target?.value;
              setSelectedOption(val);
              onChange(val);
            }}
            error={error && error()}
          />
        )}
      </Col>
      {error && error() && errorText && <InputFooter error={true}>{errorText}</InputFooter>}
    </Col>
  );
};
