import React, { FC } from 'react';
import i18n from 'i18next';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

export const ReloadButtonSection: FC = () => (
  <>
    <div className="warning pad-top-0">{i18n.t('vaccinationBonus.blurb.errorLoadingPrevious')}</div>
    <div className="report-button-section">
      <button
        className="btn btn-primary col-xs-12 mgn-btm-0"
        onClick={() => window.location.reload()}
        data-omniture-link="Vaccination Bonus - Reload click"
        data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Reload click')}
      >
        {i18n.t('vaccinationBonus.button.reload')}
      </button>
    </div>
  </>
);
