import { EmployeeInfoRecord, isPopstarFeatureEnabled } from "../../../models/EmployeeInfoRecord";

export enum VaccinationFeatures {
    ATOZ_TEST_ATTESTATION = 'test_attestation',
    VACCINATION_BONUS_ALLOW_DIFFERENT_TYPE = 'vaccination_bonus_allow_different_type',
    VACCINATION_BONUS_ALLOW_ON_OFF_SITE = 'vaccination_bonus_allow_on_off_site',
    VACCINATION_BONUS_ALLOW_SHIFT_SELECTION = 'vaccination_bonus_allow_shift_selection',
    VACCINATION_BONUS = 'vaccination_bonus',
    VACCINATION_BONUS_NO_BENEFITS = 'vaccination_bonus_no_benefits',
    VACCINATION_BONUS_BOOSTERS = 'vaccination_bonus_booster',
    ATOZ_VACCINE_BADGE = 'vaccination_badge',
    ATOZ_VACCINE_SUBMIT_CARD = 'vaccination_submit_card',
    ATOZ_FIRSTDOSE_CARD_UPLOAD = 'first_dose_card_upload',
    COVID_TEST_LITE = 'covid_test_lite_reporting',
    VACCINATION_APP = 'vaccination_app'
}

export function isUploadAllowed(context: EmployeeInfoRecord): boolean {
    return isPopstarFeatureEnabled(context, VaccinationFeatures.VACCINATION_BONUS) || isPopstarFeatureEnabled(context, VaccinationFeatures.VACCINATION_BONUS_NO_BENEFITS);
}