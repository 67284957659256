import { Action } from 'redux';
import { GetCovidTestLiteResponse } from 'src/apps/vaccination/models/apiRequest/getCovidTestLiteResponse';
import { SubmitCovidTestLiteRequest } from 'src/apps/vaccination/models/apiRequest/submitCovidTestLiteRequest';
import { CovidTestLiteRecord } from 'src/apps/vaccination/models/vaccination/covidTestLiteRecord';
import { CardType } from '../models/apiRequest/cardType';
import { GetTestAttestationResponse } from '../models/apiRequest/getTestAttestationResponse';
import { SubmitTestAttestationRequest } from '../models/apiRequest/submitTestAttestationRequest';
import { SubmitVaccinationBonusRequest } from '../models/apiRequest/submitVaccinationBonusRequest';
import { TestAttestationRecord } from '../models/vaccination/testAttestationRecord';
import { VaccinationBonusRecord } from '../models/vaccination/vaccinationBonusRecord';
import { createAction } from '@reduxjs/toolkit';
import { GetVaccinationUpdatesResponse } from '../models/vaccination/getVaccinationUpdatesResponse';
import { GetVaccinationCardStatusResponse } from '../models/vaccination/getVaccinationCardStatusResponse';
import { GetVaccinationCountryConfigResponse } from '../models/vaccination/getVaccinationCountryConfigResponse';
import { GetVaccinationBonusResponse } from '../models/vaccination/getVaccinationBonusResponse';
import { VaccinationSurveyResponse } from '../models/vaccination/vaccinationSurveyResponse';
import { SubmitVaccinationSurveyRequest } from '../models/apiRequest/submitVaccinationSurveyRequest';
import { GetVaccinationSurveyStatusResponse } from '../models/vaccination/getVaccinationSurveyStatusResponse';
import { SubmitVaccinationSurveyResponses } from '../models/vaccination/submitVaccinationSurveyResponses';
import { GetVaccinationComplianceResponse } from '../models/vaccination/getVaccinationComplianceResponse';

type VaccinationBonusPayloads =
    SubmitVaccinationBonusRequest
    | VaccinationBonusRecord
    | GetVaccinationBonusResponse
    | GetVaccinationUpdatesResponse
    | GetVaccinationCardStatusResponse
    | GetCardStatusSuccessActionPayload
    | CardType
    | GetVaccinationCountryConfigResponse
    | VaccinationSurveyResponse
    | SubmitVaccinationSurveyRequest
    | GetVaccinationSurveyStatusResponse
    | TestAttestationRecord
    | SubmitVaccinationSurveyResponses
    | GetVaccinationComplianceResponse
    | GetTestAttestationResponse
    | SubmitTestAttestationRequest
    | GetCovidTestLiteResponse
    | SubmitCovidTestLiteRequest
    ;

export type VaccinationBonusActions =
    SubmitVaccinationBonusAction
    | SubmitVaccinationBonusSuccessAction
    | SubmitVaccinationBonusFailureAction
    | InternalVaccinationBonusRequestUpdateAction
    | GetVaccinationBonusAction
    | GetVaccinationBonusSuccessAction
    | GetVaccinationBonusFailureAction
    | GetVaccinationUpdatesAction
    | GetVaccinationUpdatesSuccessAction
    | GetVaccinationUpdatesFailureAction
    | GetVaccinationCountryConfigAction
    | GetVaccinationCountryConfigSuccessAction
    | GetVaccinationCountryConfigFailureAction
    | GetVaccinationCardStatusAction
    | GetVaccinationCardStatusSuccessAction
    | GetVaccinationCardStatusFailureAction
    | GetBoosterCardStatusAction
    | GetBoosterCardStatusSuccessAction
    | GetBoosterCardStatusFailureAction
    | typeof setEditDoseSuccessful
    | typeof resetVaccinationResponse
    | SubmitVaccinationSurveyAction
    | GetSurveyStatusAction
    | GetSurveyStatusSuccessAction
    | GetSurveyStatusFailureAction
    | GetFirstDoseCardStatusAction
    | GetFirstDoseCardStatusSuccessAction
    | GetFirstDoseCardStatusFailureAction
    | typeof updateTestAttestationRecordAction
    | typeof updateCovidTestLiteRecordAction
    | GetCardStatusAction
    | GetCardStatusSuccessAction
    | GetCardStatusFailureAction
    | GetVaccinationComplianceAction
    | GetVaccinationComplianceSuccessAction
    | GetVaccinationComplianceFailureAction
    | GetTestAttestationsAction
    | GetTestAttestationsSuccessAction
    | GetTestAttestationsFailureAction
    | SubmitTestAttestationAction
    | SubmitTestAttestationSuccessAction
    | SubmitTestAttestationFailureAction
    | UpdateTestAttestationAction
    | UpdateTestAttestationSuccessAction
    | UpdateTestAttestationFailureAction
    | GetCovidTestLiteAction
    | GetCovidTestLiteSuccessAction
    | GetCovidTestLiteFailureAction
    | SubmitCovidTestLiteAction
    | SubmitCovidTestLiteSuccessAction
    | SubmitCovidTestLiteFailureAction
    | UpdateCovidTestLiteAction
    | UpdateCovidTestLiteSuccessAction
    | UpdateCovidTestLiteFailureAction
    ;

export enum VaccinationBonusActionTypes {
    INTERNAL_VACCINATION_BONUS_REQUEST_UPDATE = 'INTERNAL_VACCINATION_BONUS_REQUEST_UPDATE',

    SUBMIT_VACCINATION_BONUS_REQUEST = 'SUBMIT_VACCINATION_BONUS_REQUEST',
    SUBMIT_VACCINATION_BONUS_SUCCESS = 'SUBMIT_VACCINATION_BONUS_SUCCESS',
    SUBMIT_VACCINATION_BONUS_FAILURE = 'SUBMIT_VACCINATION_BONUS_FAILURE',

    GET_VACCINATION_BONUS_REQUEST = 'GET_VACCINATION_BONUS_REQUEST',
    GET_VACCINATION_BONUS_SUCCESS = 'GET_VACCINATION_BONUS_SUCCESS',
    GET_VACCINATION_BONUS_FAILURE = 'GET_VACCINATION_BONUS_FAILURE',

    GET_VACCINATION_UPDATES_REQUEST = 'GET_VACCINATION_UPDATES_REQUEST',
    GET_VACCINATION_UPDATES_SUCCESS = 'GET_VACCINATION_UPDATES_SUCCESS',
    GET_VACCINATION_UPDATES_FAILURE = 'GET_VACCINATION_UPDATES_FAILURE',

    GET_VACCINATION_COUNTRY_CONFIG_REQUEST = 'GET_VACCINATION_COUNTRY_CONFIG_REQUEST',
    GET_VACCINATION_COUNTRY_CONFIG_SUCCESS = 'GET_VACCINATION_COUNTRY_CONFIG_SUCCESS',
    GET_VACCINATION_COUNTRY_CONFIG_FAILURE = 'GET_VACCINATION_COUNTRY_CONFIG_FAILURE',

    GET_VACCINATION_CARD_STATUS_REQUEST = 'GET_VACCINATION_CARD_STATUS_REQUEST',
    GET_VACCINATION_CARD_STATUS_SUCCESS = 'GET_VACCINATION_CARD_STATUS_SUCCESS',
    GET_VACCINATION_CARD_STATUS_FAILURE = 'GET_VACCINATION_CARD_STATUS_FAILURE',

    GET_BOOSTER_CARD_STATUS_REQUEST = 'GET_BOOSTER_CARD_STATUS_REQUEST',
    GET_BOOSTER_CARD_STATUS_SUCCESS = 'GET_BOOSTER_CARD_STATUS_SUCCESS',
    GET_BOOSTER_CARD_STATUS_FAILURE = 'GET_BOOSTER_CARD_STATUS_FAILURE',

    SUBMIT_VACCINATION_SURVEY_REQUEST = 'SUBMIT_VACCINATION_SURVEY_REQUEST',
    SUBMIT_VACCINATION_SURVEY_SUCCESS = 'SUBMIT_VACCINATION_SURVEY_SUCCESS',
    SUBMIT_VACCINATION_SURVEY_FAILURE = 'SUBMIT_VACCINATION_SURVEY_FAILURE',

    GET_VACCINATION_SURVEY_STATUS_REQUEST = 'GET_VACCINATION_SURVEY_STATUS_REQUEST',
    GET_VACCINATION_SURVEY_STATUS_SUCCESS = 'GET_VACCINATION_SURVEY_STATUS_SUCCESS',
    GET_VACCINATION_SURVEY_STATUS_FAILURE = 'GET_VACCINATION_SURVEY_STATUS_FAILURE',

    GET_FIRSTDOSE_CARD_STATUS_REQUEST = 'GET_FIRSTDOSE_CARD_STATUS_REQUEST',
    GET_FIRSTDOSE_CARD_STATUS_SUCCESS = 'GET_FIRSTDOSE_CARD_STATUS_SUCCESS',
    GET_FIRSTDOSE_CARD_STATUS_FAILURE = 'GET_FIRSTDOSE_CARD_STATUS_FAILURE',

    GET_VACCINATION_COMPLIANCE_REQUEST = 'GET_VACCINATION_COMPLIANCE_REQUEST',
    GET_VACCINATION_COMPLIANCE_SUCCESS = 'GET_VACCINATION_COMPLIANCE_SUCCESS',
    GET_VACCINATION_COMPLIANCE_FAILURE = 'GET_VACCINATION_COMPLIANCE_FAILURE',

    GET_TEST_ATTESTATIONS_REQUEST = 'GET_TEST_ATTESTATIONS_REQUEST',
    GET_TEST_ATTESTATIONS_SUCCESS = 'GET_TEST_ATTESTATIONS_SUCCESS',
    GET_TEST_ATTESTATIONS_FAILURE = 'GET_TEST_ATTESTATIONS_FAILURE',

    SUBMIT_TEST_ATTESTATION_REQUEST = 'SUBMIT_TEST_ATTESTATION_REQUEST',
    SUBMIT_TEST_ATTESTATION_SUCCESS = 'SUBMIT_TEST_ATTESTATION_SUCCESS',
    SUBMIT_TEST_ATTESTATION_FAILURE = 'SUBMIT_TEST_ATTESTATION_FAILURE',

    UPDATE_TEST_ATTESTATION_REQUEST = 'UPDATE_TEST_ATTESTATION_REQUEST',
    UPDATE_TEST_ATTESTATION_SUCCESS = 'UPDATE_TEST_ATTESTATION_SUCCESS',
    UPDATE_TEST_ATTESTATION_FAILURE = 'UPDATE_TEST_ATTESTATION_FAILURE',

    GET_COVID_TEST_LITE_REQUEST = 'GET_COVID_TEST_LITE_REQUEST',
    GET_COVID_TEST_LITE_SUCCESS = 'GET_COVID_TEST_LITE_SUCCESS',
    GET_COVID_TEST_LITE_FAILURE = 'GET_COVID_TEST_LITE_FAILURE',

    SUBMIT_COVID_TEST_LITE_REQUEST = 'SUBMIT_COVID_TEST_LITE_REQUEST',
    SUBMIT_COVID_TEST_LITE_SUCCESS = 'SUBMIT_COVID_TEST_LITE_SUCCESS',
    SUBMIT_COVID_TEST_LITE_FAILURE = 'SUBMIT_COVID_TEST_LITE_FAILURE',

    UPDATE_COVID_TEST_LITE_REQUEST = 'UPDATE_COVID_TEST_LITE_REQUEST',
    UPDATE_COVID_TEST_LITE_SUCCESS = 'UPDATE_COVID_TEST_LITE_SUCCESS',
    UPDATE_COVID_TEST_LITE_FAILURE = 'UPDATE_COVID_TEST_LITE_FAILURE',

    GET_CARD_STATUS_REQUEST = 'GET_CARD_STATUS_REQUEST',
    GET_CARD_STATUS_SUCCESS = 'GET_CARD_STATUS_SUCCESS',
    GET_CARD_STATUS_FAILURE = 'GET_CARD_STATUS_FAILURE',
}

export interface VaccinationBonusAction extends Action {
    type: VaccinationBonusActionTypes;
    payload?: VaccinationBonusPayloads;
    error?: boolean;
}

export interface InternalVaccinationBonusRequestUpdateAction extends VaccinationBonusAction {
    payload: VaccinationBonusRecord;
}

export interface SubmitVaccinationBonusAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_BONUS_REQUEST;
    payload: SubmitVaccinationBonusRequest;
}

export interface SubmitVaccinationBonusSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_BONUS_SUCCESS;
    payload: GetVaccinationBonusResponse;
}

export interface SubmitVaccinationBonusFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_BONUS_FAILURE;
    error: boolean;
}

export interface GetVaccinationBonusAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_BONUS_REQUEST;
}

export interface GetVaccinationBonusSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_BONUS_SUCCESS;
    payload: GetVaccinationBonusResponse;
}

export interface GetVaccinationBonusFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_BONUS_FAILURE;
    error: boolean;
}

export interface GetVaccinationUpdatesAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_UPDATES_REQUEST;
}

export interface GetVaccinationUpdatesSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_UPDATES_SUCCESS;
    payload: GetVaccinationUpdatesResponse;
}

export interface GetVaccinationUpdatesFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_UPDATES_FAILURE;
    error: boolean;
}

export interface GetVaccinationCountryConfigAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_COUNTRY_CONFIG_REQUEST;
}

export interface GetVaccinationCountryConfigSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_COUNTRY_CONFIG_SUCCESS;
    payload: GetVaccinationCountryConfigResponse;
}

export interface GetVaccinationCountryConfigFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_COUNTRY_CONFIG_FAILURE;
    error: boolean;
}

export interface GetVaccinationCardStatusAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_CARD_STATUS_REQUEST;
}

export interface GetVaccinationCardStatusSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_CARD_STATUS_SUCCESS;
    payload: GetVaccinationCardStatusResponse;
}

export interface GetVaccinationCardStatusFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_CARD_STATUS_FAILURE;
    error: boolean;
}

export interface GetBoosterCardStatusAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_BOOSTER_CARD_STATUS_REQUEST;
}

export interface GetBoosterCardStatusSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_BOOSTER_CARD_STATUS_SUCCESS;
    payload: GetVaccinationCardStatusResponse;
}

export interface GetBoosterCardStatusFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_BOOSTER_CARD_STATUS_FAILURE;
    error: boolean;
}

export interface GetVaccinationComplianceAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_COMPLIANCE_REQUEST;
}

export interface GetVaccinationComplianceSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_COMPLIANCE_SUCCESS;
    payload: GetVaccinationComplianceResponse;
}

export interface GetVaccinationComplianceFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_COMPLIANCE_FAILURE;
    error: boolean;
}

export interface GetFirstDoseCardStatusAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_FIRSTDOSE_CARD_STATUS_REQUEST;
}

export interface GetFirstDoseCardStatusSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_FIRSTDOSE_CARD_STATUS_SUCCESS;
    payload: GetVaccinationCardStatusResponse;
}

export interface GetFirstDoseCardStatusFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_FIRSTDOSE_CARD_STATUS_FAILURE;
    error: boolean;
}

export interface SubmitVaccinationSurveyAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_SURVEY_REQUEST;
    payload: SubmitVaccinationSurveyRequest;
}

export interface SubmitVaccinationSurveySuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_SURVEY_SUCCESS;
    payload: SubmitVaccinationSurveyResponses;
}

export interface SubmitVaccinationSurveyFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_SURVEY_FAILURE;
}

export interface GetSurveyStatusAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_SURVEY_STATUS_REQUEST;
}

export interface GetSurveyStatusSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_SURVEY_STATUS_SUCCESS;
    payload: GetVaccinationSurveyStatusResponse;
}

export interface GetSurveyStatusFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_VACCINATION_SURVEY_STATUS_FAILURE;
}

// TestAttestation actions
export interface GetTestAttestationsAction extends VaccinationBonusAction {
  type: VaccinationBonusActionTypes.GET_TEST_ATTESTATIONS_REQUEST;
}

export interface GetTestAttestationsSuccessAction extends VaccinationBonusAction {
  type: VaccinationBonusActionTypes.GET_TEST_ATTESTATIONS_SUCCESS;
  payload: GetTestAttestationResponse;
}

export interface GetTestAttestationsFailureAction extends VaccinationBonusAction {
  type: VaccinationBonusActionTypes.GET_TEST_ATTESTATIONS_FAILURE;
}

export interface SubmitTestAttestationAction extends VaccinationBonusAction {
  type: VaccinationBonusActionTypes.SUBMIT_TEST_ATTESTATION_REQUEST;
  payload: SubmitTestAttestationRequest;
}

export interface SubmitTestAttestationSuccessAction extends VaccinationBonusAction {
  type: VaccinationBonusActionTypes.SUBMIT_TEST_ATTESTATION_SUCCESS;
  payload: GetTestAttestationResponse;
}

export interface SubmitTestAttestationFailureAction extends VaccinationBonusAction {
  type: VaccinationBonusActionTypes.SUBMIT_TEST_ATTESTATION_FAILURE;
}

export interface UpdateTestAttestationAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.UPDATE_TEST_ATTESTATION_REQUEST;
    payload: SubmitTestAttestationRequest;
}

export interface UpdateTestAttestationSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.UPDATE_TEST_ATTESTATION_SUCCESS;
    payload: GetTestAttestationResponse;
}

export interface UpdateTestAttestationFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.UPDATE_TEST_ATTESTATION_FAILURE;
}

// CovidTestLite actions
export interface GetCovidTestLiteAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_COVID_TEST_LITE_REQUEST;
}

export interface GetCovidTestLiteSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_COVID_TEST_LITE_SUCCESS;
    payload: GetCovidTestLiteResponse;
}

export interface GetCovidTestLiteFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.GET_COVID_TEST_LITE_FAILURE;
}

export interface SubmitCovidTestLiteAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.SUBMIT_COVID_TEST_LITE_REQUEST;
    payload: SubmitCovidTestLiteRequest;
}

export interface SubmitCovidTestLiteSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.SUBMIT_COVID_TEST_LITE_SUCCESS;
    payload: GetCovidTestLiteResponse;
}

export interface SubmitCovidTestLiteFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.SUBMIT_COVID_TEST_LITE_FAILURE;
}

export interface UpdateCovidTestLiteAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.UPDATE_COVID_TEST_LITE_REQUEST;
    payload: SubmitCovidTestLiteRequest;
}

export interface UpdateCovidTestLiteSuccessAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.UPDATE_COVID_TEST_LITE_SUCCESS;
    payload: GetCovidTestLiteResponse;
}

export interface UpdateCovidTestLiteFailureAction extends VaccinationBonusAction {
    type: VaccinationBonusActionTypes.UPDATE_COVID_TEST_LITE_FAILURE;
}

// GetCardStatus Actions
export interface GetCardStatusAction extends VaccinationBonusAction {
  type: VaccinationBonusActionTypes.GET_CARD_STATUS_REQUEST;
  payload: CardType;
}

export interface GetCardStatusSuccessActionPayload {
  cardType: CardType;
  response: GetVaccinationCardStatusResponse;
}

export interface GetCardStatusSuccessAction extends VaccinationBonusAction {
  type: VaccinationBonusActionTypes.GET_CARD_STATUS_SUCCESS;
  payload: GetCardStatusSuccessActionPayload;
}

export interface GetCardStatusFailureAction extends Action {
    type: VaccinationBonusActionTypes.GET_CARD_STATUS_FAILURE;
    payload: CardType;
    error: boolean;
}

/**
 * Returns an action to update the vaccination bonus request in app state.
 * @param request new vaccination bonus request
 */
export const createInternalVaccinationBonusRequestUpdateAction =
    (request: VaccinationBonusRecord): InternalVaccinationBonusRequestUpdateAction => ({
        type: VaccinationBonusActionTypes.INTERNAL_VACCINATION_BONUS_REQUEST_UPDATE,
        payload: request
    });

/**
 * Returns an action to submit vaccination bonus.
 * @param request - absence report request.
 */
export const createSubmitVaccinationBonusRequestAction =
    (request: SubmitVaccinationBonusRequest): SubmitVaccinationBonusAction => ({
        type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_BONUS_REQUEST,
        payload: request
    });

/**
 * Returns an action to handle success in submitting vaccination bonus.
 * @param response
 */
export const createSubmitVaccinationBonusSuccessAction =
    (response: GetVaccinationBonusResponse): SubmitVaccinationBonusSuccessAction => ({
        type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_BONUS_SUCCESS,
        payload: response
    });

/**
 * Returns an action to handle failure in submitting vaccination bonus.
 */
export const createSubmitVaccinationBonusFailureAction =
    (): SubmitVaccinationBonusFailureAction => ({
        type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_BONUS_FAILURE,
        error: true
    });

/**
 * Returns an action to get vaccination bonus records.
 */
export const createGetVaccinationBonusAction =
    (): GetVaccinationBonusAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_BONUS_REQUEST,
    });

/**
 * Returns an action to handle success in getting vaccination bonus records.
 */
export const createGetVaccinationBonusSuccessAction =
    (response: GetVaccinationBonusResponse): GetVaccinationBonusSuccessAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_BONUS_SUCCESS,
        payload: response
    });

/**
 * Returns an action to handle failure in getting vaccination bonus records.
 */
export const createGetVaccinationBonusFailureAction =
    (): GetVaccinationBonusFailureAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_BONUS_FAILURE,
        error: true
    });

/**
 * Returns an action to get vaccination update records.
 */
export const createGetVaccinationUpdatesAction =
    (): GetVaccinationUpdatesAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_UPDATES_REQUEST,
    });

/**
 * Returns an action to handle success in getting vaccination update records.
 */
export const createGetVaccinationUpdatesSuccessAction =
    (response: GetVaccinationUpdatesResponse): GetVaccinationUpdatesSuccessAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_UPDATES_SUCCESS,
        payload: response
    });

/**
 * Returns an action to handle failure in getting vaccination update records.
 */
export const createGetVaccinationUpdatesFailureAction =
    (): GetVaccinationUpdatesFailureAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_UPDATES_FAILURE,
        error: true
    });

/**
 * Returns an action to get vaccination country config.
 */
export const createGetVaccinationCountryConfigAction =
    (): GetVaccinationCountryConfigAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_COUNTRY_CONFIG_REQUEST,
    });

/**
 * Returns an action to handle success in getting vaccination country config.
 */
export const createGetVaccinationCountryConfigSuccessAction =
    (response: GetVaccinationCountryConfigResponse): GetVaccinationCountryConfigSuccessAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_COUNTRY_CONFIG_SUCCESS,
        payload: response
    });

/**
 * Returns an action to handle failure in getting vaccination country config.
 */
export const createGetVaccinationCountryConfigFailureAction =
    (): GetVaccinationCountryConfigFailureAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_COUNTRY_CONFIG_FAILURE,
        error: true
    });

/**
 * Returns an action to get vaccination card status.
 */
export const createGetVaccinationCardStatusAction =
    (): GetVaccinationCardStatusAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_CARD_STATUS_REQUEST,
    });

/**
 * Returns an action to handle success in getting vaccination card status.
 */
export const createGetVaccinationCardStatusSuccessAction =
    (response: GetVaccinationCardStatusResponse): GetVaccinationCardStatusSuccessAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_CARD_STATUS_SUCCESS,
        payload: response
    });

/**
 * Returns an action to handle failure in getting vaccination card status.
 */
export const createGetVaccinationCardStatusFailureAction =
    (): GetVaccinationCardStatusFailureAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_CARD_STATUS_FAILURE,
        error: true
    });

/**
 * Returns an action to get booster card status.
 */
 export const createGetBoosterCardStatusAction =
    (): GetBoosterCardStatusAction => ({
        type: VaccinationBonusActionTypes.GET_BOOSTER_CARD_STATUS_REQUEST,
    });

/**
 * Returns an action to handle success in getting booster card status.
 */
export const createGetBoosterCardStatusSuccessAction =
    (response: GetVaccinationCardStatusResponse): GetBoosterCardStatusSuccessAction => ({
        type: VaccinationBonusActionTypes.GET_BOOSTER_CARD_STATUS_SUCCESS,
        payload: response
    });

/**
 * Returns an action to handle failure in getting booster card status.
 */
export const createGetBoosterCardStatusFailureAction =
    (): GetBoosterCardStatusFailureAction => ({
        type: VaccinationBonusActionTypes.GET_BOOSTER_CARD_STATUS_FAILURE,
        error: true
    });

/**
 * Returns an action to get vaccination compliance.
 */
export const createGetVaccinationComplianceAction =
    (): GetVaccinationComplianceAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_COMPLIANCE_REQUEST,
    });

/**
 * Returns an action to handle success in getting vaccination compliance.
 */
export const createGetVaccinationComplianceSuccessAction =
    (response: GetVaccinationComplianceResponse): GetVaccinationComplianceSuccessAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_COMPLIANCE_SUCCESS,
        payload: response
    });

/**
 * Returns an action to handle failure in getting vaccination compliance.
 */
export const createGetVaccinationComplianceFailureAction =
    (): GetVaccinationComplianceFailureAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_COMPLIANCE_FAILURE,
        error: true
    });

export const createGetFirstDoseCardStatusAction =
    (): GetFirstDoseCardStatusAction => ({
        type: VaccinationBonusActionTypes.GET_FIRSTDOSE_CARD_STATUS_REQUEST,
    });

export const createGetFirstDoseCardStatusSuccessAction =
    (response: GetVaccinationCardStatusResponse): GetFirstDoseCardStatusSuccessAction => ({
        type: VaccinationBonusActionTypes.GET_FIRSTDOSE_CARD_STATUS_SUCCESS,
        payload: response
    });

export const createGetFirstCardStatusFailureAction =
    (): GetFirstDoseCardStatusFailureAction => ({
        type: VaccinationBonusActionTypes.GET_FIRSTDOSE_CARD_STATUS_FAILURE,
        error: true
    });

export const createSubmitVaccinationSurveyAction =
    (request: SubmitVaccinationSurveyRequest): SubmitVaccinationSurveyAction => ({
        type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_SURVEY_REQUEST,
        payload: request
    });

export const createSubmitVaccinationSurveySuccessAction =
    (response: SubmitVaccinationSurveyResponses): SubmitVaccinationSurveySuccessAction => ( {
        type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_SURVEY_SUCCESS,
        payload: response
    });

export const createSubmitVaccinationSurveyFailureAction =
    (): SubmitVaccinationSurveyFailureAction => ({
        type: VaccinationBonusActionTypes.SUBMIT_VACCINATION_SURVEY_FAILURE,
        error: true
    });

export const createGetSurveyStatusAction = (
    (): GetSurveyStatusAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_SURVEY_STATUS_REQUEST
    }));

export const createGetSurveyStatusSuccessAction = (
    (response: GetVaccinationSurveyStatusResponse): GetSurveyStatusSuccessAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_SURVEY_STATUS_SUCCESS,
        payload: response
    }));

export const createGetSurveyStatusFailureAction = (
    (): GetSurveyStatusFailureAction => ({
        type: VaccinationBonusActionTypes.GET_VACCINATION_SURVEY_STATUS_FAILURE,
        error: true
    }));

// TestAttestation action creators
export const createGetTestAttestationsAction = (
  (): GetTestAttestationsAction => ({
    type: VaccinationBonusActionTypes.GET_TEST_ATTESTATIONS_REQUEST
  }));

export const createGetTestAttestationsSuccessAction = (
  (response: GetTestAttestationResponse): GetTestAttestationsSuccessAction => ({
    type: VaccinationBonusActionTypes.GET_TEST_ATTESTATIONS_SUCCESS,
    payload: response
  }));

export const createGetTestAttestationsFailureAction = (
  (): GetTestAttestationsFailureAction => ({
    type: VaccinationBonusActionTypes.GET_TEST_ATTESTATIONS_FAILURE,
    error: true
  }));

export const createSubmitTestAttestationAction =
  (request: SubmitTestAttestationRequest): SubmitTestAttestationAction => ({
    type: VaccinationBonusActionTypes.SUBMIT_TEST_ATTESTATION_REQUEST,
    payload: request
  });

export const createSubmitTestAttestationSuccessAction =
  (response: GetTestAttestationResponse): SubmitTestAttestationSuccessAction => ( {
    type: VaccinationBonusActionTypes.SUBMIT_TEST_ATTESTATION_SUCCESS,
    payload: response
  });

export const createSubmitTestAttestationFailureAction =
  (): SubmitTestAttestationFailureAction => ({
    type: VaccinationBonusActionTypes.SUBMIT_TEST_ATTESTATION_FAILURE,
    error: true
  });

export const createUpdateTestAttestationAction =
    (request: SubmitTestAttestationRequest): UpdateTestAttestationAction => ({
        type: VaccinationBonusActionTypes.UPDATE_TEST_ATTESTATION_REQUEST,
        payload: request
    });

export const createUpdateTestAttestationSuccessAction =
    (response: GetTestAttestationResponse): UpdateTestAttestationSuccessAction => ( {
        type: VaccinationBonusActionTypes.UPDATE_TEST_ATTESTATION_SUCCESS,
        payload: response
    });

export const createUpdateTestAttestationFailureAction =
    (): UpdateTestAttestationFailureAction => ({
        type: VaccinationBonusActionTypes.UPDATE_TEST_ATTESTATION_FAILURE,
        error: true
    });

// CovidTestLite action creators
export const createGetCovidTestLiteAction = (
    (): GetCovidTestLiteAction => ({
        type: VaccinationBonusActionTypes.GET_COVID_TEST_LITE_REQUEST
    }));

export const createGetCovidTestLiteSuccessAction = (
    (response: GetCovidTestLiteResponse): GetCovidTestLiteSuccessAction => ({
        type: VaccinationBonusActionTypes.GET_COVID_TEST_LITE_SUCCESS,
        payload: response
    }));

export const createGetCovidTestLiteFailureAction = (
    (): GetCovidTestLiteFailureAction => ({
        type: VaccinationBonusActionTypes.GET_COVID_TEST_LITE_FAILURE,
        error: true
    }));

export const createSubmitCovidTestLiteAction =
    (request: SubmitCovidTestLiteRequest): SubmitCovidTestLiteAction => ({
        type: VaccinationBonusActionTypes.SUBMIT_COVID_TEST_LITE_REQUEST,
        payload: request
    });

export const createSubmitCovidTestLiteSuccessAction =
    (response: GetCovidTestLiteResponse): SubmitCovidTestLiteSuccessAction => ( {
        type: VaccinationBonusActionTypes.SUBMIT_COVID_TEST_LITE_SUCCESS,
        payload: response
    });

export const createSubmitCovidTestLiteFailureAction =
    (): SubmitCovidTestLiteFailureAction => ({
        type: VaccinationBonusActionTypes.SUBMIT_COVID_TEST_LITE_FAILURE,
        error: true
    });

export const createUpdateCovidTestLiteAction =
    (request: SubmitCovidTestLiteRequest): UpdateCovidTestLiteAction => ({
        type: VaccinationBonusActionTypes.UPDATE_COVID_TEST_LITE_REQUEST,
        payload: request
    });

export const createUpdateCovidTestLiteSuccessAction =
    (response: GetCovidTestLiteResponse): UpdateCovidTestLiteSuccessAction => ( {
        type: VaccinationBonusActionTypes.UPDATE_COVID_TEST_LITE_SUCCESS,
        payload: response
    });

export const createUpdateCovidTestLiteFailureAction =
    (): UpdateCovidTestLiteFailureAction => ({
        type: VaccinationBonusActionTypes.UPDATE_COVID_TEST_LITE_FAILURE,
        error: true
    });

// GetCardStatus action creators
export const createGetCardStatusAction = (
  (cardType: CardType): GetCardStatusAction => ({
    type: VaccinationBonusActionTypes.GET_CARD_STATUS_REQUEST,
    payload: cardType,
  }));

export const createGetCardStatusSuccessAction = (
  (cardType: CardType, response: GetVaccinationCardStatusResponse): GetCardStatusSuccessAction => ({
    type: VaccinationBonusActionTypes.GET_CARD_STATUS_SUCCESS,
    payload: {
      cardType: cardType,
      response: response,
    },
  }));

export const createGetCardStatusFailureAction = (
  (cardType: CardType): GetCardStatusFailureAction => ({
    type: VaccinationBonusActionTypes.GET_CARD_STATUS_FAILURE,
      payload: cardType,
    error: true
  }));

export const resetVaccinationResponse = createAction('vaccinationBonus/resetVaccinationResponse');
export const setEditDoseSuccessful = createAction('vaccinationBonus/setEditDoseSuccessful');
export const updateTestAttestationRecordAction = createAction<TestAttestationRecord>('vaccinationBonus/updateTestAttestationRecord');
export const updateCovidTestLiteRecordAction = createAction<CovidTestLiteRecord>('covidTestLite/updateRecordAction');
