import { DatePicker } from '@amzn/stencil-react-components/date-picker';
import { Radio } from '@amzn/stencil-react-components/dist/submodules/form';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { H3, Label, Text } from '@amzn/stencil-react-components/text';
import i18n from 'i18next';
import { DateTime, Duration } from 'luxon';
import React, { Component, Dispatch } from 'react';
import { isDesktop } from 'react-device-detect';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import { updateCovidTestLiteRecordAction } from 'src/apps/vaccination/actions/vaccinationBonusActions';
import {
    CovidTestLiteRecord,
    CovidTestLiteResult, defaultCovidTestLiteRecord, isRecordFilled,
    isRecordFilledWithoutImage
} from 'src/apps/vaccination/models/vaccination/covidTestLiteRecord';
import { ApiClientsProps } from 'src/models/ApiClients';
import { ROUTES } from 'src/routes';
import { withClient } from '../../../../../contexts/clientContext';
import { ILoggerProps, withLogger } from '../../../../../logger';
import { AppState } from '../../../../../models/appState';
import { CountryConfig } from '../../../models/vaccination/countryConfig';
import { PrimaryButton, TertiaryButton } from '../../__shared__/button/Button';
import { ConfirmationModal } from '../__shared__/ConfirmationModal';
import { ErrorStatusIndicator } from '../__shared__/ErrorStatusIndicator';
import { ErrorBannerWithRejectReasons } from './ErrorBannerWithRejectReasons';
import './styles.scss';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

const DEFAULT_TEST_COLLECTION_EARLIEST_DAYS_AGO = 7;

export interface CovidTestLiteInfoFormProps extends RouteComponentProps, ILoggerProps, ApiClientsProps {
    countryConfig: CountryConfig;
    getVaccinationCountryConfig: () => void;
    record: CovidTestLiteRecord;
    updateCovidTestLiteRecord: (record: CovidTestLiteRecord) => void;
}

interface CovidTestLiteInfoFormState extends CovidTestLiteRecord {
    highlightEmptyFields: boolean;
    errorBannerMessageId: string | undefined;
    rejectReasons: string[];
    confirmCancelModalVisible: boolean;
}

export class _CovidTestLiteInfoForm extends Component<CovidTestLiteInfoFormProps, CovidTestLiteInfoFormState> {
    state: CovidTestLiteInfoFormState = {
        ...this.props.record,

        highlightEmptyFields: false,
        errorBannerMessageId: undefined,
        rejectReasons: [],
        confirmCancelModalVisible: false,
    };

    dismissErrorMessage = () => this.setState({ errorBannerMessageId: undefined });

    setTestResult = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            result: CovidTestLiteResult[e?.target?.value],
            errorBannerMessageId: undefined,
        });
    }
    setTestCollectionDate = (value: string) => {
        const collectionDate = DateTime.fromISO(value);
        this.setState({
            collectionDate: collectionDate,
            errorBannerMessageId: undefined,
        });
    };

    handleContinue = () => {
        if (!isRecordFilledWithoutImage(this.state)) {
            this.setState({
                highlightEmptyFields: true,
                errorBannerMessageId: 'fieldsAreUnfilled',
            });
            window.scrollTo(0, 0);
            return;
        }

        this.props.updateCovidTestLiteRecord(this.state);
        window.scrollTo(0, 0);
        this.props.history.push(ROUTES.VACCINATION.COVID_TEST_LITE.ADD_IMAGE.path);
    };

    handleCancelConfirmed = () => {
        this.props.updateCovidTestLiteRecord(defaultCovidTestLiteRecord);
        this.props.history.push(ROUTES.VACCINATION.path);
    };

    render() {
        const isTestResultSelectError = this.state.highlightEmptyFields && this.state.result === CovidTestLiteResult.UNKNOWN;
        const isTestCollectionDatePickerError = this.state.highlightEmptyFields && !this.state.collectionDate;

        const testCollectionEarliestDaysAgo = this.props.countryConfig?.covidTestLiteCollectionEarliestDaysAgo ?? DEFAULT_TEST_COLLECTION_EARLIEST_DAYS_AGO;
        const testCollectionLatestDate = DateTime.now();
        const testCollectionEarliestDate = testCollectionLatestDate.minus(Duration.fromObject({days: testCollectionEarliestDaysAgo}));

        return (
            <div className="test-attestation-form">
                <View maxWidth="480px" minWidth="320px" margin="0 auto" padding="20px 15px" backgroundColor="neutral0">
                    <ErrorBannerWithRejectReasons
                        messageId={this.state.errorBannerMessageId}
                        rejectReasons={this.state.rejectReasons}
                        onDismissed={this.dismissErrorMessage}
                    />
                    <Col>
                        <H3 fontWeight="bold">
                            {i18n.t('covidTestLite.testInfoForm.title')}
                        </H3>
                    </Col>
                    <Spacer height={20}/>

                    <Col>
                        <Col padding="0 0 20px 0">
                            <Label>{i18n.t('covidTestLite.testInfoForm.testRadio.title')}</Label>
                        </Col>
                        <Col gridGap="S300">
                            {[CovidTestLiteResult.NEGATIVE, CovidTestLiteResult.POSITIVE].map((testResult, i) => (
                                <Row key={`test-result-radio-${i}`} alignItems="center" gridGap="S200">
                                    <Radio id={`${testResult}-test-radio`} name="test-result-radio" value={testResult} onChange={this.setTestResult}
                                           checked={this.state.result === testResult}
                                           error={isTestResultSelectError}
                                    />
                                    <Label htmlFor={`${testResult}-test-radio`}>{i18n.t(`covidTestLite.testInfoForm.testRadio.${testResult}`)}</Label>
                                </Row>
                            ))}
                            {isTestResultSelectError && <ErrorStatusIndicator message={i18n.t('covidTestLite.errorTooltip.selectOption')} /> }
                        </Col>
                    </Col>
                    <Spacer height={30}/>

                    <Col>
                        <Col>
                            <Label>{i18n.t('covidTestLite.testInfoForm.testCollectionDateLabel')}</Label>
                        </Col>
                        <DatePicker
                            id="test-collection-date-picker"
                            value={this.state.collectionDate?.toISODate()}
                            onChange={this.setTestCollectionDate}
                            isDateDisabled={(date) => {
                                const dateTime = DateTime.fromISO(date);
                                return dateTime < testCollectionEarliestDate || dateTime > testCollectionLatestDate;
                            }}
                            error={isTestCollectionDatePickerError}
                        />
                        {isTestCollectionDatePickerError && <ErrorStatusIndicator message={i18n.t('covidTestLite.errorTooltip.selectDate')} /> }
                    </Col>

                    <Spacer height={30}/>

                    <Col>
                        <PrimaryButton
                            isFullWidth={true}
                            onClick={this.handleContinue}
                            data-omniture-link="Covid Test Lite Reporting - Continue to Image"
                            data-pxt-telemetry-events={describeTelemetryClickEvent('Covid Test Lite Reporting - Continue to Image')}
                        >
                            <Text>{i18n.t('covidTestLite.testInfoForm.continueButton')}</Text>
                        </PrimaryButton>
                    </Col>

                    {isDesktop && <>
                        <Spacer height={20}/>
                        <Col>
                            <TertiaryButton isFullWidth={true} onClick={() => this.setState({ confirmCancelModalVisible: true })}>
                                <Text>{i18n.t('covidTestLite.testInfoForm.cancelButton')}</Text>
                            </TertiaryButton>

                            <ConfirmationModal
                                isOpen={this.state.confirmCancelModalVisible}
                                title={i18n.t('covidTestLite.confirmCancelModal.title')}
                                text={i18n.t('covidTestLite.confirmCancelModal.text')}
                                discardText={i18n.t('covidTestLite.confirmCancelModal.goBackButton')}
                                confirmText={i18n.t('covidTestLite.confirmCancelModal.confirmButton')}
                                onConfirmClick={this.handleCancelConfirmed}
                                onClose={() => this.setState({ confirmCancelModalVisible: false })}
                            />
                        </Col>
                    </>}
                </View>
            </div>
        );
    }
}

export const mapStateToProps = (state: AppState) => ({
    record: state.vaccination.covidTestLiteLatestRecord,
} as CovidTestLiteInfoFormProps);

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    updateCovidTestLiteRecord: (record: CovidTestLiteRecord) => dispatch(updateCovidTestLiteRecordAction(record)),
} as CovidTestLiteInfoFormProps);

export const CovidTestLiteInfoForm =
    connect(mapStateToProps, mapDispatchToProps)(withClient(withLogger(withRouter(_CovidTestLiteInfoForm))));
