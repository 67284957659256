import { CovidTestLiteResponseRecord } from 'src/apps/vaccination/models/apiRequest/getCovidTestLiteResponse';
import { CovidTestLiteRecord } from 'src/apps/vaccination/models/vaccination/covidTestLiteRecord';

export interface SubmitCovidTestLiteRequest extends CovidTestLiteResponseRecord {
    employeeId: string;
    imagePath?: string,
}

export function submitCovidTestLiteRequestFrom(record: CovidTestLiteRecord, employeeId: string): SubmitCovidTestLiteRequest {
    return {
        createAt: record.createAt,
        createdAtDate: record.createdAtDate?.toISODate(),
        result: record.result,
        collectionDate: record.collectionDate ? record.collectionDate.toISODate() : '',
        employeeId: employeeId,
        imagePath: record.imagePath,
    } as SubmitCovidTestLiteRequest
}
