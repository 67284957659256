import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import languageMap from '@amzn/katal-localization/webpack-loader!';
import { Settings } from 'luxon';

// Whether initialization is complete
let isInitialized = false;
// If initialization is complete, the last error, if any
let initializationError: string | undefined;

type I18nCallback = (err?: string) => void;
// Registered callbacks for when i18n initialization completes
let i18nInitializationCallbacks: I18nCallback[] = [];

const originalT = i18n.t;
const originalExists = i18n.exists;
i18n.t = (...args) => {
    let keys = args.shift();
    if (keys && Array.isArray(keys)) {
        let newKeys = [];
        for (const key of keys) {
            newKeys.push(key.replaceAll('.', '_'));
        }
        keys = newKeys;
    } else if (keys) {
        keys = keys.replaceAll('.', '_');
    }
    return originalT.call(i18n, keys, ...args);
}

i18n.exists = (...args) => {
    let key = args.shift() as string;
    if (key) {
        key = key.replaceAll('.', '_');
    }
    return originalExists.call(i18n, key, ...args);
}

// https://sim.amazon.com/issues/A2ZC-148
const esspBrokenLocales = {
    'en': 'en-US',
    'cs': 'cs-CZ',
    'sk': 'sk-SK',
};

export function normalizeESSPLocale(locale: string): string {
    return esspBrokenLocales[locale] ?? locale;
}

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            debug: process.env.NODE_ENV !== 'production',
            fallbackLng: 'en-US',
            interpolation: {
                // not needed for react as it escapes by default
                escapeValue: false
            },
            keySeparator: false,
            load: 'currentOnly',
            backend: {
                loadPath: (localeList: any) => {
                    const locale = normalizeESSPLocale(localeList[0]);
                    return languageMap[locale];
                }
            },
            detection: {
                // order and from where user language should be detected
                order: ['htmlTag', 'navigator']
            }
        },
        (err?: string) => {
            initializationError = err;
            isInitialized = true;
            i18nInitializationCallbacks.forEach(callback => {
              callback(initializationError);
            });
            i18nInitializationCallbacks.length = 0;
        }
    );

/**
 * Helper function to run the given callback to report the status of translation initialization.
 *
 * If translation is already initialized the callback will run immediately, otherwise it will run
 * when translation initialization is complete.
 *
 * This helper function fills a gap in the i18next library, where it doesn't report the initialization
 * status through any of its own events or status flags.
 */
const i18nWhenReady = (callback: I18nCallback) => {
    if (isInitialized) {
        callback(initializationError);
    } else {
        i18nInitializationCallbacks.push(callback);
    }
};


i18n.on('languageChanged', lng => {
    Settings.defaultLocale = lng;
});


export { i18nWhenReady };
export default i18n;
