import { Action } from 'redux';
import { List } from 'immutable';
import { FeatureRecord } from '../models/feature/featureRecord';

export enum FeatureActionTypes {
    GET_FEATURES_REQUEST = 'GET_FEATURES_REQUEST',
    GET_FEATURES_SUCCESS = 'GET_FEATURES_SUCCESS',
    GET_FEATURES_FAILURE = 'GET_FEATURES_FAILURE'
}

export type FeaturePayloads = string | List<FeatureRecord>;

export type FeatureActions = GetFeaturesRequestAction
    | GetFeaturesSuccessAction
    | GetFeaturesFailureAction;

export interface FeatureAction extends Action {
    type: FeatureActionTypes;
    payload?: FeaturePayloads;
    error?: boolean;
}

export interface GetFeaturesRequestAction extends FeatureAction {
    type: FeatureActionTypes.GET_FEATURES_REQUEST;
}

export interface GetFeaturesSuccessAction extends FeatureAction {
    type: FeatureActionTypes.GET_FEATURES_SUCCESS;
    payload: List<FeatureRecord>;
}

export interface GetFeaturesFailureAction extends FeatureAction {
    type: FeatureActionTypes.GET_FEATURES_FAILURE;
    payload: any;
}

export const createGetFeaturesRequestAction = (): GetFeaturesRequestAction => ({
    type: FeatureActionTypes.GET_FEATURES_REQUEST,
});

export const createGetFeaturesSuccessAction = (features: List<FeatureRecord>): GetFeaturesSuccessAction => ({
    type: FeatureActionTypes.GET_FEATURES_SUCCESS,
    payload: features
});

export const createGetFeaturesFailureAction = (error: any): GetFeaturesFailureAction => ({
    type: FeatureActionTypes.GET_FEATURES_FAILURE,
    error: true,
    payload: error
});