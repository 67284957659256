import React, { Component, Dispatch } from 'react';
import i18n from 'i18next';
import { ControlLabel, Form, FormGroup } from 'react-bootstrap';
import { ROUTES } from '../../../../routes';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { getVaccineType, VaccineTypeRecord } from '../../models/vaccination/vaccineTypeRecord';
import { VaccinationBonusRecord } from '../../models/vaccination/vaccinationBonusRecord';
import { ValidationStateOptions } from '../../../../models/validation/validationStateOptions';
import { LoadingIndicator, LoadingIndicatorTheme, AccessibleDatepicker, DropdownChosen, Feedback } from '@atoz/atoz-common-ui-components';
import { DateTime } from 'luxon';
import classnames from 'classnames';
import { VaccinationAlert } from '../__shared__/vaccinationAlert/VaccinationAlert';
import { VaccinationFormWarning } from '../../models/vaccinationFormWarning/VaccinationFormWarning';
import { SiteSetting } from '../../models/vaccination/countryConfig';
import { AppState } from '../../../../models/appState';
import { createInternalVaccinationBonusRequestUpdateAction, VaccinationBonusActions } from '../../actions/vaccinationBonusActions';
import { EmployeeInfo } from '../../../../contexts/EmployeeInfoContext';
import { DateValidationResult } from '../../models/vaccination/DateValidationResult';
import { isPopstarFeatureEnabled } from '../../../../models/EmployeeInfoRecord';
import { VaccinationFeatures } from '../../constant/vaccinationFeatures';
import {getTranslatedVaccineType} from "../../utils/vaccination/getTranslatedVaccineType";
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

interface VaccinationBonusRequestProps extends RouteComponentProps {
  vaccineTypes: List<VaccineTypeRecord>;
  request: VaccinationBonusRecord;
  isLoadingFromBackend: boolean;
  isLoadingFromBackendFailed: boolean;
  previousRecords?: List<VaccinationBonusRecord>;
  onsiteSetting?: SiteSetting;
  offsiteSetting?: SiteSetting;
  isFinalDoseReported: boolean;
  canSubmitBooster: boolean;

  updateVaccinationBonusRequest: (request: VaccinationBonusRecord) => void;
}

interface VaccinationBonusRequestState {
  selectedVaccine?: VaccineTypeRecord;
  validationVaccinationDateState?: ValidationStateOptions;
  validationAlerts: VaccinationFormWarning[];
  request: VaccinationBonusRecord;
}

class _VaccinationBonusRequest extends Component<VaccinationBonusRequestProps, VaccinationBonusRequestState> {
  state = {
    validationAlerts: [] as VaccinationFormWarning[],
    selectedVaccine: undefined,
    validationVaccinationDateState: undefined,
    request: this.props.request
  };

  static contextType = EmployeeInfo;

  render() {
    if (this.shouldNotRequest()) return <Redirect push={true} to={ROUTES.VACCINATION.path} />;

    return (
      <div className="vaccination-bonus-page vaccination-request">
        {this.props.isLoadingFromBackend ? <LoadingIndicator theme={LoadingIndicatorTheme.LIGHT} /> : this.renderForm()}
      </div>
    );
  }

  private renderForm = () => {
    return (
      <>
        {this.state.validationAlerts.map(alert => (
          <VaccinationAlert key={alert.id} onClose={this.closeAlert(alert.id)}>
            {alert.message}
          </VaccinationAlert>
        ))}
        <div className="background styled-form">
          <Form>
            {this.state.request.isEdit ? (
              <>
                <div className="title">{i18n.t('vaccinationBonus.title.editDose')}</div>
                <p className="vaccination-help-block-text">{i18n.t('vaccinationBonus.blurb.editing')}</p>
              </>
            ) : (
              <>
                <div className="title">{i18n.t('vaccinationBonus.title.request')}</div>
                <p className="vaccination-help-block-text">{i18n.t('vaccinationBonus.blurb.helpBlock')}</p>
                  {!isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_NO_BENEFITS) && i18n.t('vaccinationBonus.blurb.reportDurationDisclaimer')}
              </>
            )}
            {isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_ON_OFF_SITE) && this.renderOnOffSite()}
            {isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_SHIFT_SELECTION) && this.renderDuringShift()}
            {this.renderVaccineTypeSelect()}
            {this.renderVaccinationDate()}
            {this.renderValidation()}
          </Form>
        </div>
      </>
    );
  };

  private onOffSiteChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ request: this.state.request.setOnSite(event.currentTarget.value === 'true') });
  };

  private renderOnOffSite = () => {
    const { onSiteWarning, onsite } = this.state.request;
    return (
      <>
        <div className="section-title">{i18n.t('vaccinationBonus.section.onOffSite')}</div>
        <FormGroup>
          <ControlLabel className="rad vaccination-bonus-radio" key="onsite">
            <input
              type="radio"
              value="true"
              name="onsite"
              onChange={this.onOffSiteChange}
              checked={onsite === true}
              disabled={this.props.onsiteSetting === SiteSetting.DISABLED}
            />
            <i className={classnames({ 'error-field': onSiteWarning })} />
            <span className={this.props.onsiteSetting === SiteSetting.DISABLED ? 'disabled' : ''}>{i18n.t('vaccinationBonus.onOffSite.onsitefull')}</span>
          </ControlLabel>
          <ControlLabel className="rad vaccination-bonus-radio" key="offsite">
            <input
              type="radio"
              value="false"
              name="offsite"
              onChange={this.onOffSiteChange}
              checked={onsite === false}
              disabled={this.props.offsiteSetting === SiteSetting.DISABLED}
            />
            <i className={classnames({ 'error-field': onSiteWarning })} />
            <span className={this.props.offsiteSetting === SiteSetting.DISABLED ? 'disabled' : ''}>{i18n.t('vaccinationBonus.onOffSite.offsitefull')}</span>
          </ControlLabel>
        </FormGroup>
        <Feedback show={onSiteWarning}>{i18n.t('vaccinationBonus.errors.noOptionSelected')}</Feedback>
      </>
    );
  };

  private duringShiftChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ request: this.state.request.setDuringShift(event.currentTarget.value === 'true') });
  };

  private renderDuringShift = () => {
    const { duringShiftWarning, duringShift } = this.state.request;
    return (
      <>
        <div className="section-title">{i18n.t('vaccinationBonus.section.duringShift')}</div>
        <FormGroup>
          <ControlLabel className="rad vaccination-bonus-radio" key="duringShift">
            <input
              type="radio"
              value="true"
              name="duringShift"
              onChange={this.duringShiftChange}
              checked={duringShift === true}
            />
            <i className={classnames({ 'error-field': duringShiftWarning })} />
            <span className={this.props.onsiteSetting === SiteSetting.DISABLED ? 'disabled' : ''}>{i18n.t('vaccinationBonus.duringShift.duringShiftFull')}</span>
          </ControlLabel>
          <ControlLabel className="rad vaccination-bonus-radio" key="outsideShift">
            <input
              type="radio"
              value="false"
              name="outsideShift"
              onChange={this.duringShiftChange}
              checked={duringShift === false}
            />
            <i className={classnames({ 'error-field': duringShiftWarning })} />
            <span className={this.props.offsiteSetting === SiteSetting.DISABLED ? 'disabled' : ''}>{i18n.t('vaccinationBonus.duringShift.outsideShiftFull')}</span>
          </ControlLabel>
        </FormGroup>
        <Feedback show={duringShiftWarning}>{i18n.t('vaccinationBonus.errors.noOptionSelected')}</Feedback>
      </>
    );
  };

  private vaccineTypeChange = (newVaccineType: string) => {
    const vaccineType = getVaccineType(newVaccineType, this.props.vaccineTypes);
    const nextRequest = this.state.request.setVaccineType(vaccineType);
    this.setState({ request: nextRequest });
    if (nextRequest.vaccinationDate < vaccineType.startDate) {
      // invalidate datepicker date so the user has to select again
      this.vaccinationDateChange('', nextRequest);
    }
  };

  private closeAlert = (id: string) => () =>
    this.setState(({ validationAlerts }) => ({ validationAlerts: validationAlerts.filter(alert => alert.id !== id) }));

  private shouldRenderVaccineType = (
    vaccineType: VaccineTypeRecord,
    renderAll: boolean,
    previousVaccineType?: VaccineTypeRecord,
    previousRecords?: List<VaccinationBonusRecord>
  ) => {
    const previousVaccinations = previousRecords?.filter(record => !record.isBooster);
    // do not show vaccines with `hidden: true`
    if (vaccineType.hidden) return false;

    if (this.state.request.isBooster) return true;  // display all vaccines when submitting boosters

    if (this.state.request.isEdit) {
      // always show the already selected vaccine type
      if (vaccineType === this.state.request.vaccineType) return true;

      // if editing, do not show vaccines which can't have current doses (e.g. do not show J&J if there are 2 doses)
      return (!previousVaccinations || previousVaccinations.size <= vaccineType.doses);
    }

    // do not show vaccines which can't have more doses (e.g. do not show J&J if there is already 1 dose)
    if (previousVaccinations && previousVaccinations.size >= vaccineType.doses) return false;
    // show if there is no previous vaccine type (or is 'UNKNOWN')
    if (!previousVaccineType || !previousVaccineType.isValid()) return true;
    // show if the same as the previous vaccine
    if (vaccineType === previousVaccineType) return true;

    return renderAll;
  };

  private renderVaccineTypes = (renderAll: boolean = true, previousVaccineType?: VaccineTypeRecord) => {
    const { vaccineTypeWarning } = this.state.request;
    const defaultVaccine = this.state.request.vaccineTypeTouched
      ? this.state.request.vaccineType
      : previousVaccineType;
    const placeHolderText = defaultVaccine
      ? getTranslatedVaccineType(defaultVaccine.name)
      : i18n.t('vaccinationBonus.button.vaccinePlaceholder');
    return <div className={classnames({ 'error-field': vaccineTypeWarning })}>
      <DropdownChosen
        placeHolder={placeHolderText}
        onChange={this.vaccineTypeChange}
        defaultValue={defaultVaccine?.enumValue}
      >
        {this.props.vaccineTypes
          .filter(vaccineType =>
            this.shouldRenderVaccineType(vaccineType, renderAll, previousVaccineType, this.props.previousRecords))
          .map(vaccineType => (
            <option value={vaccineType.enumValue} key={vaccineType.enumValue}>
                {getTranslatedVaccineType(vaccineType.name)}
            </option>
        ))}
      </DropdownChosen>
    </div>;
  };

  private renderVaccineTypeSelect = () => {
    let options: JSX.Element;
    if (this.props.previousRecords === undefined || this.props.previousRecords.size === 0) {
      options = this.renderVaccineTypes();
    } else if (this.state.request.isEdit) {
      options = this.renderVaccineTypes(isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_DIFFERENT_TYPE),
        this.state.request.vaccineType);
    } else {
      const previousRecord = this.props.previousRecords.first() as VaccinationBonusRecord;
      options = this.renderVaccineTypes(isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_DIFFERENT_TYPE),
        previousRecord.vaccineType);
    }

    const { vaccineTypeWarning } = this.state.request;
    const changedVaccines = !isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_DIFFERENT_TYPE) &&        // show warning only if mix&match is disabled
      this.state.request.isEdit &&            // show warning only on edits
      this.props.previousRecords &&           // show warning if there are previous records
      this.props.previousRecords.size > 1 &&  // show warning if there are more than 1 submissions
      this.props.previousRecords.filter((record) => record.vaccinationDose === this.state.request.vaccinationDose)
        .some((record) => record.vaccineType !== this.state.request.vaccineType); // only if vaccinetype has changed
    return (
      <>
        <div className="section-title">{i18n.t('vaccinationBonus.section.vaccinetype')}</div>
        <FormGroup>{options}</FormGroup>
        <Feedback show={vaccineTypeWarning}>{i18n.t('vaccinationBonus.errors.vaccineNotSelected')}</Feedback>
        <Feedback show={changedVaccines} warning={true}>{i18n.t('vaccinationBonus.errors.changeManufacturer')}</Feedback>
      </>
    );
  };

  private vaccinationDateChange = (date: string, request?: VaccinationBonusRecord) => {
    let nextRequest = request || this.state.request;
    nextRequest = nextRequest.setVaccinationDate(date);

    const validationResult = nextRequest.isDateValid() ? this.verifyDateDifference(nextRequest.vaccinationDate)
                                                       : DateValidationResult.Valid;
    nextRequest = nextRequest.set('vaccinationDateValidationResult', validationResult);

    const beforeHire =
      !isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_NO_BENEFITS)
      && nextRequest.vaccinationDate < DateTime.fromISO(this.context.hireDate).startOf('day');
    nextRequest = nextRequest.set('vaccinationDateBeforeHire', beforeHire);

    this.setState({ request: nextRequest });
  };

  private renderVaccinationDate = () => {
    const { vaccinationDateWarning, vaccinationDateValidationResult } = this.state.request;

    return (
      <FormGroup>
        <div className="section-title">{i18n.t('vaccinationBonus.section.vaccinationDate')}</div>
        <AccessibleDatepicker
          onSelect={this.vaccinationDateChange}
          selectedDate={this.state.request.vaccinationDate}
          minDate={this.state.request.vaccineType.startDate}
          maxDate={DateTime.local()}
          locale={this.context.locale}
        />
        <Feedback show={vaccinationDateWarning}>{i18n.t('vaccinationBonus.errors.emptyDate')}</Feedback>
        <Feedback show={vaccinationDateValidationResult === DateValidationResult.TooCloseVaccination}>
          {this.state.request.isBooster ? i18n.t('vaccinationBonus.errors.boosterTooClose', { days: this.state.request.vaccineType.boosterPeriod })
            : i18n.t('vaccinationBonus.errors.tooClose', { days: this.state.request.vaccineType.daysBetween })}
        </Feedback>
        <Feedback show={vaccinationDateValidationResult === DateValidationResult.TooCloseBooster}>
          {this.state.request.isBooster ? i18n.t('vaccinationBonus.errors.boosterSameDay')
            : i18n.t('vaccinationBonus.errors.tooCloseToBooster', { days: this.state.request.vaccineType.boosterPeriod })}
        </Feedback>
      </FormGroup>
    );
  };

  private renderValidation(): JSX.Element {
    return (
      <div className="report-button-section">
        <button
          onClick={this.handleFormConfirm}
          className="btn btn-primary col-xs-12"
          data-omniture-link={'Vaccination Bonus - Preview report'}
          data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Preview report')}
        >
          {i18n.t('vaccinationBonus.button.next')}
        </button>
      </div>
    );
  }

  private handleFormConfirm = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const validationResult = this.state.request.validate(this.verifyDateDifference,
      isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_ON_OFF_SITE),
      isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_SHIFT_SELECTION));
    this.props.updateVaccinationBonusRequest(validationResult.next);    
    if (!validationResult.isValid) {
      const warnings: VaccinationFormWarning[] = [];

      if (!validationResult.next.isDateValid()) {
        warnings.push(new VaccinationFormWarning({ message: i18n.t('vaccinationBonus.message.errorEmptyDate') }));
      } else switch (validationResult.next.vaccinationDateValidationResult) {
        case DateValidationResult.TooCloseVaccination:
          if (this.state.request.isBooster) {
            warnings.push(new VaccinationFormWarning({
              message: i18n.t('vaccinationBonus.message.boosterTooClose', { days: this.state.request.vaccineType.boosterPeriod })
            }));
          } else {
            warnings.push(new VaccinationFormWarning({
              message: i18n.t('vaccinationBonus.message.errorTooClose', { days: this.state.request.vaccineType.daysBetween })
            }));
          }
          break;
        case DateValidationResult.TooCloseBooster:
          // TODO
          if (this.state.request.isBooster) {
            warnings.push(new VaccinationFormWarning({
              message: i18n.t('vaccinationBonus.message.boosterSameDay')
            }));
          } else {
            warnings.push(new VaccinationFormWarning({
              message: i18n.t('vaccinationBonus.message.tooCloseToBooster', { days: this.state.request.vaccineType.boosterPeriod })
            }));
          }
          break;
        default:
          // do nothing
      }
      if (!validationResult.next.isVaccineTypeValid()) {
        warnings.push(new VaccinationFormWarning({ message: i18n.t('vaccinationBonus.message.errorVaccineNotSelected') }));
      }
      if (isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_ON_OFF_SITE) && !validationResult.next.isOnSiteValid()) {
        warnings.push(new VaccinationFormWarning({ message: i18n.t('vaccinationBonus.message.errorVaccinationPlace') }));
      }
      if (isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_ALLOW_SHIFT_SELECTION) && !validationResult.next.isDuringShiftValid()) {
        warnings.push(new VaccinationFormWarning({ message: i18n.t('vaccinationBonus.message.errorVaccinationTime') }));
      }

      this.setState({ validationAlerts: warnings });
      window.scrollTo(0, 0);

      return;
    }
    this.props.history.push(ROUTES.VACCINATION.CONFIRM.path);
  };

  private verifyDateDifference = (vaccinationDate: DateTime): DateValidationResult => {
    if (this.props.previousRecords) {
      const otherRecords = this.props.previousRecords
        .filter(record => !this.state.request.isEdit || record.vaccinationDose !== this.state.request.vaccinationDose)

      // previous dates, or other dates in case of edit, when vaccination was received, excluding boosters
      const otherVaccinationDates = otherRecords.filter(record => !record.isBooster).map(record => record.vaccinationDate);

      // previous dates, or other dates in case of edit, when boosters were received
      const otherBoosterDates = otherRecords.filter(record => record.isBooster).map(record => record.vaccinationDate);

      if (this.state.request.isBooster) {
        const latestDoseDate = otherVaccinationDates.max(); // validates only against the latest vaccination date

        // only allow doses `boosterPeriod` days AFTER latest dose, and don't overlap with other booster dates
        if (Math.ceil(vaccinationDate.diff(latestDoseDate!, 'days').days) < this.state.request.vaccineType.boosterPeriod) {
          return DateValidationResult.TooCloseVaccination;
        }
        if (otherBoosterDates.contains(vaccinationDate)) {
          return DateValidationResult.TooCloseBooster;
        }
        return DateValidationResult.Valid;
      }

      const firstBooster = otherBoosterDates.min(); // validates only against the first booster

      // validate vaccination date is not too close to other vaccination dates, and `boosterPeriod` days before booster (if it exists)
      if (otherVaccinationDates.find(previousDate =>
        Math.ceil(Math.abs(previousDate.diff(vaccinationDate, 'days').days)) < this.state.request.vaccineType.daysBetween
        ) !== undefined) {
          return DateValidationResult.TooCloseVaccination;
        }
      if (firstBooster && Math.ceil(firstBooster!.diff(vaccinationDate, 'days').days) < this.state.request.vaccineType.boosterPeriod) {
        return DateValidationResult.TooCloseBooster;
      }
      return DateValidationResult.Valid;
    }
    return DateValidationResult.Valid;
  };

  private shouldNotRequest = () => {
    if (this.props.isLoadingFromBackendFailed) return true;

    return !this.state.request.isEdit && this.props.isFinalDoseReported
      && !isPopstarFeatureEnabled(this.context, VaccinationFeatures.VACCINATION_BONUS_BOOSTERS)
      && this.props.canSubmitBooster;
  };
}

const mapStateToProps = ({ vaccination }: AppState) => ({
  vaccineTypes: vaccination.vaccineTypeRecords,
  request: vaccination.vaccinationBonusRequest,
  isLoadingFromBackend: vaccination.isLoadingFromBackend(),
  isLoadingFromBackendFailed: vaccination.isLoadingFromBackendFailed(),
  previousRecords: vaccination.previousVaccinationRecords,
  onsiteSetting: vaccination.countryConfig?.onsiteSetting,
  offsiteSetting: vaccination.countryConfig?.offsiteSetting,
  isFinalDoseReported: vaccination.isFinalDoseReported(),
  canSubmitBooster: vaccination.canSubmitBooster(),
} as VaccinationBonusRequestProps)

const mapDispatch = (dispatch: Dispatch<VaccinationBonusActions>) => ({
  updateVaccinationBonusRequest: (request: VaccinationBonusRecord) => dispatch(createInternalVaccinationBonusRequestUpdateAction(request))
});

// @ts-ignore
export const VaccinationBonusRequest = connect(mapStateToProps, mapDispatch)(withRouter(_VaccinationBonusRequest));
