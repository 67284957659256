import { Component, Dispatch } from 'react';
import i18n from 'i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from 'src/models/appState';
import { ILoggerProps, withLogger } from 'src/logger';
import { Action } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { DatePicker } from '@amzn/stencil-react-components/date-picker';
import { DateTime } from 'luxon';
import { Text, Label, H3 } from '@amzn/stencil-react-components/text';
import { Checkbox } from '@amzn/stencil-react-components/dist/submodules/form';
import { PrimaryButton, TertiaryButton } from '../../__shared__/button/Button';
import { TestAttestationRecord } from '../../../models/vaccination/testAttestationRecord';
import { SubmitTestAttestationRequest, submitTestAttestationRequestFrom } from '../../../models/apiRequest/submitTestAttestationRequest';
import { createUpdateTestAttestationAction } from '../../../actions/vaccinationBonusActions';
import { ROUTES } from 'src/routes';
import { ErrorStatusIndicator } from '../__shared__/ErrorStatusIndicator';
import { EmployeeInfo } from 'src/contexts/EmployeeInfoContext';

export interface ReturnToWorkProps extends RouteComponentProps, ILoggerProps {
    latestTest: TestAttestationRecord;
    updateTestAttestation: (request: SubmitTestAttestationRequest) => void;
}

export interface ReturnToWorkState {
    highlightEmptyFields: boolean;
    acknowledged: boolean;
    returnToWorkDate: DateTime;
}

const initialState: ReturnToWorkState = {
    highlightEmptyFields: false,
    acknowledged: false,
    returnToWorkDate: DateTime.local()
};

export class _ReturnToWork extends Component<ReturnToWorkProps, ReturnToWorkState> {
    static contextType = EmployeeInfo;

    state: ReturnToWorkState = initialState;

    handleAcknowledge = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ acknowledged: e?.target?.checked });

    setSelectedDate = (value: string) => {
        const date = DateTime.fromISO(value);
        this.setState({ returnToWorkDate: date });
    };

    confirmReturnToWork = () => {
        if (!this.state.acknowledged) {
            this.setState({ highlightEmptyFields: true });
            return;
        }
        let updateRequest = submitTestAttestationRequestFrom(this.props.latestTest, this.context.employeeId);
        updateRequest = {
            ...updateRequest,
            returnToWorkDate: this.state.returnToWorkDate.toISODate()
        }
        this.props.updateTestAttestation(updateRequest);
        this.props.history.push(ROUTES.VACCINATION.PROOF.path);
    };

    cancel = () => {
        this.props.history.push(ROUTES.VACCINATION.path);
    }

    render() {
        const isAcknowlegmentError = this.state.highlightEmptyFields && !this.state.acknowledged;

        return (
            <div className="test-attestation-form">

                <View maxWidth="480px" minWidth="320px" height="100%" margin="0 auto" padding="20px 15px" backgroundColor="neutral0" >
                    <Col>
                        <Text>{i18n.t('testAttestation.returnToWork.confirm')}</Text>
                    </Col>
                    <Spacer height={10} />
                    <Col>
                        <Text fontWeight="light">{i18n.t('testAttestation.returnToWork.chooseDate')}</Text>
                    </Col>
                    <DatePicker
                        id="test-collection-date-picker"
                        value={this.state.returnToWorkDate.toISODate()}
                        onChange={this.setSelectedDate}
                        isDateDisabled={(date) => {
                            const dateTime = DateTime.fromISO(date);
                            return dateTime < DateTime.local();
                        }}
                    />
                    <Col gridGap="S200">
                        <Text fontWeight="bold">{i18n.t('testAttestation.returnToWork.legalTitle')}</Text>
                    </Col>
                    <Spacer height={10} />
                    <Col>
                        <Label>
                            <Row alignItems="top" gridGap="S300">
                                <Checkbox checked={this.state.acknowledged} required={true} onChange={this.handleAcknowledge} error={isAcknowlegmentError} />
                                <Label>{i18n.t('testAttestation.returnToWork.acknowledgement')}</Label>
                            </Row>
                        </Label>
                        {isAcknowlegmentError && <ErrorStatusIndicator message={i18n.t('testAttestation.errorTooltip.checkConsent')} />}
                    </Col>

                    <Spacer height={10} />

                    <Col>
                        <PrimaryButton isFullWidth={true} onClick={this.confirmReturnToWork}>
                            <Text fontSize="T400">{i18n.t('testAttestation.testInfoSummaryForm.submitButton')}</Text>
                        </PrimaryButton>
                    </Col>

                    <Spacer height={10} />

                    <Col>
                        <TertiaryButton isFullWidth={true} onClick={this.cancel}>
                            <Text>{i18n.t('testAttestation.testInfoSummaryForm.cancelButton')}</Text>
                        </TertiaryButton>
                    </Col>
                </View>
            </div >
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    latestTest: state.vaccination.latestTest,
} as ReturnToWorkProps);

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    updateTestAttestation: (request: SubmitTestAttestationRequest) => dispatch(createUpdateTestAttestationAction(request)),
} as ReturnToWorkProps);

export const ReturnToWork =
    connect(mapStateToProps, mapDispatchToProps)(withLogger(withRouter(_ReturnToWork)));