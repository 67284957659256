import { Record } from 'immutable';
import { DateTime } from 'luxon';

export enum ComplianceTypes {
  COMPLIANT = 'COMPLIANT',
  NON_COMPLIANT = 'NON_COMPLIANT',
  EXEMPT = 'EXEMPT'
}

const defaultComplianceDetails = {
  isAllowedOnsite: true,
  compliantStatus: ComplianceTypes.COMPLIANT,
  isAttestationRequired: false,
  reason: null,
  expires: DateTime.invalid('blank')
};

export interface RawComplianceDetails {
  employeeId: string;
  isAllowedOnsite: boolean;
  compliantStatus: ComplianceTypes;
  isAttestationRequired: boolean;
  reason: string | null;
  expires: number;
}

type ComplianceDetailsType = {
  isAllowedOnsite: boolean;
  compliantStatus: ComplianceTypes;
  isAttestationRequired: boolean;
  reason: string | null;
  expires: DateTime;
};

export class ComplianceDetails extends Record<ComplianceDetailsType>(defaultComplianceDetails) {
  static fromRaw(rawComplianceDetails?: RawComplianceDetails) {
    if (!rawComplianceDetails) return new ComplianceDetails();

    return new ComplianceDetails({
      expires: DateTime.fromMillis(rawComplianceDetails.expires)
    });
  }
}
