import { List, Record } from 'immutable';
import {
  CovidTestLiteRecord,
  defaultCovidTestLiteRecord
} from 'src/apps/vaccination/models/vaccination/covidTestLiteRecord';
import { CardType } from '../apiRequest/cardType';
import { defaultTestAttestationRecord, TestAttestationRecord } from './testAttestationRecord';
import { getVaccineType, VaccineTypeRecord } from './vaccineTypeRecord';
import { VaccinationBonusRecord } from './vaccinationBonusRecord';
import { CardStatus, GetVaccinationCardStatusResponse } from './getVaccinationCardStatusResponse';
import { DateTime } from 'luxon';
import { VaccinationBonusUpdate } from './vaccinationBonusUpdate';
import { GetVaccinationCountryConfigResponse } from './getVaccinationCountryConfigResponse';
import { CountryConfig, DefaultSetting } from './countryConfig';
import { VaccinationSurveyRecord } from './vaccinationSurveyRecord';
import { ComplianceDetails } from './ComplianceDetails';
import { GetVaccinationBonusResponse } from "src/apps/vaccination/models/vaccination/getVaccinationBonusResponse";

export const VACCINATION_DATE_FORMAT = 'LLL d, yyyy';

const defaultVaccinationBonusAppState = {
  isInitialLoad: true,
  isGettingCountryConfig: false,
  isGettingCountryConfigFailed: false,
  countryConfig: null,
  vaccineTypeRecords: List<VaccineTypeRecord>(),
  vaccinationBonusRequest: new VaccinationBonusRecord(),
  isSubmittingVaccinationReport: false,
  isSubmittingVaccinationReportFailed: false,
  submitVaccinationBonusResponse: null,
  isGettingVaccinationBonus: false,
  isGettingVaccinationBonusFailed: false,
  previousVaccinationRecords: List<VaccinationBonusRecord>(),
  isGettingVaccinationUpdates: false,
  isGettingVaccinationUpdatesFailed: false,
  vaccinationUpdates: List<VaccinationBonusUpdate>(),

  cardStatusResponse: new Map<CardType, GetVaccinationCardStatusResponse>(),
  isGettingCardStatusResponse: new Map<CardType, boolean>(),
  isGettingCardStatusResponseSuccess: new Map<CardType, boolean>(),
  isGettingCardStatusResponseFailure: new Map<CardType, boolean>(),

  isGettingVaccinationCardStatus: false,
  isGettingVaccinationCardStatusFailed: false,
  vaccinationCardStatus: CardStatus.NOT_UPLOADED,
  cardRejectionReasons: List<string>(),
  isInitiatingCardUpload: false,
  isInitiatingCardUploadFailed: false,
  vaccinationCardCreatedAt: 0,
  vaccinationCardUpdatedAt: -1,
  vaccinationCardReupload: true,
  surveyAnswers: List<VaccinationSurveyRecord>(),
  editDoseSuccessful: false,
  complianceDetails: new ComplianceDetails(),
  isGettingBoosterCardStatus: false,
  isGettingBoosterCardStatusFailed: false,
  boosterCardStatus: CardStatus.NOT_UPLOADED,
  boosterCardCreatedAt: 0,
  boosterCardUpdatedAt: -1,
  boosterCardReupload: true,
  boosterCardRejectionReasons: List<string>(),
  isSurveyRequired: false,
  surveyResponseSubmitting: false,
  surveyResponseSubmittedSuccess: false,
  surveyResponseSubmittedFailure: false,
  isGettingFirstDoseCardStatus: false,
  isGettingFirstDoseCardStatusFailed: false,
  firstDoseCardStatus: CardStatus.NOT_UPLOADED,
  firstDoseCardCreatedAt: 0,
  firstDoseCardUpdatedAt: -1,
  firstDoseCardReupload: true,
  firstDoseRejectionReasons: List<string>(),

  testAttestationRecord: defaultTestAttestationRecord,

  testAttestations: List(),
  isGettingTestAttestations: false,
  isGettingTestAttestationsSuccess: false,
  isGettingTestAttestationsFailure: false,
  isSubmittingTestAttestation: false,
  isSubmittingTestAttestationSuccess: false,
  isSubmittingTestAttestationFailure: false,
  isUpdatingTestAttestation: false,
  isUpdatingTestAttestationSuccess: false,
  isUpdatingTestAttestationFailure: false,

  // covid test lite
  covidTestLiteLatestRecord: defaultCovidTestLiteRecord,

  covidTestLiteRecords: List(),
  isGettingCovidTestLite: false,
  isGettingCovidTestLiteSuccess: false,
  isGettingCovidTestLiteFailure: false,
  isSubmittingCovidTestLite: false,
  isSubmittingCovidTestLiteSuccess: false,
  isSubmittingCovidTestLiteFailure: false,
  isUpdatingCovidTestLite: false,
  isUpdatingCovidTestLiteSuccess: false,
  isUpdatingCovidTestLiteFailure: false,

  testCompliance: 'NOT_COMPLIANT',
  vaccinationCompliance: 'NOT_COMPLIANT',
  isGettingVaccinationCompliance: false,
  isGettingVaccinationComplianceSuccess: false,
  isGettingVaccinationComplianceFailure: false,
  testExpirationDate: '',
  latestTest: defaultTestAttestationRecord,
  inoculationPassedDate: ''
} as VaccinationBonusAppStateType;

type VaccinationBonusAppStateType = {
  // general states
  isInitialLoad: boolean;

  // vaccine types
  isGettingCountryConfig: boolean;
  isGettingCountryConfigFailed: boolean;
  countryConfig: CountryConfig | null;
  vaccineTypeRecords: List<VaccineTypeRecord>;

  // submit vaccination request
  vaccinationBonusRequest: VaccinationBonusRecord;
  isSubmittingVaccinationReport: boolean;
  isSubmittingVaccinationReportFailed: boolean;
  submitVaccinationBonusResponse: GetVaccinationBonusResponse | null;

  // previous vaccinations
  isGettingVaccinationBonus: boolean;
  isGettingVaccinationBonusFailed: boolean;
  previousVaccinationRecords: List<VaccinationBonusRecord>;

  // vaccination updates
  isGettingVaccinationUpdates: boolean;
  isGettingVaccinationUpdatesFailed: boolean;
  vaccinationUpdates: List<VaccinationBonusUpdate>;

  // Card Status responses per CardType
  cardStatusResponse: Map<CardType, GetVaccinationCardStatusResponse>;
  isGettingCardStatusResponse: Map<CardType, boolean>;
  isGettingCardStatusResponseSuccess: Map<CardType, boolean>;
  isGettingCardStatusResponseFailure: Map<CardType, boolean>;

  // vaccination card status
  isGettingVaccinationCardStatus: boolean;
  isGettingVaccinationCardStatusFailed: boolean;
  vaccinationCardStatus: CardStatus;
  vaccinationCardCreatedAt: number;
  vaccinationCardUpdatedAt: number;
  vaccinationCardReupload: boolean;
  cardRejectionReasons: List<string>;

  // initiating card upload
  isInitiatingCardUpload: boolean;
  isInitiatingCardUploadFailed: boolean;

  surveyAnswers: List<VaccinationSurveyRecord>;
  editDoseSuccessful: boolean;

  complianceDetails: ComplianceDetails;

  isGettingBoosterCardStatus: boolean;
  isGettingBoosterCardStatusFailed: boolean;
  boosterCardStatus: CardStatus;
  boosterCardCreatedAt: number;
  boosterCardUpdatedAt: number;
  boosterCardReupload: boolean;
  boosterCardRejectionReasons: List<string>;

  isSurveyRequired: boolean;
  surveyResponseSubmitting: boolean;
  surveyResponseSubmittedSuccess: boolean;
  surveyResponseSubmittedFailure: boolean;

  // test attestation
  testAttestationRecord: TestAttestationRecord;

  testAttestations: List<TestAttestationRecord>;
  isGettingTestAttestations: boolean;
  isGettingTestAttestationsSuccess: boolean;
  isGettingTestAttestationsFailure: boolean;
  isSubmittingTestAttestation: boolean;
  isSubmittingTestAttestationSuccess: boolean;
  isSubmittingTestAttestationFailure: boolean;
  isUpdatingTestAttestation: boolean;
  isUpdatingTestAttestationSuccess: boolean;
  isUpdatingTestAttestationFailure: boolean;

  // covid test lite
  covidTestLiteLatestRecord: CovidTestLiteRecord;

  covidTestLiteRecords: List<CovidTestLiteRecord>;
  isGettingCovidTestLite: boolean;
  isGettingCovidTestLiteSuccess: boolean;
  isGettingCovidTestLiteFailure: boolean;
  isSubmittingCovidTestLite: boolean;
  isSubmittingCovidTestLiteSuccess: boolean;
  isSubmittingCovidTestLiteFailure: boolean;
  isUpdatingCovidTestLite: boolean;
  isUpdatingCovidTestLiteSuccess: boolean;
  isUpdatingCovidTestLiteFailure: boolean;

  // first dose
  isGettingFirstDoseCardStatus: boolean,
  isGettingFirstDoseCardStatusFailed: boolean,
  firstDoseCardStatus: CardStatus;
  firstDoseCardCreatedAt: number;
  firstDoseCardUpdatedAt: number;
  firstDoseCardReupload: boolean;
  firstDoseRejectionReasons: List<string>;

  // compliance
  testCompliance: string;
  vaccinationCompliance: string;
  isGettingVaccinationCompliance: boolean;
  isGettingVaccinationComplianceSuccess: boolean;
  isGettingVaccinationComplianceFailure: boolean; 
  testExpirationDate: string
  latestTest: TestAttestationRecord;
  inoculationPassedDate: string;
};

const getConfigSettingOrDefault = (vaccineConfig: number|undefined, countryConfig: number|undefined, defaultValue: number) => {
  if (vaccineConfig && vaccineConfig >= 0) {
    return vaccineConfig;
  }
  if (countryConfig && countryConfig >= 0) {
    return countryConfig;
  }
  return defaultValue;
}

export class VaccinationBonusAppState extends Record<VaccinationBonusAppStateType>(defaultVaccinationBonusAppState) {
  public setCardStatus(cardStatusData: GetVaccinationCardStatusResponse): VaccinationBonusAppState {
    const cardStatus = CardStatus[cardStatusData.status.toUpperCase()] || CardStatus.NOT_UPLOADED;
    const rejectionReasons = cardStatusData.rejectionReason ? cardStatusData.rejectionReason.split(',') : [];
    this.cardStatusResponse.set(CardType.Vaccine, cardStatusData);
    return this.set('vaccinationCardStatus', cardStatus)
      .set('vaccinationCardCreatedAt', cardStatusData.createdAt)
      .set('vaccinationCardUpdatedAt', cardStatusData.updatedAt)
      .set('vaccinationCardReupload', !!cardStatusData.canReupload)
      .set('cardRejectionReasons', List<string>(rejectionReasons))
      .set('complianceDetails', ComplianceDetails.fromRaw(cardStatusData.complianceDetails));
  }

  public setFirstDoseCardStatus(cardStatusData: GetVaccinationCardStatusResponse): VaccinationBonusAppState {
    const cardStatus = CardStatus[cardStatusData.status.toUpperCase()] || CardStatus.NOT_UPLOADED;
    const rejectionReasons = cardStatusData.rejectionReason ? cardStatusData.rejectionReason.split(',') : [];
    this.cardStatusResponse.set(CardType.FirstDose, cardStatusData);
    return this.set('firstDoseCardStatus', cardStatus)
        .set('firstDoseCardCreatedAt', cardStatusData.createdAt)
        .set('firstDoseCardUpdatedAt', cardStatusData.updatedAt)
        .set('firstDoseCardReupload', !! cardStatusData.canReupload)
        .set('firstDoseRejectionReasons', List<string>(rejectionReasons));
  }

  public setBoosterCardStatus(cardStatusData: GetVaccinationCardStatusResponse): VaccinationBonusAppState {
    const cardStatus = CardStatus[cardStatusData.status.toUpperCase()] || CardStatus.NOT_UPLOADED;
    const rejectionReasons = cardStatusData.rejectionReason ? cardStatusData.rejectionReason.split(',') : [];
    this.cardStatusResponse.set(CardType.Booster, cardStatusData);
    return this.set('boosterCardStatus', cardStatus)
      .set('boosterCardCreatedAt', cardStatusData.createdAt)
      .set('boosterCardUpdatedAt', cardStatusData.updatedAt)
      .set('boosterCardReupload', !!cardStatusData.canReupload)
      .set('boosterCardRejectionReasons', List<string>(rejectionReasons));
  }

  public setCountryConfig(countryConfigData: GetVaccinationCountryConfigResponse): VaccinationBonusAppState {
    const countryConfig = CountryConfig.fromBackend(countryConfigData);
    const locationPreference = countryConfigData.configs.vaccinationLocationPreferences;
    const vaccineTypes = List<VaccineTypeRecord>(
      countryConfigData.configs.vaccineTypes.map(vaccineType =>
        new VaccineTypeRecord()
          .set('name', vaccineType.translationKey)
          .set('doses', vaccineType.numDoses)
          .set('enumValue', vaccineType.enumValue.toUpperCase())
          .set('hidden', vaccineType.hidden ? vaccineType.hidden : false)
          .set('daysBetween', getConfigSettingOrDefault(vaccineType.daysBetween,
            undefined, VaccineTypeRecord.UNSPECIFIED_DAYS_BETWEEN))
          .set('startDate', vaccineType.startDate ? DateTime.fromISO(vaccineType.startDate) : VaccineTypeRecord.DEFAULT_START_DATE)
          .set('inoculationPeriod', getConfigSettingOrDefault(vaccineType.inoculationPeriod,
            locationPreference.inoculationPeriod, VaccineTypeRecord.DEFAULT_INOCULATION_PERIOD))
          .set('boosterPeriod', getConfigSettingOrDefault(vaccineType.boosterPeriod,
            locationPreference.boosterPeriod, VaccineTypeRecord.DEFAULT_BOOSTER_PERIOD))
          .set('maxBoosters', getConfigSettingOrDefault(vaccineType.maxBoosters,
            locationPreference.maxBoosters, VaccineTypeRecord.DEFAULT_MAX_BOOSTERS))
          .set('vaccinationExpiration', getConfigSettingOrDefault(vaccineType.vaccinationExpiration,
            locationPreference.vaccinationExpiration, VaccineTypeRecord.DEFAULT_VACCINATION_EXPIRATION))
          .set('requiredBoosters', getConfigSettingOrDefault(vaccineType.requiredBoosters,
            locationPreference.requiredBoosters, VaccineTypeRecord.DEFAULT_REQUIRED_BOOSTERS))
    ));

    const previousVaccinationRecords = this.previousVaccinationRecords.map(record =>
      record.set('vaccineType', getVaccineType(record.vaccineName, vaccineTypes))
    );

    // set default settings for submit request, if needed
    let nextRequest = this.vaccinationBonusRequest;
    if (!nextRequest.vaccineType.isValid()) {
      nextRequest = nextRequest.set('vaccineType', getVaccineType(this.vaccinationBonusRequest.vaccineName, vaccineTypes));
    }
    if (nextRequest.onsite == null && countryConfig.defaultSetting !== DefaultSetting.NONE) {
      nextRequest = nextRequest.set('onsite', countryConfig.defaultSetting === DefaultSetting.ONSITE);
    }

    return this.set('countryConfig', countryConfig)
      .set('vaccineTypeRecords', vaccineTypes)
      .set('previousVaccinationRecords', previousVaccinationRecords)
      .set('vaccinationBonusRequest', nextRequest)
      .setNextBooster();
  }

  // make sure to call this AFTER setting previousVaccinationRecords or vaccinationBonusRequest parameters!
  public setNextBooster(): VaccinationBonusAppState {
    if (this.previousVaccinationRecords.size > 0) {
      const maxDoses = this.previousVaccinationRecords.first()!.vaccineType.doses;
      if (this.previousVaccinationRecords.size >= maxDoses) {
        // prepare for submitting a booster
        const nextRequest = this.vaccinationBonusRequest.set('isBooster', true);
        return this.set('vaccinationBonusRequest', nextRequest);
      }
    }
    return this;
  }

  public isFinalDoseReported() {
    if (this.previousVaccinationRecords.isEmpty()) return false;

    const firstRecord: VaccinationBonusRecord = this.previousVaccinationRecords.first();
    const maxDoses = firstRecord.vaccineType.doses;

    // Return true for "invalid" vaccine types, as the actual number of needed doses is unknown
    return (
      (!firstRecord.vaccineType.isValid() && firstRecord.vaccineName === VaccineTypeRecord.UNKNOWN) ||
      this.previousVaccinationRecords.size >= maxDoses
    );
  }

  public canSubmitBooster() {
    if (this.previousVaccinationRecords.isEmpty()) return false;

    const firstRecord: VaccinationBonusRecord = this.previousVaccinationRecords.first();
    const maxDoses = firstRecord.vaccineType.doses + firstRecord.vaccineType.maxBoosters;

    return this.previousVaccinationRecords.size < maxDoses;
  }

  public canSubmitFirstDose() {
    return this.isFirstDoseReported()
        && !this.isFinalDoseReported()
        && this.firstDoseCardStatus === CardStatus.NOT_UPLOADED;
  }

  public isFirstDoseReported() {
    return this.previousVaccinationRecords.size > 0;
  }

  public reportedVaccinationsCount() {
    return this.previousVaccinationRecords.filter(record => !record.isBooster).size;
  }

  public getInoculationPeriod(vaccinationRecord?: VaccinationBonusRecord) {
    const mostRecent = vaccinationRecord ?? this.getMostRecentVaccination();
    return mostRecent !== undefined ? mostRecent.vaccineType.inoculationPeriod : VaccineTypeRecord.DEFAULT_INOCULATION_PERIOD;
  }

  public isInoculationPeriodPassed() {
    const today = DateTime.now().startOf('day');
    const inoculationDateTime = DateTime.fromISO(this.inoculationPassedDate);
    return inoculationDateTime.isValid ? inoculationDateTime <= today : false
  }

  public isTestCompliant() {
    return this.testCompliance === 'COMPLIANT';
  }

  public isVaccineCompliant() {
    return this.vaccinationCompliance === 'COMPLIANT';
  }

  public getInoculationPassedDate() {
    return this.inoculationPassedDate;
  }

  public isCardUploaded() {
    const cardUploadedStatuses = [CardStatus.UPLOADED, CardStatus.APPROVED];
    return (
      this.vaccinationCardStatus !== null &&
      cardUploadedStatuses.includes(this.vaccinationCardStatus)
    );
  }

  public isCardUploadMandatory() {
    if (this.isGettingVaccinationBonusFailed || this.isGettingVaccinationCardStatusFailed) return false;
    if (this.isInitialLoad) return false;
    if (this.previousVaccinationRecords.isEmpty()) return false;
    if (!this.isFinalDoseReported()) return false;
    if (!this.countryConfig || !this.countryConfig.cardUploadStartDate.isValid) return false;
    if (this.getMostRecentReportDate() < this.countryConfig.cardUploadStartDate) return false; // submitted before "V2" launch ==> "grandfathered"
    return true;
  }

  public mustUploadBoosterCard() {
    const latestBooster = this.previousVaccinationRecords.filter(record => record.isBooster).map(record => record.createdAt).max();
    return latestBooster !== undefined && ([CardStatus.NOT_UPLOADED, CardStatus.INITIAL, CardStatus.REJECTED].includes(this.boosterCardStatus) ||
      latestBooster.toMillis() > this.boosterCardCreatedAt);
  }

  public isLoadingFromBackendFailed() {
    return (
      this.isGettingVaccinationBonusFailed ||
      this.isGettingVaccinationUpdatesFailed ||
      this.isGettingCountryConfigFailed ||
      this.isGettingVaccinationCardStatusFailed ||
      this.isGettingTestAttestationsFailure ||
      this.isGettingVaccinationComplianceFailure ||
      this.isGettingCovidTestLiteFailure
    );
  }

  public isLoadingFromBackend() {
    return (
      this.isGettingVaccinationBonus ||
      this.isGettingVaccinationUpdates ||
      this.isGettingCountryConfig ||
      this.isGettingVaccinationCardStatus ||
      this.isGettingTestAttestations ||
      this.isGettingVaccinationCompliance ||
      this.isUpdatingTestAttestation ||
      this.isGettingCovidTestLite
    );
  }

  public canSubmitRequest() {
    if (this.isLoadingFromBackendFailed()) return false;
    if (this.previousVaccinationRecords.size > 0) {
      const firstRecord: VaccinationBonusRecord = this.previousVaccinationRecords.first();
      if (!firstRecord.vaccineType.isValid() || firstRecord.vaccineName === VaccineTypeRecord.UNKNOWN) {
        // this.props.isFinalDoseReported returns true if vaccine type is UNKNOWN, so this case needs to be treated explicitly
        return this.previousVaccinationRecords.size < firstRecord.vaccineType.doses;
      }
    }

    return !this.isFinalDoseReported();
  }

  canViewSurvey() {
    return this.previousVaccinationRecords.isEmpty();
  }

  private getMostRecentVaccination() {
    return this.previousVaccinationRecords.filter(record => !record.isBooster)
      .max(
        (recordA, recordB) => recordA.vaccinationDate.toMillis() - recordB.vaccinationDate.toMillis()
      );
  }

  private getMostRecentReportDate(): DateTime {
    const mostRecent = this.previousVaccinationRecords.map(record => record.createdAt).max();
    return mostRecent || DateTime.invalid('No previous records');
  }
}
