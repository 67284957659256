import { DateTime } from 'luxon';
import { CovidTestLiteResponseRecord } from 'src/apps/vaccination/models/apiRequest/getCovidTestLiteResponse';

export enum CovidTestLiteResult {
    UNKNOWN = '',
    NEGATIVE = 'NEGATIVE',
    POSITIVE = 'POSITIVE',
}

export interface CovidTestLiteRecord {
    createAt: number | undefined;
    createdAtDate: DateTime | undefined;

    result: CovidTestLiteResult;
    collectionDate: DateTime | undefined;

    imageFile: File | undefined;
    imageSrc: string;

    imagePath?: string;   // backend image path
}

export const defaultCovidTestLiteRecord: CovidTestLiteRecord = {
    createAt: undefined,
    createdAtDate: undefined,
    result: CovidTestLiteResult.UNKNOWN,
    collectionDate: undefined,

    imageFile: undefined,
    imageSrc: '',

    imagePath: undefined,
};

export function isRecordFilledWithoutImage(record: CovidTestLiteRecord): boolean {
    return !!record.result
        && !!record.collectionDate;
}

export function isRecordFilled(record: CovidTestLiteRecord): boolean {
    return isRecordFilledWithoutImage(record) && !!record.imageFile;
}

export function covidTestLiteRecordFrom(response: CovidTestLiteResponseRecord): CovidTestLiteRecord {
    if (response) {
        return {
            createAt: response.createAt,
            createdAtDate: DateTime.fromISO(response.createdAtDate),
            result: response.result,
            collectionDate: DateTime.fromISO(response.collectionDate),
        } as CovidTestLiteRecord;
    }

    return defaultCovidTestLiteRecord;
}
