import React, { FC } from 'react';
import { BadgeScreen } from './BadgeScreen';
import { Watermark } from '../../models/vaccination/watermark';
import i18n from 'i18next';
import Checkmark from './checkmark.svg';
import { H1, Text } from '@amzn/stencil-react-components/text';
import { useHistory } from 'react-router-dom';
import { useEmployeeContext } from 'src/contexts/EmployeeInfoContext';
import { connect } from 'react-redux';
import { AppState } from 'src/models/appState';
import { PrimaryButton, SecondaryButton } from '../__shared__/button/Button';
import { Col } from '@amzn/stencil-react-components/layout';
import { ROUTES } from 'src/routes';
import { DateTime } from 'luxon';

type Props = {
  testExpirationDate: string;
};

const _BlueBadgeScreen: FC<Props> = ({ testExpirationDate }) => {
  const history = useHistory();
  const employee = useEmployeeContext();

  const reportHandler = () => history.push(ROUTES.VACCINATION.TEST_ATTESTATION.path);

  const handleGoBackMainPage = () => history.push(ROUTES.VACCINATION.path);

  return (
    <BadgeScreen
      watermark={Watermark.BLUE}
      topSection={
        <>
          <Col padding="20px 0 30px 0">
            <H1 fontSize="T600" fontWeight="bold" color="neutral0" textAlign="center">
              {i18n.t('testAttestation.blueBadgeScreen.title')}
            </H1>
          </Col>
          <div className="top-section">
            <img src={Checkmark} alt="checkmark" width={48} height={48} className="checkmark" />
            <Text fontSize="T400" fontWeight="bold">
              {DateTime.local().toLocaleString(DateTime.DATE_MED)}
            </Text>
          </div>
        </>
      }
      bottomSection={
        <>
          <Col padding="0 0 20px 0">
            <Text textAlign="center" fontSize="T600" fontWeight="bold">{`${employee.firstName} ${employee.lastName}`}</Text>
          </Col>
          <Col padding="0 0 20px 0">
            <Text fontSize="T500" fontWeight="bold">
              <span className="glyphicon glyphicon-time" />
              {i18n.t('testAttestation.reportingSection.testExpiration.text', { date: DateTime.fromISO(testExpirationDate).toLocaleString(DateTime.DATE_MED) })}
            </Text>
          </Col>
          <Col padding="0 0 20px 0">
            <PrimaryButton isFullWidth={true} onClick={reportHandler}>
              <Text fontSize="T400">{i18n.t('testAttestation.reportingSection.actionButtons.reportMyResult')}</Text>
            </PrimaryButton>
          </Col>
          <Col padding="0 0 20px 0">
            <SecondaryButton onClick={handleGoBackMainPage}>
              {i18n.t('testAttestation.positiveResultForm.backButton')}
            </SecondaryButton>
          </Col>
        </>
      }
    />
  );
};

const mapState = (state: AppState) => ({
  testExpirationDate: state.vaccination.testExpirationDate,
});

export const BlueBadgeScreen = connect(mapState)(_BlueBadgeScreen);
