import { Record } from 'immutable';
import * as uuid from 'uuid';

const defaultValue = {
  id: '',
  message: ''
};

type Type = {
  message: string;
  id: string;
};

export class VaccinationFormWarning extends Record<Type>(defaultValue) {
  constructor({ message }: Partial<Type>) {
    const finalValues = {
      id: uuid.v4(),
      message
    };
    super(finalValues);
  }
}
