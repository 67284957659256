/**
 * Client side routes used for rendering react components ("pages")
 * Used with ReactRouter
 */
export const ROUTES = {
  VACCINATION: {
    path: '/campaign/vaccination',
    REQUEST: {path: '/campaign/vaccination/request'},
    CONFIRM: {path: '/campaign/vaccination/confirm'},
    FAQ: {path: '/campaign/vaccination/faq'},
    PROOF: {path: '/campaign/vaccination/proof'},
    SUBMIT_CARD: {path: '/campaign/vaccination/submit-card'},
    BOOSTER_CARD: { path: '/campaign/vaccination/submit-booster' },
    FIRSTDOSE_CARD: { path: '/campaign/vaccination/submit-firsdose' },
    SUBMISSIONS: {path: '/campaign/vaccination/submissions'},
    COVID_TEST_LITE: {
      ADD_DETAILS: { path: '/campaign/vaccination/covidTestLite/add-details' },
      ADD_IMAGE: { path: '/campaign/vaccination/covidTestLite/add-image' },
      REVIEW: { path: '/campaign/vaccination/covidTestLite/review' },
      RETURN_TO_WORK: { path: '/campaign/vaccination/covidTestLite/return-work' },
      POSITIVE_RESULT: { path: '/campaign/vaccination/covidTestLite/positive-result' },
    },
    TEST_ATTESTATION: {
      path: '/campaign/vaccination/test-attestation',
      ADD_IMAGE: { path: '/campaign/vaccination/test-attestation/add-image' },
      REVIEW: { path: '/campaign/vaccination/test-attestation/review' },
      RETURN_TO_WORK: { path: '/campaign/vaccination/return-work' },
      POSITIVE_RESULT: { path: '/campaign/vaccination/test-attestation/positive-result' },
    }
  },
  UNAUTHORIZED: {
    path: '/campaign/unauthorized'
  },
};
