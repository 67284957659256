import { List } from 'immutable';
import { FeatureStateRecord } from '../../models/feature/featureStateRecord';
import { FeatureRecord } from '../../models/feature/featureRecord';
import { FeatureActions, FeatureActionTypes } from '../../actions/featureActions';
import { useSelector } from 'react-redux'
import { AppState } from '../../models/appState';
import { getLogger } from 'src/logger';

export const feature = (
    state: FeatureStateRecord = new FeatureStateRecord(),
    action: FeatureActions
): FeatureStateRecord => {
    switch (action.type) {
        case (FeatureActionTypes.GET_FEATURES_REQUEST):
            return state
                .set('isLoading', true);
        case (FeatureActionTypes.GET_FEATURES_SUCCESS):
            return state
                .set('isLoading', false)
                .set('features', action.payload);
        case (FeatureActionTypes.GET_FEATURES_FAILURE):
            // make network issues to be only warnings because we don't want to trigger our alarms for that
            if (action.payload?.message === "Network Error" ||
                action.payload?.code === "ECONNABORTED"  ||
                action.payload?.code === "ETIMEDOUT") {
                getLogger().warn("GET_FEATURES_FAILURE", action.payload, action);
            } else {
                getLogger().error("GET_FEATURES_FAILURE", action.payload, action);
            }
            return state
                .set('isLoading', false)
                .set('features', List<FeatureRecord>());
        default: return state;
    }
};

export function useFeatureState() {
    return useSelector((state: AppState) => state.feature);
}
