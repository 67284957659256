import 'rxjs/add/operator/mergeMap';
import { ActionsObservable } from 'redux-observable';
import { Store } from 'redux';
import { AppAction } from '../actions/appAction';
import {
    FeatureActionTypes,
    GetFeaturesRequestAction,
    createGetFeaturesSuccessAction,
    createGetFeaturesFailureAction
} from '../actions/featureActions';
import { AppState } from '../models/appState';
import { EpicDIConfig } from './config';


export function getFeatureEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { featureClientSDK }: EpicDIConfig) {
    return action$.ofType(FeatureActionTypes.GET_FEATURES_REQUEST)
        .mergeMap((action: GetFeaturesRequestAction) =>
            featureClientSDK.getFeatures()
                .then(function (response) {
                    return createGetFeaturesSuccessAction(response);
                })
                .catch(error => createGetFeaturesFailureAction(error))
        );
}