import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/mergeMap';
import { Observable } from 'rxjs/Observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import 'rxjs/Rx';
import { GetCovidTestLiteResponse } from 'src/apps/vaccination/models/apiRequest/getCovidTestLiteResponse';
import { GetTestAttestationResponse } from '../models/apiRequest/getTestAttestationResponse';
import { GetVaccinationBonusResponse } from '../models/vaccination/getVaccinationBonusResponse';
import { AppAction } from 'src/actions/appAction';
import {
    createGetBoosterCardStatusFailureAction,
    createGetBoosterCardStatusSuccessAction,
    createGetSurveyStatusSuccessAction,
    createGetVaccinationBonusFailureAction,
    createGetVaccinationBonusSuccessAction,
    createGetVaccinationCardStatusFailureAction,
    createGetVaccinationCardStatusSuccessAction,
    createGetVaccinationCountryConfigFailureAction,
    createGetVaccinationCountryConfigSuccessAction,
    createGetVaccinationUpdatesFailureAction,
    createGetVaccinationUpdatesSuccessAction,
    createSubmitVaccinationBonusFailureAction,
    createSubmitVaccinationBonusSuccessAction,
    createSubmitVaccinationSurveyFailureAction,
    createSubmitVaccinationSurveySuccessAction,
    GetBoosterCardStatusAction,
    GetBoosterCardStatusSuccessAction,
    GetVaccinationBonusAction,
    GetVaccinationBonusSuccessAction,
    GetVaccinationCardStatusAction,
    GetVaccinationCardStatusSuccessAction,
    GetVaccinationCountryConfigAction,
    GetVaccinationCountryConfigSuccessAction,
    GetSurveyStatusAction,
    GetVaccinationUpdatesAction,
    GetVaccinationUpdatesSuccessAction,
    SubmitVaccinationBonusAction,
    SubmitVaccinationBonusSuccessAction,
    SubmitVaccinationSurveyAction,
    SubmitVaccinationSurveySuccessAction,
    VaccinationBonusActionTypes,
    GetSurveyStatusSuccessAction,
    createGetSurveyStatusFailureAction,
    GetFirstDoseCardStatusAction,
    createGetFirstDoseCardStatusSuccessAction,
    createGetFirstCardStatusFailureAction,
    GetFirstDoseCardStatusSuccessAction,
    GetVaccinationComplianceAction,
    createGetVaccinationComplianceFailureAction,
    createGetVaccinationComplianceSuccessAction,
    GetVaccinationComplianceSuccessAction,
    GetTestAttestationsAction,
    GetTestAttestationsSuccessAction,
    createGetTestAttestationsSuccessAction,
    createGetTestAttestationsFailureAction,
    SubmitTestAttestationAction,
    SubmitTestAttestationSuccessAction,
    createSubmitTestAttestationSuccessAction,
    createSubmitTestAttestationFailureAction,
    UpdateTestAttestationAction,
    UpdateTestAttestationSuccessAction,
    createUpdateTestAttestationSuccessAction,
    createUpdateTestAttestationFailureAction,
    createGetVaccinationComplianceAction,
    GetCardStatusAction,
    GetCardStatusSuccessAction,
    createGetCardStatusSuccessAction,
    createGetCardStatusFailureAction,
    createGetCovidTestLiteFailureAction,
    GetCovidTestLiteSuccessAction,
    GetCovidTestLiteAction,
    SubmitCovidTestLiteSuccessAction,
    createSubmitCovidTestLiteFailureAction,
    SubmitCovidTestLiteAction,
    createGetCovidTestLiteSuccessAction,
    UpdateCovidTestLiteAction,
    UpdateCovidTestLiteSuccessAction,
    createUpdateCovidTestLiteSuccessAction,
    createUpdateCovidTestLiteFailureAction,
    createSubmitCovidTestLiteSuccessAction,
} from '../actions/vaccinationBonusActions';
import { CardType } from '../models/apiRequest/cardType';
import { AppState } from '../../../models/appState';
import { GetVaccinationCardStatusResponse } from '../models/vaccination/getVaccinationCardStatusResponse';
import { GetVaccinationCountryConfigResponse } from '../models/vaccination/getVaccinationCountryConfigResponse';
import { GetVaccinationSurveyStatusResponse } from '../models/vaccination/getVaccinationSurveyStatusResponse';
import { GetVaccinationUpdatesResponse } from '../models/vaccination/getVaccinationUpdatesResponse';
import { SubmitVaccinationSurveyResponses } from '../models/vaccination/submitVaccinationSurveyResponses';
import { GetVaccinationComplianceResponse } from '../models/vaccination/getVaccinationComplianceResponse';
import { EpicDIConfig } from 'src/epics/config';
/**
 * Catch GetVaccinationBonusRequest action and call AWS backend.
 * @param action$ - Actions observable.
 * @param store - Redux store.
 * @param param2 - The atoz api gateway campaings client SDK.
 */
export function getVaccinationBonusEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
     { employeeInfo, campaignsClientSDK }: EpicDIConfig) {
     return action$.ofType(VaccinationBonusActionTypes.GET_VACCINATION_BONUS_REQUEST)
         .mergeMap((action: GetVaccinationBonusAction) => campaignsClientSDK
            .getVaccinationBonus(employeeInfo.employeeId)
            .pipe(
                map((response: GetVaccinationBonusResponse): GetVaccinationBonusSuccessAction => createGetVaccinationBonusSuccessAction(response)),
                catchError(error => Observable.of(createGetVaccinationBonusFailureAction()))
            )
        );
}

/**
 * Catch SubmitVaccinationBonusRequest action and call AWS backend.
 * @param action$ - Actions observable.
 * @param store - Redux store.
 * @param param2 - The atoz api gateway campaings client SDK.
 */
export function submitVaccinationBonusEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { campaignsClientSDK }: EpicDIConfig) {
    return action$.ofType(VaccinationBonusActionTypes.SUBMIT_VACCINATION_BONUS_REQUEST)
        .mergeMap((action: SubmitVaccinationBonusAction) => campaignsClientSDK
            .submitVaccinationBonus(action.payload)
            .pipe(
                mergeMap(response =>
                    [
                        createSubmitVaccinationBonusSuccessAction(response),
                        createGetVaccinationBonusSuccessAction(response)
                    ]),
                catchError(error => Observable.of(createSubmitVaccinationBonusFailureAction()))
            )
        );
}

/**
 * Catch GetVaccinationUpdatesRequest action and call AWS backend.
 * @param action$ - Actions observable.
 * @param store - Redux store.
 * @param param2 - The atoz api gateway campaings client SDK.
 */
export function getVaccinationUpdatesEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { employeeInfo, campaignsClientSDK }: EpicDIConfig) {
    return action$.ofType(VaccinationBonusActionTypes.GET_VACCINATION_UPDATES_REQUEST)
        .mergeMap((action: GetVaccinationUpdatesAction) => campaignsClientSDK
            .getVaccinationUpdates(employeeInfo.employeeId)
            .pipe(
                map((response: GetVaccinationUpdatesResponse): GetVaccinationUpdatesSuccessAction => createGetVaccinationUpdatesSuccessAction(response)),
                catchError(error => Observable.of(createGetVaccinationUpdatesFailureAction()))
            )
        );
}

/**
 * Catch GetVaccinationCountryConfigRequest action and call backend.
 * @param action$ - Actions observable.
 * @param store - Redux store.
 * @param param2 - The atoz api gateway campaings client SDK.
 */
export function getVaccinationCountryConfigEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { employeeInfo, campaignsClientSDK }: EpicDIConfig) {
    return action$.ofType(VaccinationBonusActionTypes.GET_VACCINATION_COUNTRY_CONFIG_REQUEST)
        .mergeMap((action: GetVaccinationCountryConfigAction) => campaignsClientSDK
            .getVaccinationCountryConfig(employeeInfo.countryCode)
            .pipe(
                map((response: GetVaccinationCountryConfigResponse): GetVaccinationCountryConfigSuccessAction =>
                    createGetVaccinationCountryConfigSuccessAction(response)),
                catchError(error => Observable.of(createGetVaccinationCountryConfigFailureAction()))
            )
        );
}

/**
 * Catch GetVaccinationCardStatusRequest action and call backend.
 * @param action$ - Actions observable.
 * @param store - Redux store.
 * @param param2 - The atoz api gateway campaings client SDK.
 */
export function getVaccinationCardStatusEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { employeeInfo, campaignsClientSDK }: EpicDIConfig) {
    return action$.ofType(VaccinationBonusActionTypes.GET_VACCINATION_CARD_STATUS_REQUEST)
        .mergeMap((action: GetVaccinationCardStatusAction) => campaignsClientSDK
            .getVaccinationCardStatus(employeeInfo.employeeId, CardType.Vaccine)
            .pipe(
                map((response: GetVaccinationCardStatusResponse): GetVaccinationCardStatusSuccessAction =>
                    createGetVaccinationCardStatusSuccessAction(response)),
                catchError(error => Observable.of(createGetVaccinationCardStatusFailureAction()))
            )
        );
}

export function getCardStatusEpic(
  action$: ActionsObservable<AppAction>,
  store: Store<AppState>,
  { employeeInfo, campaignsClientSDK }: EpicDIConfig
) {
  let cardType: CardType;

  return action$.ofType(VaccinationBonusActionTypes.GET_CARD_STATUS_REQUEST)
    .mergeMap((action: GetCardStatusAction) => {
      cardType = action.payload;
      return campaignsClientSDK.getVaccinationCardStatus(employeeInfo.employeeId, cardType)
    }).pipe(
      map((response: GetVaccinationCardStatusResponse): GetCardStatusSuccessAction =>
        createGetCardStatusSuccessAction(cardType, response)),
      catchError(error => Observable.of(createGetCardStatusFailureAction(cardType)))
    )
}

/**
 * Catch GetBoosterCardStatusRequest action and call backend.
 * @param action$ - Actions observable.
 * @param store - Redux store.
 * @param param2 - The atoz api gateway campaings client SDK.
 */
 export function getBoosterCardStatusEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { employeeInfo, campaignsClientSDK }: EpicDIConfig) {
    return action$.ofType(VaccinationBonusActionTypes.GET_BOOSTER_CARD_STATUS_REQUEST)
        .mergeMap((action: GetBoosterCardStatusAction) => campaignsClientSDK
            .getVaccinationCardStatus(employeeInfo.employeeId, CardType.Booster)
            .pipe(
                map((response: GetVaccinationCardStatusResponse): GetBoosterCardStatusSuccessAction =>
                    createGetBoosterCardStatusSuccessAction(response)),
                catchError(error => Observable.of(createGetBoosterCardStatusFailureAction()))
            )
        );
}

export function getFirstDoseCardStatusEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { employeeInfo, campaignsClientSDK }: EpicDIConfig) {
    return action$.ofType(VaccinationBonusActionTypes.GET_FIRSTDOSE_CARD_STATUS_REQUEST)
        .mergeMap((action: GetFirstDoseCardStatusAction) => campaignsClientSDK
            .getVaccinationCardStatus(employeeInfo.employeeId, CardType.FirstDose)
            .pipe(
                map((response: GetVaccinationCardStatusResponse): GetFirstDoseCardStatusSuccessAction =>
                    createGetFirstDoseCardStatusSuccessAction(response)),
                catchError(error => Observable.of(createGetFirstCardStatusFailureAction()))
            )
        );
}

export function submitVaccinationSurveyEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { campaignsClientSDK }: EpicDIConfig) {

     return action$.ofType(VaccinationBonusActionTypes.SUBMIT_VACCINATION_SURVEY_REQUEST)
         .mergeMap((action: SubmitVaccinationSurveyAction) =>
             campaignsClientSDK.submitVaccinationSurveyAnswerV2(action.payload))
         .pipe(
             map((response: SubmitVaccinationSurveyResponses): SubmitVaccinationSurveySuccessAction =>
                 createSubmitVaccinationSurveySuccessAction(response)),
             catchError(error => Observable.of(createSubmitVaccinationSurveyFailureAction()))
         )
}

export function getVaccinationSurveyStatusEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { employeeInfo, campaignsClientSDK }: EpicDIConfig) {

    return action$.ofType(VaccinationBonusActionTypes.GET_VACCINATION_SURVEY_STATUS_REQUEST)
        .mergeMap((action: GetSurveyStatusAction) =>
            campaignsClientSDK.getVaccinationSurveyStatusV2(
                employeeInfo.employeeId,
                employeeInfo.countryCode))
        .pipe(
            map((response: GetVaccinationSurveyStatusResponse): GetSurveyStatusSuccessAction =>
                createGetSurveyStatusSuccessAction(response)),
            catchError(error => Observable.of(createGetSurveyStatusFailureAction()))
        )
}

export function getVaccinationComplianceEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { employeeInfo, campaignsClientSDK }: EpicDIConfig) {
    return action$.ofType(VaccinationBonusActionTypes.GET_VACCINATION_COMPLIANCE_REQUEST)
        .mergeMap((action: GetVaccinationComplianceAction) => campaignsClientSDK
            .getVaccinationCompliance(employeeInfo.employeeId, employeeInfo.countryCode)
            .pipe(
                map((response: GetVaccinationComplianceResponse): GetVaccinationComplianceSuccessAction =>
                    createGetVaccinationComplianceSuccessAction(response)),
                catchError(error => Observable.of(createGetVaccinationComplianceFailureAction()))
            )
        );
}

export function getTestAttestationsEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { employeeInfo, campaignsClientSDK }: EpicDIConfig) {

    return action$.ofType(VaccinationBonusActionTypes.GET_TEST_ATTESTATIONS_REQUEST)
        .mergeMap((action: GetTestAttestationsAction) =>
            campaignsClientSDK.getTestAttestations(employeeInfo.employeeId))
        .pipe(
            map((response: GetTestAttestationResponse): GetTestAttestationsSuccessAction =>
                createGetTestAttestationsSuccessAction(response)),
            catchError(error => Observable.of(createGetTestAttestationsFailureAction()))
        )
}

export function submitTestAttestationEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { campaignsClientSDK }: EpicDIConfig) {

    return action$.ofType(VaccinationBonusActionTypes.SUBMIT_TEST_ATTESTATION_REQUEST)
        .mergeMap((action: SubmitTestAttestationAction) =>
            campaignsClientSDK.submitTestAttestation(action.payload))
        .pipe(
            map((response: GetTestAttestationResponse): SubmitTestAttestationSuccessAction =>
                createSubmitTestAttestationSuccessAction(response)),
            catchError(error => Observable.of(createSubmitTestAttestationFailureAction()))
        )
}

export function updateTestAttestationEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { campaignsClientSDK }: EpicDIConfig) {

    return action$.ofType(VaccinationBonusActionTypes.UPDATE_TEST_ATTESTATION_REQUEST)
        .mergeMap((action: UpdateTestAttestationAction) =>
            campaignsClientSDK.updateTestAttestation(action.payload))
        .pipe(
            map((response: GetTestAttestationResponse): UpdateTestAttestationSuccessAction =>
                createUpdateTestAttestationSuccessAction(response)),
            catchError(error => Observable.of(createUpdateTestAttestationFailureAction()))
        )
}

export function refreshComplianceEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { campaignsClientSDK }: EpicDIConfig) {
    return action$.ofType(VaccinationBonusActionTypes.UPDATE_TEST_ATTESTATION_SUCCESS,
        VaccinationBonusActionTypes.SUBMIT_TEST_ATTESTATION_SUCCESS,
        VaccinationBonusActionTypes.SUBMIT_VACCINATION_BONUS_SUCCESS)
        .map(() => createGetVaccinationComplianceAction())
}

export function getCovidTestLiteEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { employeeInfo, campaignsClientSDK }: EpicDIConfig) {

    return action$.ofType(VaccinationBonusActionTypes.GET_COVID_TEST_LITE_REQUEST)
        .mergeMap((action: GetCovidTestLiteAction) =>
            campaignsClientSDK.getCovidTestLite(employeeInfo.employeeId))
        .pipe(
            map((response: GetCovidTestLiteResponse): GetCovidTestLiteSuccessAction =>
                createGetCovidTestLiteSuccessAction(response)),
            catchError(error => Observable.of(createGetCovidTestLiteFailureAction()))
        )
}

export function submitCovidTestLiteEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { campaignsClientSDK }: EpicDIConfig) {

    return action$.ofType(VaccinationBonusActionTypes.SUBMIT_COVID_TEST_LITE_REQUEST)
        .mergeMap((action: SubmitCovidTestLiteAction) =>
            campaignsClientSDK.submitCovidTestLite(action.payload))
        .pipe(
            map((response: GetCovidTestLiteResponse): SubmitCovidTestLiteSuccessAction =>
                createSubmitCovidTestLiteSuccessAction(response)),
            catchError(error => Observable.of(createSubmitCovidTestLiteFailureAction()))
        )
}

export function updateCovidTestLiteEpic(
    action$: ActionsObservable<AppAction>,
    store: Store<AppState>,
    { campaignsClientSDK }: EpicDIConfig) {

    return action$.ofType(VaccinationBonusActionTypes.UPDATE_COVID_TEST_LITE_REQUEST)
        .mergeMap((action: UpdateCovidTestLiteAction) =>
            campaignsClientSDK.updateCovidTestLite(action.payload))
        .pipe(
            map((response: GetCovidTestLiteResponse): UpdateCovidTestLiteSuccessAction =>
                createUpdateCovidTestLiteSuccessAction(response)),
            catchError(error => Observable.of(createUpdateCovidTestLiteFailureAction()))
        )
}
