import i18n from 'i18next';
import Checkmark from './checkmark.svg';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useCurrentEffect } from '../../../../utils/useCurrentEffect';
import { DateTime } from 'luxon';
import { AppState } from '../../../../models/appState';
import { LoggerProps, withLogger } from '../../../../logger';
import { Link } from 'react-router-dom';
import Chevron from './chevron.svg';
import { submitOmnitureEvent } from '../../../../utils/adobeAnalytics';
import { ROUTES } from '../../../../routes';
import UploadIcon from './upload.svg';
import { Watermark } from '../../models/vaccination/watermark';
import { BadgeScreen } from './BadgeScreen';
import { useEmployeeContext } from 'src/contexts/EmployeeInfoContext';
import { isPopstarFeatureEnabled } from 'src/models/EmployeeInfoRecord';
import { VaccinationFeatures } from '../../constant/vaccinationFeatures';
import { sendTelemetryEvent } from 'src/utils/telemetry/TelemetryHelper';
import { VACCINATION_BONUS_TELEMETRY_SCOPE } from 'src/consts';

const DATE_FORMAT = 'LLL d, yyyy';
const ONE_SECOND = 1000;

function useFormattedDate() {
  const today = DateTime.now();
  return {
    date: today.toFormat(DATE_FORMAT),
    oddTime: today.toFormat('hh:mm:ss a'),
    evenTime: today.toFormat('hh mm ss a')
  };
}

type Props = {
  isCardUploaded: boolean;
  isGrandFathered: boolean;
} & LoggerProps;

const DateTimeSection: FC = () => {
  const [isOddTime, setIsOddTime] = useState(false);
  const { date, oddTime, evenTime } = useFormattedDate();

  useCurrentEffect(
    isCurrent => {
      const interval = setInterval(() => isCurrent() && setIsOddTime(!isOddTime), ONE_SECOND);
      return () => clearInterval(interval);
    },
    [isOddTime]
  );

  return (
    <div className="live-time">
      <p className="current-date">{date}</p>
      <p className="current-date">{isOddTime ? oddTime : evenTime}</p>
    </div>
  );
};

const _GreenBadgeScreen: FC<Props> = ({ logger, isCardUploaded, isGrandFathered }) => {
  useEffect(() => {
    logger.info('VaccinationProof:entered');
    submitOmnitureEvent('VaccinationBonus - GreenBadge Rendered');
    sendTelemetryEvent(VACCINATION_BONUS_TELEMETRY_SCOPE, `${VACCINATION_BONUS_TELEMETRY_SCOPE} - GreenBadge Rendered`);
  }, []);
  const employee = useEmployeeContext();

  return (
    <BadgeScreen
      watermark={Watermark.GREEN}
      topSection={
        <>
          <h1 className="badge-screen-title">
            {isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
              && isGrandFathered
              ? i18n.t('vaccinationBonus.workWithoutMask.greenBadge.grandfatheredTitle')
              : i18n.t('vaccinationBonus.workWithoutMask.greenBadge.title')}
          </h1>
          <div className="top-section">
            <img src={Checkmark} alt="checkmark" width={48} height={48} className="checkmark" />
            {isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
              && isGrandFathered ? (
              <DateTimeSection />
            ) : (
              <h2 className="title checkmark-title">{i18n.t('vaccinationBonus.workWithoutMask.greenBadge.checkmarkText')}</h2>
            )}
          </div>
          {isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
            && !isGrandFathered && <DateTimeSection />}
        </>
      }
      bottomSection={
        <>
          <p className="employee-full-name">
            {employee.firstName} {employee.lastName}
          </p>
          {isPopstarFeatureEnabled(employee, VaccinationFeatures.ATOZ_VACCINE_SUBMIT_CARD)
            && !isCardUploaded && (
            <Link className="btn btn-primary card-form-link" to={ROUTES.VACCINATION.SUBMIT_CARD.path}>
              <img src={UploadIcon} className="button-icon" alt="icon" />{' '}
              {i18n.t('vaccinationBonus.workWithoutMask.banner.submitProofPhotoBtn')}
            </Link>
          )}
          <Link to={'/campaign/vaccination'} className="btn btn-primary reporting-history-link">
            {i18n.t('vaccinationBonus.workWithoutMask.greenBadge.backButton')}
            <img src={Chevron} className="reporting-link-chevron" />
          </Link>
        </>
      }
    />
  );
};

const mapState = (state: AppState) => ({
  isCardUploaded: state.vaccination.isCardUploaded(),
  isGrandFathered: !state.vaccination.isCardUploadMandatory() && !state.vaccination.isCardUploaded()
});

export const GreenBadgeScreen = connect(mapState)(withLogger(_GreenBadgeScreen));
