import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { catchError, map } from 'rxjs/operators';
import { AtozGatewayClient, STAGES, Logger } from 'atoz-api-gateway-client';
import { RawTimeZoneResponseTimeoffService } from './../models/timeoff/rawTimeZoneResponseTimeoffService';
import { STAGE_NAME } from '../consts';
import { getLogger } from 'src/logger';

const GET_TIMEZONE_API = (employeeId: string) => {
    return `/timeoff/timezone?employeeId=${employeeId}`;
};

export const getTimeoffService = (employeeId: string, stage: STAGES, logger?: Logger) => {
    return new AtozGatewayClient({
        clientId: 'ATOZ_TIMEOFF_SERVICE',
        stage: stage,
        employeeId: employeeId,
        logger: logger
    });
}

export class TimeoffClientSDK {
    private client: AtozGatewayClient;
    private logger: Logger;

    constructor(timeoffService: AtozGatewayClient, logger: Logger) {
        this.client = timeoffService;
        this.logger = logger;
    }

    getTimeZone(employeeId: string) {
        const timeZonePath: string = GET_TIMEZONE_API(employeeId);
        return this.client.get(timeZonePath, {}, false)
                          .pipe(
                              map((response: RawTimeZoneResponseTimeoffService): string => response.timezone),
                              catchError(error => {
                                  if (error.status === undefined) {
                                    // some unexpected error
                                    this.logger.error(error);
                                  } else if (error.status >= 400 && ![400, 404].includes(error.status)) {
                                    // log HTTP 4xx and 5xx as warnings
                                    // except these as they are expected to happen frequently
                                    // * TAAPI/GetTimeZone returned 404 'Not Found'
                                    // * TAAPI/GetTimeZone returned 400 'Bad Request' - Profiles not found for employee
                                    this.logger.warn(error);
                                } else {
                                    this.logger.info(error);
                                }
                                throw error;
                              })
                          );
    }
}

let timeoffClient: TimeoffClientSDK = null;
export const getTimeoffClient = (employeeId?: string, logger?: Logger) => {
    if (!timeoffClient) {
        if (!employeeId) {
            throw "employeeId is required for first use!";
        }
        if (!logger) {
            logger = getLogger();
        }
        const stageName = STAGES[STAGE_NAME.toLowerCase()] || STAGES.development;
        const timeoffService = getTimeoffService(employeeId, stageName, logger);
        timeoffClient = new TimeoffClientSDK(timeoffService, logger);
    };
    return timeoffClient;
}
