import React, { FC } from 'react';
import { Col } from '@amzn/stencil-react-components/layout';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import i18n from 'i18next';

type Props = {
  show?: boolean;
  messageId?: string;
  rejectReasons?: string[];
  onDismissed: () => void;
};

export const ErrorBannerWithRejectReasons: FC<Props> = ({ show, messageId, rejectReasons, onDismissed }) => {
  return (<>
      {(show || messageId) && (
        <Col padding="0 0 20px 0">
          <MessageBanner isDismissible={true} type={MessageBannerType.Error} onDismissed={onDismissed}>
            {i18n.t(`testAttestation.errorMessage.${messageId}`)}
            {rejectReasons && (
              <ul>
                {rejectReasons.map((value, key) => (
                  <li key={key}>
                    {i18n.t(`testAttestation.rejectionReasons.${value.toUpperCase()}`)}
                  </li>
                ))}
              </ul>
            )}
          </MessageBanner>
        </Col>)
    }
  </>);
};
