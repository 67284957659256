import React, { Dispatch, FC, ReactElement, useState } from 'react';
import { Col, View } from '@amzn/stencil-react-components/layout';
import { H1, Text } from '@amzn/stencil-react-components/text';
import i18n from 'i18next';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { PrimaryButton, SecondaryButton } from '../../__shared__/button/Button';
import { ROUTES } from 'src/routes';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from 'src/models/appState';
import { PCRTestResult, TestAttestationRecord } from '../../../models/vaccination/testAttestationRecord';
import { Action } from 'redux';
import { DateTime } from 'luxon';
import { useEmployeeContext } from 'src/contexts/EmployeeInfoContext';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

type Props = {
  isTestCompliant: boolean;
  testExpirationDate: string;
  latestTest: TestAttestationRecord;
}

export const _TestReportingSection: FC<Props> = ({ isTestCompliant, testExpirationDate, latestTest }) => {
  return (
    <div className="vaccination-paper">
      <View>
        <Col padding="0 0 16px 0">
          <H1 fontSize="T700" fontWeight="bold">
            {i18n.t('testAttestation.reportingSection.title')}
          </H1>
        </Col>
        <Col padding="0 0 16px 0">
          <BannerFactory isTestCompliant={isTestCompliant}
            testExpirationDate={testExpirationDate}
            latestTest={latestTest} />
        </Col>
        <ReportButtonsFactory isTestCompliant={isTestCompliant}
          latestTest={latestTest} />
        {/*<Text*/}
        {/*  fontSize="T400"*/}
        {/*  textAlign="center"*/}
        {/*  dangerouslySetInnerHTML={{*/}
        {/*    __html: i18n.t('testAttestation.reportingSection.lastUpdated', {*/}
        {/*      time: DateTime.now().toFormat('HH:mm'),*/}
        {/*      interval: 5*/}
        {/*    })*/}
        {/*  }}*/}
        {/*/>*/}
      </View>
    </div>
  );
};

enum BannerTypes {
  Info = 'Info',
  Warning = 'Warning',
  OutOfPolicy = 'OutOfPolicy'
}

type BannerProps = {
  isTestCompliant: boolean;
  testExpirationDate: string;
  latestTest: TestAttestationRecord;
};

function BannerFactory({ testExpirationDate, isTestCompliant, latestTest }: BannerProps): ReactElement | null {
  if (!isTestCompliant && latestTest.result === PCRTestResult.POSITIVE && !latestTest.returnToWorkDate) {
    return (
      <MessageBanner
        titleText={i18n.t('testAttestation.reportingSection.positiveTest.text')}
        type={MessageBannerType.Informational}
      >
        {i18n.t('testAttestation.reportingSection.positiveTest.explanation')}
      </MessageBanner>
    );
  } else if (!isTestCompliant && latestTest.returnToWorkDate) {
    return (
      <MessageBanner
        titleText={i18n.t('testAttestation.reportingSection.returnToWork.text',
          { date: latestTest.returnToWorkDate.toLocaleString(DateTime.DATE_MED) })}
        type={MessageBannerType.Informational}
      >
        {i18n.t('testAttestation.reportingSection.returnToWork.explanation',
          { date: latestTest.returnToWorkDate.toLocaleString(DateTime.DATE_MED) })}
      </MessageBanner>
    );
  }
  return (
    <MessageBanner
      titleText={i18n.t('testAttestation.reportingSection.testExpiration.text',
        { date: testExpirationDate ? DateTime.fromISO(testExpirationDate).toLocaleString(DateTime.DATE_MED) : '' })}
      type={MessageBannerType.Informational}
    >
      {i18n.t('testAttestation.reportingSection.testExpiration.explanation')}
    </MessageBanner>
  );
}

type ReportButtonProps = {
  isTestCompliant: boolean;
  latestTest: TestAttestationRecord;
};

function ReportButtonsFactory({ isTestCompliant, latestTest }: ReportButtonProps): ReactElement {
  const history = useHistory();
  const employeeId = useEmployeeContext().employeeId;

  const reportHandler = () => history.push(ROUTES.VACCINATION.TEST_ATTESTATION.path);

  const vaccinationProof = () => history.push(ROUTES.VACCINATION.PROOF.path);

  const returnToWork = () => history.push(ROUTES.VACCINATION.TEST_ATTESTATION.RETURN_TO_WORK.path);

  return (
    <>
      {isTestCompliant && (
        <Col padding="0 0 16px 0">
          <PrimaryButton
            isFullWidth={true}
            onClick={vaccinationProof}
            data-omniture-link="Vaccination Bonus - Proof Link Test attestation"
            data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Proof Link Test attestation')}
          >
            <Text fontSize="T400">{i18n.t('testAttestation.reportingSection.actionButtons.testCertificate')}</Text>
          </PrimaryButton>
        </Col>
      )}
      {(!isTestCompliant && latestTest.result === PCRTestResult.POSITIVE && !latestTest.returnToWorkDate) && (
        <>
          <Col padding="0 0 16px 0">
            <PrimaryButton
              isFullWidth={true}
              onClick={returnToWork}
              data-omniture-link="Vaccination Bonus - Return to work"
              data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Return to work')}  
            >
              <Text fontSize="T400">{i18n.t('testAttestation.reportingSection.actionButtons.returnToWrk')}</Text>
            </PrimaryButton>
          </Col>
        </>
      )}
      <Col padding="0 0 16px 0">
        <PrimaryButton isFullWidth={true} onClick={reportHandler}>
          <Text fontSize="T400">{i18n.t('testAttestation.reportingSection.actionButtons.reportMyResult')}</Text>
        </PrimaryButton>
      </Col>
    </>
  );
}

const mapState = (state: AppState) => ({
  isTestCompliant: state.vaccination.isTestCompliant(),
  testExpirationDate: state.vaccination.testExpirationDate,
  latestTest: state.vaccination.latestTest,
});

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
});

export const TestReportingSection = connect(mapState, mapDispatchToProps)(_TestReportingSection);
