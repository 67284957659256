export enum CardType {
  Vaccine = 'Vaccine',
  PCRTest = 'PCRTest',
  Booster = 'Booster',
  FirstDose = 'FirstDose',
  CovidTestLite = 'CovidTestLite',
}

export function safeCardType(cardType: CardType | undefined | null): CardType {
  return cardType ?? CardType.Vaccine;
}
