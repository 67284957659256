import { DatePicker } from '@amzn/stencil-react-components/date-picker';
import { Radio } from '@amzn/stencil-react-components/dist/submodules/form';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { H3, Label, Text } from '@amzn/stencil-react-components/text';
import i18n from 'i18next';
import { DateTime, Duration } from 'luxon';
import React, { Component, Dispatch } from 'react';
import { isDesktop } from 'react-device-detect';
import { connect } from 'react-redux';
import { BrowserRouterProps, RouteComponentProps, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import {
  createGetVaccinationCountryConfigAction,
  updateTestAttestationRecordAction
} from '../../../actions/vaccinationBonusActions';
import { withClient } from '../../../../../contexts/clientContext';
import { ILoggerProps, withLogger } from '../../../../../logger';
import { ApiClientsProps } from 'src/models/ApiClients';
import { AppState } from '../../../../../models/appState';
import { CountryConfig } from '../../../models/vaccination/countryConfig';
import {
  defaultTestAttestationRecord,
  isRecordFilledWithoutImage,
  isTestTypeRequired,
  PCRTestProvider,
  PCRTestResult,
  PCRTestType,
  TestAttestationRecord
} from '../../../models/vaccination/testAttestationRecord';
import { ROUTES } from 'src/routes';
import { PrimaryButton, TertiaryButton } from '../../__shared__/button/Button';
import { ConfirmationModal } from '../__shared__/ConfirmationModal';
import { DetailedRadioGroup } from '../__shared__/DetailedRadioGroup';
import { ErrorStatusIndicator } from '../__shared__/ErrorStatusIndicator';
import { ErrorBannerWithRejectReasons } from './ErrorBannerWithRejectReasons';
import './styles.scss';
import { getTranslationInfos } from 'src/utils/translations';

const DEFAULT_TEST_COLLECTION_EARLIEST_DAYS_AGO = 10;
const DEAFULT_TEST_RESULT_EARLIEST_DAYS_AGO = 7;

export interface TestAttestationInfoFormProps extends RouteComponentProps, ILoggerProps, ApiClientsProps {
  countryConfig: CountryConfig;
  getVaccinationCountryConfig: () => void;
  record: TestAttestationRecord;
  updateTestAttestationRecord: (record: TestAttestationRecord) => void;
}

interface TestAttestationInfoFormState extends TestAttestationRecord {
  highlightEmptyFields: boolean;
  errorBannerMessageId: string | undefined;
  rejectReasons: string[];
  confirmCancelModalVisible: boolean;
}

export class _TestAttestationInfoForm extends Component<TestAttestationInfoFormProps, TestAttestationInfoFormState> {
  state: TestAttestationInfoFormState = {
    ...this.props.record,

    highlightEmptyFields: false,
    errorBannerMessageId: undefined,
    rejectReasons: [],
    confirmCancelModalVisible: false,
  };

  dismissErrorMessage = () => this.setState({ errorBannerMessageId: undefined });

  setTestResult = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ result: PCRTestResult[e?.target?.value] });
  setTestCollectionDate = (value: string) => {
    const collectionDate = DateTime.fromISO(value);
    this.setState({ collectionDate: collectionDate });
    if (this.state.resultDate && this.state.resultDate < collectionDate) {
      this.setState({ resultDate: undefined });
    }
  };
  setTestResultDate = (value: string) => this.setState({ resultDate: DateTime.fromISO(value) });
  setTestProvider = (value: string) => {
    const provider = PCRTestProvider[value];
    this.setState({ provider: provider });
    // cleanup not needed fields to eliminate wrong field values for browser-forth-and-back cases
    if (provider === PCRTestProvider.AMAZON_DX) {
      this.setState({
        testType: undefined,
        imageFile: undefined,
        imageSrc: '',
      });
    }
  };
  setTestType = (value: string) => this.setState({ testType: PCRTestType[value] });

  handleContinue = () => {
    if (!isRecordFilledWithoutImage(this.state)) {
      this.setState({
        highlightEmptyFields: true,
        errorBannerMessageId: 'fieldsAreUnfilled',
      });
      window.scrollTo(0, 0);
      return;
    }

    this.props.updateTestAttestationRecord(this.state);

    if (this.state.provider === PCRTestProvider.AMAZON_DX) {
      this.props.history.push(ROUTES.VACCINATION.TEST_ATTESTATION.REVIEW.path);
    } else {
      this.props.history.push(ROUTES.VACCINATION.TEST_ATTESTATION.ADD_IMAGE.path);
    }
  };

  handleCancelConfirmed = () => {
    this.props.updateTestAttestationRecord(defaultTestAttestationRecord);
    this.props.history.push(ROUTES.VACCINATION.path);
  };

  render() {
    const isTestResultSelectError = this.state.highlightEmptyFields && this.state.result === PCRTestResult.UNKNOWN;
    const isTestCollectionDatePickerError = this.state.highlightEmptyFields && !this.state.collectionDate;
    const isTestResultDatePickerError = this.state.highlightEmptyFields && !this.state.resultDate;
    const isTestProviderRadioError = this.state.highlightEmptyFields && !this.state.provider;
    const isTestTypeRadioError = this.state.highlightEmptyFields && (isTestTypeRequired(this.state.provider) && !this.state.testType);

    const testCollectionEarliestDaysAgo = this.props.countryConfig?.testCollectionEarliestDaysAgo ?? DEFAULT_TEST_COLLECTION_EARLIEST_DAYS_AGO;
    const testCollectionLatestDate = DateTime.now();
    const testCollectionEarliestDate = testCollectionLatestDate.minus(Duration.fromObject({days: testCollectionEarliestDaysAgo}));

    const testResultEarliestDaysAgo = this.props.countryConfig?.testResultEarliestDaysAgo ?? DEAFULT_TEST_RESULT_EARLIEST_DAYS_AGO;
    const testResultLatestDate = DateTime.now();
    const testResultEarliestDate = testResultLatestDate.minus(Duration.fromObject({days: testResultEarliestDaysAgo}));

    return (
      <div className="test-attestation-form">
        <View maxWidth="480px" minWidth="320px" margin="0 auto" padding="20px 15px" backgroundColor="neutral0">
          <ErrorBannerWithRejectReasons
            messageId={this.state.errorBannerMessageId}
            rejectReasons={this.state.rejectReasons}
            onDismissed={this.dismissErrorMessage}
          />
          <Col>
            <H3 fontWeight="bold">
              {i18n.t('testAttestation.testInfoForm.title')}
            </H3>
          </Col>
          <Spacer height={20}/>

          <Col>
            <Col padding="0 0 20px 0">
              <Label>{i18n.t('testAttestation.testInfoForm.testRadio.title')}</Label>
            </Col>
            <Col gridGap="S300">
              {[PCRTestResult.NEGATIVE, PCRTestResult.POSITIVE].map((testResult, i) => (
                <Row key={`test-result-radio-${i}`} alignItems="center" gridGap="S200">
                  <Radio id={`${testResult}-test-radio`} name="test-result-radio" value={testResult} onChange={this.setTestResult}
                         checked={this.state.result === testResult}
                         error={isTestResultSelectError}
                  />
                  <Label htmlFor={`${testResult}-test-radio`}>{i18n.t(`testAttestation.testInfoForm.testRadio.${testResult}`)}</Label>
                </Row>
              ))}
              {isTestResultSelectError && <ErrorStatusIndicator message={i18n.t('testAttestation.errorTooltip.selectOption')} /> }
            </Col>
          </Col>
          <Spacer height={30}/>

          <Col>
            <Col>
              <Label>{i18n.t('testAttestation.testInfoForm.testCollectionDateLabel')}</Label>
            </Col>
            <DatePicker
              id="test-collection-date-picker"
              value={this.state.collectionDate?.toISODate()}
              onChange={this.setTestCollectionDate}
              isDateDisabled={(date) => {
                const dateTime = DateTime.fromISO(date);
                return dateTime < testCollectionEarliestDate || dateTime > testCollectionLatestDate;
              }}
              error={isTestCollectionDatePickerError}
            />
            {isTestCollectionDatePickerError && <ErrorStatusIndicator message={i18n.t('testAttestation.errorTooltip.selectDate')} /> }
          </Col>

          <Spacer height={20}/>

          <Col>
            <Col>
              <Label>{i18n.t('testAttestation.testInfoForm.testResultDateLabel')}</Label>
            </Col>
            <DatePicker
              id="test-result-date-picker"
              value={this.state.resultDate?.toISODate()}
              onChange={this.setTestResultDate}
              isDateDisabled={date => {
                const dateTime = DateTime.fromISO(date);
                return dateTime < testResultEarliestDate ||  dateTime > testResultLatestDate || (!!this.state.collectionDate && dateTime < this.state.collectionDate);
              }}
              error={isTestResultDatePickerError}
            />
            {isTestResultDatePickerError && <ErrorStatusIndicator message={i18n.t('testAttestation.errorTooltip.selectDate')} /> }
          </Col>

          <Spacer height={20}/>

          <DetailedRadioGroup
            groupName={'test-provider-radio'}
            title={i18n.t('testAttestation.testInfoForm.testProvider.title')}
            value={this.state.provider}
            options={
              [PCRTestProvider.AMAZON_DX, PCRTestProvider.HEALTHCARE_PROVIDER, PCRTestProvider.PHARMACY].map(item => ({
                title: i18n.t(`testAttestation.testInfoForm.testProvider.${item}`),
                details: i18n.t(`testAttestation.testInfoForm.testProvider.${item}_LABEL`),
                value: item,
              }))
            }
            onChange={this.setTestProvider}
            error={() => isTestProviderRadioError}
            errorText={i18n.t('testAttestation.errorTooltip.selectOption')}
          />

          <Spacer height={30}/>

          {isTestTypeRequired(this.state.provider) &&
            <DetailedRadioGroup
              groupName={'test-type-radio'}
              title={i18n.t('testAttestation.testInfoForm.typeOfTest.title')}
              value={this.state.testType}
              options={
                [PCRTestType.PCR, PCRTestType.RAPID_OR_ANTIGEN].map(item => ({
                  title: i18n.t(`testAttestation.testInfoForm.typeOfTest.${item}`),
                  details: i18n.t(`testAttestation.testInfoForm.typeOfTest.${item}_LABEL`),
                  value: item,
                }))
              }
              onChange={this.setTestType}
              error={() => isTestTypeRadioError}
              errorText={i18n.t('testAttestation.errorTooltip.selectOption')}
            />}

          <Spacer height={30}/>

          <Col>
            <PrimaryButton isFullWidth={true} onClick={this.handleContinue}>
              <Text>{i18n.t('testAttestation.testInfoForm.continueButton')}</Text>
            </PrimaryButton>
          </Col>

          {isDesktop && <>
            <Spacer height={20}/>
            <Col>
              <TertiaryButton isFullWidth={true} onClick={() => this.setState({ confirmCancelModalVisible: true })}>
                <Text>{i18n.t('testAttestation.testInfoForm.cancelButton')}</Text>
              </TertiaryButton>

              <ConfirmationModal
                isOpen={this.state.confirmCancelModalVisible}
                title={i18n.t('testAttestation.confirmCancelModal.title')}
                text={i18n.t('testAttestation.confirmCancelModal.text')}
                discardText={i18n.t('testAttestation.confirmCancelModal.goBackButton')}
                confirmText={i18n.t('testAttestation.confirmCancelModal.confirmButton')}
                onConfirmClick={this.handleCancelConfirmed}
                onClose={() => this.setState({ confirmCancelModalVisible: false })}
              />
            </Col>
          </>}
        </View>
      </div>
    );
  }
}

export const mapStateToProps = (state: AppState) => ({
  record: state.vaccination.testAttestationRecord,
  countryConfig: state.vaccination.countryConfig,
} as TestAttestationInfoFormProps);

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateTestAttestationRecord: (record: TestAttestationRecord) => dispatch(updateTestAttestationRecordAction(record)),
  getVaccinationCountryConfig: () => dispatch(createGetVaccinationCountryConfigAction()),
} as TestAttestationInfoFormProps);

export const TestAttestationInfoForm =
  connect(mapStateToProps, mapDispatchToProps)(withClient(withLogger(withRouter(_TestAttestationInfoForm))));
