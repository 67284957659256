import * as React from 'react';
import i18n from 'i18next';

export class Unauthorized extends React.Component<{}, {}> {
    render(): JSX.Element {
        return (
            <div className="container centered-page">
                <div className="server-error">
                    <div className="error-status">{i18n.t('errors.unauthorized')}</div>
                    <div className="error-divider" />
                    <h2 className="error-message">
                        {i18n.t('errors.unauthorized_msg')}&nbsp;&nbsp;
                        <a href="/login">{i18n.t('actions.pleaseLogin')}</a>
                    </h2>
                </div>
            </div>
        );
    }
}