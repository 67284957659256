import * as React from 'react';
import { FC } from 'react';

export interface ErrorProps {
  status: string;
  message: string;
  additionalInfo?: string;
}

export const Error: FC<ErrorProps> = (props) => {

  return (
    <div className="container centered-page">
      <div className="server-error">
        <div className="error-status">{props.status}</div>
        <div className="error-divider"/>
        <h2 className="error-message">{props.message}</h2>
        {props.additionalInfo && <h2 className="error-message">{props.additionalInfo}</h2>}
      </div>
    </div>
  );
}