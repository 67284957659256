import { CardType } from '../../models/apiRequest/cardType';

export const GET_VACCINATION_BONUS = (employeeId: string) => `/vaccination/records?employeeId=${employeeId}`

export const UPDATE_VACCINATION_BONUS = () => `/vaccination/records`

export const SUBMIT_VACCINATION_BONUS = () => `/vaccination/records`

export const GET_VACCINATION_UPDATES = (employeeId: string) => `/vaccination/updates?employeeId=${employeeId}`

export const GET_VACCINATION_COUNTRY_CONFIG = (country: string) => `/vaccination/configs?country=${country}`

export const INITIATE_SUBMIT_VACCINATION_CARD = () => '/vaccination/initiateSubmitVCard'

export const GET_VACCINATION_CARD_STATUS = (employeeId: string, cardType: CardType) => `/vaccination/cardStatus?employeeId=${employeeId}&cardType=${cardType}`;

export const VACCINATION_RESPONSE_SURVEY = (employeeId?: string) => `/vaccination/surveyResponse${employeeId ? ('?employeeId=' + employeeId) : ''}`;

export const GET_VACCINATION_RESPONSE_SURVEY = (employeeId: string, surveyType: string) =>
    `/vaccination/surveyResponse?employeeId=${employeeId}&surveyType=${surveyType}`;

export const GET_VACCINATION_SURVEY_STATUS = (employeeId: string, country: string) => `/vaccination/surveyStatus?employeeId=${employeeId}&country=${country}`;

export const GET_VACCINATION_CARD_S3_URL = (employeeId: string, cardType: CardType) => `/vaccination/getCardUrl?employeeId=${employeeId}&cardType=${cardType}`;

export const GET_VACCINATION_COMPLIANCE = (employeeId: string, country: string) => `/vaccination/compliance?employeeId=${employeeId}&country=${country}`;

export const GET_TEST_ATTESTATIONS = (employeeId: string) => `/attestation/records?employeeId=${employeeId}`;

export const SUBMIT_TEST_ATTESTATION = () => `/attestation/records`;

export const UPDATE_TEST_ATTESTATION = () => `/attestation/records`;

export const GET_COVID_TEST_LITE = (employeeId: string) => `/covidTestLite/records?employeeId=${employeeId}`;

export const SUBMIT_COVID_TEST_LITE = () => `/covidTestLite/records`;

export const UPDATE_COVID_TEST_LITE = () => `/covidTestLite/records`;
