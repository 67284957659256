import React, { Component, CSSProperties } from 'react';
import { isIOS } from 'react-device-detect';
import { ILoggerProps, withLogger } from '../../../../../logger';
import { ALLOWED_FILE_TYPES } from '../../../utils/vaccination/isFileTypeAllowed';
import { IosSubmitButton } from './IosSubmitButton';
import { StandardSubmitButton } from './StandardSubmitButton';

interface ChooseImageButtonProps extends ILoggerProps {
  title: string;
  onFileSelected: (file: File) => void;
  logPrefix: string;
  withoutIcon?: boolean;
  style?: CSSProperties;
}

interface ChooseImageButtonState {
  hovered: boolean;
}

export class _SelectImageButton extends Component<ChooseImageButtonProps, ChooseImageButtonState> {
  state: ChooseImageButtonState = {
    hovered: false,
  };

  render() {
    const style = {
      ...this.props.style,
      ...(this.state.hovered && this.props.style ? this.props.style[':hover'] : null)
    };

    return (isIOS ? (
      <IosSubmitButton title={this.props.title}
                       onClick={this.requestPermissions} onChange={this.onFileSelectedChange} style={style}
                       onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}
                       withoutIcon={this.props.withoutIcon} />
    ) : (
      <StandardSubmitButton title={this.props.title}
                            onClick={this.onFileSelectedClick} style={style}
                            onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}
                            withoutIcon={this.props.withoutIcon} />
    ));
  }

  private onMouseEnter = () => { this.setState({ hovered: true }); };
  private onMouseLeave = () => { this.setState({ hovered: false }); };

  private onFileSelectedChange = (event: any) => {
    const file = event.target.files[0];
    this.props.onFileSelected(file);
  };

  private onFileSelectedClick = async (event: any) => {
    await this.requestPermissions(event);

    // create input element and trigger
    const elem = document.createElement('input');
    // https://stackoverflow.com/questions/3691061/uiimagepickercontroller-exception-source-type-must-be-uiimagepickercontroller/48466875#48466875
    elem.accept = ALLOWED_FILE_TYPES.join() + ',capture=camera';
    elem.style.display = 'none';
    elem.type = 'file';
    elem.onchange = (a: any) => {
      this.props.logger.info(`${this.props.logPrefix}:input onChange`, a);
      this.onFileSelectedChange(a);
    };
    elem.click();
  };

  private requestPermissions = async (event: any) => {
    if (window.atozmobile && window.atozmobile.camera && typeof window.atozmobile.camera.getStatus === 'function') {
      this.props.logger.info(`${this.props.logPrefix}:requestPermissions have getStatus function`);
    } else {
      this.props.logger.info(`${this.props.logPrefix}:requestPermissions don't have integration`);
    }
    await this.wrapRequestPermissions();
    this.props.logger.info(`${this.props.logPrefix}:requestPermissions continue`);
  };

  private wrapRequestPermissions = async () => {
    if (window.atozmobile && window.atozmobile.camera && typeof window.atozmobile.camera.requestPermissions === 'function') {
      return new Promise((resolve, reject) => {
        window?.atozmobile?.camera.requestPermissions(
          (a: any) => {
            this.props.logger.info(`${this.props.logPrefix}:requestPermissions success`);
            resolve(a);
          },
          (err: any) => {
            this.props.logger.info(`${this.props.logPrefix}:requestPermissions failed`);
            reject(err);
          }
        );
      });
    }
    return Promise.resolve();
  };
}

export const SelectImageButton = withLogger(_SelectImageButton);
