import { PxtTelemetryWebClient } from '@amzn/pxt-telemetry-web-client';
import { clientFactory } from '@amzn/pxt-telemetry-web-client';
import { NAMESPACE } from '../../consts';

export class Telemetry {
  private static _client: PxtTelemetryWebClient;
  public static get client() {
    if (!this._client) {
      this._client = clientFactory(NAMESPACE);
    }
    return this._client;
  }
}