import { fromJS, List } from 'immutable';
import { GetCovidTestLiteResponse } from 'src/apps/vaccination/models/apiRequest/getCovidTestLiteResponse';
import {
    CovidTestLiteRecord,
    covidTestLiteRecordFrom
} from 'src/apps/vaccination/models/vaccination/covidTestLiteRecord';
import {
    resetVaccinationResponse,
    setEditDoseSuccessful,
    updateTestAttestationRecordAction,
    VaccinationBonusActions,
    VaccinationBonusActionTypes,
    GetCardStatusSuccessActionPayload,
    GetCardStatusFailureAction,
    updateCovidTestLiteRecordAction,
} from '../actions/vaccinationBonusActions';
import { GetTestAttestationResponse } from '../models/apiRequest/getTestAttestationResponse';
import { testAtestationRecordFrom, TestAttestationRecord } from '../models/vaccination/testAttestationRecord';
import { VaccinationBonusAppState } from '../models/vaccination/vaccinationBonusAppState';
import { getVaccineType } from '../models/vaccination/vaccineTypeRecord';
import { GetVaccinationCardStatusResponse } from '../models/vaccination/getVaccinationCardStatusResponse';
import { VaccinationBonusRecord } from '../models/vaccination/vaccinationBonusRecord';
import { DateTime } from 'luxon';
import { GetVaccinationUpdatesResponse } from '../models/vaccination/getVaccinationUpdatesResponse';
import { VaccinationBonusUpdate } from '../models/vaccination/vaccinationBonusUpdate';
import { GetVaccinationCountryConfigResponse } from '../models/vaccination/getVaccinationCountryConfigResponse';
import { GetVaccinationBonusResponse } from '../models/vaccination/getVaccinationBonusResponse';
import { VaccinationSurveyRecord } from '../models/vaccination/vaccinationSurveyRecord';
import { getVaccinationSurveyAnswersV2Op } from '../actions/vaccinationBonusOperations';
import { GetVaccinationSurveyStatusResponse } from '../models/vaccination/getVaccinationSurveyStatusResponse';
import { SubmitVaccinationSurveyResponses } from '../models/vaccination/submitVaccinationSurveyResponses';
import { GetVaccinationComplianceResponse } from '../models/vaccination/getVaccinationComplianceResponse';

export const vaccination = (
    state: VaccinationBonusAppState = new VaccinationBonusAppState(),
    action: VaccinationBonusActions
): VaccinationBonusAppState => {
    switch (action.type) {
        case (VaccinationBonusActionTypes.INTERNAL_VACCINATION_BONUS_REQUEST_UPDATE):
            const request: VaccinationBonusRecord = action.payload;
            if (!request.vaccineType.isValid() && !state.previousVaccinationRecords.isEmpty()) {
                const first: VaccinationBonusRecord = state.previousVaccinationRecords.first()
                const nextRequest = request.setVaccineType(first.vaccineType, first.vaccineName);
                state = state.set('vaccinationBonusRequest', nextRequest)
            } else {
                state = state.set('vaccinationBonusRequest', request);
            }
            // re-evaluate booster status of request, except when editing
            if (!request.isEdit) {
                state = state.setNextBooster();
            }
            return state;

        // Submit Vaccine record
        case (VaccinationBonusActionTypes.SUBMIT_VACCINATION_BONUS_REQUEST):
            return state.set('isSubmittingVaccinationReport', true)
                .set('isSubmittingVaccinationReportFailed', false);
        case (VaccinationBonusActionTypes.SUBMIT_VACCINATION_BONUS_SUCCESS):
            return state.set('isSubmittingVaccinationReport', false)
                .set('isSubmittingVaccinationReportFailed', false)
                .set('submitVaccinationBonusResponse', action.payload as GetVaccinationBonusResponse);
        case (VaccinationBonusActionTypes.SUBMIT_VACCINATION_BONUS_FAILURE):
            return state.set('isSubmittingVaccinationReport', false)
                .set('isSubmittingVaccinationReportFailed', true);

        // Get previous records
        case (VaccinationBonusActionTypes.GET_VACCINATION_BONUS_REQUEST):
            return state.set('isGettingVaccinationBonus', true)
                .set('isGettingVaccinationBonusFailed', false);
        case (VaccinationBonusActionTypes.GET_VACCINATION_BONUS_SUCCESS):
            const { vaccinationRecords } = action.payload as GetVaccinationBonusResponse;
            if (vaccinationRecords) {
                let nextState = state;
                const previousVaccinationBonusRecords: List<VaccinationBonusRecord> =
                    List(vaccinationRecords.map((responseRecord) => {
                        const updatedAt = responseRecord.updatedAt > 0 ? DateTime.fromMillis(responseRecord.updatedAt) : DateTime.invalid('none');
                        return new VaccinationBonusRecord()
                            .set('vaccinationDate', DateTime.fromISO(responseRecord.vaccinationDate))
                            .set('vaccinationDose', responseRecord.vaccinationDose)
                            .set('vaccineName', responseRecord.vaccineType)
                            .set('vaccineType', getVaccineType(responseRecord.vaccineType, state.vaccineTypeRecords))
                            .set('onsite', responseRecord.onsite)
                            .set('duringShift', responseRecord.duringShift)
                            .set('disclaimerConfirmed', true)
                            .set('createdAt', DateTime.fromMillis(responseRecord.createdAt))
                            .set('updatedAt', updatedAt)
                            .set('isBooster', !!responseRecord.booster)
                    }));

                if (previousVaccinationBonusRecords.size > 0) {
                    const first: VaccinationBonusRecord = previousVaccinationBonusRecords.first()
                    const nextRequest = nextState.vaccinationBonusRequest.setVaccineType(first.vaccineType, first.vaccineName);
                    nextState = nextState.set('vaccinationBonusRequest', nextRequest)
                }

                return nextState.set('isGettingVaccinationBonus', false)
                    .set('isGettingVaccinationBonusFailed', false)
                    .set('isInitialLoad', false)
                    .set('previousVaccinationRecords', previousVaccinationBonusRecords)
                    .setNextBooster();
            }
            return state.set('isGettingVaccinationBonus', false)
                .set('isInitialLoad', false)
                .set('isGettingVaccinationBonusFailed', false);
        case (VaccinationBonusActionTypes.GET_VACCINATION_BONUS_FAILURE):
            return state.set('isGettingVaccinationBonus', false)
                .set('isGettingVaccinationBonusFailed', true);

        // Get vaccination card status
        case (VaccinationBonusActionTypes.GET_VACCINATION_CARD_STATUS_REQUEST):
            return state.set('isGettingVaccinationCardStatus', true)
                .set('isGettingVaccinationCardStatusFailed', false);
        case (VaccinationBonusActionTypes.GET_VACCINATION_CARD_STATUS_SUCCESS):
            const getCardStatusResponse = action.payload as GetVaccinationCardStatusResponse;
            if (getCardStatusResponse.updatedAt <= state.vaccinationCardUpdatedAt) {
                return state
                    .set('isGettingVaccinationCardStatus', false)
                    .set('isGettingVaccinationCardStatusFailed', false);
            }
            return state
                .setCardStatus(getCardStatusResponse)
                .set('isGettingVaccinationCardStatus', false)
                .set('isGettingVaccinationCardStatusFailed', false);
        case (VaccinationBonusActionTypes.GET_VACCINATION_CARD_STATUS_FAILURE):
            return state.set('isGettingVaccinationCardStatus', false)
                .set('isGettingVaccinationCardStatusFailed', true);

        // Get booster card status
        case(VaccinationBonusActionTypes.GET_BOOSTER_CARD_STATUS_REQUEST):
            return state.set('isGettingBoosterCardStatus', true)
                .set('isGettingBoosterCardStatusFailed', false);
        case(VaccinationBonusActionTypes.GET_BOOSTER_CARD_STATUS_SUCCESS):
            const getBoosterCardStatusResponse = action.payload as GetVaccinationCardStatusResponse;
            if (getBoosterCardStatusResponse.updatedAt <= state.boosterCardUpdatedAt) {
                return state
                  .set('isGettingBoosterCardStatus', false)
                  .set('isGettingBoosterCardStatusFailed', false);
            }
            return state
                .setBoosterCardStatus(getBoosterCardStatusResponse)
                .set('isGettingBoosterCardStatus', false)
                .set('isGettingVaccinationCardStatusFailed', false);
        case (VaccinationBonusActionTypes.GET_BOOSTER_CARD_STATUS_FAILURE):
            return state.set('isGettingBoosterCardStatus', false)
                .set('isGettingBoosterCardStatusFailed', true);
        case (VaccinationBonusActionTypes.GET_FIRSTDOSE_CARD_STATUS_REQUEST): {
            return state.set('isGettingFirstDoseCardStatus', true)
                .set('isGettingFirstDoseCardStatus', false);
        }
        case(VaccinationBonusActionTypes.GET_FIRSTDOSE_CARD_STATUS_SUCCESS): {
            const getFirstDoseCardStatusResponse = action.payload as GetVaccinationCardStatusResponse;
            if (getFirstDoseCardStatusResponse.updatedAt <= state.firstDoseCardUpdatedAt) {
                return state
                    .set('isGettingFirstDoseCardStatus', false)
                    .set('isGettingFirstDoseCardStatusFailed', false);
            }
            return state
                .setFirstDoseCardStatus(getFirstDoseCardStatusResponse)
                .set('isGettingFirstDoseCardStatus', false)
                .set('isGettingVaccinationCardStatusFailed', false);
        }
        case (VaccinationBonusActionTypes.GET_FIRSTDOSE_CARD_STATUS_FAILURE):
            return state.set('isGettingFirstDoseCardStatus', false)
                .set('isGettingFirstDoseCardStatusFailed', true);
        // Get vaccination updates
        case (VaccinationBonusActionTypes.GET_VACCINATION_UPDATES_REQUEST):
            return state.set('isGettingVaccinationUpdates', true)
                .set('isGettingVaccinationUpdatesFailed', false);
        case (VaccinationBonusActionTypes.GET_VACCINATION_UPDATES_SUCCESS):
            const getUpdatesResponse = action.payload as GetVaccinationUpdatesResponse;
            if (getUpdatesResponse.vaccinationUpdates) {
                const updatesList = List<VaccinationBonusUpdate>(
                    getUpdatesResponse.vaccinationUpdates.map((entry) => {
                        return new VaccinationBonusUpdate()
                            .set('createdAt', DateTime.fromMillis(entry.createdAt))
                            .set('source', entry.source)
                            .set('payload', fromJS(entry.payload))
                    }));
                return state.set('vaccinationUpdates', updatesList)
                    .set('isGettingVaccinationUpdates', false)
                    .set('isGettingVaccinationUpdatesFailed', false);
            }
            return state.set('isGettingVaccinationUpdates', false)
                .set('isGettingVaccinationUpdatesFailed', false);
        case (VaccinationBonusActionTypes.GET_VACCINATION_UPDATES_FAILURE):
            return state.set('isGettingVaccinationUpdates', false)
                .set('isGettingVaccinationUpdatesFailed', true);

        // Get country config
        case (VaccinationBonusActionTypes.GET_VACCINATION_COUNTRY_CONFIG_REQUEST):
            return state.set('isGettingCountryConfig', true)
                .set('isGettingCountryConfigFailed', false);
        case (VaccinationBonusActionTypes.GET_VACCINATION_COUNTRY_CONFIG_SUCCESS):
            const getCountryConfigResponse = action.payload as GetVaccinationCountryConfigResponse;
            return state.setCountryConfig(getCountryConfigResponse)
                .set('isGettingCountryConfig', false)
                .set('isGettingCountryConfigFailed', false);
        case (VaccinationBonusActionTypes.GET_VACCINATION_COUNTRY_CONFIG_FAILURE):
            return state.set('isGettingCountryConfig', false)
                .set('isGettingCountryConfigFailed', true);

        // Reset response
        case (resetVaccinationResponse.type.toString()):
            return state.set('submitVaccinationBonusResponse', null)
                .set('editDoseSuccessful', false);

        // Set edit dose action as successful
        case (setEditDoseSuccessful.type.toString()):
            return state.set('editDoseSuccessful', true);

        // Get vaccination survey answers
        case (getVaccinationSurveyAnswersV2Op.fulfilled.type):
            return state.set('surveyAnswers', List(VaccinationSurveyRecord
                .fromRawSurveyV2(action.payload as SubmitVaccinationSurveyResponses)));

        case (VaccinationBonusActionTypes.GET_VACCINATION_SURVEY_STATUS_SUCCESS):
            const surveyStatusResponse = action.payload as GetVaccinationSurveyStatusResponse;
            return state.set('isSurveyRequired', surveyStatusResponse.surveyRequired);

        case (VaccinationBonusActionTypes.SUBMIT_VACCINATION_SURVEY_REQUEST):
            return state
                .set('surveyResponseSubmitting', true);

        case (VaccinationBonusActionTypes.SUBMIT_VACCINATION_SURVEY_SUCCESS):
            return state
                .set('surveyResponseSubmittedSuccess', true)
                .set('surveyResponseSubmittedFailure', false)
                .set('surveyResponseSubmitting', false)
                .set('surveyAnswers', List(VaccinationSurveyRecord
                    .fromRawSurveyV2(action.payload as SubmitVaccinationSurveyResponses)));

        case (VaccinationBonusActionTypes.SUBMIT_VACCINATION_SURVEY_FAILURE):
            return state
                .set('surveyResponseSubmittedSuccess', false)
                .set('surveyResponseSubmittedFailure', true)
                .set('surveyResponseSubmitting', false);

        // Get Vaccination Compliance
        case (VaccinationBonusActionTypes.GET_VACCINATION_COMPLIANCE_REQUEST):
            return state.set('isGettingVaccinationCompliance', true)
                .set('isGettingVaccinationComplianceFailure', false);
        case (VaccinationBonusActionTypes.GET_VACCINATION_COMPLIANCE_SUCCESS):
            const getVaccinationCompliance = action.payload as GetVaccinationComplianceResponse;
            return state
                .set('testCompliance', getVaccinationCompliance.testCompliance)
                .set('testExpirationDate', getVaccinationCompliance.testExpirationDate)
                .set('latestTest', testAtestationRecordFrom(getVaccinationCompliance.latestTest))
                .set('vaccinationCompliance', getVaccinationCompliance.vaccinationCompliance)
                .set('inoculationPassedDate', getVaccinationCompliance.inoculationPassedDate)
                .set('isGettingVaccinationCompliance', false)
                .set('isGettingVaccinationComplianceFailure', false)
                .set('isGettingVaccinationComplianceSuccess', true);
        case (VaccinationBonusActionTypes.GET_VACCINATION_COMPLIANCE_FAILURE):
            return state.set('isGettingVaccinationCompliance', false)
                .set('isGettingVaccinationComplianceFailure', true)
                .set('isGettingVaccinationComplianceSuccess', false);

        // Test Attestation
        case (updateTestAttestationRecordAction.type.toString()):
            return state.set('testAttestationRecord', action.payload as TestAttestationRecord);

        case (VaccinationBonusActionTypes.GET_TEST_ATTESTATIONS_REQUEST):
            return state
                .set('isGettingTestAttestations', true)
                .set('isGettingTestAttestationsSuccess', false)
                .set('isGettingTestAttestationsFailure', false);

        case (VaccinationBonusActionTypes.GET_TEST_ATTESTATIONS_SUCCESS):
            const testAttestationResponse = action.payload as GetTestAttestationResponse;
            return state
                .set('testAttestations', List(testAttestationResponse.attestations.map((entry) => testAtestationRecordFrom(entry))))
                .set('isGettingTestAttestations', false)
                .set('isGettingTestAttestationsSuccess', true)
                .set('isGettingTestAttestationsFailure', false);

        case (VaccinationBonusActionTypes.GET_TEST_ATTESTATIONS_FAILURE):
            return state
                .set('isGettingTestAttestations', false)
                .set('isGettingTestAttestationsSuccess', false)
                .set('isGettingTestAttestationsFailure', true);

        case (VaccinationBonusActionTypes.SUBMIT_TEST_ATTESTATION_REQUEST):
            return state
                .set('isSubmittingTestAttestation', true)
                .set('isSubmittingTestAttestationSuccess', false)
                .set('isSubmittingTestAttestationFailure', false);

        case (VaccinationBonusActionTypes.SUBMIT_TEST_ATTESTATION_SUCCESS):
            const submittedTestAttestation = (action.payload as GetTestAttestationResponse).attestations[0];
            return state
                .set('latestTest', testAtestationRecordFrom(submittedTestAttestation))
                .set('isSubmittingTestAttestation', false)
                .set('isSubmittingTestAttestationSuccess', true)
                .set('isSubmittingTestAttestationFailure', false);

        case (VaccinationBonusActionTypes.SUBMIT_TEST_ATTESTATION_FAILURE):
            return state
                .set('isSubmittingTestAttestation', false)
                .set('isSubmittingTestAttestationSuccess', false)
                .set('isSubmittingTestAttestationFailure', true);

        case (VaccinationBonusActionTypes.UPDATE_TEST_ATTESTATION_REQUEST):
            return state
                .set('isUpdatingTestAttestation', true)
                .set('isUpdatingTestAttestationSuccess', false)
                .set('isUpdatingTestAttestationFailure', false);

        case (VaccinationBonusActionTypes.UPDATE_TEST_ATTESTATION_SUCCESS):
            const updatedTestAttestation = (action.payload as GetTestAttestationResponse).attestations[0];
            return state
                .set('latestTest', testAtestationRecordFrom(updatedTestAttestation))
                .set('isUpdatingTestAttestation', false)
                .set('isUpdatingTestAttestationSuccess', true)
                .set('isUpdatingTestAttestationFailure', false);

        case (VaccinationBonusActionTypes.UPDATE_TEST_ATTESTATION_FAILURE):
            return state
                .set('isUpdatingTestAttestation', false)
                .set('isUpdatingTestAttestationSuccess', false)
                .set('isUpdatingTestAttestationFailure', true);

        // Covid Test Lite
        case (updateCovidTestLiteRecordAction.type.toString()):
            return state.set('covidTestLiteLatestRecord', action.payload as CovidTestLiteRecord);

        case (VaccinationBonusActionTypes.GET_COVID_TEST_LITE_REQUEST):
            return state
                .set('isGettingCovidTestLite', true)
                .set('isGettingCovidTestLiteSuccess', false)
                .set('isGettingCovidTestLiteFailure', false);

        case (VaccinationBonusActionTypes.GET_COVID_TEST_LITE_SUCCESS):
            const covidTestLiteResponse = action.payload as GetCovidTestLiteResponse;
            return state
                .set('covidTestLiteRecords', List(covidTestLiteResponse.testRecords.map((entry) => covidTestLiteRecordFrom(entry))))
                .set('isGettingCovidTestLite', false)
                .set('isGettingCovidTestLiteSuccess', true)
                .set('isGettingCovidTestLiteFailure', false);

        case (VaccinationBonusActionTypes.GET_COVID_TEST_LITE_FAILURE):
            return state
                .set('isGettingCovidTestLite', false)
                .set('isGettingCovidTestLiteSuccess', false)
                .set('isGettingCovidTestLiteFailure', true);

        case (VaccinationBonusActionTypes.SUBMIT_COVID_TEST_LITE_REQUEST):
            return state
                .set('isSubmittingCovidTestLite', true)
                .set('isSubmittingCovidTestLiteSuccess', false)
                .set('isSubmittingCovidTestLiteFailure', false);

        case (VaccinationBonusActionTypes.SUBMIT_COVID_TEST_LITE_SUCCESS):
            const submitCovidTestLiteResponse = action.payload as GetCovidTestLiteResponse;
            return state
                .set('covidTestLiteRecords', List(submitCovidTestLiteResponse.testRecords.map((entry) => covidTestLiteRecordFrom(entry))))
                .set('isSubmittingCovidTestLite', false)
                .set('isSubmittingCovidTestLiteSuccess', true)
                .set('isSubmittingCovidTestLiteFailure', false);

        case (VaccinationBonusActionTypes.SUBMIT_COVID_TEST_LITE_FAILURE):
            return state
                .set('isSubmittingCovidTestLite', false)
                .set('isSubmittingCovidTestLiteSuccess', false)
                .set('isSubmittingCovidTestLiteFailure', true);

        case (VaccinationBonusActionTypes.UPDATE_COVID_TEST_LITE_REQUEST):
            return state
                .set('isUpdatingCovidTestLite', true)
                .set('isUpdatingCovidTestLiteSuccess', false)
                .set('isUpdatingCovidTestLiteFailure', false);

        case (VaccinationBonusActionTypes.UPDATE_COVID_TEST_LITE_SUCCESS):
            const updatedCovidTestLite = (action.payload as GetCovidTestLiteResponse).testRecords[0];
            return state
                .set('covidTestLiteLatestRecord', covidTestLiteRecordFrom(updatedCovidTestLite))
                .set('isUpdatingCovidTestLite', false)
                .set('isUpdatingCovidTestLiteSuccess', true)
                .set('isUpdatingCovidTestLiteFailure', false);

        case (VaccinationBonusActionTypes.UPDATE_COVID_TEST_LITE_FAILURE):
            return state
                .set('isUpdatingCovidTestLite', false)
                .set('isUpdatingCovidTestLiteSuccess', false)
                .set('isUpdatingCovidTestLiteFailure', true);

        // Card Status per CardType
        case (VaccinationBonusActionTypes.GET_CARD_STATUS_REQUEST):
            const csPayloadReq = action.payload as GetCardStatusSuccessActionPayload;
            const [isGettingCSResponseReq, isGettingCSResponseSuccessReq, isGettingCSResponseFailureReq] = [
                new Map(state.get('isGettingCardStatusResponse')),
                new Map(state.get('isGettingCardStatusResponseSuccess')),
                new Map(state.get('isGettingCardStatusResponseFailure')),
            ];
            isGettingCSResponseReq.set(csPayloadReq.cardType, true);
            isGettingCSResponseSuccessReq.set(csPayloadReq.cardType, false);
            isGettingCSResponseFailureReq.set(csPayloadReq.cardType, false);
            return state
              .set('isGettingCardStatusResponse', isGettingCSResponseReq)
              .set('isGettingCardStatusResponseSuccess', isGettingCSResponseSuccessReq)
              .set('isGettingCardStatusResponseFailure', isGettingCSResponseFailureReq);

        case (VaccinationBonusActionTypes.GET_CARD_STATUS_SUCCESS):
            const csPayload = action.payload as GetCardStatusSuccessActionPayload;
            const [csResponse, isGettingCSResponse, isGettingCSResponseSuccess, isGettingCSResponseFailure] = [
                new Map(state.get('cardStatusResponse')),
                new Map(state.get('isGettingCardStatusResponse')),
                new Map(state.get('isGettingCardStatusResponseSuccess')),
                new Map(state.get('isGettingCardStatusResponseFailure')),
            ];
            csResponse.set(csPayload.cardType, csPayload.response);
            isGettingCSResponse.set(csPayload.cardType, false);
            isGettingCSResponseSuccess.set(csPayload.cardType, true);
            isGettingCSResponseFailure.set(csPayload.cardType, false);
            return state
              .set('cardStatusResponse', csResponse)
              .set('isGettingCardStatusResponse', isGettingCSResponse)
              .set('isGettingCardStatusResponseSuccess', isGettingCSResponseSuccess)
              .set('isGettingCardStatusResponseFailure', isGettingCSResponseFailure);

        case (VaccinationBonusActionTypes.GET_CARD_STATUS_FAILURE):
            const csPayloadFail = (action as GetCardStatusFailureAction).payload;
            const [isGettingCSResponseFail, isGettingCSResponseSuccessFail, isGettingCSResponseFailureFail] = [
                new Map(state.get('isGettingCardStatusResponse')),
                new Map(state.get('isGettingCardStatusResponseSuccess')),
                new Map(state.get('isGettingCardStatusResponseFailure')),
            ];
            isGettingCSResponseFail.set(csPayloadFail, false);
            isGettingCSResponseSuccessFail.set(csPayloadFail, false);
            isGettingCSResponseFailureFail.set(csPayloadFail, true);
            return state
              .set('isGettingCardStatusResponse', isGettingCSResponseFail)
              .set('isGettingCardStatusResponseSuccess', isGettingCSResponseSuccessFail)
              .set('isGettingCardStatusResponseFailure', isGettingCSResponseFailureFail);

        default: return state;
    }
};
