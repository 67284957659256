import { Record } from 'immutable';
import { VaccineTypeRecord } from './vaccineTypeRecord';
import { DateTime } from 'luxon';
import { DateValidationResult } from './DateValidationResult';

const defaultVaccinationBonusRecord = {
  vaccinationDate: DateTime.invalid('default'),
  vaccinationDateWarning: false,
  vaccinationDateValidationResult: DateValidationResult.Valid,
  vaccinationDateBeforeHire: false,
  vaccinationDose: 0,
  vaccineName: VaccineTypeRecord.UNKNOWN,
  vaccineType: new VaccineTypeRecord(),
  vaccineTypeTouched: false,
  vaccineTypeWarning: false,
  onsite: null,
  noBenefits: false,
  duringShift: null,
  createdAt: DateTime.invalid('default'),
  onSiteWarning: false,
  duringShiftWarning: false,
  disclaimerConfirmed: false,
  disclaimerConfirmedWarning: false,
  isEdit: false,
  isBooster: false,
  updatedAt: DateTime.invalid('default'),
};

type VaccinationBonusRecordType = {
  vaccinationDate: DateTime;
  vaccinationDateWarning: boolean;
  vaccinationDateValidationResult: DateValidationResult;
  vaccinationDateBeforeHire: boolean;
  vaccinationDose: number;
  vaccineName: string;
  vaccineType: VaccineTypeRecord;
  vaccineTypeTouched: boolean;
  vaccineTypeWarning: boolean;
  onsite: boolean | null;
  noBenefits: boolean;
  duringShift: boolean | null;
  createdAt: DateTime;
  onSiteWarning: boolean;
  duringShiftWarning: boolean;
  disclaimerConfirmed: boolean;
  disclaimerConfirmedWarning: boolean;
  isEdit: boolean;
  isBooster: boolean;
  updatedAt: DateTime;
};

export class VaccinationBonusRecord extends Record<VaccinationBonusRecordType>(defaultVaccinationBonusRecord) {
  public isComplete(): boolean {
    return this.vaccineType.isValid() && this.isDateValid();
  }

  public isDateValid() {
    return this.vaccinationDate.isValid;
  }

  public isDisclaimerConfirmed() {
    return this.disclaimerConfirmed;
  }

  public isVaccineTypeValid() {
    return this.vaccineType.isValid();
  }

  public isOnSiteValid() {
    return this.onsite !== null;
  }

  public isDuringShiftValid() {
    return this.duringShift !== null;
  }

  public setVaccinationDate(date: string) {
    return this.set('vaccinationDate', DateTime.fromISO(date))
      .set('vaccinationDateWarning', false)
      .set('vaccinationDateValidationResult', DateValidationResult.Valid)
      .set('vaccinationDateBeforeHire', false);
  }

  public setVaccineType(vaccineType: VaccineTypeRecord, vaccineName?: string) {
    return this.set('vaccineType', vaccineType)
      .set('vaccineName', vaccineName || vaccineType.enumValue)
      .set('vaccineTypeTouched', true)
      .set('vaccineTypeWarning', false);
  }

  public setOnSite(value: boolean) {
    return this.set('onsite', value).set('onSiteWarning', false);
  }

  public setDuringShift(value: boolean) {
    return this.set('duringShift', value).set('duringShiftWarning', false);
  }

  validate(dateValidator: {(date: DateTime): DateValidationResult}, canSelectOnOffSite: boolean, canSelectDuringShift: boolean) {
    let isValid = true;
    let next = this;
    if (!this.isDateValid()) {
      isValid = false;
      next = next.set('vaccinationDateWarning', true);
    } else {
      const validatorResult = dateValidator(this.vaccinationDate);
      if (validatorResult !== DateValidationResult.Valid) {
        isValid = false;
        next = next.set('vaccinationDateValidationResult', validatorResult);
      }
    }
    if (!this.isVaccineTypeValid()) {
      isValid = false;
      next = next.set('vaccineTypeWarning', true);
    }
    if (canSelectOnOffSite && !this.isOnSiteValid()) {
      isValid = false;
      next = next.set('onSiteWarning', true);
    }
    if (canSelectDuringShift && !this.isDuringShiftValid()) {
      isValid = false;
      next = next.set('duringShiftWarning', true);
    }

    return { isValid, next };
  }
}
