import React, { FC, useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { VaccinationFeatures } from 'src/apps/vaccination/constant/vaccinationFeatures';
import { useEmployeeContext } from 'src/contexts/EmployeeInfoContext';
import { isPopstarFeatureEnabled } from 'src/models/EmployeeInfoRecord';
import { ROUTES } from 'src/routes';
import { Unauthorized } from 'src/components/__shared__/error/Unauthorized';
import { NoMatch } from 'src/components/NoMatch';
import { Forbidden } from 'src/components/__shared__/error/Forbidden';
import { RequestVaccinationBonusContainerWithRouter } from '../apps/vaccination/containers/vaccination/vaccinationBonusContainer';
import { useFeatureState } from '../redux/reducers/featureReducer';
import { LoadingIndicator, LoadingIndicatorTheme } from '@atoz/atoz-common-ui-components';
import { useTimezoneState } from 'src/redux/reducers/timezoneReducer';
import { LoggerContext } from 'src/logger';
import { METRIC_NAME, metricsPublisher } from 'src/metrics';
import i18n from 'src/i18n';
import { NAMESPACE } from 'src/consts';

let mountMetricEmited = false;

export const App: FC = () => {
  if (!mountMetricEmited) {
    mountMetricEmited = true;
    // emit metric about first time we mounted
    // note that App() will be invoked multiple times - once for every state change
    // but we emit metric only for the first time
    // this is different than using useEffect() because that would give us after rendering is done
    metricsPublisher.emitLatencyMetric(METRIC_NAME.APP_MOUNT, window?.performance?.now());
  }

  const { isLoading } = useFeatureState();
  const { isLoadingTimeZone, loadTimeZoneFailed } = useTimezoneState();
  const employee = useEmployeeContext();

  useEffect(() => {
    document.title = i18n.t('page_title');
  }, [])
  

  if (isLoading || isLoadingTimeZone) {
    return <LoadingIndicator theme={LoadingIndicatorTheme.DARK} />;
  }

  const loggerContext = useContext(LoggerContext);
  if (loadTimeZoneFailed) {
    loggerContext.logger.warn("Failed to load timezone! Continuing anyway with browser's timezone");
  }

  const vaccinationBonusEnabled = isPopstarFeatureEnabled(employee, VaccinationFeatures.VACCINATION_BONUS) || isPopstarFeatureEnabled(employee, VaccinationFeatures.VACCINATION_BONUS_NO_BENEFITS) || isPopstarFeatureEnabled(employee, VaccinationFeatures.VACCINATION_APP);

  return (
    <div data-pxt-telemetry-namespace={NAMESPACE}>
      <Switch>
        <Route path={ROUTES.VACCINATION.path} component={vaccinationBonusEnabled ? RequestVaccinationBonusContainerWithRouter : Forbidden} />
        <Route path={ROUTES.UNAUTHORIZED.path} component={Unauthorized} />
        <Route component={NoMatch} />
      </Switch>
    </div>
  )
}

export default App;
