import { DateTime } from 'luxon';
import { GetVaccinationCountryConfigResponse } from './getVaccinationCountryConfigResponse';

export enum SiteSetting { AVAILABLE = 'AVAILABLE', DISABLED = 'DISABLED' };
export enum DefaultSetting { ONSITE = 'ONSITE', OFFSITE = 'OFFSITE', NONE = 'NONE' };

export class CountryConfig {
    onsiteSetting: SiteSetting;
    offsiteSetting: SiteSetting;
    defaultSetting: DefaultSetting;
    cardUploadStartDate: DateTime;
    resourcesLink?: string;
    persistFile: boolean;
    returnToWorkDaliLink?: string;
    returnToWorkIsolationDays?: number;
    testCollectionEarliestDaysAgo?: number;
    testResultEarliestDaysAgo?: number;
    covidTestLiteCollectionEarliestDaysAgo?: number;

    public static fromBackend(response: GetVaccinationCountryConfigResponse): CountryConfig {
        return {
            onsiteSetting: SiteSetting[response.configs.vaccinationLocationPreferences.onsiteSetting] || SiteSetting.AVAILABLE,
            offsiteSetting: SiteSetting[response.configs.vaccinationLocationPreferences.offsiteSetting] || SiteSetting.AVAILABLE,
            defaultSetting: DefaultSetting[response.configs.vaccinationLocationPreferences.defaultSetting] || DefaultSetting.NONE,
            cardUploadStartDate: response.configs.vaccinationLocationPreferences.cardUploadStartDate
                    ? DateTime.fromISO(response.configs.vaccinationLocationPreferences.cardUploadStartDate)
                    : DateTime.invalid('unspecified'),
            resourcesLink: response.configs.vaccinationLocationPreferences.resourcesLink,
            persistFile: response.configs.vaccinationLocationPreferences.persistFile ?? true,
            returnToWorkDaliLink: response.configs.vaccinationLocationPreferences.returnToWorkDaliLink,
            returnToWorkIsolationDays: response.configs.vaccinationLocationPreferences.returnToWorkIsolationDays,
            testCollectionEarliestDaysAgo: response.configs.vaccinationLocationPreferences.testCollectionEarliestDaysAgo,
            testResultEarliestDaysAgo: response.configs.vaccinationLocationPreferences.testResultEarliestDaysAgo,
            covidTestLiteCollectionEarliestDaysAgo: response.configs.vaccinationLocationPreferences.covidTestLiteCollectionEarliestDaysAgo,
        }
    };
}