import { Record, List } from 'immutable';
import { FeatureRecord } from './featureRecord';

const FeatureStateRecordDefault = {
    isLoading: true,
    features: List<FeatureRecord>()
};

type FeatureStateRecordType = {
    isLoading: boolean;
    features: List<FeatureRecord>;
};

export class FeatureStateRecord extends Record<FeatureStateRecordType>(FeatureStateRecordDefault) { }