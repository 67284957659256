import { Record, List } from 'immutable';

const TimezoneStateRecordDefault = {
    isLoadingTimeZone: true,
    loadTimeZoneFailed: false,
    timeZone: null,
};

type TimezoneStateRecordType = {
    isLoadingTimeZone: boolean,
    loadTimeZoneFailed: boolean,
    timeZone: string,
};

export class TimezoneStateRecord extends Record<TimezoneStateRecordType>(TimezoneStateRecordDefault) { }
