import { IconExternalLink } from '@amzn/stencil-react-components/icons';
import { Col, Hr, Spacer, View } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import { H3, H4, Text } from '@amzn/stencil-react-components/text';
import i18n from 'i18next';
import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';
import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action } from 'redux';
import {
  createGetVaccinationComplianceAction,
  createGetVaccinationCountryConfigAction,
  createUpdateTestAttestationAction,
} from '../../../actions/vaccinationBonusActions';
import { withClient } from 'src/contexts/clientContext';
import { ILoggerProps, withLogger } from 'src/logger';
import { ApiClientsProps } from 'src/models/ApiClients';
import { AppState } from 'src/models/appState';
import { CountryConfig } from '../../../models/vaccination/countryConfig';
import { PCRTestResult, TestAttestationRecord } from '../../../models/vaccination/testAttestationRecord';
import { ROUTES } from 'src/routes';
import { PrimaryButton, SecondaryButton } from '../../__shared__/button/Button';
import { dateDisplayOptions } from '../VaccinationBonusPage';
import { DEFAULT_ISOLATION_DAYS } from './Constants';
import { EmployeeInfo } from 'src/contexts/EmployeeInfoContext';
import { describeTelemetryClickEvent } from 'src/utils/telemetry/TelemetryHelper';

export interface TestAttestationPositiveResultProps extends RouteComponentProps, ILoggerProps, ApiClientsProps {
  countryConfig: CountryConfig;
  testCompliance: string;
  latestTest: TestAttestationRecord;
  getVaccinationCountryConfig: () => void;
}

export interface TestAttestationPositiveResultState {
}

const initialState: TestAttestationPositiveResultState = {
};

export class _TestAttestationPositiveResult extends Component<TestAttestationPositiveResultProps, TestAttestationPositiveResultState> {
  static contextType = EmployeeInfo;

  state: TestAttestationPositiveResultState = initialState;

  componentDidMount() {
    if (this.props.latestTest.result !== PCRTestResult.POSITIVE || this.props.latestTest.returnToWorkDate) {
      this.props.history.push(ROUTES.VACCINATION.path);
    }
  }

  handleGoBackMainPage = () => {
    this.props.history.push(ROUTES.VACCINATION.path);
  };

  handleReturnToWork = () => {
    this.props.history.push(ROUTES.VACCINATION.TEST_ATTESTATION.RETURN_TO_WORK.path);
  };

  render() {
    const returnToWorkDate = this.toLocaleDateString(DateTime.fromISO(this.props.latestTest.returnToWorkDate));
    const isolationDays = this.props.countryConfig?.returnToWorkIsolationDays ?? DEFAULT_ISOLATION_DAYS;

    return (
      <div className="test-attestation-form">
        <View maxWidth="480px" minWidth="320px" height="100%" margin="0 auto" padding="20px 15px" backgroundColor="neutral0">
          <Col gridGap="S200">
            <H3 fontWeight="bold">
              {i18n.t('testAttestation.positiveResultForm.title')}
            </H3>

            {this.props.countryConfig?.returnToWorkDaliLink && <>
              <Col gridGap="S200">
                <H4 fontWeight="bold">{i18n.t('testAttestation.positiveResultForm.subTitle')}</H4>
                <Text>{i18n.t('testAttestation.positiveResultForm.text1')}</Text>
                <Text>{i18n.t('testAttestation.positiveResultForm.text2')}</Text>
                <Spacer height={10}/>
                <SecondaryButton onClick={() => { document.getElementById('dali-link')?.click(); }}>
                  <Link id={'dali-link'} href={this.props.countryConfig.returnToWorkDaliLink} target="_blank">
                    {i18n.t('testAttestation.positiveResultForm.daliButton')}
                  </Link>
                  <IconExternalLink title={i18n.t('testAttestation.positiveResultForm.daliButtonLinkAlt')} />
                </SecondaryButton>
              </Col>

              <Spacer height={20}/>
            </>}

            <Col gridGap="S200">
              <H4 fontWeight="bold">{i18n.t('testAttestation.positiveResultForm.rtwTitle')}</H4>
              <Text>
                {i18n.t('testAttestation.positiveResultForm.rtwText', {date: returnToWorkDate})}
              </Text>
              <Spacer height={10}/>
              <SecondaryButton
                isFullWidth={true}
                onClick={this.handleReturnToWork}
                data-omniture-link="Vaccination Bonus - Return to work"
                data-pxt-telemetry-events={describeTelemetryClickEvent('Vaccination Bonus - Return to work')}
              >
                <Text fontSize="T400">{i18n.t('testAttestation.positiveResultForm.rtwButton')}</Text>
              </SecondaryButton>
            </Col>

            <Spacer height={40}/>
            <Hr/>
            <Spacer height={10}/>

            <Col gridGap="S300">
              <Text>{i18n.t('testAttestation.positiveResultForm.backText')}</Text>
              <PrimaryButton onClick={this.handleGoBackMainPage}>
                {i18n.t('testAttestation.positiveResultForm.backButton')}
              </PrimaryButton>
            </Col>

          </Col>
        </View>
      </div>
    );
  }

  private toLocaleDateString = (dateTime: DateTime): string => {
    return dateTime.toLocaleString(dateDisplayOptions as DateTimeFormatOptions, { locale: this.context.locale });
  };
}

const mapStateToProps = (state: AppState) => ({
  countryConfig: state.vaccination.countryConfig,
  testCompliance: state.vaccination.testCompliance,
  latestTest: state.vaccination.latestTest,
} as TestAttestationPositiveResultProps);

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getVaccinationCountryConfig: () => dispatch(createGetVaccinationCountryConfigAction()),
} as TestAttestationPositiveResultProps);

export const TestAttestationPositiveResult =
  connect(mapStateToProps, mapDispatchToProps)(withClient(withLogger(withRouter(_TestAttestationPositiveResult))));
