import * as React from 'react';
import i18n from 'i18next';
import { getTranslationInfos, TranslationInfo } from 'src/utils/translations';

interface VaccinationBonusFAQProps {
  countryCode: string;
}

export class VaccinationBonusFAQ extends React.Component<VaccinationBonusFAQProps, {}> {
  render() {
    const questions = getTranslationInfos('vaccinationBonus_faq', this.props.countryCode, 'question');

    return (
      <div className="vaccination-bonus-page">
        <div className="background">
          <div className="title">{i18n.t('vaccinationBonus_title_faq')}</div>
          {questions.map(questionObject => this.renderQuestion(questionObject))}
          <br />
          {i18n.t('vaccinationBonus_faq_notAnswered')}
        </div>
      </div>
    );
  }

  private renderQuestion(questionInfo: TranslationInfo) {
    return (
      <div className="section" key={questionInfo.index}>
        <div className="faq-question">{questionInfo.translation}</div>
        <div
          className="faq-answer"
          dangerouslySetInnerHTML={{ __html: i18n.t(`${questionInfo.key}_answer`) }}
        />
      </div>
    );
  }
}
