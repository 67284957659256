import React, { Dispatch, FC, useState } from 'react';
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal';
import { Col, View } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { AppState } from '../../../../../models/appState';
import {
    createSubmitVaccinationSurveyAction,
    VaccinationBonusActions
} from '../../../actions/vaccinationBonusActions';
import { SubmitVaccinationSurveyRequest } from '../../../models/apiRequest/submitVaccinationSurveyRequest';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { PrimaryButton, SecondaryButton } from '../../__shared__/button/Button';
import { useEmployeeContext } from 'src/contexts/EmployeeInfoContext';

type Props = {
    submitVaccinationRequest: (request: SubmitVaccinationSurveyRequest) => {},
    isOpen: boolean,
    onCloseButtonClick: () => {},
    surveyResponseSubmitting: boolean,
    surveyResponseSubmittedSuccess: boolean,
    surveyResponseSubmittedFailure: boolean
};

export const _SurveyModal: FC<Props> = (props: Props) => {
    const FULL_VACCINATION_SURVEY = 'FULL_VACCINATION_SURVEY';
    const NOT_VACCINATED_PAYLOAD = JSON.stringify({data: 'No'});
    const employee = useEmployeeContext();

    const cancelClick = () => {
        props.onCloseButtonClick();
    }

    const submitClick = async () => {
        props.submitVaccinationRequest(
            new SubmitVaccinationSurveyRequest(employee.employeeId,
                FULL_VACCINATION_SURVEY,
                NOT_VACCINATED_PAYLOAD
            )
        );
        // props.onCloseButtonClick();
        // stay on page until survey will be sended
    }

    if (props.surveyResponseSubmittedSuccess || props.surveyResponseSubmittedFailure) {
        props.onCloseButtonClick();
    }

    return (
        <Modal close={() => props.onCloseButtonClick()} isOpen={props.isOpen}>
            <ModalContent
                titleText={i18n.t('vaccinationBonus.survey.reportTitle')}
                maxWidth="480px"
                buttons={[
                    <SecondaryButton key="1" onClick={cancelClick}>
                        {i18n.t('actions.cancel')}
                    </SecondaryButton>,
                    <PrimaryButton key="2" onClick={submitClick} disabled={props.surveyResponseSubmitting}>
                        {i18n.t('actions.submit')}
                    </PrimaryButton>
                ]}
                onCloseButtonClick={props.onCloseButtonClick}
            >
                <Col padding="-10px 0 0 0">
                    <Text fontSize="T400">{i18n.t('vaccinationBonus.survey.firstLine')}</Text>
                </Col>
                <Col padding="10px 0 0 0">
                    <Text fontSize="T400">{i18n.t('vaccinationBonus.survey.secondLine')}</Text>
                </Col>
            </ModalContent>
        </Modal>
    );
}
const mapState = ({ vaccination }: AppState) => ({
    surveyResponseSubmitting: vaccination.surveyResponseSubmitting,
    surveyResponseSubmittedSuccess: vaccination.surveyResponseSubmittedSuccess,
    surveyResponseSubmittedFailure: vaccination.surveyResponseSubmittedFailure
});

export const mapDispatch = (dispatch: Dispatch<VaccinationBonusActions>) => ({
    submitVaccinationRequest: (request: SubmitVaccinationSurveyRequest) => dispatch(createSubmitVaccinationSurveyAction(request)),
} as Props);

export const SurveyModal = connect(mapState, mapDispatch)(_SurveyModal);
